import { LoadingButton } from "@mui/lab";
import { Alert } from "@mui/material";
import React, { Fragment } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useBehaviourSubject } from "../../../hooks/use-observable";
import { sidebar$ } from "../../../state/sidebar";
import { FetchDmarcMemberRecord } from "../../../services/dmarc.service";
import { BrandService } from "../../../services/brand.service";
import { DmarcConfigurationModal } from "./dmarc-configuration-modal";

const DmarcConfiguration: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isDmarcConfigured, setIsDmarcConfigured] = React.useState<boolean>(true);
  const [menuStatus] = React.useState<boolean>(useBehaviourSubject(sidebar$) || true);
  const [data, setData] = React.useState<
    {
      member_id: number;
      member_name: string;
      member_configured: boolean;
      member_domains: {
        domain: string;
        rua_list: string[];
        suggested_record: string;
        dmarc_record: string;
      }[];
    }[]
  >([]);
  const selectedMemberIds = useBehaviourSubject<number[]>(BrandService.selectedMemberIds$) || [];
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(true);

  const fetchMemberDomainsRuaList = () => {
    setLoading(true);
    setIsDmarcConfigured(true);
    FetchDmarcMemberRecord()
      .then((res) => {
        setData(res.data);
        setIsDmarcConfigured(res.is_dmarc_configured);
      })
      .catch(() => {
        setData([]);
        setIsDmarcConfigured(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useDeepCompareEffect(fetchMemberDomainsRuaList, [selectedMemberIds]);

  return (
    <Fragment>
      {!isDmarcConfigured ? (
        <Alert
          severity="warning"
          action={
            <LoadingButton
              loading={loading}
              color="inherit"
              size="small"
              variant="outlined"
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Find out why
            </LoadingButton>
          }
          sx={{
            paddingLeft: menuStatus ? "236px !important" : "66px !important",
            transition: "all 0.6s",
          }}
        >
          You&apos;re currently using the platform with limited visibility.
        </Alert>
      ) : null}

      {isModalOpen && !loading && !isDmarcConfigured ? (
        <DmarcConfigurationModal
          isOpen={isModalOpen}
          handleCloseModal={() => {
            setIsModalOpen(false);
          }}
          handleRefresh={() => {
            fetchMemberDomainsRuaList();
          }}
          data={data}
          loading={loading}
        />
      ) : null}
    </Fragment>
  );
};

export default DmarcConfiguration;
