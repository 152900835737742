import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../App.interface";
import { AppDocView } from "../../views/app.doc.view";
import { AppAssetsSectionBox } from "../../views/app.section.box";

export const WebsiteOverView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  const [parsedDoc, setParsedDoc] = React.useState<IESDoc>(doc);
  useEffect(() => {
    setParsedDoc(() => ({
      ...doc,
    }));
  }, [doc]);
  return (
    <Fragment>
      <AppAssetsSectionBox title="Overview" type="domain">
        <AppDocView
          colDefs={[
            {
              masterColumnName: "web.url",
              masterColumnTitle: "URL",
              renderType: "url",
            },
            {
              masterColumnName: "value",
              masterColumnTitle: "Host",
              renderType: "url",
            },
            {
              masterColumnName: "resolved_ip",
              masterColumnTitle: "IP",
              renderType: "url",
            },
            {
              masterColumnName: "web.title",
              masterColumnTitle: "Website Title",
              renderType: "title",
            },
            {
              masterColumnName: "ip.open_ports",
              masterColumnTitle: "Open Ports",
              renderType: "tags",
            },
            {
              masterColumnName: "tags.ssl",
              masterColumnTitle: "SSL Certificate Status",
              renderType: "tags",
            },
            {
              masterColumnName: "ssl.supported_protocol_versions",
              masterColumnTitle: "Supported Protocol Version",
              renderType: "tags",
            },
            {
              masterColumnName: "meta.first_seen",
              masterColumnTitle: "First Seen",
              renderType: "date",
            },
            {
              masterColumnName: "meta.last_seen",
              masterColumnTitle: "Last Seen",
              renderType: "date",
            },
          ]}
          doc={parsedDoc}
        />
      </AppAssetsSectionBox>
    </Fragment>
  );
};
