import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../App.interface";
import { AppDocView } from "../../views/app.doc.view";
import { AppAssetsSectionBox } from "../../views/app.section.box";

export const SslCertificatesOverView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  const [parsedDoc, setParsedDoc] = React.useState<IESDoc>(doc);
  useEffect(() => {
    setParsedDoc(() => ({
      ...doc,
    }));
  }, [doc]);
  return (
    <Fragment>
      <AppAssetsSectionBox title="Overview" type="domain">
        <AppDocView
          colDefs={[
            {
              masterColumnName: "ssl.issued_date",
              masterColumnTitle: "Issued Date",
              renderType: "date",
            },
            {
              masterColumnName: "ssl.expiry_date",
              masterColumnTitle: "Expiry Date",
              renderType: "date",
            },
            {
              masterColumnName: "ssl.issued_to",
              masterColumnTitle: "Issued To",
              renderType: "text",
            },
            {
              masterColumnName: "ssl.issued_by",
              masterColumnTitle: "Issued By",
              renderType: "text",
            },

            {
              masterColumnName: "ssl.public_key",
              masterColumnTitle: "Public Key",
              renderType: "text",
            },
            {
              masterColumnName: "ssl.supported_protocol_versions",
              masterColumnTitle: "Protocol Versions",
              renderType: "tags",
            },
            {
              masterColumnName: "ssl.status",
              masterColumnTitle: "SSL Certificate Status",
              renderType: "tags",
            },
            {
              masterColumnName: "ssl.fingerprint_md5",
              masterColumnTitle: "Fingerprint MD5",
              renderType: "text",
            },
            {
              masterColumnName: "ssl.fingerprint_sha1",
              masterColumnTitle: "Fingerprint SHA1",
              renderType: "text",
            },
            {
              masterColumnName: "ssl.fingerprint_sha256",
              masterColumnTitle: "Fingerprint SHA256",
              renderType: "text",
            },
          ]}
          doc={parsedDoc}
        />
      </AppAssetsSectionBox>
    </Fragment>
  );
};
