import {
  Grid,
  Box,
  Typography,
  Button,
  Icon,
  Tooltip,
  Divider,
  Tab,
  Tabs,
  Paper,
  Dialog,
  DialogContent,
  Skeleton,
  Alert,
  Link,
} from "@mui/material";
import has from "lodash/has";
import React, { Fragment, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IDateFilterObj, IMasterColumns } from "../../../module.interface";
import { IESDoc } from "../../../../App.interface";
import { indepthTabs } from "./tabs";
import { DmarcAggregateCompliant } from "./views/compliant-chart";
import { setInitialDateRanges } from "../../../../util/date-range";
import { FetchAnalyticsEmailSources } from "../../../../services/dmarc.service";
import { toast } from "../../../../state/snackbar";
import { OfflineAppMasterTable } from "../../environment/domains/components/offline-table";
import { DmarcAggregateCategories } from "./views/aggregate-chart";
import { DmarcAggregateTopSources } from "./views/top-sources-chart";
import { DmarcAggregateEmails } from "./views/email-sources.chart";
import { DmarcAnalyticsSourceInfo } from "./views/source-info";

export const DmarcAggregateReportsIndex: React.FC<any> = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const [selectedTab, setSelectedTab] = React.useState<number>(
    indepthTabs.filter((f) => pathname?.startsWith?.(`/analytics${f.link}`))?.[0]?.category ||
      indepthTabs[0].category
  );
  const [categoryValue, setCategoryValue] = React.useState<string>(
    indepthTabs.filter((f) => pathname?.startsWith?.(`/analytics${f.link}`))?.[0]?.value ||
      indepthTabs[0].value
  );

  const [docs, setDocs] = React.useState<IESDoc[]>([]);

  const [sortField, setSortField] = React.useState("verify");
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("desc");
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  const [loading, setLoading] = React.useState(false);

  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc | undefined>(undefined);
  const [currentDateRange] = React.useState<IDateFilterObj>(setInitialDateRanges());

  const [description] = React.useState<any>({
    0: {
      title: "Global Data",
      description: "All the outbound emails sent from your domains",
    },
    1: {
      title: "Compliant",
      description: "Your genuine emails that are DMARC compliant (passing SPF and/or DKIM)",
    },
    2: {
      title: "Forwarded",
      description:
        "This is an informational category, filtering the emails that are automatically forwarded by your recipient (i.e., emails forwarded by a group address, or automated forwarding rules)",
    },
    3: {
      title: "Unauthenticated",
      description:
        "The illegitimate emails failing DMARC.\n• Spoof attempts\n• Unauthenticated legitimate sources\n• Forwarded emails altered by recipients",
    },
  });

  const colDefs: IMasterColumns[] = [
    {
      masterColumnName: "source_name",
      masterColumnTitle: "Source",
      renderType: "Title",
      filterable: true,
      sortable: true,
      cellRender: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"}>
            {params?.service_name != params?.source_name ? (
              <Tooltip title={params?.source_name}>{params?.service_name}</Tooltip>
            ) : (
              params?.service_name
            )}
            {params?.verify ? (
              <Icon style={{ fontSize: 22, margin: "0px 5px" }} color="primary">
                verified
              </Icon>
            ) : null}
          </Box>
        );
      },
    },
    {
      masterColumnName: "email_count",
      masterColumnTitle: "Email Volume",
      renderType: "number",
      filterable: true,
      sortable: true,
    },
    {
      masterColumnName: "ip_count",
      masterColumnTitle: "IP Count",
      renderType: "number",
      filterable: true,
      sortable: true,
    },
    {
      masterColumnName: "dmarc",
      masterColumnTitle: "DMARC",
      renderType: "risk_grade",
      filterable: true,
      sortable: true,
    },
    {
      masterColumnName: "dkim",
      masterColumnTitle: "DKIM",
      renderType: "risk_grade",
      filterable: true,
      sortable: true,
    },
    {
      masterColumnName: "spf",
      masterColumnTitle: "SPF",
      renderType: "risk_grade",
      filterable: true,
      sortable: true,
    },
  ];

  //data fetching
  const fetchSources = () => {
    setLoading(true);
    const tab = indepthTabs.filter((item) => item.category === selectedTab);
    const category = tab[0].value;
    FetchAnalyticsEmailSources(category)
      .then((response) => {
        setDocs(response.data);
      })
      .catch(() => {
        toast("Error happened whille fetching indepth sources", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(fetchSources, [selectedTab]);

  React.useEffect(() => {
    //construct URL when date, filters or tab changes

    const selectedTabLink = indepthTabs.filter((f) => f.category === selectedTab)?.[0]?.link;
    const selectedTabValue = indepthTabs.filter((f) => f.category === selectedTab)?.[0]?.value;
    push(`/dmarc/analytics${selectedTabLink}`);
    setCategoryValue(selectedTabValue);
  }, [selectedTab, push]);

  return (
    <Fragment>
      <Box
        className="indepth-container"
        sx={{
          position: "relative",
          width: "100%",
          margin: "auto",
          maxWidth: "1600px",
        }}
      >
        {/* header */}
        <Grid container alignItems="center">
          <Grid item sm={8} xs={12}>
            <Box className="app-module-container">
              <Box className="app-module-header">
                <Box className="header">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="h4" className="module-title" color="primary">
                        Aggregate Reports
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    variant="body2"
                    component="p"
                    color="textPrimary"
                    className="module-description"
                  >
                    Detailed view of your DMARC aggregate reports.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item sm={4} xs={12}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Tooltip title="Not available for freemium users">
                  <span>
                    <Button
                      className="action-btns range"
                      color="primary"
                      variant="outlined"
                      size="small"
                      disabled
                      startIcon={<Icon>lock</Icon>}
                    >
                      LAST 1 MONTH
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={(event, value) => {
              setSelectedTab(value);
            }}
            aria-label="cyberassets-table"
          >
            {indepthTabs.map((t) => (
              <Tab label={t.title} key={t.category} value={t.category} />
            ))}
          </Tabs>
          <Divider />

          <Box height={10} />
          {/* page content */}
          <Grid container spacing={2}>
            {/* content header */}
            <Grid item xs={12}>
              <Box sx={{ bgcolor: "background.paper", p: 3, boxShadow: 1 }}>
                <Typography sx={{ fontSize: 22, fontWeight: 600, color: "primary.main" }}>
                  {description[selectedTab]?.title}
                </Typography>
                <Typography sx={{ fontSize: 15, whiteSpace: "pre-line" }}>
                  {description[selectedTab]?.description}
                </Typography>
              </Box>
            </Grid>
            {/* charts (first chart, might change based on selected tab)*/}
            <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
              {categoryValue === "compliant" ? (
                <DmarcAggregateCompliant sourcesLoading={loading} />
              ) : (
                <DmarcAggregateCategories category={categoryValue} sourcesLoading={loading} />
              )}
            </Grid>
            {/* charts (second chart, might change based on selected tab)*/}
            <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
              {categoryValue === "unauthenticated" ? (
                <DmarcAggregateTopSources
                  category={categoryValue}
                  sourcesLoading={loading}
                  docs={docs}
                />
              ) : (
                <DmarcAggregateEmails
                  category={categoryValue}
                  sourcesLoading={loading}
                  isDetailsAllowed={true}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Alert
                severity="info"
                action={
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    component={Link}
                    href={`https://${
                      process.env.NODE_ENV === "development"
                        ? "cyno.edxdev.link"
                        : "start.ctm360.com"
                    }/pricing`}
                    target="_blank"
                  >
                    Upgrade Now
                  </Button>
                }
              >
                <strong>Restricted View</strong> &nbsp; &nbsp; The table below shows only your
                domain&apos;s data up to 5k monthly email volume. Upgrade your subscription to view
                all
              </Alert>
            </Grid>
            {/* records table */}
            <Grid item xs={12}>
              <Paper>
                {loading ? (
                  <Skeleton variant="rectangular" height={300} />
                ) : (
                  <Box sx={{ mt: -2 }}>
                    <OfflineAppMasterTable
                      loading={loading}
                      dataSource=""
                      name=""
                      title=""
                      colDefs={colDefs}
                      docs={docs}
                      totalDocs={docs.length}
                      pageIndex={pageIndex}
                      pageSize={pageSize}
                      sortField={sortField}
                      sortOrder={sortOrder}
                      onChangeSortField={setSortField}
                      onChangeSortOrder={setSortOrder}
                      onChangePageIndex={(page) => setPageIndex(page)}
                      onChangePageSize={setPageSize}
                      enablePagination={true}
                      enableCheckBox={false}
                      enableSelection={true}
                      enableBrandColum={false}
                      checkedDocs={[]}
                      onChangeCheckedDocs={() => null}
                      onChangeSelectedDoc={setSelectedDoc}
                      showBlur={false}
                    />
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </>

        {/* details */}
        {selectedDoc ? (
          <Dialog maxWidth="xl" fullWidth={true} open onClose={() => setSelectedDoc(undefined)}>
            <Box display={"flex"} alignItems={"flex-end"}>
              <Typography
                style={{ paddingLeft: 30, paddingTop: 30, fontWeight: 500, fontSize: 18 }}
              >
                {has(selectedDoc, "service_name") &&
                selectedDoc?.service_name != selectedDoc?.source_name
                  ? `${selectedDoc?.service_name} ( ${selectedDoc?.source_name} )`
                  : selectedDoc?.source_name}
              </Typography>
              {selectedDoc?.verify ? (
                <Icon
                  style={{ fontSize: 22, margin: "0px 5px", marginBottom: "2px" }}
                  color="primary"
                >
                  verified
                </Icon>
              ) : null}
            </Box>

            <Typography style={{ paddingLeft: 40, fontWeight: 500, fontSize: 14, color: "grey" }}>
              Source
            </Typography>
            <DialogContent>
              <DmarcAnalyticsSourceInfo
                doc={selectedDoc}
                masterFilters={[]}
                category={categoryValue}
                currentDateRange={currentDateRange}
              />
            </DialogContent>
          </Dialog>
        ) : null}
      </Box>
    </Fragment>
  );
};
