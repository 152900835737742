import {
  Alert,
  Box,
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import _ from "lodash";
import { IESDoc } from "../../../../App.interface";
import { IMasterColumns } from "../../../module.interface";
import { FetchDomainsList } from "../../../../services/dmarc.service";
import { toast } from "../../../../state/snackbar";
import { DomainHostTable } from "./components/domains-table";
import "../styles.scss";
import dummyDomains from "./assets/dummy-domains.png";
import { DmarcDomainsAdvancedView } from "./views/advanced.view";
import { DmarcDomainHistoricData } from "./views/historic-dns.view";
export const DmarcMainDomains: React.FC = () => {
  // states and variables
  const theme = useTheme();

  const [loading, setLoading] = React.useState<string[]>([]);

  const [filteredActiveDocs, setFilteredActiveDocs] = React.useState<IESDoc[]>([]);

  const [pageIndexVerified, setPageIndexVerified] = React.useState(0);
  const [pageSizeVerified, setPageSizeVerified] = React.useState(25);
  const [totalDocs, setTotalDocs] = React.useState(0);

  const [sortVerified, setSortVerified] = React.useState("active");
  const [sortOrderByVerified, setSortByVerified] = React.useState<"asc" | "desc">("desc");
  const [refresh, setRefresh] = React.useState(false);
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc | undefined>();
  const [selectedHistory, setSelectedHistory] = React.useState<any>(undefined);

  const [activeCheckedDocs, setActiveCheckedDocs] = React.useState<IESDoc[]>([]);

  const colDefs: IMasterColumns[] = [
    {
      masterColumnName: "hostname",
      masterColumnTitle: "Hostname",
      renderType: "text",
      cellRender: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"}>
            {params?.hostname}
            {(params?.email_volume !== 0 && params?.is_active) ||
            (params?.is_active && !params.sub_domain) ? (
              <Tooltip title="Sending domain">
                <Icon style={{ fontSize: 22, margin: "0px 5px" }} color="primary">
                  outgoing_mail
                </Icon>
              </Tooltip>
            ) : params?.email_volume === 0 && params?.is_active ? (
              <Tooltip title="Return-path">
                <Icon style={{ fontSize: 22, margin: "0px 5px" }} color="primary">
                  replay
                </Icon>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
    {
      masterColumnName: "is_active",
      masterColumnTitle: "Status",
      renderType: "text",
      cellRender: () => {
        return (
          <Box className={"active-card-table"}>
            <Box className={"active-name-table"}>Active</Box>
          </Box>
        );
      },
    },
    {
      masterColumnName: "email_volume",
      masterColumnTitle: "Email Volume",
      renderType: "number",
    },
    {
      masterColumnName: "deployment_progress",
      masterColumnTitle: "Deployment Progress",
      renderType: "text",
      cellRender: (params) => {
        return <Typography>{`${params.deployment_progress}%`}</Typography>;
      },
    },
  ];

  // fetch data
  const fetchHostnames = () => {
    setTotalDocs(0);

    setLoading((preVal) => [...preVal, "loading-hosts"]);
    FetchDomainsList()
      .then((res) => {
        const x = _.orderBy(res.data, "email_volume", ["desc"]);

        setFilteredActiveDocs(x);
        setTotalDocs(res.total);
      })
      .catch(() => {
        toast("Error happpened while fetching active hosts", "error");
      })
      .finally(() => {
        setLoading((preVal) =>
          _.remove(preVal, function (x) {
            x === "loading-hosts";
          })
        );
      });
  };

  useEffect(fetchHostnames, [refresh]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} marginTop={5}>
        <Box className="app-module-container">
          <Box className="app-module-header">
            <Box className="header">
              <Typography variant="h4" className="module-title" color="primary">
                Hosts
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textPrimary"
                className="module-description"
              >
                List of domains and subdomains, classified by their email activity status.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>

      {/* table */}
      <Grid item xs={12}>
        <Paper sx={{ paddingX: 1 }}>
          <Grid container justifyContent={"flex-end"}>
            <Grid item alignSelf={"center"} sx={{ mr: 1 }}>
              <IconButton
                onClick={() => {
                  setRefresh((v) => !v);
                }}
              >
                <Icon color="secondary">refresh</Icon>
              </IconButton>
            </Grid>
          </Grid>

          <Divider sx={{ mb: "-16px" }} />

          <DomainHostTable
            loading={_.includes(loading, "loading-hosts")}
            dataSource=""
            name=""
            title=""
            colDefs={colDefs}
            docs={filteredActiveDocs}
            totalDocs={totalDocs}
            pageIndex={pageIndexVerified}
            pageSize={pageSizeVerified}
            sortField={sortVerified}
            sortOrder={sortOrderByVerified}
            onChangeSortField={setSortVerified}
            onChangeSortOrder={setSortByVerified}
            onChangePageIndex={setPageIndexVerified}
            onChangePageSize={setPageSizeVerified}
            enablePagination={false}
            enableCheckBox={false}
            enableSelection={true}
            enableBrandColum={false}
            enableSwitch={false}
            historicalDNS={true}
            disabledSwitch={true}
            switchTitle={"Active"}
            onSelectHistory={setSelectedHistory}
            checkedDocs={activeCheckedDocs}
            onChangeCheckedDocs={setActiveCheckedDocs}
            onChangeSelectedDoc={setSelectedDoc}
          />
          {!_.includes(loading, "loading-hosts") ? (
            <Box sx={{ width: "100%", height: 600, userSelect: "none", position: "relative" }}>
              <img
                src={dummyDomains}
                width={"100%"}
                height={600}
                style={{ marginTop: "-20px", objectFit: "fill" }}
                className="domains-dummy-img"
              />
              <Paper
                elevation={5}
                sx={{
                  position: "absolute",
                  top: "25%",
                  right: "40%",

                  width: 450,
                  height: 240,
                  backgroundColor: theme.palette.background.default,
                  zIndex: 20,
                  padding: 3,
                }}
              >
                <Stack spacing={1}>
                  <Typography sx={{ fontSize: 20, fontWeight: 700 }} color="primary">
                    Get full visibility over your list of domains
                  </Typography>

                  <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                    Upgrade your plan to get the full list of your domains and subdomains.
                  </Typography>

                  <Box sx={{ paddingTop: 5 }}>
                    <Grid container justifyContent={"center"}>
                      <Grid item>
                        <Alert severity="warning">
                          The view is restricted to the Freemium plan
                        </Alert>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </Paper>
            </Box>
          ) : null}
        </Paper>

        {/* Right drawer */}
        {selectedDoc ? (
          <DmarcDomainsAdvancedView onClose={() => setSelectedDoc(undefined)} item={selectedDoc} />
        ) : null}
        {_.isUndefined(selectedHistory) ? null : (
          <DmarcDomainHistoricData
            doc={selectedHistory}
            onClose={() => setSelectedHistory(undefined)}
          />
        )}
      </Grid>
    </Grid>
  );
};
