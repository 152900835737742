import { Chip, Typography } from "@mui/material";
import startCase from "lodash/startCase";

import React, { Fragment } from "react";
import { IncidentStatusFormatted, IncidentCoaFormatted } from "../../util/incident.formatters";
interface IButtonFilterSelected {
  name: string;
  title: string;
  selection: string[];
  onSelect: (e: any, name: string) => void;
  onDelete: (options: string[]) => void;
}
export const ButtonFilterSelected: React.FC<IButtonFilterSelected> = ({
  onSelect,
  selection,
  onDelete,
  title,
  name,
}) => {
  const formatValue = (value: string): string => {
    switch (name) {
      case "incident_status":
      case "status":
        return IncidentStatusFormatted(value);
      case "incident_coa":
        return IncidentCoaFormatted(value);
      default:
        return startCase(value);
    }
  };
  const formatMultiple = (): string => {
    switch (name) {
      case "incident_status":
      case "status":
        return `${selection.length} Status`;
      case "incident_coa":
        return `${selection.length} Actions`;
      default:
        return `${selection.length} ${title}s`;
    }
  };
  return (
    <Fragment>
      <Typography variant="subtitle1" className="button-filter-label" color="textPrimary">
        {title}
      </Typography>
      {selection.length === 1 ? (
        <Chip
          color="secondary"
          label={formatValue(selection[0])}
          onClick={(e) => onSelect(e, name)}
          className="filter-value-chip"
          onDelete={() => onDelete([])}
        />
      ) : (
        <Chip
          color="secondary"
          className="filter-value-chip"
          label={formatMultiple()}
          onClick={(e) => onSelect(e, name)}
          onDelete={() => onDelete([])}
        />
      )}
    </Fragment>
  );
};
