import { Box, Divider, Grid, Paper, Stack, Typography, alpha, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useBehaviourSubject } from "../../../../../hooks/use-observable";
import { BrandService } from "../../../../../services/brand.service";
import { toast } from "../../../../../state/snackbar";
import { KFormatter } from "../../../../../util/k_formatter";
import { FetchAnalyticsEmailsAuthScores } from "../../../../../services/dmarc.service";
import { DmarcProgressBarChart } from "../../../summary/dashboard/components/progress-bar";
import { PaperTiledWithIcon } from "../components/tiled-paper-icon";

interface Type {
  category: string;
  sourcesLoading: boolean;
  isDefault?: boolean;
  isDetailsAllowed?: boolean;
}
interface IAuthData {
  label: string;
  count: number;
}

interface ITileData {
  label: string;
  count: number;
  color:
    | "primary"
    | "secondary"
    | "info"
    | "disabled"
    | "action"
    | "inherit"
    | "error"
    | "success"
    | "warning";
  bgColor: string;
  icon: string;
  titleColor: string;
}

export const DmarcAggregateEmails: React.FC<Type> = ({
  category,
  sourcesLoading,
  isDefault,
  isDetailsAllowed,
}) => {
  const theme = useTheme();
  const errorBg = alpha(theme.palette.error.main, 0.2);
  const primaryBg = alpha(theme.palette.primary.main, 0.2);
  const warningBg = alpha(theme.palette.warning.main, 0.2);
  const successBg = alpha(theme.palette.success.main, 0.2);

  const [loading, setLoading] = React.useState(false);
  const [currentCategory, setCurrentCategory] = React.useState<string>(category);
  const selectedMemberIds = useBehaviourSubject<number[]>(BrandService.selectedMemberIds$) || [];
  const [data, setData] = React.useState<IAuthData[]>([
    {
      label: "DMARC",
      count: 0,
    },
    {
      label: "DKIM",
      count: 0,
    },
    {
      label: "SPF",
      count: 0,
    },
  ]);
  const [emailType, setEmailType] = React.useState<ITileData[]>([
    {
      label: "Total Emails",
      count: 0,
      icon: "email",
      color: "primary",
      bgColor: primaryBg,
      titleColor: theme.palette.primary.main,
    },

    {
      label: "Recieved",
      count: 0,
      icon: "check_circle_outline",
      color: "success",
      bgColor: successBg,
      titleColor: theme.palette.success.main,
    },
    {
      label: "Quarantined",
      count: 0,
      icon: "pause_circle",
      color: "warning",
      bgColor: warningBg,
      titleColor: theme.palette.warning.main,
    },
    {
      label: "Rejected",
      count: 0,
      icon: "block",
      color: "error",
      bgColor: errorBg,
      titleColor: theme.palette.error.main,
    },
  ]);

  const fetchEmailScores = () => {
    setLoading(true);

    FetchAnalyticsEmailsAuthScores(category)
      .then((res) => {
        setEmailType([
          {
            label: "Total Emails",
            count: res.total_emails,
            icon: "email",
            color: "primary",
            bgColor: primaryBg,
            titleColor: theme.palette.primary.main,
          },

          {
            label: "Recieved",
            count: res.recieved_emails,
            icon: "check_circle_outline",
            color: "success",
            bgColor: successBg,
            titleColor: theme.palette.success.main,
          },
          {
            label: "Quarantined",
            count: res.quarantined_emails,
            icon: "pause_circle",
            color: "warning",
            bgColor: warningBg,
            titleColor: theme.palette.warning.main,
          },
          {
            label: "Rejected",
            count: res.rejected_emails,
            icon: "block",
            color: "error",
            bgColor: errorBg,
            titleColor: theme.palette.error.main,
          },
        ]);
        setData([
          {
            label: "DMARC",
            count: _.floor(res.dmarc),
          },
          {
            label: "DKIM",
            count: _.floor(res.dkim),
          },
          {
            label: "SPF",
            count: _.floor(res.spf),
          },
        ]);
      })
      .catch(() => {
        toast("Error fetching categories", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    setCurrentCategory(category);
  }, [category]);
  useDeepCompareEffect(fetchEmailScores, [currentCategory, selectedMemberIds]);

  return (
    <Fragment>
      <Stack height={"100%"} spacing={1} sx={{ minHeight: isDetailsAllowed ? "450px" : "320px" }}>
        <Paper
          style={{
            height: isDetailsAllowed ? "66%" : "50%",
            backgroundColor: isDefault
              ? theme.palette.background.default
              : theme.palette.background.paper,
          }}
        >
          <Grid container direction="column" wrap="nowrap">
            {isDetailsAllowed ? (
              <>
                <Grid item sx={{ paddingX: "8px" }}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent={"space-between"}
                    style={{ margin: "10px 12px" }}
                  >
                    <Grid item xs={8}>
                      <Typography color="primary" sx={{ fontSize: 13, fontWeight: 600 }}>
                        Email Compliance
                      </Typography>
                      <Typography color="textSecondary" sx={{ fontSize: 11 }}>
                        Your daily email compliance statistics
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  {" "}
                  <Divider />
                </Grid>
              </>
            ) : null}

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={1} sx={{ paddingTop: isDetailsAllowed ? 4 : 0 }}>
                {data.map((item) => {
                  return (
                    <Grid item lg={4} md={4} sm={4} xs={12} key={item.label}>
                      <Grid container justifyContent={"center"}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <DmarcProgressBarChart
                            title={item.label}
                            value={item.count}
                            loading={loading || sourcesLoading}
                            size={200}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          {/* ) : (
            <EmptyChart title={""} />
          )} */}
        </Paper>
        <Box style={{ height: isDetailsAllowed ? "34%" : "50%" }}>
          <Grid container display={"flex"} justifyContent="center" spacing={1}>
            {emailType.map((item, index) => (
              <Grid item lg={6} md={6} xs={6} key={`${index}__${item.label}`}>
                <PaperTiledWithIcon
                  title={item.label}
                  value={
                    isDetailsAllowed ? KFormatter(item.count) : item.count.toLocaleString("en")
                  }
                  loading={loading || sourcesLoading}
                  color={item.color}
                  bgColor={item.bgColor}
                  icon={item.icon}
                  isDefault={isDefault ? true : false}
                  titleColor={item.titleColor}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </Fragment>
  );
};
