import { Box, Slider, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
interface IDrpAddons {
  executive: number;

  onChangeExecutive: (params: number) => void;
}
export const DrpAddons: React.FC<IDrpAddons> = ({
  executive,

  onChangeExecutive,
}) => {
  return (
    <Fragment>
      <Typography variant="subtitle1" color={"secondary"} marginBottom={2} fontWeight={600}>
        Digital Risk Protection
      </Typography>
      <Box padding={"4px 16px"}>
        <Box padding={"0px 8px"}>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
            marginBottom={1}
          >
            <Box>
              <Typography variant="subtitle1" fontSize={"14px"} fontWeight={600}>
                Executive Monitoring
              </Typography>
              <Typography variant="subtitle1" fontSize={"12px"} color={"textSecondary"}>
                Add more executive to the current plan
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontSize={"14px"}>
                <strong>
                  {`$${(2500).toLocaleString()}`}
                  <Typography
                    variant="subtitle2"
                    component={"span"}
                    fontWeight={600}
                    fontSize={"10px"}
                  >
                    /executive
                  </Typography>
                </strong>
              </Typography>
            </Box>
          </Stack>
          <Slider
            aria-label="TPRML1"
            value={executive}
            onChange={(e, v) => onChangeExecutive(v as number)}
            min={0}
            max={10}
            step={1}
            valueLabelDisplay={"auto"}
          />
        </Box>
      </Box>
    </Fragment>
  );
};
