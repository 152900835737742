import { Paper, Box, Typography, Divider, Stack } from "@mui/material";
import React, { Fragment } from "react";
import "../module.styles.scss";
interface IAppCard {
  name: string;
  title: string;
  description: string;
  color?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  variant?: "elevation" | "outlined";
  children?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
}
export const AppCard: React.FC<IAppCard> = ({
  description,
  name,
  title,
  children,
  color,
  variant,
  actions,
  className,
}) => {
  return (
    <Fragment>
      <Paper
        className={`dashboard-cards ${className || ""}`}
        id={`dashboard-card-${name}`}
        variant={variant ? variant : "elevation"}
      >
        <Box className="header">
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={1}
          >
            <Box>
              <Typography variant="h6" color={color ? color : "primary"} className="main-heading">
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary" className="sub-heading">
                {description}
              </Typography>
            </Box>
            {actions ? actions : null}
          </Stack>
        </Box>
        <Divider />
        <Box className="contents">{children}</Box>
      </Paper>
    </Fragment>
  );
};
