import { BehaviorSubject } from "rxjs";

export interface ISnackBar {
  open: boolean;
  message: string;
  severity: "error" | "warning" | "info" | "success";
}

export const $snackBar = new BehaviorSubject<ISnackBar>({
  open: false,
  message: "",
  severity: "info",
});

export const toast = (message: string, severity: ISnackBar["severity"] = "info") => {
  $snackBar.next({ open: true, message, severity });
};
