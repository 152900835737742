import {
  Box,
  Typography,
  List,
  ListItemIcon,
  ListItemText,
  Icon,
  ListItemButton,
  Link,
} from "@mui/material";
import React from "react";
import { Fragment } from "react";
import { useBehaviourSubject } from "../../hooks/use-observable";
import { user$ } from "../../state/user";

import { LogOffModel } from "../logout.model";
interface IAccessibility {
  onClose: () => void;
}

export const Accessibility: React.FC<IAccessibility> = ({ onClose }) => {
  const user = useBehaviourSubject(user$);

  const [logoutModel, setLogoutModel] = React.useState<boolean>(false);

  const onSignOut = () => {
    setLogoutModel(true);
  };
  const onCloseModel = () => {
    onClose();
    setLogoutModel(false);
  };
  return (
    <Fragment>
      <Box className="app-settings-item app-themes-wrapper">
        <Typography variant="h6" color="primary" className="title">
          Accessibility
        </Typography>
        <Box className="contents">
          <List aria-label="main mailbox folders" dense>
            <ListItemButton
              disableGutters
              LinkComponent={Link}
              href="https://platform.ctm360.com/start/profile/update?openAuthenticator=default"
              target="_blank"
            >
              <ListItemIcon>
                <Icon>qr_code</Icon>
              </ListItemIcon>
              <ListItemText primary="Enable 2 Factor Authentication" />
            </ListItemButton>
            <ListItemButton disableGutters onClick={onSignOut}>
              <ListItemIcon>
                <Icon>power_settings_new</Icon>
              </ListItemIcon>
              <ListItemText primary="Sign out" />
            </ListItemButton>
          </List>
        </Box>
      </Box>
      {logoutModel && user ? <LogOffModel user={user} onClose={onCloseModel} /> : null}
    </Fragment>
  );
};
