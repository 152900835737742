import { BehaviorSubject } from "rxjs";

export class SessionStorageBehaviorSubject<T> extends BehaviorSubject<T> {
  private propName: string;

  constructor(propName: string, defaultValue?: T) {
    const localStorageValue = JSON.parse(sessionStorage.getItem(propName) || "null");
    super(localStorageValue !== null ? localStorageValue : defaultValue);
    this.propName = propName;
  }

  public next(value: T) {
    sessionStorage.setItem(this.propName, JSON.stringify(value));
    super.next(value);
  }
}
