import {
  TextField,
  CircularProgress,
  Chip,
  Box,
  Button,
  Typography,
  Grid,
  Icon,
  Tooltip,
  Stack,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useCallback, Fragment } from "react";
import { Brand } from "../App.interface";
import { useBehaviourSubject, useObservable } from "../hooks/use-observable";
import { BrandService } from "../services/brand.service";
import { fetchAllOrganizations } from "../services/query.service";
import { toast } from "../state/snackbar";
import useDeepCompareEffect from "use-deep-compare-effect";
import { userPerformAction } from "../util/user.activity.fingerprint";
import { user$ } from "../state/user";
import { SelectedMemberId } from "../util/current.member.id";
import { findParentName } from "../util/find.parent";
import { noBrands$ } from "./brand.empty";
export const BrandSelector: React.FC = () => {
  const user = useBehaviourSubject(user$);
  const loadingBrands = useBehaviourSubject(BrandService.loadingBrands$);
  const emptyBrandSelection = useBehaviourSubject(noBrands$);
  const allBrandSelection = useObservable(BrandService.allBrands$) || [];
  const selectedMemberIds = useBehaviourSubject<number[]>(BrandService.selectedMemberIds$) || [];
  const [loading, setLoading] = React.useState<boolean>(false);
  const [initializedBrand, setInitializedBrand] = React.useState<boolean>(false);

  const [allBrands, setAllBrands] = React.useState<Brand[]>(
    useObservable(BrandService.allBrands$) || []
  );
  const [selectedBrand, setSelectedBrand] = React.useState<Brand[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const onChangeBrand = (event: any, value: Brand[]) => {
    setSelectedBrand(value);
    localStorage.setItem("brand_selection", JSON.stringify(value.map((x) => x.memberId)));
    BrandService.selectedMemberIds$.next(value.map((x) => x.memberId));
  };
  const sortOrganizations = (org: Brand[]): Brand[] => {
    const parents = org.filter((f) => f.memberId === f.parent_organization);
    const childrens = org.filter((f) => f.memberId !== f.parent_organization);
    return _.sortBy(_.uniqWith([...parents, ...childrens], _.isEqual), (sort) => sort.parent_name);
  };
  const onSearchOrganization = (searchQuery: string) => {
    setLoading(true);
    fetchAllOrganizations(searchQuery)
      .then((response) => {
        if (response.data.length) {
          BrandService.setAllBrands(response.data);
          setAllBrands(
            response.data.map((x) => ({
              brandName: x.brand,
              isPotential:
                x.potential_client === "1" ||
                x.potential_client === 1 ||
                x.potential_client === true,
              memberId: x.member_id,
              parent_organization: x.parent_organization,
              parent_name: findParentName(response.data, x.parent_organization),
              status: x.status,
            }))
          );
        }
      })
      .catch(() => {
        toast("Error in fetching organization", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const selectAllChildren = (parent: string) => {
    const children = allBrands.filter((f) => f.brandName === parent).map((x) => x.memberId)[0];
    if (children) {
      const childs = allBrands.filter((f) => f.parent_organization === children);
      onChangeBrand(null, childs);
    }
  };
  // Set the search query for searching
  useEffect(() => {
    if (searchQuery) {
      onSearchOrganization(searchQuery);
    }
  }, [searchQuery]);
  // Set preselected brands

  useDeepCompareEffect(() => {
    if (!_.isEmpty(allBrands)) {
      const currentSelection = allBrands.filter((f) => _.includes(selectedMemberIds, f.memberId));
      if (!initializedBrand) {
        setInitializedBrand(true);
        setSelectedBrand(currentSelection);
      }
    }
  }, [selectedMemberIds, allBrands, initializedBrand]);
  useDeepCompareEffect(() => {
    if (selectedMemberIds.length) {
      noBrands$.next(false);
    }
  }, [selectedMemberIds]);

  // Setting all brands form Observable to a state variable
  useDeepCompareEffect(() => {
    if (allBrandSelection.length) {
      setAllBrands(allBrandSelection);
    }
  }, [allBrandSelection]);
  // Log the change of brand filter
  useDeepCompareEffect(() => {
    if (selectedBrand.length) {
      userPerformAction(
        "brand_filter",
        "FILTER",
        selectedBrand.map((x) => ({ field: "brand", value: x.brandName }))
      );
    }
  }, [selectedBrand]);
  // saving sessions before tab close
  const saveSelectedBrand = () => {
    const selectedIds = SelectedMemberId();
    localStorage.setItem("brand_selection", JSON.stringify(selectedIds));
  };
  const reSetSelectedBrand = useCallback(() => {
    const selectedIds = SelectedMemberId();
    if (
      _.includes(
        selectedIds.map((x) => x.toString()),
        "-1"
      )
    ) {
      setSelectedBrand([
        {
          brandName: "All Clients",
          isPotential: false,
          memberId: -1,
          parent_name: "All Clients",
          parent_organization: -1,
          status: "",
        },
      ]);
    }
    if (!_.isEmpty(allBrands)) {
      const currentSelection = allBrands.filter((f) => _.includes(selectedIds, f.memberId));
      if (!initializedBrand) {
        setInitializedBrand(true);
        setSelectedBrand(currentSelection);
        BrandService.selectedMemberIds$.next(selectedIds.map((x) => x));
      }
    }
  }, [allBrands, initializedBrand]);
  useEffect(() => {
    window.addEventListener("beforeunload", saveSelectedBrand);
    return () => {
      window.removeEventListener("beforeunload", saveSelectedBrand);
    };
  }, []);
  useEffect(() => {
    reSetSelectedBrand();
  }, [reSetSelectedBrand]);
  return (
    <Fragment>
      {loadingBrands ? null : (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {_.isEmpty(selectedBrand) ? (
            <Box>
              <Tooltip title={"Currently viewing data for CTM360"} placement="right" arrow>
                <Icon color="warning">info</Icon>
              </Tooltip>
            </Box>
          ) : null}
          <Box
            className={`organization-selector-container ${
              emptyBrandSelection ? "highlight-organization-selector" : ""
            }`}
          >
            <Autocomplete
              key="organizationSelector"
              id="hvOrganizationSelector"
              size="small"
              options={
                user && user.internal
                  ? [
                      {
                        brandName: "All Clients",
                        isPotential: false,
                        memberId: -1,
                        parent_name: "All Clients",
                        parent_organization: -1,
                        status: "",
                      },
                      ...sortOrganizations(allBrands),
                    ]
                  : sortOrganizations(allBrands)
              }
              getOptionLabel={(op) => op.brandName}
              groupBy={(option) => option.parent_name}
              renderGroup={(group) => (
                <Box className="organization-group-wrapper" key={group.key}>
                  <Box className="organization-group-header">
                    <Box className="title">
                      <Typography variant="h6" color="primary" className="parent-name">
                        {group.group}
                      </Typography>
                    </Box>
                    <Box className="select-all-children">
                      <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        onClick={() => selectAllChildren(group.group)}
                      >
                        Select all
                      </Button>
                    </Box>
                  </Box>
                  <Box className="organizations-options">{group.children}</Box>
                </Box>
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.brandName}>
                  <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center">
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        component={"span"}
                        className="option-brand-name"
                      >
                        {option.brandName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {!option.isPotential ? (
                        <Tooltip title="Client">
                          <Icon fontSize="small" className="option-brand-icon" color="inherit">
                            verified
                          </Icon>
                        </Tooltip>
                      ) : null}
                    </Grid>
                  </Grid>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  key="organizationTextFieldKey"
                  id="organizationTextFieldKey"
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Organization"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingBrands || loading ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              renderTags={(v, getTagProps) => {
                const currentLength = v.length;
                const currentVisibility = v.filter((f, index) => index < 1);
                const remainingTags = currentLength - currentVisibility.length;

                return (
                  <Box className="render-tags-wrapper">
                    <Grid
                      container
                      alignItems={"center"}
                      wrap="nowrap"
                      justifyContent={"space-between"}
                    >
                      {currentVisibility.map((b, index) => (
                        <Grid item key={b.brandName}>
                          <Chip
                            label={b.brandName}
                            size="small"
                            variant="outlined"
                            {...getTagProps({ index })}
                            key={b.brandName}
                            className="organization-selected-tag"
                          />
                        </Grid>
                      ))}
                      {remainingTags ? (
                        <Grid>
                          <Typography
                            variant="subtitle1"
                            component={"span"}
                            color="secondary"
                            className="selected-offset-label"
                          >
                            +{remainingTags}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                );
              }}
              onInputChange={_.debounce((event, value) => setSearchQuery(value), 300)}
              disableCloseOnSelect
              noOptionsText="Organization not found"
              multiple
              limitTags={1}
              value={selectedBrand}
              onChange={onChangeBrand}
              ChipProps={{
                size: "small",
              }}
              loading={loading}
              loadingText="Loading..."
            />
          </Box>
        </Stack>
      )}
    </Fragment>
  );
};
