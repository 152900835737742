import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { DmarcMainDomains } from "./domains/domains.index";
import { DmarcDomainSourceDetails } from "./sources/sources.index";
import { DmarcIssuesController } from "./issues/issues.index";

export const EnvironmentController: React.FC = () => {
  return (
    <Fragment>
      <Switch>
        <Route path={"/dmarc/environment*"}>
          <Switch>
            <Route path={"/dmarc/environment/domains"}>
              <DmarcMainDomains />
            </Route>
            <Route path={"/dmarc/environment/emailsources*"}>
              <DmarcDomainSourceDetails />
            </Route>
            <Route path={"/dmarc/environment/issues*"}>
              <DmarcIssuesController />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </Fragment>
  );
};
