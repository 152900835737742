import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../App.interface";
import { FetchCyberAssets } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import _ from "lodash";
import {
  Alert,
  Box,
  Button,
  Grid,
  Icon,
  Link,
  Paper,
  Skeleton,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { RenderRiskGrade, RenderSeverityIssueCount } from "../../util/cell-render/renderers";
import { AppDrawer } from "../views/Drawer";
import { IMasterColumns } from "../module.interface";
import useDeepCompareEffect from "use-deep-compare-effect";
import { CyberAssetsAdvancedView } from "./cyber_assets_advanced_view";
import { AppBlurTable } from "../views/blur.table";
import { AppMD5Hash } from "../../util/md5.hash";
import { useObservable } from "../../hooks/use-observable";
import { BrandService } from "../../services/brand.service";
import { UUIDGenerator } from "../../util/uuid";
const generateDummyData = (params: IESDoc, pos: number): IESDoc => {
  const doc: IESDoc = {
    ...params,
    _id: AppMD5Hash(pos.toString()),
    dummy_data: true,
  };

  return doc;
};
const colDefs = (resource: string, theme: Theme): IMasterColumns[] => {
  switch (resource) {
    case "domains":
      return [
        {
          masterColumnName: "meta.last_seen",
          masterColumnTitle: "Last Updated",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "value",
          masterColumnTitle: "Domain",
          renderType: "url",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "ip_address_block",
          masterColumnTitle: "IP Addresses",
          renderType: "url_array_max_block",
          filterable: false,
          sortable: false,
        },
        {
          masterColumnName: "tags.domain",
          masterColumnTitle: "Type",
          renderType: "tags",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "issues_summary",
          masterColumnTitle: "Severe Issues",
          renderType: "severity_issue_count",
          filterable: true,
          sortable: true,
          cellRender: (params) => {
            return <span>{RenderSeverityIssueCount(params.issues_summary, theme)}</span>;
          },
        },
        {
          masterColumnName: "security_score",
          masterColumnTitle: "Security Grade",
          renderType: "risk_grade",
          cellRender: (params) => {
            return <span>{RenderRiskGrade(params.security_score, theme)}</span>;
          },
          filterable: true,
          sortable: true,
        },
      ];
    case "hosts":
      return [
        {
          masterColumnName: "value",
          masterColumnTitle: "Host",
          renderType: "url",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "ip_address_block",
          masterColumnTitle: "IP Addresses",
          renderType: "url_array_max_block",
          filterable: false,
          sortable: false,
        },
        {
          masterColumnName: "tags.host",
          masterColumnTitle: "Type",
          renderType: "host_type",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "issues_summary",
          masterColumnTitle: "Severe Issues",
          renderType: "severity_issue_count",
          filterable: false,
          sortable: false,
          cellRender: (params) => {
            return <span>{RenderSeverityIssueCount(params.issues_summary, theme)}</span>;
          },
        },
        {
          masterColumnName: "security_score",
          masterColumnTitle: "Security Grade",
          renderType: "risk_grade",
          filterable: true,
          sortable: true,
          cellRender: (params) => {
            return <span>{RenderRiskGrade(params.security_score, theme)}</span>;
          },
        },
      ];
    case "websites":
      return [
        {
          masterColumnName: "web.url",
          masterColumnTitle: "Website",
          renderType: "url",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "web.title",
          masterColumnTitle: "Title",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "ip.open_ports",
          masterColumnTitle: "Open Ports",
          renderType: "tags_array_max_block",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "web.status_code",
          masterColumnTitle: "Status Code",
          renderType: "number",
          filterable: true,
          sortable: false,
        },
      ];
    case "ip_addresses":
      return [
        {
          masterColumnName: "meta.last_seen",
          masterColumnTitle: "Last Seen",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "value",
          masterColumnTitle: "IP Address",
          renderType: "url",
          filterable: true,
          sortable: false,
        },
        {
          masterColumnName: "open_ports",
          masterColumnTitle: "Open Ports",
          renderType: "tags_array_max_block",
          filterable: true,
          sortable: true,
        },

        {
          masterColumnName: "whois.asn.name",
          masterColumnTitle: "Provider",
          renderType: "text",
          filterable: true,
          sortable: false,
        },
        {
          masterColumnName: "type",
          masterColumnTitle: "IP Type",
          renderType: "title",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "issues_summary",
          masterColumnTitle: "Severe Issues",
          renderType: "severity_issue_count",
          filterable: true,
          sortable: true,
          cellRender: (params) => {
            return <span>{RenderSeverityIssueCount(params.issues_summary, theme)}</span>;
          },
        },
        {
          masterColumnName: "security_score",
          masterColumnTitle: "Security Grade",
          renderType: "risk_grade",
          filterable: true,
          sortable: true,
          cellRender: (params) => {
            return <span>{RenderRiskGrade(params.security_score, theme)}</span>;
          },
        },
      ];
    case "ssl_certificates":
      return [
        {
          masterColumnName: "ssl.issued_to",
          masterColumnTitle: "Issued To",
          renderType: "url",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "ssl.issued_by",
          masterColumnTitle: "Issued By",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "ssl.issued_date",
          masterColumnTitle: "Issued Date",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "ssl.expiry_date",
          masterColumnTitle: "Expiry Date",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "ssl.public_key",
          masterColumnTitle: "Public Key",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "ssl.supported_protocol_versions",
          masterColumnTitle: "Supported Protocol",
          renderType: "array",
          filterable: true,
          sortable: true,
        },
      ];
    case "ip_ranges":
      return [
        {
          masterColumnName: "value",
          masterColumnTitle: "IP Range",
          filterable: true,
          sortable: true,
          renderType: "text",
        },
        {
          masterColumnName: "cidr",
          masterColumnTitle: "CIDR",
          filterable: true,
          sortable: true,
          renderType: "text",
        },
        {
          masterColumnName: "whois.org",
          masterColumnTitle: "Organization",
          filterable: true,
          sortable: false,
          renderType: "text",
        },
      ];
    case "open_ports":
      return [
        {
          masterColumnName: "port",
          masterColumnTitle: "Port",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "ip",
          masterColumnTitle: "IP Address",
          renderType: "url",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "ip_type",
          masterColumnTitle: "IP Type",
          renderType: "title",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "product",
          masterColumnTitle: "Services / Product",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "meta.last_seen",
          masterColumnTitle: "Last Seen",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "tags",
          masterColumnTitle: "Tags",
          renderType: "tags",
          filterable: true,
          sortable: true,
        },
      ];
    case "bin_numbers":
      return [
        {
          masterColumnName: "@timestamp",
          masterColumnTitle: "First Seen",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "bin",
          masterColumnTitle: "BIN Number",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "card_level",
          masterColumnTitle: "Card Level",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "vendor",
          masterColumnTitle: "Vendor",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
      ];
    case "social_media":
      return [
        {
          masterColumnName: "url",
          masterColumnTitle: "URL",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "title",
          masterColumnTitle: "Title",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "platform",
          masterColumnTitle: "Platform",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
      ];
    case "mobile_apps":
      return [
        {
          masterColumnName: "first_seen",
          masterColumnTitle: "Detected On",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "app_name",
          masterColumnTitle: "App Name",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "url",
          masterColumnTitle: "URL",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "publisher",
          masterColumnTitle: "Publisher",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "store",
          masterColumnTitle: "Store",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
      ];
    case "third_party_content":
      return [
        {
          masterColumnName: "first_seen",
          masterColumnTitle: "Detected On",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "title",
          masterColumnTitle: "Title",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "url",
          masterColumnTitle: "URL",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
      ];
    case "executive_management":
      return [
        {
          masterColumnName: "first_seen",
          masterColumnTitle: "Detected On",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "executive_name",
          masterColumnTitle: "Executive Name",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "designation",
          masterColumnTitle: "Designation",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
      ];
    case "code_repositories":
      return [
        {
          masterColumnName: "first_seen",
          masterColumnTitle: "Detected On",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "name",
          masterColumnTitle: "Name",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "url",
          masterColumnTitle: "URL",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "type",
          masterColumnTitle: "Type",
          renderType: "text",
          filterable: true,
          sortable: true,
        },
      ];
    default:
      return [
        {
          masterColumnName: "meta.last_seen",
          masterColumnTitle: "Last Updated",
          renderType: "date",
          filterable: true,
          sortable: true,
        },
        {
          masterColumnName: "value",
          masterColumnTitle: "Domain",
          renderType: "url",
          filterable: true,
          sortable: true,
        },

        {
          masterColumnName: "security_score",
          masterColumnTitle: "Security Grade",
          renderType: "risk_grade",
          cellRender: (params) => {
            return <span>{RenderRiskGrade(params.security_score, theme)}</span>;
          },
        },
      ];
  }
};
const generate_ip_address_block = (doc: IESDoc): string[] => {
  const ips: string[] = [];
  if (_.has(doc, "resolved_ip") && !_.isEmpty(_.get(doc, "resolved_ip"))) {
    ips.push(_.get(doc, "resolved_ip"));
  }
  if (_.has(doc, "dns.a_record") && _.isArray(_.get(doc, "dns.a_record"))) {
    _.forEach(_.get(doc, "dns.a_record"), (a_record) => {
      if (a_record.a_record) {
        ips.push(a_record.a_record);
      }
    });
  }
  return ips;
};
export const CyberAssetsTable: React.FC<{ resource: string; isLocked: boolean }> = ({
  resource,
  isLocked,
}) => {
  const theme = useTheme<Theme>();
  const [requestCount, setRequestCount] = React.useState<number>(0);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [allDocs, setAllDocs] = React.useState<IESDoc[]>([]);
  const [totalDocs, setTotalDocs] = React.useState<number>(0);
  const [docCount, setDocCount] = React.useState<number>(0);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("asc");
  const [sortField, setSortField] = React.useState<string>("@timestamp");
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc>();
  const [masterCols, setMasterCols] = React.useState(colDefs(resource, theme));
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const [updateId, setUpdateId] = React.useState("");
  const [locked, setLocked] = React.useState(isLocked);
  const fetchAsset = () => {
    setRequestCount((x) => x + 1);
    FetchCyberAssets(resource)
      .then((response) => {
        const dummyRecords: IESDoc[] = _.range(2).map((x) =>
          generateDummyData(
            response.data[0] || {
              _id: "",
            },
            x
          )
        );
        const uniqueDocs: IESDoc[] = _.uniqBy([...response.data, ...dummyRecords], (x) => x._id);
        setDocs(uniqueDocs);
        setAllDocs(
          response.data.map((i) => ({ ...i, ip_address_block: generate_ip_address_block(i) }))
        );
        setTotalDocs(_.get(response, "metadata.total.value") as unknown as number);
        setDocCount(response.data.length);
      })
      .catch(() => {
        toast(`Error in loading ${_.startCase(resource)}`, "error");
      })
      .finally(() => {
        setRequestCount((c) => c - 1);
      });
  };
  const formatTitle = () => {
    switch (resource) {
      case "ip_addresses":
        return "IP Addresses";
      case "ssl_certificates":
        return "SSL Certificates";
      case "ip_ranges":
        return "IP Ranges";
      case "bin_numbers":
        return "BIN Numbers";
      default:
        return _.startCase(resource);
    }
  };

  useEffect(fetchAsset, [resource, updateId]);
  useDeepCompareEffect(() => {
    setMasterCols(colDefs(resource, theme));
  }, [resource, theme]);
  useDeepCompareEffect(() => {
    const normalize = (str: string) => _.toLower(_.deburr(str));

    const includesValue = (val: string, obj: IESDoc) => {
      const search = normalize(val);
      return _.some(obj, (v) => normalize(v).includes(search));
    };
    const dummyRecords: IESDoc[] = _.range(2).map((x) =>
      generateDummyData(
        allDocs[0] || {
          _id: "",
        },
        x
      )
    );
    if (searchQuery) {
      setDocs(
        _.uniqBy(
          [...allDocs.filter((f) => includesValue(searchQuery, f)), ...dummyRecords],
          (x) => x._id
        )
      );
    } else {
      setDocs(_.uniqBy([...allDocs, ...dummyRecords], (x) => x._id));
    }
  }, [allDocs, searchQuery]);
  useDeepCompareEffect(() => {
    const dummyRecords: IESDoc[] = _.range(2).map((x) =>
      generateDummyData(
        allDocs[0] || {
          _id: "",
        },
        x
      )
    );
    const orderedDocs = _.orderBy(allDocs, [sortField], [sortOrder]);
    setDocs(_.uniqBy([...orderedDocs, ...dummyRecords], (x) => x._id));
  }, [allDocs, sortField, sortOrder]);

  useDeepCompareEffect(() => {
    setUpdateId(UUIDGenerator());
  }, [selectedMemberIds]);
  useEffect(() => {
    setLocked(isLocked);
  }, [isLocked]);
  useDeepCompareEffect(() => {
    if (!locked) {
      if (docCount >= totalDocs) {
        setDocs((arr) => arr.filter((f) => !f.dummy_data));
      }
    }
  }, [docCount, totalDocs, docs, locked]);
  return (
    <Fragment>
      <Box marginTop={2}>
        <Box marginBottom={2}>
          <Typography variant="h6" color={"primary"} fontWeight={600}>
            {formatTitle()}
          </Typography>
          {requestCount > 0 ? (
            <Skeleton variant="text" width={320} />
          ) : locked ? (
            <Alert
              severity="warning"
              action={
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  component={Link}
                  href={`https://${
                    process.env.NODE_ENV === "development" ? "cyno.edxdev.link" : "start.ctm360.com"
                  }/pricing`}
                  target="_blank"
                >
                  Upgrade Now
                </Button>
              }
            >
              <Typography variant="subtitle1" fontSize={"14px"} fontWeight={500}>
                <Typography
                  variant="subtitle1"
                  fontSize={"inherit"}
                  component={"span"}
                  marginRight={1}
                >
                  <strong>Restricted View</strong>
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={"inherit"}
                  component={"span"}
                  fontWeight={500}
                >
                  {`Upgrade to view the full list of  ${formatTitle()}`}
                </Typography>
              </Typography>
            </Alert>
          ) : docCount >= totalDocs ? (
            <Typography variant="subtitle1" fontSize={"14px"} fontWeight={500} marginTop={0.5}>
              {`Summary of your ${formatTitle()}`}
            </Typography>
          ) : (
            <Alert
              severity="warning"
              action={
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  component={Link}
                  href={`https://${
                    process.env.NODE_ENV === "development" ? "cyno.edxdev.link" : "start.ctm360.com"
                  }/pricing`}
                  target="_blank"
                >
                  Upgrade Now
                </Button>
              }
            >
              <Typography variant="subtitle1" fontSize={"14px"} fontWeight={500}>
                <Typography
                  variant="subtitle1"
                  fontSize={"inherit"}
                  component={"span"}
                  marginRight={1}
                >
                  <strong>Restricted View</strong>
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontSize={"inherit"}
                  component={"span"}
                  fontWeight={500}
                >
                  {`Disclosing ${docCount} ${formatTitle()} from a total of ${totalDocs}`}
                </Typography>
              </Typography>
            </Alert>
          )}
        </Box>
        <Box>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  variant="outlined"
                  value={searchQuery}
                  size="small"
                  InputProps={{
                    endAdornment: <Icon>search</Icon>,
                  }}
                  id="outlined-basic"
                  label={"Search..."}
                  fullWidth
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <AppBlurTable
            loading={Boolean(requestCount > 0)}
            dataSource={""}
            name={""}
            title={""}
            colDefs={masterCols}
            docs={docs}
            checkedDocs={[]}
            totalDocs={totalDocs}
            pageIndex={0}
            pageSize={docs.length}
            sortField={sortField}
            sortOrder={sortOrder}
            enablePagination={false}
            enableCheckBox={false}
            enableSelection={true}
            showBlur={locked ? locked : !(totalDocs === docCount)}
            onChangeSortField={setSortField}
            onChangeSortOrder={setSortOrder}
            onChangePageIndex={() => null}
            onChangePageSize={() => null}
            onChangeCheckedDocs={() => null}
            onChangeSelectedDoc={setSelectedDoc}
          />
        </Paper>
      </Box>
      {selectedDoc ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={formatTitle()}
          onClose={() => {
            setSelectedDoc(undefined);
          }}
        >
          <CyberAssetsAdvancedView type={resource} doc={selectedDoc} />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
