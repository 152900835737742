import { Box, CircularProgress, Icon, Paper, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { IESDoc } from "../../../App.interface";
import { FetchConfigurationBrandLogos } from "../../../services/cbs.service";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";

interface IBrandLogoThumbnail {
  name: string;
  doc: IESDoc;
  selectedTab: string;
}
export const BrandLogoThumbnail: React.FC<IBrandLogoThumbnail> = ({ doc, name, selectedTab }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [image, setImage] = React.useState<string>("");
  const [status, setStatus] = React.useState<boolean>(false);

  const fetchBrandLogo = () => {
    if (_.isEmpty(doc?.s3_file_name)) {
      return setLoading(false);
    }
    setLoading(true);
    FetchConfigurationBrandLogos(name, selectedTab, doc.s3_file_name)
      .then((resp) => {
        setImage(resp);
        setStatus(true);
      })
      .catch(() => {
        setStatus(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useDeepCompareEffect(fetchBrandLogo, [doc, name, selectedTab]);
  return (
    <Fragment>
      <Paper
        className="brand-logo-wrapper"
        variant="outlined"
        sx={{
          padding: "8px",
          width: "150px",
          height: "150px",
          position: "relative",
          marginBottom: "8px",
        }}
      >
        <Box
          className="brand-logo-thumbnail-wrapper"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          height={"100%"}
          overflow={"hidden"}
        >
          {loading ? (
            <CircularProgress />
          ) : status ? (
            <img src={`data:image/png;base64,${image}`} alt="brand-img" />
          ) : (
            <Icon>image</Icon>
          )}
        </Box>
      </Paper>
      <Typography
        variant="subtitle1"
        component="p"
        fontSize={"12px"}
        fontWeight={600}
        color="textPrimary"
      >
        {doc.file_name}
      </Typography>
    </Fragment>
  );
};
