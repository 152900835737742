import { Box, Icon, Paper, Skeleton, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

export const PaperTiledWithIcon: React.FC<{
  title: string;
  value: string | number;
  loading: boolean;
  color:
    | "primary"
    | "secondary"
    | "info"
    | "disabled"
    | "action"
    | "inherit"
    | "error"
    | "success"
    | "warning";
  bgColor: string;
  icon: string;
  isDefault: boolean;
  titleColor: string;
  isSmall?: boolean;
}> = ({ title, value, loading, color, bgColor, icon, isDefault, titleColor, isSmall }) => {
  const theme = useTheme();
  return (
    <Paper
      elevation={2}
      sx={{
        backgroundColor: isDefault
          ? theme.palette.background.default
          : theme.palette.background.paper,
        padding: 2,
      }}
    >
      {loading ? (
        <Skeleton variant="rectangular" height={isSmall ? 30 : 40} />
      ) : (
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <Box sx={{ maxWidth: "70%" }}>
            <Stack>
              <Typography
                sx={{
                  fontSize: isSmall ? 12 : 14,
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: titleColor,
                }}
              >
                {title}
              </Typography>
              <Typography sx={{ fontSize: isSmall ? 12 : 14, fontWeight: 600 }}>{value}</Typography>
            </Stack>
          </Box>
          <Box sx={{ alignSelf: "center" }}>
            <Box
              sx={{
                bgcolor: bgColor,
                width: isSmall ? 30 : 40,
                height: isSmall ? 30 : 40,
                borderRadius: 100,
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              <Icon color={color} sx={{ mt: "20%" }}>
                {icon}
              </Icon>
            </Box>
          </Box>
        </Stack>
      )}
    </Paper>
  );
};
