import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/sad-search.json";

export const EmptyTableView: React.FC = () => {
  return (
    <Fragment>
      <Box className="no-rows-container">
        <Box>
          <Box className="icon">
            <Lottie
              options={{
                animationData: animationData,
                loop: true,
                autoplay: true,
                rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
              }}
              width={180}
              height={180}
            />
          </Box>
          <Box className="content">
            <Typography variant="h4" gutterBottom>
              No results found
            </Typography>
            {/* <Typography variant="subtitle2" gutterBottom color="textSecondary">
              Try to adjust your search or filter to find what you are looking for
            </Typography> */}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
