import { AppKeyCloak } from "../auth/Keycloak";
import { user$ } from "../state/user";
import axios from "./axios.service";

export const isLoggedIn = () => {
  if (
    localStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.sso.authtoken`) &&
    localStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.sso.user`)
  ) {
    return true;
  } else {
    return false;
  }
};
const CynoLogOut = async () => {
  const response = await axios.post("https://cyno.edxlabs.com/api/logout", undefined, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem(
        `${process.env.REACT_APP_SSO_CLIENT_ID}.sso.authtoken`
      )}`,
    },
  });
  return response;
};
export const logout = () => {
  CynoLogOut();
  localStorage.clear();
  sessionStorage.clear();
  user$.next(null);
  AppKeyCloak.logout();
};
