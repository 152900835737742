import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _has from "lodash/has";
import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../App.interface";
import { user$ } from "../../../state/user";
import { IMasterColumns } from "../../module.interface";
import { useBehaviourSubject } from "../../../hooks/use-observable";
import { AppAssetsSectionBox } from "../../views/app.section.box";
import { AppDocView } from "../../views/app.doc.view";

export const DomainOverView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  const user = useBehaviourSubject(user$);
  const [parsedDoc, setParsedDoc] = React.useState<IESDoc>(doc);
  const [colDefs, setColDefs] = React.useState<IMasterColumns[]>([
    {
      masterColumnName: "whois.parsed.domain_created_date",
      masterColumnTitle: "Domain Age",
      renderType: "time_since",
    },
    {
      masterColumnName: "whois.parsed.domain_expiry_date",
      masterColumnTitle: "Expires",
      renderType: "time_since",
    },
    { masterColumnName: "tags.domain", masterColumnTitle: "Domain Type", renderType: "tags" },
    { masterColumnName: "value", masterColumnTitle: "Domain", renderType: "url" },
    { masterColumnName: "resolved_ip", masterColumnTitle: "Resolved IP", renderType: "url" },
    {
      masterColumnName: "parsed_contains_ssl",
      masterColumnTitle: "Contains SSL",
      renderType: "boolean",
    },
    { masterColumnName: "parsed_a_record", masterColumnTitle: "A Record", renderType: "url_array" },
    {
      masterColumnName: "parsed_cname_record",
      masterColumnTitle: "CNAME Record",
      renderType: "array",
    },
    { masterColumnName: "parsed_ns_record", masterColumnTitle: "NS Record", renderType: "array" },
    { masterColumnName: "parsed_mx_record", masterColumnTitle: "MX Record", renderType: "array" },
    { masterColumnName: "parsed_spf_record", masterColumnTitle: "SPF Record", renderType: "array" },
    {
      masterColumnName: "parsed_dkim_record",
      masterColumnTitle: "DKIM Record",
      renderType: "array",
    },
    {
      masterColumnName: "parsed_dmarc_record",
      masterColumnTitle: "DMARC Record",
      renderType: "array",
    },
    { masterColumnName: "parsed_txt_record", masterColumnTitle: "TXT Record", renderType: "array" },
    { masterColumnName: "parsed_soa_record", masterColumnTitle: "SOA Record", renderType: "array" },
    {
      masterColumnName: "meta.first_seen",
      masterColumnTitle: "First Seen",
      renderType: "date",
    },
    {
      masterColumnName: "meta.last_seen",
      masterColumnTitle: "Last Seen",
      renderType: "date",
    },
    {
      masterColumnName: "meta.discovery_source",
      masterColumnTitle: "Discovery Source",
      renderType: "title",
    },
  ]);
  useEffect(() => {
    if (user && !user.internal) {
      setColDefs((arr) => arr.filter((f) => f.masterColumnName !== "meta.discovery_source"));
    }
  }, [user]);
  useEffect(() => {
    setParsedDoc(() => ({
      ...doc,
      parsed_contains_ssl: _has(doc, "ssl.status") && doc.ssl.status === "valid" ? true : false,
      parsed_a_record:
        _has(doc, "dns.a_record") && _isArray(_get(doc, "dns.a_record"))
          ? _get(doc, "dns.a_record").map((x: any) => x.a_record)
          : [],
      parsed_cname_record:
        _has(doc, "dns.cname_record") && _isArray(_get(doc, "dns.cname_record"))
          ? _get(doc, "dns.cname_record").map(
              (x: any) => `alias_from ${x.cname_alias_from} - alias_to ${x.cname_alias_to}`
            )
          : [],
      parsed_ns_record:
        _has(doc, "dns.ns_record") && _isArray(_get(doc, "dns.ns_record"))
          ? _get(doc, "dns.ns_record").map((x: any) => x.nameserver)
          : [],
      parsed_mx_record:
        _has(doc, "dns.mx_record") && _isArray(_get(doc, "dns.mx_record"))
          ? _get(doc, "dns.mx_record").map((x: any) => x.mx)
          : [],
      parsed_spf_record:
        _has(doc, "dns.spf_record") && _isArray(_get(doc, "dns.spf_record"))
          ? _get(doc, "dns.spf_record").map((x: any) => x.spf_record)
          : [],
      parsed_dkim_record:
        _has(doc, "dns.dkim_record") && _isArray(_get(doc, "dns.dkim_record"))
          ? _get(doc, "dns.dkim_record").map((x: any) => x.dkim_raw)
          : [],
      parsed_dmarc_record:
        _has(doc, "dns.dmarc_record") && _isArray(_get(doc, "dns.dmarc_record"))
          ? _get(doc, "dns.dmarc_record").map((x: any) => x.dmarc_raw)
          : [],
      parsed_txt_record:
        _has(doc, "dns.other_txt_record") && _isArray(_get(doc, "dns.other_txt_record"))
          ? _get(doc, "dns.other_txt_record").map((x: any) => x.txt_record)
          : [],
      parsed_soa_record:
        _has(doc, "dns.soa_record") && _isArray(_get(doc, "dns.soa_record"))
          ? _get(doc, "dns.soa_record").map((x: any) => x.mname)
          : [],
    }));
  }, [doc]);
  return (
    <Fragment>
      <AppAssetsSectionBox title="Overview" type="domain">
        <AppDocView colDefs={colDefs} doc={parsedDoc} />
      </AppAssetsSectionBox>
    </Fragment>
  );
};
