import { Grid } from "@mui/material";
import React, { Fragment } from "react";
import { HackerViewAssets } from "./asset.cards";
import { IssuesCard } from "./issues.card";
import { OverallScoreCard } from "./overall.score.card";
import "./styles.scss";
import { HostOverviewPieChart } from "./host_overview_pie_chart";
import { WebServerPieChart } from "./web_servers_pie_chart";
import { FreemiumAssetFeed } from "./asset_feed";
import { FreemiumIssueFeed } from "./issue_feed";
import { EnvironmentsPieChart } from "./environments_pie_chart";
import { IPOverviewPieChart } from "./ip_overview_pie_chart";
import { useObservable } from "../../hooks/use-observable";
import { BrandService } from "../../services/brand.service";
import useDeepCompareEffect from "use-deep-compare-effect";
import { UUIDGenerator } from "../../util/uuid";

export const MainDashboard: React.FC<any> = () => {
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const [updateId, setUpdateId] = React.useState("");
  useDeepCompareEffect(() => {
    setUpdateId(UUIDGenerator());
  }, [selectedMemberIds]);
  return (
    <Fragment>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <OverallScoreCard updateId={updateId} />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <IssuesCard updateId={updateId} />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12} style={{ display: "flex" }}>
              <HackerViewAssets updateId={updateId} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={2}>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <WebServerPieChart updateId={updateId} />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <EnvironmentsPieChart updateId={updateId} />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <HostOverviewPieChart updateId={updateId} />
            </Grid>

            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
              <IPOverviewPieChart updateId={updateId} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <FreemiumAssetFeed updateId={updateId} />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <FreemiumIssueFeed updateId={updateId} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
