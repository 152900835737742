import { Box, Link } from "@mui/material";
import React, { ReactNode } from "react";
import { Fragment } from "react";
import { useBehaviourSubject } from "../../hooks/use-observable";
import { user$ } from "../../state/user";
import { UrlWarning } from "./url.warning";

export const UrlRenderer: React.FC<{
  url: string;
  multiline: boolean;
  renderComponent?: ReactNode;
}> = ({ url, multiline, renderComponent }) => {
  const user = useBehaviourSubject(user$);
  const [showWarning, setShowWarning] = React.useState(false);
  const onCloseModel = () => {
    setShowWarning(false);
  };
  return (
    <Fragment>
      {user ? (
        <Box className={`${multiline ? "multiline" : "single-line"}-url-wrapper`}>
          <Link
            className="url-render-subject"
            href={url.startsWith("http://") || url.startsWith("https://") ? url : `http://${url}`}
            target="_blank"
            onClick={(e: any) => {
              if (!user.internal) {
                e.preventDefault();
                setShowWarning(true);
              }
              e.stopPropagation();
            }}
          >
            {renderComponent ? renderComponent : url}
          </Link>
        </Box>
      ) : null}
      {showWarning ? <UrlWarning url={url} onClose={onCloseModel} /> : null}
    </Fragment>
  );
};
