import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Box,
  TextField,
} from "@mui/material";
import React, { Fragment } from "react";
import { LoadingButton } from "@mui/lab";
import { toast } from "../../state/snackbar";
import { SubmitProfilingRequest } from "../../services/dashboard.service";
import _ from "lodash";
interface IRequestEntityScore {
  searchQuery: string;
  onClose: () => void;
}
export const RequestEntityScore: React.FC<IRequestEntityScore> = ({ searchQuery, onClose }) => {
  const [loading, setLoading] = React.useState(false);
  const [organizationGist, setOrganizationGist] = React.useState(searchQuery);
  // const [customMessage, setCustomMessage] = React.useState(
  //   `Hey CTM360, \n\n This is ${user?.name} from ${user?.organization}.\n I would like to get a personalized pricing plan for your ${platform} platform !`
  // );
  const onSubmitQuote = () => {
    setLoading(true);
    SubmitProfilingRequest(organizationGist)
      .then(() => {
        toast("Request received successfully", "success");
        toast("Please expect callback within 24 hours", "info");
        onClose();
      })
      .catch(() => {
        toast("Error in requesting organization", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Box padding={4}>
            <Box marginBottom={4}>
              <Typography variant="h6" fontWeight={600} color={"primary"}>
                <strong>{"Missing an Organization?"}</strong>
              </Typography>
              <Typography variant="subtitle2" color={"textSecondary"}>
                Please provide some basic information.
                <br />
                We will find and score it for you
              </Typography>
            </Box>
            <Box marginBottom={2}>
              <Typography variant="subtitle2" fontWeight={600} marginBottom={1}>
                {"Please provide Organization name or Website"}
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                multiline
                rows={5}
                value={organizationGist}
                fullWidth
                onChange={(e) => setOrganizationGist(e.target.value)}
              />
            </Box>
            <Box>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <LoadingButton
                  autoFocus
                  color="primary"
                  variant="contained"
                  onClick={onSubmitQuote}
                  loading={loading}
                  disabled={_.isEmpty(organizationGist)}
                >
                  request
                </LoadingButton>
              </DialogActions>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
