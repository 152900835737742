import { Box } from "@mui/material";
import React, { Fragment } from "react";
import { NetworkGraphController } from "./network-graph.controller";
import "./styles.scss";
export const AttackSurfaceNetworkGraph: React.FC = () => {
  return (
    <Fragment>
      <Box className="network-graph-master-container">
        <NetworkGraphController />
      </Box>
    </Fragment>
  );
};
