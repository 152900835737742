export const getStatusColors = (value: string) => {
  switch (value.toLowerCase()) {
    case "resolved":
      return "#3A8E5C";

    case "open":
      return "#EF6C00";

    case "closed":
      return "#EF6C00";

    default:
      return "#e3e3e3";
  }
};

export const getCategoryColors = (value: string) => {
  switch (value.toLowerCase()) {
    case "dkim":
      return "#AED036";

    case "dmarc":
      return "#2DACFF";

    case "spf":
      return "#67BCBE";

    default:
      return "#e3e3e3";
  }
};

export const getFixingEffortColors = (value: string) => {
  switch (value.toLowerCase()) {
    case "simple":
      return "#4bbe1a";

    case "moderate":
      return "#ffec00";

    case "advanced":
      return "#ff3000";

    default:
      return "#e3e3e3";
  }
};
