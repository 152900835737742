import { Divider, Icon, Tabs, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { CyberAssetsTable } from "./cyber_assets_table";
import StyledTab from "../../components/styled.tab";

export const CyberAssetsModule: React.FC = () => {
  const [cyberAssets] = React.useState([
    "domains",
    "hosts",
    "websites",
    "ip_addresses",
    "ssl_certificates",
    "ip_ranges",
    "open_ports",
    "bin_numbers",
    "social_media",
    "mobile_apps",
    "third_party_content",
    "executive_management",
    "code_repositories",
  ]);
  const [selectedTab, setSelectedTab] = React.useState(cyberAssets[0]);
  const isLocked = (params: string): boolean => {
    return _.includes(
      [
        "social_media",
        "mobile_apps",
        "third_party_content",
        "executive_management",
        "code_repositories",
      ],
      params
    );
  };
  const formatTitle = (params: string): string => {
    switch (params) {
      case "ip_addresses":
        return "IP Addresses";
      case "ssl_certificates":
        return "SSL Certificates";
      case "ip_ranges":
        return "IP Ranges";
      case "bin_numbers":
        return "BIN Numbers";
      default:
        return _.startCase(params);
    }
  };
  return (
    <Fragment>
      <Tabs
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        onChange={(event, value) => {
          setSelectedTab(value);
        }}
        aria-label="cyberassets-table"
      >
        {cyberAssets.map((t) => (
          <StyledTab
            label={
              <Typography
                variant="subtitle1"
                fontSize={"12px"}
                fontWeight={600}
                textTransform={"none"}
              >
                {formatTitle(t)}
              </Typography>
            }
            key={t}
            value={t}
            icon={isLocked(t) ? <Icon sx={{ fontSize: "18px !important" }}>lock</Icon> : undefined}
            iconPosition={isLocked(t) ? "end" : undefined}
          />
        ))}
      </Tabs>
      <Divider />
      <CyberAssetsTable resource={selectedTab} isLocked={isLocked(selectedTab)} />
    </Fragment>
  );
};
