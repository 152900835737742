import { Box, Chip, Grid, Icon, IconButton, Tooltip, Typography } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { IESDoc } from "../../../../../App.interface";
import { IDateFilterObj, IMasterColumns, ITableFilters } from "../../../../module.interface";
import { useBehaviourSubject } from "../../../../../hooks/use-observable";
import { BrandService } from "../../../../../services/brand.service";
import { TooltipIcon } from "../components/tooltip-icon";
import { AuthResultColorMapping } from "../../../util/auth-result-colors";
import { ValidateDomain } from "../../../../../util/validators";
import { toast } from "../../../../../state/snackbar";
import {
  FetchAnalyticsDkimSourcesList,
  FetchAnalyticsIpInfo,
  FetchAnalyticsSourceDetailsList,
} from "../../../../../services/dmarc.service";
import { DkimListPopover } from "../components/indepth-dkim-list";
import { DmarcIPInfoDialog } from "../components/ip-info.dialog";
import { OfflineAppMasterTable } from "../../../environment/domains/components/offline-table";

interface Type {
  doc: IESDoc;
  masterFilters: ITableFilters[];
  category: string;
  currentDateRange: IDateFilterObj;
}
export const DmarcAnalyticsSourceInfo: React.FC<Type> = ({
  doc,
  masterFilters,
  category,
  currentDateRange,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [sortVerified, setSortVerified] = React.useState("email_count");
  const [sortOrderByVerified, setSortByVerified] = React.useState<"asc" | "desc">("desc");
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);

  const [selectedSourceDkimList, setSelectedSourceDkimList] = React.useState<
    { result: string; domain: string; selector: string }[]
  >([]);
  const [actionsEl, setActionsEl] = React.useState<null | HTMLElement>(null);
  const [actionsElIP, setActionsElIP] = React.useState<null | HTMLElement>(null);

  const [selectedDkimLoading, setSelectedDkimLoading] = React.useState<boolean>(false);
  const selectedMemberIds = useBehaviourSubject<number[]>(BrandService.selectedMemberIds$) || [];
  const [ipInfoDialog, setIpInfoDialog] = React.useState<boolean>(false);

  const actionsColorMap: Record<
    string,
    "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
  > = {
    Received: "success",
    Rejected: "error",
    Quarantined: "warning",
  };
  const [selectedDkimListID, setSelectedDkimListID] = React.useState<string>("");
  const [selectedDkimListIDIP, setSelectedDkimListIDIP] = React.useState<string>("");
  const [ipLoading, setIpLoading] = React.useState(false);

  const [ipInfoData, setIpInfoData] = React.useState<{
    data: {
      netname: string;
      location: string;
      ip_address: string;
    };
  }>({
    data: {
      netname: "",
      location: "",
      ip_address: "",
    },
  });
  const getActionApplied = (action: string): string => {
    switch (action) {
      case "reject":
        return "Rejected";
      case "none":
        return "Received";
      case "quarantine":
        return "Quarantined";
      default:
        return "";
    }
  };

  const fetchSourceDkimList = (
    from_domain: string | null,
    source_ip: string | null,
    host_name: string | null,
    dkim_auth: string | null,
    dkim_result: string | null,
    dkim_alignment: string | null,
    dkim_selector: string | null,
    dkim_domain: string | null,
    spf_auth: string | null,
    spf_result: string | null,
    spf_alignment: string | null,
    spf_domain: string | null,
    actions_applied: string | null,
    auto_generated: string | null,
    reason: string | null
  ) => {
    const selectionFilters: ITableFilters[] = [];

    if (!_.isNull(from_domain) && from_domain != "") {
      selectionFilters.push({
        id: "from_domain",
        title: "from_domain",
        field: "identifiers.header_from",
        operator: "is",
        value: from_domain,
      });
    }
    if (!_.isNull(source_ip) && source_ip != "") {
      selectionFilters.push({
        id: "source_ip",
        title: "source_ip",
        field: "source_ip_info.source_ip",
        operator: "is",
        value: source_ip,
      });
    }
    if (!_.isNull(host_name) && host_name != "") {
      selectionFilters.push({
        id: "host_name",
        title: "host_name",
        field: "source_ip_info.hostname",
        operator: "is",
        value: host_name,
      });
    }
    if (!_.isNull(dkim_auth) && dkim_auth != "") {
      selectionFilters.push({
        id: "dkim_auth",
        title: "dkim_auth",
        field: "auth_results.main_dkim.result",
        operator: "is",
        value: dkim_auth,
      });
    }
    if (!_.isNull(dkim_result) && dkim_result != "") {
      selectionFilters.push({
        id: "dkim_result",
        title: "dkim_result",
        field: "policy_evaluated.dkim",
        operator: "is",
        value: dkim_result,
      });
    }
    if (!_.isNull(dkim_alignment) && dkim_alignment != "") {
      selectionFilters.push({
        id: "dkim_alignment",
        title: "dkim_alignment",
        field: "dkim_alignment",
        operator: "is",
        value: dkim_alignment,
      });
    }
    if (!_.isNull(dkim_selector) && dkim_selector != "") {
      selectionFilters.push({
        id: "dkim_selector",
        title: "dkim_selector",
        field: "auth_results.main_dkim.selector",
        operator: "is",
        value: dkim_selector,
      });
    }
    if (!_.isNull(dkim_domain) && dkim_domain != "") {
      selectionFilters.push({
        id: "dkim_domain",
        title: "dkim_domain",
        field: "auth_results.main_dkim.domain",
        operator: "is",
        value: dkim_domain,
      });
    }
    if (!_.isNull(spf_auth) && spf_auth != "") {
      selectionFilters.push({
        id: "spf_auth",
        title: "spf_auth",
        field: "auth_results.spf.result",
        operator: "is",
        value: spf_auth,
      });
    }
    if (!_.isNull(spf_result) && spf_result != "") {
      selectionFilters.push({
        id: "spf_result",
        title: "spf_result",
        field: "policy_evaluated.spf",
        operator: "is",
        value: spf_result,
      });
    }
    if (!_.isNull(spf_alignment) && spf_alignment != "") {
      selectionFilters.push({
        id: "spf_alignment",
        title: "spf_alignment",
        field: "spf_alignment",
        operator: "is",
        value: spf_alignment,
      });
    }
    if (!_.isNull(spf_domain) && spf_domain != "") {
      selectionFilters.push({
        id: "spf_domain",
        title: "spf_domain",
        field: "auth_results.spf.domain",
        operator: "is",
        value: spf_domain,
      });
    }
    if (!_.isNull(actions_applied) && actions_applied != "") {
      selectionFilters.push({
        id: "actions_applied",
        title: "actions_applied",
        field: "policy_evaluated.disposition",
        operator: "is",
        value: actions_applied,
      });
    }
    if (!_.isNull(auto_generated) && auto_generated != "") {
      selectionFilters.push({
        id: "auto_generated",
        title: "auto_generated",
        field: "auto_generated",
        operator: "is",
        value: auto_generated,
      });
    }
    if (!_.isNull(reason) && reason != "") {
      selectionFilters.push({
        id: "reason",
        title: "reason",
        field: "policy_evaluated.reason.type",
        operator: "is",
        value: reason,
      });
    }

    const filters: ITableFilters[] = [];
    if (category != "") {
      filters.push({
        id: "category",
        title: "category",
        field: "category",
        operator: "is",
        value: category,
      });
    }

    if (!masterFilters.some((item) => item.field == "false_positive" && item.value == "true")) {
      filters.push({
        id: "false_positive",
        title: "false_positive",
        field: "false_positive",
        operator: "is",
        value: "false",
      });
    }

    const resultFilters = [...masterFilters, ...filters, ...selectionFilters];

    setSelectedDkimLoading(true);
    FetchAnalyticsDkimSourcesList(resultFilters)
      .then((res) => {
        setSelectedSourceDkimList(res.data);
      })
      .catch(() => {
        toast("Error happpened while fetching dkim list", "error");
      })
      .finally(() => {
        setSelectedDkimLoading(false);
      });
  };
  const fetchIpInfo = (ipAddress: string) => {
    setIpLoading(true);
    FetchAnalyticsIpInfo(ipAddress)
      .then((response) => {
        setIpInfoData(response.data);
      })
      .catch(() => toast("Error fetching ip info data", "error"))
      .finally(() => {
        setIpLoading(false);
      });
  };
  const colDefs: IMasterColumns[] = [
    {
      masterColumnName: "from_domain",
      masterColumnTitle: "Domain",
      renderType: "text",
      filterable: true,
      sortable: true,
    },
    {
      masterColumnName: "host.name",
      masterColumnTitle: "Source IP",
      renderType: "text",
      filterable: true,
      sortable: true,
      cellRender: (params) => {
        return (
          <Fragment>
            <Typography variant="subtitle2">{params?.host?.name}</Typography>
            <Chip
              icon={
                <Tooltip title="IP Analysis">
                  <IconButton
                    onClick={(e) => {
                      setSelectedDkimListIDIP(params._id);
                      setActionsElIP(e.currentTarget);

                      setIpInfoDialog(true);
                      fetchIpInfo(params?.host?.ip || "");
                    }}
                    size="small"
                  >
                    <Icon color={params._id == selectedDkimListIDIP ? "primary" : undefined}>
                      location_on
                    </Icon>
                  </IconButton>
                </Tooltip>
              }
              label={params?.host?.ip || ""}
            />
            {ipInfoDialog ? (
              <DmarcIPInfoDialog
                actionsEl={actionsElIP}
                ipInfoData={ipInfoData}
                loading={ipLoading}
                isOpen={true}
                onClose={() => {
                  setIpInfoDialog(false);
                  setActionsElIP(null);
                  setSelectedDkimListIDIP("");
                }}
              />
            ) : null}
          </Fragment>
        );
      },
    },
    {
      masterColumnName: "email_count",
      masterColumnTitle: "Emails Sent",
      renderType: "number",
      filterable: true,
      sortable: true,
    },
    {
      masterColumnName: "dkim_authentication.result",
      masterColumnTitle: "DKIM Authentication",
      renderType: "Authentication",
      filterable: true,
      sortable: true,
      cellRender: (row) => {
        const domain = row?.dkim_authentication?.domain || "";
        const resultKey = row?.dkim_authentication?.auth || "none";
        const aligned = row?.dkim_authentication?.alignment || "un-aligned";
        const selector = row?.dkim_authentication?.selector || "";
        const finalResult = row?.dkim_authentication?.result || "fail";
        return (
          <Fragment>
            {domain ? <Typography variant="subtitle2">{domain}</Typography> : null}
            <Grid container>
              {resultKey ? (
                <Grid item>
                  <TooltipIcon
                    color={AuthResultColorMapping(resultKey)}
                    icon="vpn_key"
                    label="Authentication"
                    value={resultKey}
                  />
                </Grid>
              ) : null}

              {aligned ? (
                <Grid item>
                  <TooltipIcon
                    color={AuthResultColorMapping(aligned)}
                    icon="link"
                    label="Alignment"
                    value={aligned}
                  />
                </Grid>
              ) : null}

              {finalResult ? (
                <Grid item>
                  <TooltipIcon
                    color={AuthResultColorMapping(finalResult)}
                    icon={finalResult === "pass" ? "check_circle" : "cancel"}
                    label="Result"
                    value={finalResult}
                  />
                </Grid>
              ) : null}

              {selector ? (
                <Grid item>
                  <TooltipIcon
                    color={"inherit"}
                    icon={"local_offer"}
                    label="Selector"
                    value={selector}
                  />
                </Grid>
              ) : null}
              {domain != "" ? (
                <Grid item>
                  <Box style={{ margin: "0 3px" }}>
                    <Tooltip title="All DKIM Signatures">
                      <IconButton
                        size="small"
                        sx={{ marginTop: "-5px" }}
                        onClick={(event) => {
                          setActionsEl(event.currentTarget);
                          setSelectedDkimListID(row._id);
                          fetchSourceDkimList(
                            row.from_domain,
                            row.host.ip,
                            row.host.name,
                            row.dkim_authentication.auth,
                            row.dkim_authentication.result,
                            row.dkim_authentication.alignment,
                            row.dkim_authentication.selector,
                            row.dkim_authentication.domain,
                            row.spf_authentication.auth,
                            row.spf_authentication.result,
                            row.spf_authentication.alignment,
                            row.spf_authentication.domain,
                            row.actions_applied,
                            row.spf_authentication.auto_generated,
                            row.reason
                          );
                        }}
                      >
                        <Icon color={row._id == selectedDkimListID ? "primary" : undefined}>
                          list_alt
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <DkimListPopover
                    doc={row}
                    actionsEl={actionsEl}
                    onClose={() => {
                      setSelectedDkimListID("");
                      setActionsEl(null);
                      setSelectedSourceDkimList([]);
                    }}
                    dkimList={selectedSourceDkimList}
                    loading={selectedDkimLoading}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Fragment>
        );
      },
    },
    {
      masterColumnName: "spf_authentication.result",
      masterColumnTitle: "SPF Authentication",
      renderType: "Authentication",
      filterable: true,
      sortable: true,
      cellRender: (row) => {
        const domain = row?.spf_authentication?.domain || "";
        const resultKey = row?.spf_authentication?.auth || "none";
        const aligned = row?.spf_authentication?.alignment || "un-aligned";
        const finalResult = row?.spf_authentication?.result || "fail";
        const auto_generated = row?.spf_authentication.auto_generated;
        return (
          <Fragment>
            {domain ? <Typography variant="subtitle2">{domain}</Typography> : null}
            <Grid container>
              {resultKey ? (
                <Grid item>
                  <TooltipIcon
                    color={AuthResultColorMapping(resultKey)}
                    icon="vpn_key"
                    label="Authentication"
                    value={resultKey}
                  />
                </Grid>
              ) : null}

              {aligned ? (
                <Grid item>
                  <TooltipIcon
                    color={AuthResultColorMapping(aligned)}
                    icon="link"
                    label="Alignment"
                    value={aligned}
                  />
                </Grid>
              ) : null}

              {finalResult ? (
                <Grid item>
                  <TooltipIcon
                    color={AuthResultColorMapping(finalResult)}
                    icon={finalResult === "pass" ? "check_circle" : "cancel"}
                    label="Result"
                    value={finalResult}
                  />
                </Grid>
              ) : null}

              {auto_generated ? (
                <Grid item>
                  <TooltipIcon
                    color={"inherit"}
                    icon={"autorenew"}
                    label="Auto Generated"
                    value={""}
                    removeComma
                  />
                </Grid>
              ) : null}
            </Grid>
          </Fragment>
        );
      },
    },
    {
      masterColumnName: "dmarc_result",
      masterColumnTitle: "DMARC Authentication",
      renderType: "text",
      filterable: true,
      sortable: true,
      cellRender: (row) => (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: 5,
          }}
        >
          <Tooltip title={"Result: " + row.dmarc_result}>
            <Icon color={row.dmarc_result === "pass" ? "success" : "error"}>
              admin_panel_settings
            </Icon>
          </Tooltip>
          <Box>
            <Chip
              label={getActionApplied(row.actions_applied)}
              color={actionsColorMap[getActionApplied(row.actions_applied)]}
            />
          </Box>
          {!_.isNull(row?.reason) ? (
            <Tooltip title={"Bypass Reason: " + _.startCase(row.reason)}>
              <Icon>offline_pin</Icon>
            </Tooltip>
          ) : null}
        </Box>
      ),
    },
    {
      masterColumnName: "report_info.date_from",
      masterColumnTitle: "Report Info",
      renderType: "text",
      filterable: true,
      sortable: true,
      cellRender: (row) => {
        const displayDate: string[] = [];
        if (row?.report_info?.date_from) {
          displayDate.push(moment(row?.report_info?.date_from).format("DD/MM/YYYY"));
        }
        if (
          row?.report_info?.date_to &&
          row?.report_info?.date_to !== row?.report_info?.date_from
        ) {
          displayDate.push(" - ");
          displayDate.push(moment(row?.report_info?.date_to).format("DD/MM/YYYY"));
        }
        return (
          <Fragment>
            <Box style={{ display: "flex", gap: 5, alignItems: "center" }}>
              <Chip label={displayDate} />
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre-line" }}>
                    {"Report Providers:\n" + row?.report_info?.providers?.join("\n")}
                  </div>
                }
              >
                <Icon>article</Icon>
              </Tooltip>
              {row?.report_info?.senders?.filter((x: string) => ValidateDomain(x))?.length > 0 ||
              row?.report_info?.recipients?.filter((x: string) => ValidateDomain(x))?.length > 0 ? (
                <Tooltip
                  title={
                    <div style={{ whiteSpace: "pre-line" }}>
                      {row?.report_info?.senders?.filter((x: string) => ValidateDomain(x))?.length >
                        0 &&
                        "Senders:\n" +
                          row?.report_info?.senders
                            ?.filter((x: string) => ValidateDomain(x))
                            ?.map((x: string) => "@" + x)
                            ?.join("\n") +
                          "\n\n"}
                      {row?.report_info?.recipients?.filter((x: string) => ValidateDomain(x))
                        ?.length > 0 &&
                        "Recipients:\n" +
                          row?.report_info?.recipients
                            ?.filter((x: string) => ValidateDomain(x))
                            ?.map((x: string) => "@" + x)
                            ?.join("\n")}
                    </div>
                  }
                >
                  <Icon>contact_mail</Icon>
                </Tooltip>
              ) : null}
            </Box>
          </Fragment>
        );
      },
    },
  ];

  const fetchSourceInfo = () => {
    setLoading(true);
    FetchAnalyticsSourceDetailsList(doc.source_name, category)
      .then((response) => {
        setDocs(response.data);
      })
      .catch(() => {
        toast("Error fetching source info", "error");
      })
      .finally(() => setLoading(false));
  };

  useDeepCompareEffect(fetchSourceInfo, [doc, selectedMemberIds, currentDateRange]);
  return (
    <Fragment>
      <Box marginTop={2}>
        <OfflineAppMasterTable
          loading={loading}
          dataSource=""
          name=""
          title=""
          colDefs={colDefs}
          docs={docs}
          totalDocs={docs.length}
          pageIndex={pageIndex}
          pageSize={pageSize}
          sortField={sortVerified}
          sortOrder={sortOrderByVerified}
          onChangeSortField={setSortVerified}
          onChangeSortOrder={setSortByVerified}
          onChangePageIndex={setPageIndex}
          onChangePageSize={setPageSize}
          enablePagination={true}
          enableCheckBox={false}
          enableSelection={false}
          checkedDocs={[]}
          onChangeCheckedDocs={() => null}
          onChangeSelectedDoc={() => null}
          enableBrandColum={false}
        />
      </Box>
    </Fragment>
  );
};
