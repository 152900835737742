import {
  Box,
  Button,
  Checkbox,
  Grid,
  Icon,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import React, { useEffect } from "react";
import startCase from "lodash/startCase";
import { Fragment } from "react";
import { ValidateDomain, ValidateURL } from "../../util/validators";
import snakeCase from "lodash/snakeCase";
import { EmptyChart } from "../../modules/views/chart.empty";

interface IButtonFilterOptions {
  options: string[];
  loading: boolean;
  currentSelection: string[];
  onChangeOption: (options: string[]) => void;
  onClose: () => void;
  disableSearch?: boolean;
}
export const ButtonFilterOptions: React.FC<IButtonFilterOptions> = ({
  options,
  currentSelection,
  loading,
  disableSearch,
  onChangeOption,
  onClose,
}) => {
  const [selectedOptions, setSelectedOptions] = React.useState(currentSelection);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filteredOptions, setFilteredOptions] = React.useState<string[]>([]);

  const onChangeSelection = (value: string) => {
    if (includes(selectedOptions, value)) {
      setSelectedOptions((arr) => arr.filter((f) => f !== value));
    } else {
      setSelectedOptions((arr) => [...arr, value]);
    }
  };
  const onApplySelection = () => {
    onChangeOption(selectedOptions);
    onClose();
  };
  const onClearSelection = () => {
    setSelectedOptions([]);
    onChangeOption([]);
    onClose();
  };
  useEffect(() => {
    if (searchQuery) {
      setFilteredOptions(
        options.filter((f) =>
          includes(snakeCase(f.toLowerCase()), snakeCase(searchQuery.toLowerCase()))
        )
      );
    } else {
      setFilteredOptions(options);
    }
  }, [searchQuery, options]);
  return (
    <Fragment>
      <Box className="button-popover-filter-container">
        {!disableSearch ? (
          <Box className="search-box">
            <TextField
              variant="outlined"
              size="small"
              id="outlined-basic"
              label={"Search..."}
              value={searchQuery}
              disabled={loading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                ),
              }}
              fullWidth
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
        ) : null}
        <Box className="options-box">
          {loading ? (
            <LinearProgress />
          ) : isEmpty(filteredOptions) ? (
            <EmptyChart title={"no options found"} />
          ) : (
            <List>
              {filteredOptions.map((x) => (
                <ListItem
                  key={x}
                  role={undefined}
                  dense
                  button
                  onClick={() => onChangeSelection(x)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={includes(selectedOptions, x)}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": x }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={x}
                    primary={
                      x === "wip" ? "WIP" : ValidateURL(x) || ValidateDomain(x) ? x : startCase(x)
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        <Box className="actions-box">
          <Grid container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button variant="outlined" size="small" onClick={onClearSelection}>
                clear
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" size="small" color="secondary" onClick={onApplySelection}>
                apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
};
