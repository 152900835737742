import {
  Button,
  ButtonGroup,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { ResponsiveContainer } from "recharts";
import { KFormatter } from "../../../../../util/k_formatter";
import { toast } from "../../../../../state/snackbar";
import { FetchAnalyticsComplianceOvertime } from "../../../../../services/dmarc.service";

export const DmarcAggregateCompliant: React.FC<{
  sourcesLoading: boolean;
}> = ({ sourcesLoading }) => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);

  const [categories, setCategories] = React.useState<string[]>([]);
  const [histogram, setHistogram] = React.useState<ApexAxisChartSeries>([]);
  const [allData, setAllData] = React.useState<
    {
      title: string;
      data: ApexAxisChartSeries;
    }[]
  >([]);

  const [selectedCompliance, setSelectedCompliance] = React.useState<string>("DKIM");

  const chartSeries = histogram;
  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          customIcons: [],
        },
      },
      type: "area",
      stacked: false,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 2,
        animateGradually: {
          enabled: true,
          delay: 1,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    // theme: {
    //   palette: "palette3",
    // },
    fill: {
      opacity: 0.7,
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.8,
        opacityFrom: 0.2,
        opacityTo: 0.5,
        inverseColors: true,
        stops: [0, 100],
      },
    },
    colors: ["#13D8AA", "#D4526E", "#33B2DF"],

    stroke: {
      curve: "smooth",
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: categories,
      type: "datetime",
      labels: {
        style: {
          fontFamily: "Montserrat",
          fontSize: "10px",
          colors: theme.palette.text.secondary,
        },
      },
      axisBorder: {
        show: true,
        color: theme.palette.background.default,
      },
      axisTicks: {
        show: true,
        color: theme.palette.background.default,
      },
    },
    yaxis: {
      title: {
        style: {
          color: theme.palette.text.primary,
        },
      },
      axisBorder: {
        show: true,
        color: theme.palette.background.default,
      },
      axisTicks: {
        show: true,
        color: theme.palette.background.default,
      },
      labels: {
        style: {
          fontFamily: "Montserrat",
          fontSize: "10px",
          colors: theme.palette.text.secondary,
        },
        formatter: (e) => KFormatter(e),
      },
    },
    grid: {
      show: false,
      row: {
        colors: [theme.palette.background.paper, theme.palette.background.default],
        opacity: 1,
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      fillSeriesColor: true,
      x: {
        show: false,
      },
    },

    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "10px",
      offsetY: 10,
      labels: {
        colors: theme.palette.text.secondary,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "10px",
            labels: {
              colors: theme.palette.text.secondary,
            },
            customLegendItems: [],
          },
        },
      },
    ],
  };

  const fetchHistogram = () => {
    setLoading(true);

    FetchAnalyticsComplianceOvertime("compliant")
      .then((response) => {
        const spf_pass: number[] = [];
        const spf_fail: number[] = [];

        const dkim_pass: number[] = [];
        const dkim_fail: number[] = [];
        _.forEach(response.response, (row) => {
          spf_pass.push(
            isNaN(_.round(row?.SPF_PASS?.TOTAL?.value || 0))
              ? 0
              : _.round(row?.SPF_PASS?.TOTAL?.value || 0)
          );
          spf_fail.push(
            isNaN(_.round(row?.SPF_FAIL?.TOTAL?.value || 0))
              ? 0
              : _.round(row?.SPF_FAIL?.TOTAL?.value || 0)
          );

          dkim_pass.push(
            isNaN(_.round(row?.DKIM_PASS?.TOTAL?.value || 0))
              ? 0
              : _.round(row?.DKIM_PASS?.TOTAL?.value || 0)
          );

          dkim_fail.push(
            isNaN(_.round(row?.DKIM_FAIL?.TOTAL?.value || 0))
              ? 0
              : _.round(row?.DKIM_FAIL?.TOTAL?.value || 0)
          );
        });

        setAllData([
          {
            title: "SPF",
            data: [
              {
                name: "SPF Pass",
                data: spf_pass,
              },
              {
                name: "SPF Fail",
                data: spf_fail,
              },
            ],
          },
          {
            title: "DKIM",
            data: [
              {
                name: "DKIM Pass",
                data: dkim_pass,
              },
              {
                name: "DKIM Fail",
                data: dkim_fail,
              },
            ],
          },
        ]);
        setHistogram([
          {
            name: "DKIM Pass",
            data: dkim_pass,
          },
          {
            name: "DKIM Fail",
            data: dkim_fail,
          },
        ]);
        setCategories(
          response.response?.map((e: any) => moment(parseInt(e?.key)).format("L")) || []
        );
      })
      .catch(() => {
        toast("Error fetching compliant", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchHistogram, []);

  useEffect(() => {
    const x = [...allData];
    const values = x.find((i) => i.title == selectedCompliance);
    if (!_.isUndefined(values)) {
      setHistogram(values.data);
    }
  }, [selectedCompliance, allData]);
  return (
    <Fragment>
      <Paper style={{ height: "100%" }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item sx={{ paddingX: "8px" }}>
            <Grid container justifyContent={"space-between"}>
              <Grid item lg={7} md={7} sm={7} xs={7}>
                <Grid container alignItems="center" style={{ margin: "10px 12px" }}>
                  <Grid item xs={10}>
                    <Typography color="primary" sx={{ fontSize: 13, fontWeight: 600 }}>
                      Email Compliance
                    </Typography>
                    <Typography color="textSecondary" sx={{ fontSize: 11 }}>
                      An overview of {selectedCompliance} over selected time range.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item alignSelf={"center"}>
                <ButtonGroup>
                  <Tooltip title="View DKIM data">
                    <Button
                      variant={selectedCompliance == "DKIM" ? "contained" : "outlined"}
                      onClick={() => {
                        setSelectedCompliance("DKIM");
                      }}
                    >
                      DKIM
                    </Button>
                  </Tooltip>

                  <Tooltip title="View SPF data">
                    <Button
                      variant={selectedCompliance == "SPF" ? "contained" : "outlined"}
                      onClick={() => {
                        setSelectedCompliance("SPF");
                      }}
                    >
                      SPF
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>

          <Grid item sx={{ padding: "8px" }}>
            {loading || sourcesLoading ? (
              <Skeleton variant="rectangular" height={380} />
            ) : (
              <ResponsiveContainer height={345}>
                <ReactApexChart options={chartOptions} series={chartSeries} type="area" />
              </ResponsiveContainer>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
