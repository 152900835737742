import {
  Box,
  CircularProgress,
  Grid,
  Icon,
  Paper,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import "../../styles.scss";
import React, { Fragment, useEffect } from "react";
import {
  FetchAuthorizedIPs,
  FetchCompliantEmails,
  FetchSendingDomains,
  FetchVerifiedSources,
} from "../../../../../services/dmarc.service";
import { toast } from "../../../../../state/snackbar";
import { KFormatter } from "../../../../../util/k_formatter";

export const DmarcDashboardCards: React.FC<{ updateID: boolean }> = ({ updateID }) => {
  const [loading, setLoading] = React.useState<string[]>([]);
  const theme = useTheme();
  const primaryLightBG = alpha(theme.palette.primary.main, 0.2);

  const [assets, setAssets] = React.useState<
    {
      title: string;
      count: number;
      has_first_seen: boolean;
      icon: string;
      color: "primary" | "secondary" | "success" | "error" | "warning";
      bg_color: string;
    }[]
  >([
    {
      title: "Authorized IPs",
      count: 0,
      icon: "language",
      has_first_seen: true,
      color: "primary",
      bg_color: primaryLightBG,
    },
    {
      title: "Verified Sources",
      count: 0,
      icon: "send",
      has_first_seen: true,
      color: "primary",
      bg_color: primaryLightBG,
    },
    {
      title: "Sending Domains",
      count: 0,
      icon: "alternate_email",
      has_first_seen: true,
      color: "primary",
      bg_color: primaryLightBG,
    },
    {
      title: "Compliant Emails",
      count: 0,
      icon: "mark_email_read_outlined",
      has_first_seen: true,
      color: "primary",
      bg_color: primaryLightBG,
    },
  ]);

  const fetchSendingDomains = () => {
    setLoading((arr) => [...arr, "Sending Domains"]);
    FetchSendingDomains()
      .then((response) => {
        const count = response.data;

        setAssets((arr) =>
          arr.map((d) =>
            d.title === "Sending Domains"
              ? {
                  ...d,
                  count: count || 0,
                }
              : d
          )
        );
      })
      .catch(() => {
        toast("Error fetching domains", "error");
      })
      .finally(() => {
        setLoading((arr) => arr.filter((f) => !_.includes(["Sending Domains"], f)));
      });
  };
  const fetchVerifiedSources = () => {
    setLoading((arr) => [...arr, "Verified Sources"]);
    FetchVerifiedSources()
      .then((response) => {
        setAssets((arr) =>
          arr.map((d) =>
            d.title === "Verified Sources"
              ? {
                  ...d,
                  count: response?.count || 0,
                }
              : d
          )
        );
      })
      .catch(() => {
        toast("Error loading data", "error");
      })
      .finally(() => {
        setLoading((arr) => arr.filter((f) => !_.includes(["Verified Sources"], f)));
      });
  };
  const fetchIPCount = () => {
    setLoading((arr) => [...arr, "Authorized IPs"]);
    setAssets((arr) => arr.map((d) => (d.title === "IPs" ? { ...d, loading: true } : d)));
    FetchAuthorizedIPs()
      .then((response) => {
        setAssets((arr) =>
          arr.map((d) =>
            d.title === "Authorized IPs"
              ? {
                  ...d,
                  count: response.data,
                }
              : d
          )
        );
      })
      .catch(() => {
        toast("Error loading data", "error");
      })
      .finally(() => {
        setLoading((arr) => arr.filter((f) => f !== "Authorized IPs"));
      });
  };
  const fetchComplientEmails = () => {
    setLoading((arr) => [...arr, "Compliant Emails"]);
    FetchCompliantEmails()
      .then((response) => {
        setAssets((arr) =>
          arr.map((d) =>
            d.title === "Compliant Emails"
              ? {
                  ...d,
                  count: response?.count || 0,
                }
              : d
          )
        );
      })
      .catch(() => {
        toast("Error loading data", "error");
      })
      .finally(() => {
        setLoading((arr) => arr.filter((f) => f !== "Compliant Emails"));
      });
  };

  useEffect(() => {
    if (updateID) {
      setAssets((arr) => arr.map((x) => ({ ...x, count: 0 })));
      fetchComplientEmails();
      fetchVerifiedSources();
      fetchIPCount();
      fetchSendingDomains();
    }
  }, [updateID]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        {assets.map((d) => (
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} key={_.startCase(d.title)}>
            <Paper className="dashboard-asset-card">
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
              >
                <Box
                  className="data-wrapper"
                  style={{
                    height: 90,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box className="title-wrapper">
                    <Typography color="textSecondary" sx={{ fontSize: 11 }}>
                      {d.title}
                    </Typography>
                  </Box>
                  <Typography variant="h6" color="textPrimary">
                    {_.includes(loading, d.title) ? (
                      <CircularProgress size={28} />
                    ) : (
                      KFormatter(d.count || 0)
                    )}
                  </Typography>
                </Box>

                <Box sx={{ alignSelf: "center" }}>
                  <Box
                    sx={{
                      bgcolor: d.bg_color,
                      width: 40,
                      height: 40,
                      borderRadius: "100%",
                      alignSelf: "center",
                      textAlign: "center",
                    }}
                  >
                    <Icon color={d.color} sx={{ mt: "20%" }}>
                      {d.icon}
                    </Icon>
                  </Box>
                </Box>
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};
