import React, { Fragment, useEffect } from "react";
import { IIssueProfile } from "../module.interface";
import { FetchIssueList } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { IESDoc } from "../../App.interface";
import get from "lodash/get";
import has from "lodash/has";
import { DashboardMasterTable } from "../module.views/dashboard.master.table";
import range from "lodash/range";
import moment from "moment";
import { UUIDGenerator } from "../../util/uuid";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  LinearProgress,
  Link,
  Paper,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { ColorShades } from "../../util/color-shades";
import { UpgradeInfo } from "../module.views/Upgrade";
import { AppMasterTable } from "../views/app.table";
import { IssueProgressStatus } from "./views/issue_progress_status";
import { IssueSeverity } from "./views/issue_severity";
import { AppDrawer } from "../views/Drawer";
import { BlurInfo } from "../module.views/BlurInfo";
import { IssuesMetaData } from "./issues.meta_data";

export const IssueList: React.FC<{ issue: IIssueProfile; enabled: boolean }> = ({
  issue,
  enabled,
}) => {
  const theme = useTheme<Theme>();
  const [loading, setLoading] = React.useState(true);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [totalDocs, setTotalDocs] = React.useState<number>(0);
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc>();
  const fetchIssueList = () => {
    if (!enabled) {
      setLoading(false);
      return;
    }
    setLoading(true);
    FetchIssueList(issue.issueName)
      .then((response) => {
        setDocs(response.data);
        setTotalDocs(
          has(response, "metadata.total.value")
            ? (get(response, "metadata.total.value") as unknown as number)
            : 0
        );
      })
      .catch((err) => {
        const receivedError = has(err, "errors.errors[0]")
          ? (get(err, "errors.errors[0]") as unknown as string)
          : "";
        toast(
          receivedError ? receivedError : `Error in fetching issues of ${issue.issueName}`,
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchIssueList, [enabled, issue.issueName]);
  return (
    <Fragment>
      {enabled ? (
        <>
          {loading ? (
            <LinearProgress />
          ) : (
            <Box>
              {totalDocs > docs.length ? (
                <Box marginBottom={2} paddingLeft={2} maxWidth={720}>
                  <Alert severity="warning" variant="outlined">
                    <AlertTitle>
                      <Typography variant="h6" color={"inherit"} fontSize={"16px"} fontWeight={600}>
                        Restricted View
                      </Typography>
                    </AlertTitle>
                    <Typography
                      variant="subtitle1"
                      color={"inherit"}
                      fontSize={"12px"}
                      fontWeight={500}
                      marginBottom={1.5}
                    >
                      You are viewing a free version of your issues with {docs.length} issue details
                      <br />
                      To explore more information about your issues and assets please upgrade your
                      subscription.
                    </Typography>
                    <Button
                      variant="contained"
                      component={Link}
                      href={`https://${
                        process.env.NODE_ENV === "development"
                          ? "cyno.edxdev.link"
                          : "start.ctm360.com"
                      }/pricing`}
                      target="_blank"
                      size="small"
                    >
                      <Typography
                        variant="h6"
                        textTransform={"capitalize"}
                        fontWeight={600}
                        fontSize={"12px"}
                      >
                        Upgrade Now
                      </Typography>
                    </Button>
                  </Alert>
                </Box>
              ) : null}
              <IssuesMetaData
                selectedIssue={issue.issueName}
                severity={issue.severity}
                fixingEffort={0}
                issueType={""}
                issueCategory={""}
              />
              <AppMasterTable
                loading={loading}
                dataSource={""}
                name={""}
                title={""}
                colDefs={[
                  {
                    masterColumnName: "meta.asset",
                    masterColumnTitle: "Asset",
                    renderType: "url",
                    filterable: true,
                    sortable: true,
                  },

                  {
                    masterColumnName: "progress_status",
                    masterColumnTitle: "Status",
                    renderType: "progress_status",
                    filterable: true,
                    sortable: true,
                    cellRender: (params) => <IssueProgressStatus doc={params} />,
                  },
                  {
                    masterColumnName: "severity",
                    masterColumnTitle: "Severity",
                    renderType: "severity",
                    filterable: true,
                    sortable: true,
                    cellRender: (params) => <IssueSeverity doc={params} />,
                  },
                  {
                    masterColumnName: "assigned_to",
                    masterColumnTitle: "Assigned To",
                    renderType: "text",
                    filterable: true,
                    sortable: true,
                  },
                  {
                    masterColumnName: "meta.first_seen",
                    masterColumnTitle: "First Seen",
                    renderType: "date",
                    filterable: true,
                    sortable: true,
                  },
                  {
                    masterColumnName: "meta.last_seen",
                    masterColumnTitle: "Last Seen",
                    renderType: "date",
                    filterable: true,
                    sortable: true,
                  },
                ]}
                docs={docs}
                checkedDocs={[]}
                totalDocs={10}
                pageIndex={0}
                pageSize={docs.length}
                sortField={""}
                sortOrder={"desc"}
                enablePagination={false}
                enableCheckBox={false}
                enableSelection={true}
                enableBrandColum={false}
                onChangeSortField={() => null}
                onChangeSortOrder={() => null}
                onChangePageIndex={() => null}
                onChangePageSize={() => null}
                onChangeCheckedDocs={() => null}
                onChangeSelectedDoc={setSelectedDoc}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          <DashboardMasterTable
            loading={false}
            dataSource="ds_asset_feed_dashboard"
            name="dashboard_asset_feed"
            title="Asset Feed"
            colDefs={[
              {
                masterColumnName: "brand",
                masterColumnTitle: "Brand",
                renderType: "text",
                filterable: true,
                sortable: true,
              },
              {
                masterColumnName: "asset",
                masterColumnTitle: "Asset",
                renderType: "url",
                filterable: true,
                sortable: true,
              },
              {
                masterColumnName: "cve_id",
                masterColumnTitle: "CVE ID",
                renderType: "text",
                filterable: true,
                sortable: true,
              },
              {
                masterColumnName: "progress_status",
                masterColumnTitle: "Status",
                renderType: "progress_status",
                filterable: true,
                sortable: true,
              },
              {
                masterColumnName: "severity",
                masterColumnTitle: "Severity",
                renderType: "severity",
                filterable: true,
                sortable: true,
              },
              {
                masterColumnName: "assigned_to",
                masterColumnTitle: "Assigned To",
                renderType: "text",
                filterable: true,
                sortable: true,
              },
              {
                masterColumnName: "first_seen",
                masterColumnTitle: "First Seen",
                renderType: "date",
                filterable: true,
                sortable: true,
              },
              {
                masterColumnName: "last_seen",
                masterColumnTitle: "Last Seen",
                renderType: "date",
                filterable: true,
                sortable: true,
              },
            ]}
            docs={range(25).map((x) => ({
              _id: x.toString(),
              "@timestamp": moment(new Date()).utc().valueOf(),
              brand: `${x}`,
              asset: `${UUIDGenerator()}.com`,

              cve_id: `${x}`,

              progress_status: `${x}`,

              severity: `${x}`,

              assigned_to: `${x}`,

              first_seen: `${x}`,

              last_seen: `${x}`,
            }))}
          />
          <Box
            width={"500px"}
            margin={"auto"}
            position={"absolute"}
            top={"150px"}
            left={"calc(50% - 250px)"}
            boxShadow={`9px 13px 82px 46px ${ColorShades(
              theme.palette.background.paper,
              theme.palette.mode === "dark" ? "800" : "300"
            )}`}
          >
            <Paper>
              <UpgradeInfo />
            </Paper>
          </Box>
        </>
      )}
      {selectedDoc ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={issue.issueName}
          onClose={() => {
            setSelectedDoc(undefined);
          }}
        >
          <BlurInfo />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
