import React, { Fragment } from "react";
import { IESDoc } from "../../../App.interface";
import { Chip, Theme, useTheme } from "@mui/material";
import startCase from "lodash/startCase";
import useDeepCompareEffect from "use-deep-compare-effect";
import { getSeverityStr } from "../../../util/severity-mappings";

export const IssueSeverity: React.FC<{ doc: IESDoc }> = ({ doc }) => {
  const theme = useTheme<Theme>();
  const [progressStatus, setProgressStatus] = React.useState(doc.severity);
  useDeepCompareEffect(() => {
    setProgressStatus(doc.severity);
  }, [doc]);
  return (
    <Fragment>
      <Chip
        size="small"
        className="severity-chip"
        style={{
          color: theme?.severity[getSeverityStr(progressStatus)],
          borderColor: theme?.severity[getSeverityStr(progressStatus)],
          textAlign: "center",
        }}
        variant="outlined"
        label={startCase(getSeverityStr(progressStatus))}
      />
    </Fragment>
  );
};
