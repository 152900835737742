import React, { Fragment } from "react";
import { IESDoc } from "../../../App.interface";
import { Box, Icon, Stack, Typography } from "@mui/material";
import {
  issueProgressStatusColor,
  issueProgressStatusLabel,
} from "../issues-progress-status-mappings";
import startCase from "lodash/startCase";
import useDeepCompareEffect from "use-deep-compare-effect";

export const IssueProgressStatus: React.FC<{ doc: IESDoc }> = ({ doc }) => {
  const [progressStatus, setProgressStatus] = React.useState(doc.progress_status || 1);
  useDeepCompareEffect(() => {
    setProgressStatus(doc.progress_status || 1);
  }, [doc]);
  return (
    <Fragment>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Box className="priority-icon" style={{ color: issueProgressStatusColor(progressStatus) }}>
          <Icon fontSize="inherit" color="inherit">
            model_training
          </Icon>
        </Box>
        <Box className="priority-label">
          <Typography variant="subtitle2" component="span" color="textPrimary">
            {startCase(issueProgressStatusLabel(progressStatus))}
          </Typography>
        </Box>
      </Stack>
    </Fragment>
  );
};
