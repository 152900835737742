import {
  AccordionSummary,
  Icon,
  Grid,
  Typography,
  Box,
  Skeleton,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { Fragment, ReactNode } from "react";
import "../../../styles.scss";

interface Type {
  expanded: boolean;
  list: { label: string; count: string; color: string; isCenter?: boolean }[];
  header: string | ReactNode;
  detection_source: string;
  headerNode?: boolean;
  totalItem: { label: string; count: number; color: string };
  loading: boolean;
}
export default function DmarcAppAccordingPanel({
  expanded,
  header,
  list,
  totalItem,
  headerNode,
  loading,
  detection_source,
}: Type) {
  return (
    <Fragment>
      <Box className="panel-header-wrapper">
        <AccordionSummary className="panel-header">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  {loading ? (
                    <Skeleton variant="circular" height={20} width={20} style={{ margin: 10 }} />
                  ) : (
                    <Icon
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "8px",
                      }}
                    >
                      {expanded ? "expand_more" : "chevron_right"}
                    </Icon>
                  )}
                </Grid>
                <Grid item>
                  {loading ? (
                    <Skeleton width={150} />
                  ) : (
                    <Stack direction={"row"} spacing={1}>
                      <Box>
                        {headerNode ? (
                          header
                        ) : (
                          <Typography variant="h6" color="textPrimary" className="accordion-title">
                            {header}
                          </Typography>
                        )}
                      </Box>
                      {detection_source != "dns" ? (
                        <Box alignSelf={"center"}>
                          <Tooltip title="Not available for freemium users">
                            <Icon color="disabled" style={{ fontSize: 14 }}>
                              lock
                            </Icon>
                          </Tooltip>
                        </Box>
                      ) : null}
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Box className="severity-wrapper">
                    {loading ? (
                      <Fragment>
                        {[1, 2].map((s) => (
                          <Box className={"severity-box text-center"} key={`${s}__sevbox`}>
                            <Skeleton width={50} />
                            <Skeleton />
                          </Box>
                        ))}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {list.map((s, i) => (
                          <Box
                            className={`severity-box text-${s.isCenter ? "center" : "start"}`}
                            key={`${s.label}__${i}_sevbox`}
                          >
                            <Typography
                              variant="h6"
                              className="severity-count"
                              style={{ color: s.color }}
                            >
                              {s.count.toLocaleString()}
                            </Typography>
                            <Typography
                              variant="h6"
                              className="severity-label"
                              color="textSecondary"
                            >
                              {s.label}
                            </Typography>
                          </Box>
                        ))}
                      </Fragment>
                    )}
                  </Box>
                </Grid>
                <Grid item>
                  {loading ? (
                    <Box className="issue-count">
                      <Skeleton width={50} />
                      <Skeleton />
                    </Box>
                  ) : (
                    <Box className="issue-count">
                      <Typography variant="h6" color="error" className="issue-count-text">
                        {totalItem.count.toLocaleString()}
                      </Typography>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className="issue-count-label"
                        style={{ color: totalItem.color }}
                      >
                        {totalItem.label}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Box>
    </Fragment>
  );
}
