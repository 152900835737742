import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { getSourceVersion } from "./util/user.attributes";
import { setLatestBuildHash } from "./util/cache-burster";
import { setSessionSignature } from "./util/user.activity.fingerprint";
import { AppKeyCloak } from "./auth/Keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { EDXLabsApp } from "./App";
import { createRoot } from "react-dom/client";
import { init, setTag, BrowserTracing } from "@sentry/react";
import { ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations";
const CURRENT_SOURCE_HASH = getSourceVersion();
const LATEST_SOURCE_HASH = process.env.REACT_APP_SOURCE_CODE_HASH;
if (CURRENT_SOURCE_HASH !== LATEST_SOURCE_HASH) {
  setLatestBuildHash(LATEST_SOURCE_HASH);
  window.location.reload();
}
const eventLogger = (event: unknown) => {
  if (event === "onAuthSuccess") {
    setSessionSignature();
  }
};

const tokenLogger = (tokens: any) => {
  localStorage.setItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.sso.authtoken`, tokens.token);
};
init({
  dsn: "https://1e56379df7313c73be624a1540cd7caa@o4504281528467456.ingest.sentry.io/4506490334609408",
  release: LATEST_SOURCE_HASH,
  integrations: [new BrowserTracing(), new ExtraErrorDataIntegration()],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === "production",
});

setTag("Source_hash", `${getSourceVersion()}`);
setTag("Platform", `${window.location.hostname.replaceAll(".ctm360.com", "")}`);
const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  <ReactKeycloakProvider
    authClient={AppKeyCloak}
    initOptions={{ onLoad: "login-required" }}
    onEvent={eventLogger}
    onTokens={tokenLogger}
  >
    <EDXLabsApp />
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
