import { Box, Dialog, DialogContent, DialogTitle, Grid, Paper, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { FetchRiskProfile } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { OverallScoreCard } from "../dashboard/overall.score.card";
import { IRiskProfile } from "../module.interface";
import { GradeGauge } from "../module.views/grade.guage";
import { ScoreCardSkeleton } from "./score-card.loading";
import { IssueBreakdownPieChart } from "../dashboard/issue_breakdown_pie_chart";
import { IssueSeverityPieChart } from "../dashboard/issue_severity_pie_chart";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useObservable } from "../../hooks/use-observable";
import { BrandService } from "../../services/brand.service";
import { UUIDGenerator } from "../../util/uuid";
import { RiskProfileDetails } from "./risk_profile_details";

export const RiskProfileModule: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [riskProfile, setRiskProfile] = React.useState<IRiskProfile[]>([]);
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const [updateId, setUpdateId] = React.useState("");
  const [selectedProfile, setSelectedProfile] = React.useState<IRiskProfile>();

  const fetchData = () => {
    setLoading(true);
    FetchRiskProfile()
      .then((response) => {
        setRiskProfile(response);
      })
      .catch(() => {
        toast("Error in loading the risk profile", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onCloseModel = () => {
    setSelectedProfile(undefined);
  };
  useEffect(fetchData, [updateId]);
  useDeepCompareEffect(() => {
    setUpdateId(UUIDGenerator());
  }, [selectedMemberIds]);
  return (
    <Fragment>
      <Box marginBottom={2}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <OverallScoreCard updateId={updateId} />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <IssueBreakdownPieChart updateId={updateId} />
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <IssueSeverityPieChart updateId={updateId} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Typography variant="h6" color="primary" fontWeight={600} marginBottom={0.5}>
          Score Breakdown
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" fontSize={"13px"} fontWeight={500}>
          Classification of the overall score based on the detected issues categories
        </Typography>
        <Box>
          {loading ? (
            <ScoreCardSkeleton />
          ) : (
            <Grid container spacing={2}>
              {riskProfile.map((x) => (
                <Grid key={x.key} item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <Paper
                    className="category-score-card-wrapper"
                    onClick={() => {
                      setSelectedProfile(x);
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    <Box className="category-chart">
                      <GradeGauge score={x.AVERAGE.value} width={100} height={100} />
                    </Box>
                    <Box className="category-score-card-content">
                      <Typography variant="h6" className="category-title">
                        {x.key}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className="category-description"
                        component="p"
                        color="textSecondary"
                        gutterBottom
                      >
                        {x.DESCRIPTION.buckets[0].key}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className="category-description"
                        component="p"
                        color="error"
                      >
                        {x.TOTAL_ISSUES.value} Issues Detected
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Box>
      {selectedProfile ? (
        <Dialog maxWidth="md" fullWidth open={true} onClose={onCloseModel}>
          <DialogTitle
            color="textSecondary"
            className="issues-dialog-title"
            id="simple-dialog-title"
          >
            {selectedProfile.key}
          </DialogTitle>
          <DialogContent>
            <RiskProfileDetails doc={selectedProfile} />
          </DialogContent>
        </Dialog>
      ) : null}
    </Fragment>
  );
};
