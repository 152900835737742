import { Badge, Icon, IconButton, Tooltip } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { FetchLookupHistory } from "../../services/dashboard.service";
import { IESDoc } from "../../App.interface";
import { AppDrawer } from "../views/Drawer";
import { AppMasterTable } from "../views/app.table";

export const HistoryLookups: React.FC<{ updateId: string }> = ({ updateId }) => {
  const [loading, setLoading] = React.useState(false);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [open, setOpen] = React.useState(false);
  const fetchLookUpHistory = () => {
    setLoading(true);
    FetchLookupHistory()
      .then((response) => {
        setDocs(response);
      })
      .catch(() => {
        //
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchLookUpHistory, [updateId]);
  return (
    <Fragment>
      {loading ? null : docs.length ? (
        <Tooltip title={`View your past ${docs.length.toLocaleString()} lookups`}>
          <Badge badgeContent={docs.length} color="primary">
            <IconButton color="primary" onClick={() => setOpen(true)}>
              <Icon>history</Icon>
            </IconButton>
          </Badge>
        </Tooltip>
      ) : null}
      {open ? (
        <AppDrawer
          anchor={"right"}
          open={open}
          title={"Lookup History"}
          onClose={() => {
            setOpen(false);
          }}
        >
          <AppMasterTable
            loading={false}
            dataSource={""}
            name={""}
            title={""}
            colDefs={[
              {
                masterColumnName: "@timestamp",
                masterColumnTitle: "Date/Time",
                renderType: "date_time",
                sortable: false,
                filterable: false,
              },
              {
                masterColumnName: "name",
                masterColumnTitle: "Organization",
                renderType: "text",
                sortable: false,
                filterable: false,
              },
              {
                masterColumnName: "score",
                masterColumnTitle: "Score",
                renderType: "text",
                sortable: false,
                filterable: false,
              },
              {
                masterColumnName: "grade",
                masterColumnTitle: "Grade",
                renderType: "text",
                sortable: false,
                filterable: false,
              },
            ]}
            docs={docs}
            checkedDocs={[]}
            totalDocs={0}
            pageIndex={0}
            pageSize={0}
            sortField={""}
            sortOrder={"desc"}
            enablePagination={false}
            enableCheckBox={false}
            enableSelection={false}
            enableBrandColum={false}
            onChangeSortField={() => null}
            onChangeSortOrder={() => null}
            onChangePageIndex={() => null}
            onChangePageSize={() => null}
            onChangeCheckedDocs={() => null}
            onChangeSelectedDoc={() => null}
          />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
