import { createTheme, CssBaseline, ThemeProvider, StyledEngineProvider } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import _ from "lodash";
import { SnackbarProvider } from "notistack";
import React, { Fragment, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { $keyCloak } from "./state/keycloak";
import { user$ } from "./state/user";
import { DefaultTheme, CuriosityTheme, SweatLeaf } from "./theme";
import { ParseUserAttributes } from "./util/parse-user";
import { UserPreferences } from "./util/user.preferences";
import { AppMasterView } from "./views/Master";
import { Snackbar } from "./views/Snackbar";
import "./App.styles.scss";
import { useObservable } from "./hooks/use-observable";
import { SocketService } from "./services/socket.service";
import { getCurrentHub, Replay } from "@sentry/react";
import { setUser } from "@sentry/react";

export const EDXLabsApp: React.FC = () => {
  const { keycloak, initialized } = useKeycloak();
  const userSelectedTheme = useObservable(
    UserPreferences.local(`${process.env.REACT_APP_SSO_CLIENT_ID}.theme`)
  );

  const theme = createTheme(
    userSelectedTheme === "default-theme"
      ? DefaultTheme
      : userSelectedTheme === "curiosity"
      ? CuriosityTheme
      : userSelectedTheme === "default-light"
      ? SweatLeaf
      : DefaultTheme
  );
  useEffect(() => {
    if (initialized) {
      if (keycloak.idTokenParsed) {
        if (_.has(keycloak.idTokenParsed, `${process.env.REACT_APP_SSO_CLIENT_ID}_policy`)) {
          const modifiedAttributes = ParseUserAttributes(keycloak.idTokenParsed as any);
          if (!modifiedAttributes.internal) {
            const client = getCurrentHub().getClient();
            if (client && client.addIntegration) {
              client.addIntegration(new Replay());
            }
          }
          setUser({
            email: modifiedAttributes?.email,
          });
          $keyCloak.next(modifiedAttributes.internal);
          SocketService.onSocketToken(keycloak.token as string);
          if (!_.isEmpty(modifiedAttributes)) {
            user$.next(modifiedAttributes);
          } else {
            window.location.reload();
          }
        } else {
          window.location.replace("https://start.ctm360.com");
        }
      }
    }
  }, [initialized, keycloak]);
  return (
    <Fragment>
      {initialized ? (
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider maxSnack={50} preventDuplicate autoHideDuration={3000}>
                <AppMasterView theme={userSelectedTheme} />
                <Snackbar />
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      ) : null}
    </Fragment>
  );
};
