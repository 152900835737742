import { Grid, Skeleton, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { ResponsiveContainer } from "recharts";
import "../../styles.scss";
export const DmarcProgressBarChart: React.FC<{
  title: string;
  value: number;
  size: number;
  loading: boolean;
}> = ({ title, value, size, loading }) => {
  const theme = useTheme();
  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      height: size,
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -160,
        endAngle: 200,
        hollow: {
          margin: 0,
          size: "82%",
          background: theme.palette.background.paper,
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
        },
        track: {
          background: "#fff",
          strokeWidth: "100%",
          margin: 0, // margin is in pixels
        },

        dataLabels: {
          show: true,

          name: {
            show: true,
            offsetY: 20,
            color: theme.palette.text.secondary,
            fontWeight: 500,
            fontSize: "12px",
            fontFamily: "Montserrat",
          },
          value: {
            formatter: (val) => `${val}%`,
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: "25px",
            fontFamily: "Montserrat",
            show: true,
            offsetY: -15,
          },
        },
      },
    },

    fill: {
      type: "gradient",
      colors: ["#f90361", "#c2085b"],
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#f90361", "#c2085b"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: [title],
  };

  const chartSeries = [value];

  return (
    <Fragment>
      {loading ? (
        <Grid container justifyContent="center" sx={{ mt: 1.5 }}>
          <Grid item>
            <Skeleton variant="circular" width={size - size / 4} height={size - size / 4} />
          </Grid>
        </Grid>
      ) : (
        <ResponsiveContainer height={size} width={size} className={"center-responsive-container"}>
          <ReactApexChart options={chartOptions} series={chartSeries} type="radialBar" />
        </ResponsiveContainer>
      )}
    </Fragment>
  );
};
