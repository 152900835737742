import _get from "lodash/get";
import _has from "lodash/has";
import _forEach from "lodash/forEach";
import _startCase from "lodash/startCase";
import { useTheme, Theme, Box, Icon, Typography, Chip } from "@mui/material";
import { Alert } from "@mui/material";

import React, { Fragment, ReactNode, useCallback, useEffect } from "react";
import { IESDoc } from "../../../App.interface";

interface IIPDiscovery {
  doc: IESDoc;
}
interface ITreeData {
  id: string;
  title: string;
  value: string;
  level: number;
  connectorInfo: string;
  children: ITreeData[];
}
export const IPDiscovery: React.FC<IIPDiscovery> = ({ doc }) => {
  const theme = useTheme<Theme>();
  const [sourceDetails, setSourceDetails] = React.useState<ITreeData[]>([]);
  const getIcon = (id: string): string => {
    switch (id) {
      case "domain":
        return "language";
      case "subdomain":
        return "desktop_mac";
      case "ip":
        return "room";
      case "ssl_certificate":
        return "security";
      case "ip_range":
        return "architecture";
      case "dns_record":
        return "dns";
      default:
        return "info";
    }
  };
  const getTitle = (id: string): string => {
    switch (id) {
      case "domain":
        return "Domain";
      case "subdomain":
        return "Sub Domain";
      case "ip":
        return "IP";
      case "ssl_certificate":
        return "SSL Certificate";
      case "ip_range":
        return "IP Range";
      case "dns_record":
        return "DNS Records";
      default:
        return _startCase(id);
    }
  };
  const renderTree = (params: ITreeData[]): ReactNode => {
    const child: any[] = [];
    _forEach(params, (_child) => {
      child.push(
        <ul
          key={Math.random().toString()}
          className={`tree-view-parent-node ${
            _child.children.length ? "siblings-tree" : "single-tree"
          } ${_child.level > 0 ? "child-tree-node" : "master-tree-node"}`}
        >
          <li
            className="tree-view-child-node"
            style={{ backgroundColor: theme.palette.background.paper }}
          >
            <Box className="tree-content-wrapper">
              <Box
                className="icon"
                style={{
                  borderColor: theme.palette.text.secondary,
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <Icon fontSize="inherit" color="primary">
                  {getIcon(_child.id)}
                </Icon>
              </Box>
              <Box className="values" style={{ backgroundColor: theme.palette.background.paper }}>
                <Typography variant="h6" color="textPrimary" className="title">
                  {getTitle(_child.id)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  component="p"
                  className="value"
                >
                  {_child.value}
                </Typography>
              </Box>
            </Box>

            <Box
              className="horizontal-connector"
              style={{ borderColor: theme.palette.text.secondary }}
            ></Box>
            <Box
              className="vertical-connector"
              style={{ borderColor: theme.palette.text.secondary }}
            ></Box>
          </li>
          <li className="tree-view-connector-info">
            {_child.connectorInfo ? (
              <Chip size="small" label={_startCase(_child.connectorInfo)} color="secondary" />
            ) : null}
          </li>
          {renderTree(_child.children)}
        </ul>
      );
    });
    return child;
  };
  const findChildNodes = useCallback(
    (
      source: {
        source: string;
        value?: string | undefined;
        state?: string | undefined;
      }[],
      index: number
    ): ITreeData[] => {
      const ParsedChildren: ITreeData[] = [];
      if (index + 1 < source.length) {
        if (source[index + 1]) {
          const v = source[index + 1];
          ParsedChildren.push({
            level: index + 1,
            id: v.source,
            title: v.source,
            value: v.value ? v.value : "-",
            connectorInfo: v.state ? v.state : "",
            children: findChildNodes(source, index + 1),
          });
        }
      }
      return ParsedChildren;
    },
    []
  );
  useEffect(() => {
    if (_has(doc, "meta.source_detail")) {
      const rawSource: { source: string; value?: string; state?: string }[] =
        _get(doc, "meta.source_detail") || [];
      if (rawSource.length) {
        const ParsedSourceDetails: ITreeData[] = [
          {
            level: 0,
            id: rawSource[0].source,
            title: rawSource[0].source,
            value: rawSource[0].value ? rawSource[0].value : "",
            connectorInfo: rawSource[0].state ? rawSource[0].state : "",
            children: findChildNodes(rawSource, 0),
          },
        ];

        setSourceDetails(ParsedSourceDetails);
      }
    }
  }, [doc, findChildNodes]);
  return (
    <Fragment>
      {sourceDetails.length ? (
        <Fragment>
          <Box className="source-details-treeview">{renderTree(sourceDetails)}</Box>
        </Fragment>
      ) : (
        <Alert>No Data available </Alert>
      )}
    </Fragment>
  );
};
