import isEmpty from "lodash/isEmpty";
import forEach from "lodash/forEach";

import { UUIDGenerator } from "./uuid";
import { AppMD5Hash } from "./md5.hash";
export const stringToColor = (str: string): string => {
  if (!str) {
    return "#5d4037";
  }
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  return colour;
};

export const GenerateColor = (items?: string[]): string | string[] => {
  if (!isEmpty(items)) {
    const colors: string[] = [];
    forEach(items, (item) => {
      colors.push(stringToColor(AppMD5Hash(item)));
    });
    return colors;
  } else {
    return stringToColor(AppMD5Hash(UUIDGenerator()));
  }
};
