import { Box, Grid, Skeleton } from "@mui/material";

import React, { Fragment, useEffect } from "react";
import { toast } from "../../../state/snackbar";
import { AppCard } from "../../views/app.card";
import { FetchPhishingStats } from "../../../services/cbs.service";
import { DetectionOvertimeChart } from "../../views/ModulesOverTimeChart";
import { DetectionPieChart } from "../../views/ModulesPieChart";
import _ from "lodash";

export const PhishingStats: React.FC<{ type: string }> = ({ type }) => {
  const [requestCount, setRequestCount] = React.useState<number>(0);
  const [histogram, setHistogram] = React.useState<any[]>([]);
  const [cardExpiry, setCardExpiry] = React.useState<any[]>([]);
  const fetchStatus = () => {
    setRequestCount((count) => count + 1);
    setCardExpiry([]);

    FetchPhishingStats()
      .then((response) => {
        setCardExpiry(response.LEAK_TYPE);
        setHistogram(
          response.HISTOGRAM.map((i) => ({
            timestamp: i.key,
            doc_count: i.doc_count,
          }))
        );
      })
      .catch(() => {
        toast(`Error loading stats for ${_.startCase(type)}`, "error");
      })
      .finally(() => {
        setRequestCount((count) => count - 1);
      });
  };

  useEffect(fetchStatus, [type]);

  return (
    <Fragment>
      <Box margin={"16px 0"}>
        <Box marginBottom={2}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xl={8} lg={8} md={6} sm={6} xs={12}>
                <AppCard
                  name={"sources"}
                  title={"Phishing Detected"}
                  description={"Phishing attacks detected overtime"}
                >
                  {requestCount > 0 ? (
                    <Skeleton width={"100%"} height={300} />
                  ) : (
                    <DetectionOvertimeChart
                      data={histogram}
                      axisTitle={_.startCase(type)}
                      seriesName={_.startCase(type)}
                    />
                  )}
                </AppCard>
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <AppCard
                  name={"leak_type"}
                  title={"Detection Source"}
                  description={"Findings classified based on the Detection Source"}
                >
                  {requestCount > 0 ? (
                    <Skeleton
                      variant="circular"
                      width={300}
                      height={300}
                      style={{ margin: "auto" }}
                    />
                  ) : (
                    <DetectionPieChart
                      data={cardExpiry.map((x) => ({
                        key: x.key,
                        doc_count: x.doc_count,
                      }))}
                    />
                  )}
                </AppCard>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
