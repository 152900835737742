import { Box, Typography, Divider, Paper } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../App.interface";
import { FetchAssetFeed } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { FeedMasterTable } from "./feed.master.table";

export const FreemiumAssetFeed: React.FC<{ updateId: string }> = ({ updateId }) => {
  const [loading, setLoading] = React.useState(true);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const fetchDocs = () => {
    setLoading(true);
    FetchAssetFeed()
      .then((response) => {
        setDocs(response.map((x) => ({ ...x })));
      })
      .catch(() => {
        toast("Error in loading asset feed", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchDocs, [updateId]);
  return (
    <Fragment>
      <Paper className="dashboard-cards">
        <Box className="header">
          <Typography variant="h6" color="primary" className="main-heading">
            Asset Feed
          </Typography>
          <Typography variant="body2" color="textSecondary" className="sub-heading">
            Latest detected assets
          </Typography>
        </Box>
        <Divider />
        <Box className="contents dashboard-contents-wrapper">
          <FeedMasterTable
            loading={loading}
            dataSource="ds_asset_feed_dashboard"
            name="dashboard_asset_feed"
            title="Asset Feed"
            colDefs={[
              {
                masterColumnName: "@timestamp",
                masterColumnTitle: "Discovery Time",
                renderType: "time_since",
                filterable: true,
                sortable: true,
              },
              {
                masterColumnName: "value",
                masterColumnTitle: "Asset",
                renderType: "text",
                filterable: true,
                sortable: true,
              },
              {
                masterColumnName: "security_score",
                masterColumnTitle: "Security Grade",
                renderType: "risk_grade",
                filterable: true,
                sortable: true,
              },
            ]}
            docs={docs}
          />
        </Box>
      </Paper>
    </Fragment>
  );
};
