import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Paper,
} from "@mui/material";
import React, { Fragment } from "react";
interface IUrlWarning {
  url: string;
  onClose: () => void;
}
export const UrlWarning: React.FC<IUrlWarning> = ({ url, onClose }) => {
  const onNavigateUrl = () => {
    window.open(url, "_blank")?.focus();
    onClose();
  };
  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="sm"
        className="url-warning-model"
      >
        <DialogTitle id="customized-dialog-title">External Link Warning</DialogTitle>
        <DialogContent>
          <Paper variant="outlined" className="url-text-wrapper">
            <Typography variant="inherit" component={"pre"} className="url-text" color="secondary">
              {url}
            </Typography>
          </Paper>
          <Typography variant="subtitle1" className="url-warning-text">
            Your are about to open an external link in a new tab.
            <br />
            Do you wish to continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button autoFocus color="primary" variant="contained" onClick={onNavigateUrl}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
