import { Box, Skeleton, Theme, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import { HackerViewShield } from "./hv.shield";
import "./styles.scss";
export const HackerViewGradeShield: React.FC<{
  loading: boolean;
  score: number;
  grade: string;
  shieldDescription?: string;
  hideOffset?: boolean;
  shieldSize?: number;
  scoreSize?: number | string;
}> = ({ score, grade, loading, shieldDescription, hideOffset, shieldSize, scoreSize }) => {
  const theme = useTheme<Theme>();

  return (
    <Fragment>
      <Box
        style={{
          fill: theme.palette.background.default,
        }}
        className={`${loading ? "loading-grade" : ""} hackerview-grade-container`}
      >
        <HackerViewShield loading={loading} grade={grade} shieldSize={shieldSize} />
        <Box className="grade-shield">
          <Box className="grade-score-wrapper">
            {loading ? (
              <Skeleton variant="text" width={170} height={40}></Skeleton>
            ) : (
              <Typography
                variant="h4"
                fontSize={scoreSize}
                className="grade-score-text"
                color="textPrimary"
              >
                {score}
                {hideOffset ? null : (
                  <Typography
                    variant="caption"
                    component="span"
                    className="grade-score-full"
                    color="textPrimary"
                  >
                    /100
                  </Typography>
                )}
              </Typography>
            )}
          </Box>

          {shieldDescription ? (
            loading ? (
              <Skeleton variant="text"></Skeleton>
            ) : (
              <Box className="grade-comment-wrapper">
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  className="grade-comment-text"
                  color="textSecondary"
                >
                  {shieldDescription}
                </Typography>
              </Box>
            )
          ) : null}
        </Box>
      </Box>
    </Fragment>
  );
};
