import React, { Fragment, useEffect } from "react";
import { IncidentTakedownProgress } from "../../../services/cbs.service";
import { toast } from "../../../state/snackbar";
import { IESDoc } from "../../../App.interface";
import {
  Chip,
  CircularProgress,
  Icon,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { ColorOpacity } from "../../../util/color_opacity";

export const TakedownProgress: React.FC<{ id: string; status: string; comment: string }> = ({
  id,
  status,
  comment,
}) => {
  const theme = useTheme<Theme>();
  const [loading, setLoading] = React.useState(true);
  const [doc, setDoc] = React.useState<IESDoc>();
  const fetchStatus = () => {
    setLoading(true);
    IncidentTakedownProgress(id)
      .then((response) => {
        setDoc(response.data[0]);
      })
      .catch((err) => {
        const receivedError = _.has(err, "errors.errors[0]")
          ? (_.get(err, "errors.errors[0]") as unknown as string)
          : "";
        toast(receivedError ? receivedError : "Error in fetching takedown status", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const IncidentStatusFormatted = (status: string): string => {
    switch (status) {
      case "resolved":
      case "closed":
        return "Resolved";
      case "wip":
        return "In Progress";
      case "rejected":
        return "Rejected";
      default:
        return "Under Review";
    }
  };
  const incidentStatusColor = (params: string): string => {
    switch (params) {
      case "resolved":
      case "closed":
        return "#22c55e";
      case "wip":
        return theme.palette.secondary.main;
      case "rejected":
        return "#e74c3c";
      default:
        return theme.palette.info.main;
    }
  };
  useEffect(fetchStatus, [id]);
  return (
    <Fragment>
      {loading ? (
        <CircularProgress size={"22px"} />
      ) : (
        <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
          <Chip
            variant="filled"
            size="small"
            label={
              <Typography variant="subtitle1" fontSize={"12px"} fontWeight={500}>
                {IncidentStatusFormatted(doc ? doc.incident.status : status)}
              </Typography>
            }
            sx={{
              borderRadius: "5px",
              color: incidentStatusColor(doc ? doc.incident.status : status),
              backgroundColor: ColorOpacity(
                incidentStatusColor(doc ? doc.incident.status : status),
                0.15
              ),
            }}
          />
          {status === "rejected" ? (
            <Tooltip title={comment}>
              <Icon color="warning">warning</Icon>
            </Tooltip>
          ) : null}
        </Stack>
      )}
    </Fragment>
  );
};
