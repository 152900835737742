import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { IEasmPlans, IDrpPricingPlans } from "../module.interface";
import { IESDoc } from "../../App.interface";
import { AppAssetsSectionBox } from "../views/app.section.box";
import { AppDocView } from "../views/app.doc.view";
import _ from "lodash";
import { GenerateTakedownPlan } from "./drp/takedown";
interface PricingReview {
  EasmPlan: IEasmPlans | undefined;
  DrpPlan: IDrpPricingPlans | undefined;
  assetCount: number;
  tpL1: number;
  trainingSession: number;
  orgScoreCard: boolean;
  executive: number;
  takeDown: number;

  selectedSubsidiary: IESDoc[];
  onNext: () => void;
}
export const PricingReview: React.FC<PricingReview> = ({
  onNext,
  DrpPlan,
  EasmPlan,
  assetCount,
  executive,
  orgScoreCard,
  selectedSubsidiary,
  tpL1,
  trainingSession,
  takeDown,
}) => {
  const moveForward = () => {
    onNext();
  };
  const [generatedPlan, setGeneratedPlan] = React.useState(GenerateTakedownPlan(takeDown));
  useEffect(() => {
    setGeneratedPlan(GenerateTakedownPlan(takeDown));
  }, [takeDown]);
  return (
    <Fragment>
      <Divider />
      <Box padding={"8px 16px"}>
        <AppAssetsSectionBox type={"overview"} title={"Overview"} flatPaper>
          <AppDocView
            doc={{
              _id: "overview",
              subsidiaries: selectedSubsidiary.map((x) => x.name),
              trainingSession: `${trainingSession} sessions`,
            }}
            colDefs={_.uniqWith(
              [
                {
                  masterColumnName: "subsidiaries",
                  masterColumnTitle: "Subsidiaries",
                  renderType: "array",
                },
                trainingSession
                  ? {
                      masterColumnName: "trainingSession",
                      masterColumnTitle: "Training Sessions",
                      renderType: "text",
                    }
                  : {
                      masterColumnName: "subsidiaries",
                      masterColumnTitle: "Subsidiaries",
                      renderType: "array",
                    },
              ],
              _.isEqual
            )}
          />
        </AppAssetsSectionBox>
        {EasmPlan ? (
          <AppAssetsSectionBox type={"easm"} title={"Attack Surface Management"} flatPaper>
            <AppDocView
              doc={{
                _id: EasmPlan.name,
                ...EasmPlan,
                assetCount,
                orgScoreCard,
                tpL1: `${tpL1} companies`,
              }}
              colDefs={_.uniqWith(
                [
                  {
                    masterColumnName: "title",
                    masterColumnTitle: "Plan Name",
                    renderType: "text",
                  },
                  {
                    masterColumnName: "user_count",
                    masterColumnTitle: "User Count",
                    renderType: "text",
                  },
                  {
                    masterColumnName: "assetCount",
                    masterColumnTitle: "Total Assets",
                    renderType: "text",
                  },
                  orgScoreCard
                    ? {
                        masterColumnName: "orgScoreCard",
                        masterColumnTitle: "Any Organization Scorecard",
                        renderType: "boolean",
                      }
                    : {
                        masterColumnName: "assetCount",
                        masterColumnTitle: "Total Assets",
                        renderType: "text",
                      },
                  tpL1
                    ? {
                        masterColumnName: "tpL1",
                        masterColumnTitle: "Third Party Risk Monitoring",
                        renderType: "text",
                      }
                    : {
                        masterColumnName: "assetCount",
                        masterColumnTitle: "Total Assets",
                        renderType: "text",
                      },
                ],
                _.isEqual
              )}
            />
          </AppAssetsSectionBox>
        ) : null}
        {DrpPlan ? (
          <AppAssetsSectionBox type={"easm"} title={"Digital Risk Protection"} flatPaper>
            <AppDocView
              doc={{
                _id: DrpPlan.name,
                ...DrpPlan,
                executive: `${executive} executives`,
              }}
              colDefs={_.uniqWith(
                [
                  {
                    masterColumnName: "title",
                    masterColumnTitle: "Plan Name",
                    renderType: "text",
                  },
                  {
                    masterColumnName: "user_count",
                    masterColumnTitle: "User Count",
                    renderType: "text",
                  },

                  executive
                    ? {
                        masterColumnName: "executive",
                        masterColumnTitle: "Executive Monitoring",
                        renderType: "text",
                      }
                    : {
                        masterColumnName: "assetCount",
                        masterColumnTitle: "Total Assets",
                        renderType: "text",
                      },
                ],
                _.isEqual
              )}
            />
          </AppAssetsSectionBox>
        ) : null}
        {generatedPlan ? (
          <AppAssetsSectionBox type={"easm"} title={"Digital Risk Protection"} flatPaper>
            <AppDocView
              doc={{
                _id: generatedPlan.name,
                ...generatedPlan,
              }}
              colDefs={_.uniqWith(
                [
                  {
                    masterColumnName: "title",
                    masterColumnTitle: "Plan Name",
                    renderType: "text",
                  },
                  {
                    masterColumnName: "user_count",
                    masterColumnTitle: "User Count",
                    renderType: "text",
                  },
                  {
                    masterColumnName: "take_down_count",
                    masterColumnTitle: "Takedown Count",
                    renderType: "text",
                  },
                  {
                    masterColumnName: "features",
                    masterColumnTitle: "Features",
                    renderType: "array",
                  },
                  {
                    masterColumnName: "freebies",
                    masterColumnTitle: "Freebies",
                    renderType: "array",
                  },
                ],
                _.isEqual
              )}
            />
          </AppAssetsSectionBox>
        ) : null}
      </Box>
      <Divider />
      <Box padding={"16px"}>
        <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"flex-end"}>
          <Button variant="contained" color="primary" onClick={moveForward}>
            <Typography fontWeight={600} textTransform={"capitalize"} fontSize={"14px"}>
              order now
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );
};
