import {
  Accordion,
  AccordionDetails,
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import _, { has, range, startCase, toUpper } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { IIssueName } from "../issues.index";
import { IESDoc } from "../../../../../App.interface";
import { useBehaviourSubject } from "../../../../../hooks/use-observable";
import { BrandService } from "../../../../../services/brand.service";
import { IMasterColumns } from "../../../../module.interface";
import { RenderText } from "../../../../../util/cell-render/renderers";
import { toast } from "../../../../../state/snackbar";
import { getSeverityColors } from "../../../util/severity-colors";
import { EmptyTableView } from "../../../../views/EmptyTable";
import { AppMasterTable } from "../../../../views/app.table";
import { FetchDmarcIssueDetails } from "../../../../../services/dmarc.service";
import { getCategoryColors, getFixingEffortColors, getStatusColors } from "../util/status.color";
import { IssueStatusTile } from "./issues.tile";
import DmarcAppAccordingPanel from "./issues.accordion.panel";
import { UpgradeInfo } from "../../../../module.views/Upgrade";
import { ColorShades } from "../../../../../util/color-shades";
import { DashboardMasterTable } from "../../../../module.views/dashboard.master.table";
import { AppDrawer } from "../../../../views/Drawer";
import { UUIDGenerator } from "../../../../../util/uuid";

interface Type {
  issueName: IIssueName;
  parentLoading: boolean;
}
export const IssuesAccordion: React.FC<Type> = ({ issueName, parentLoading }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const selectedMemberIds = useBehaviourSubject<number[]>(BrandService.selectedMemberIds$) || [];
  const [total, setTotal] = React.useState(0);
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc>();

  const commonColumns: IMasterColumns[] = [
    {
      masterColumnName: "meta.domain_name",
      masterColumnTitle: "Host Name",
      renderType: "text",
    },
    {
      masterColumnName: "total_emails",
      masterColumnTitle: "Emails",
      renderType: "number",
    },
    {
      masterColumnName: "detection_source",
      masterColumnTitle: "Source",
      renderType: "text",
      cellRender: (param) => {
        return (
          <Box display={"flex"} alignItems={"center"}>
            {param?.detection_source === "dns" ? (
              <Typography>DNS</Typography>
            ) : has(param, "humanize_source_name") ? (
              <Tooltip title={param?.source_name}>{param?.humanize_source_name}</Tooltip>
            ) : (
              <Typography>{param?.source_name || "NA"}</Typography>
            )}
          </Box>
        );
      },
    },
    {
      masterColumnName: "last_seen",
      masterColumnTitle: "Last Detection",
      renderType: "text",
      cellRender: (param) => {
        return (
          <Fragment>
            {RenderText(moment(param.last_seen).format("LLL"))}{" "}
            {RenderText("(" + moment(param.last_seen).fromNow() + ")")}{" "}
          </Fragment>
        );
      },
    },
  ];
  const additionalColumn: IMasterColumns[] = [
    {
      masterColumnName: "meta.client_name",
      masterColumnTitle: "Brand",
      renderType: "text",
    },
  ];
  const [colDefs, setColDefs] = React.useState<IMasterColumns[]>(
    selectedMemberIds?.length > 1 || selectedMemberIds.length === 0
      ? [...additionalColumn, ...commonColumns]
      : commonColumns
  );
  const fetchCategoryDocs = () => {
    if (expanded) {
      setLoading(true);

      FetchDmarcIssueDetails(issueName.issueName)
        .then((response) => {
          setDocs(response.data || []);
          setTotal(response.metadata.total.value);
        })
        .catch(() => {
          toast("Error getting issues", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useDeepCompareEffect(fetchCategoryDocs, [selectedMemberIds, expanded]);

  useEffect(() => {
    if (docs && docs[0]?.detection_source == "dns") {
      if (issueName.issueName == "SPF record missing IP Addresses") {
        setColDefs((v) => v.filter((i) => i.masterColumnName != "total_emails"));
      } else {
        setColDefs((v) =>
          v.filter(
            (i) => i.masterColumnName != "total_emails" && i.masterColumnName != "detection_source"
          )
        );
      }
    }
  }, [docs, issueName]);

  return (
    <Fragment>
      <Accordion
        square
        TransitionProps={{ unmountOnExit: true }}
        className={"appAccording"}
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        style={{
          borderLeft: `2px solid ${getSeverityColors(issueName.severity.toLowerCase())}`,
        }}
      >
        <DmarcAppAccordingPanel
          loading={loading || parentLoading}
          expanded={expanded}
          header={issueName.issueName}
          detection_source={issueName.detection_source}
          list={[
            {
              color: getSeverityColors(issueName.severity),
              count: _.startCase(issueName.severity),
              label: "Severity",
              isCenter: true,
            },
            {
              color: getFixingEffortColors(issueName.fixing_effort),
              count: _.startCase(issueName.fixing_effort),
              label: "Fixing Effort",
              isCenter: true,
            },
          ]}
          totalItem={{
            label: "Issues Detected",
            color: "secondary",
            count: issueName.total,
          }}
        />

        <AccordionDetails
          style={{
            background: theme.palette.background.default,
            border: `1px solid ${theme.palette.divider}`,
          }}
        >
          {issueName.detection_source == "dns" ? (
            <Box
              style={{
                background: theme.palette.background.default,
              }}
            >
              <Grid container justifyContent="space-between" spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={6} alignSelf={"center"}>
                  <Box marginBottom={2} paddingLeft={2} maxWidth={720}>
                    <Alert severity="warning" variant="outlined">
                      <AlertTitle>
                        <Typography
                          variant="h6"
                          color={"inherit"}
                          fontSize={"16px"}
                          fontWeight={600}
                        >
                          Restricted View
                        </Typography>
                      </AlertTitle>
                      <Typography
                        variant="subtitle1"
                        color={"inherit"}
                        fontSize={"12px"}
                        fontWeight={500}
                        marginBottom={1.5}
                      >
                        You are viewing a free version of your issues with {docs.length} issue
                        details
                        <br />
                        To explore more information about your issues and assets please upgrade your
                        subscription.
                      </Typography>
                      <Button
                        variant="contained"
                        component={Link}
                        href={`https://${
                          process.env.NODE_ENV === "development"
                            ? "cyno.edxdev.link"
                            : "start.ctm360.com"
                        }/pricing`}
                        target="_blank"
                        size="small"
                      >
                        <Typography
                          variant="h6"
                          textTransform={"capitalize"}
                          fontWeight={600}
                          fontSize={"12px"}
                        >
                          Upgrade Now
                        </Typography>
                      </Button>
                    </Alert>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Grid container spacing={4} sx={{ padding: 4 }}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <IssueStatusTile
                        icon={{
                          name: "signal_cellular_alt",
                          color:
                            getSeverityColors(docs?.[0]?.severity || "") ||
                            theme.palette.primary.main,
                        }}
                        title="Severity"
                        value={startCase(docs?.[0]?.severity || "-")}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <IssueStatusTile
                        icon={{
                          name: "construction",
                          color:
                            getFixingEffortColors(docs?.[0]?.fixing_effort || "") ||
                            theme.palette.primary.main,
                        }}
                        title="Fixing Effort"
                        value={startCase(docs?.[0]?.fixing_effort || "-")}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <IssueStatusTile
                        icon={{
                          name: "category",
                          color:
                            getCategoryColors(_.toLower(docs?.[0]?.category || "")) ||
                            theme.palette.primary.main,
                        }}
                        title="Category"
                        value={toUpper(docs?.[0]?.category) || "-"}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                      <IssueStatusTile
                        icon={{
                          name: "model_training",
                          color:
                            getStatusColors(_.toLower(docs?.[0]?.status || "")) ||
                            theme.palette.primary.main,
                        }}
                        title="Status"
                        value={startCase(docs?.[0]?.status || "-")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {parentLoading || loading ? (
                <Skeleton variant="rectangular" height={300} />
              ) : _.isEmpty(docs) ? (
                <Box
                  sx={{
                    border: "1px solid",
                    borderColor: "#ffffff1f",
                    backgroundColor: theme.palette.background.default,
                  }}
                >
                  <EmptyTableView />
                </Box>
              ) : (
                <Fragment>
                  <Box
                    sx={{
                      border: "1px solid",
                      borderColor: "#ffffff1f",
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <AppMasterTable
                      loading={loading}
                      dataSource=""
                      name=""
                      title=""
                      colDefs={colDefs}
                      docs={docs ? docs : []}
                      totalDocs={total}
                      pageIndex={0}
                      pageSize={10}
                      sortField={""}
                      sortOrder={"desc"}
                      onChangeSortField={() => null}
                      onChangeSortOrder={() => null}
                      onChangePageIndex={() => null}
                      onChangePageSize={() => null}
                      enablePagination={false}
                      enableCheckBox={false}
                      enableSelection={true}
                      enableBrandColum={false}
                      checkedDocs={[]}
                      onChangeCheckedDocs={() => null}
                      onChangeSelectedDoc={setSelectedDoc}
                    />
                  </Box>
                </Fragment>
              )}
            </Box>
          ) : (
            <>
              <DashboardMasterTable
                colDefs={colDefs}
                docs={range(25).map((x) => ({
                  _id: x.toString(),
                  "@timestamp": moment(new Date()).utc().valueOf(),
                  "meta.domain_name": `${x}`,
                  total_emails: `${x}`,

                  detection_source: `${x}`,

                  last_seen: `${x}`,
                }))}
                loading={false}
                dataSource=""
                name=""
                title=""
              />
              <Box
                width={"500px"}
                margin={"auto"}
                position={"absolute"}
                top={"200px"}
                left={"calc(50% - 250px)"}
                boxShadow={`9px 13px 82px 46px ${ColorShades(
                  theme.palette.background.paper,
                  theme.palette.mode === "dark" ? "800" : "300"
                )}`}
              >
                <Paper>
                  <UpgradeInfo />
                </Paper>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      {selectedDoc ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={issueName.issueName}
          onClose={() => {
            setSelectedDoc(undefined);
          }}
        >
          <Box position={"relative"}>
            <Paper>
              <DashboardMasterTable
                loading={false}
                dataSource="ds_asset_feed_dashboard"
                name="dashboard_asset_feed"
                title="Asset Feed"
                colDefs={[
                  {
                    masterColumnName: "meta.domain_name",
                    masterColumnTitle: "Host Name",
                    renderType: "text",
                    filterable: true,
                    sortable: true,
                  },
                  {
                    masterColumnName: "total_emails",
                    masterColumnTitle: "Emails",
                    renderType: "number",
                    filterable: true,
                    sortable: true,
                  },

                  {
                    masterColumnName: "first_seen",
                    masterColumnTitle: "First Seen",
                    renderType: "date",
                    filterable: true,
                    sortable: true,
                  },
                ]}
                docs={_.range(25).map(() => ({
                  _id: UUIDGenerator(),
                  "meta.domain_name": UUIDGenerator(),
                  total_emails: UUIDGenerator(),
                  first_seen: moment(new Date()).utc().valueOf(),
                }))}
              />
              <Box
                width={"500px"}
                margin={"auto"}
                position={"absolute"}
                top={"150px"}
                left={"calc(50% - 250px)"}
                boxShadow={`9px 13px 82px 46px ${ColorShades(
                  theme.palette.background.paper,
                  theme.palette.mode === "dark" ? "800" : "300"
                )}`}
              >
                <Paper>
                  <UpgradeInfo />
                </Paper>
              </Box>
            </Paper>
          </Box>
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
