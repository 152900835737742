import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Icon,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";

import React, { Fragment } from "react";
import { IESDoc } from "../../../../../App.interface";
import { IMasterColumns } from "../../../../module.interface";
import { AppDrawer } from "../../../../views/Drawer";
import { toast } from "../../../../../state/snackbar";
import { FetchDomainRecord } from "../../../../../services/dmarc.service";
import OverviewTab from "../components/overview.table";
import { DmarcDomainAuthorizedSources } from "./authorized-sources";

interface IDomainDetails {
  item?: IESDoc | undefined;
  onClose: () => void | undefined;
}
export const DmarcDomainsAdvancedView: React.FC<IDomainDetails> = ({ item, onClose }) => {
  const [host, setHost] = React.useState<IESDoc | undefined>(item);
  const [doc, setDoc] = React.useState<IESDoc | undefined>();
  const [loading, setLoading] = React.useState(false);
  const [tabs] = React.useState<string[]>(["DNS info", "Issues", "Authorized Sources"]);
  const [selectedTab, setSelectedTab] = React.useState(tabs[0]);

  const [dkimMapper, setDkimMapper] = React.useState<IMasterColumns[]>([]);

  const dnsInfo: IMasterColumns[] = [
    {
      masterColumnTitle: "A Record",
      masterColumnName: "dns_records.A_records",
      renderType: "chips",
    },
    {
      masterColumnTitle: "NS Record",
      masterColumnName: "dns_records.NS_records",
      renderType: "chips",
    },
    {
      masterColumnTitle: "MX Record",
      masterColumnName: "dns_records.MX_records",
      renderType: "inline_array_chips",
    },
    {
      masterColumnTitle: "CNAME Record",
      masterColumnName: "dns_records.CNAME_records",
      renderType: "inline_array_chips",
    },
    {
      masterColumnTitle: "TXT Record",
      masterColumnName: "dns_records.TXT_records",
      renderType: "array",
    },

    {
      masterColumnTitle: "SPF Record",
      masterColumnName: "dns_records.SPF_records",
      renderType: "array",
    },

    {
      masterColumnTitle: "DMARC Record",
      masterColumnName: "dns_records.DMARC_records",
      renderType: "array",
    },
  ];

  React.useEffect(() => {
    setHost(item);
  }, [item]);

  const fetchDoc = () => {
    setLoading(true);
    FetchDomainRecord()
      .then((response) => {
        setDoc(response?.data?.[0] || undefined);
        const dkim: IMasterColumns[] = [];

        if (response?.data?.[0]?.dns_records.DKIM_records?.length) {
          _.forEach(response?.data?.[0]?.dns_records.DKIM_records, (k, index) => {
            dkim.push({
              masterColumnName: `${index}.records`,
              masterColumnTitle: k.selector,
              renderType: "array",
            });
          });
        }

        setDkimMapper(dkim);
      })
      .catch(() => {
        toast(`Error getting ${host?.hostname || host?.main_domain} details`, "error");
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(fetchDoc, [host]);
  return (
    <Fragment>
      <AppDrawer
        anchor="right"
        open={true}
        title={
          <Grid container direction="column">
            <Grid item>
              <Box display="flex" flexDirection="row" marginBottom={1}>
                {loading ? (
                  <Skeleton variant="text" width={250} />
                ) : (
                  <Fragment>
                    <Typography variant="h5" color="primary" className="main-heading">
                      {host?.hostname || host?.main_domain}
                    </Typography>
                    {host?.isVerified ? <Icon style={{ margin: "0px 5px" }}>verified</Icon> : null}
                  </Fragment>
                )}
              </Box>
            </Grid>
          </Grid>
        }
        onClose={onClose}
      >
        <Fragment>
          <Box display="flex" justifyContent="center">
            <ButtonGroup color="primary">
              {tabs.map((x, i) =>
                loading ? (
                  <Skeleton key={`btn_${x}__${i}`} variant="rectangular" height={35} width={120} />
                ) : x != "DNS info" ? (
                  <Tooltip title="Not available for freemium users">
                    <span>
                      <Button
                        disabled={true}
                        key={`${x}__${i}`}
                        variant={selectedTab === x ? "contained" : "outlined"}
                        onClick={() => setSelectedTab(x)}
                        startIcon={<Icon>lock</Icon>}
                      >
                        {x}
                      </Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button
                    key={`${x}__${i}`}
                    variant={selectedTab === x ? "contained" : "outlined"}
                    onClick={() => setSelectedTab(x)}
                  >
                    {x}
                  </Button>
                )
              )}
            </ButtonGroup>
          </Box>

          <Fragment>
            {selectedTab === tabs[0] ? (
              <Fragment>
                {loading ? (
                  <>
                    <Skeleton variant="text" width={120} height={30} />
                    <Skeleton variant="rectangular" height={300} />

                    <Skeleton variant="text" width={120} height={30} />
                    <Skeleton variant="rectangular" height={150} />
                  </>
                ) : (
                  <>
                    <OverviewTab title="DNS Records" colDefs={dnsInfo} doc={doc} />
                    {!_.isEmpty(dkimMapper) ? (
                      <Fragment>
                        <OverviewTab
                          doc={doc?.dns_records?.DKIM_records}
                          colDefs={dkimMapper}
                          title="DKIM Records"
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Box style={{ margin: "0 15px" }}>
                          <Typography style={{ fontWeight: "600" }} color="secondary">
                            DKIM Records
                          </Typography>
                          <Paper>
                            <Alert severity="warning">No DKIM found</Alert>
                          </Paper>
                        </Box>
                      </Fragment>
                    )}
                  </>
                )}
              </Fragment>
            ) : null}

            {selectedTab === tabs[2] ? <DmarcDomainAuthorizedSources /> : null}
          </Fragment>
        </Fragment>
      </AppDrawer>
    </Fragment>
  );
};
