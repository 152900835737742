import { AppBar, Toolbar, Box, IconButton, Icon, Stack } from "@mui/material";
import React, { useEffect, Fragment } from "react";
import { sidebar$ } from "../state/sidebar";
import { user$ } from "../state/user";
import { LogOffModel } from "./logout.model";
import { useBehaviourSubject } from "../hooks/use-observable";
import { AppUserProfile } from "./UserProfile";
import { BrandName } from "./BrandName";
import { AppProductSwitch } from "./ProductSwitch";
import { BrandSelector } from "../components/brand-selector";
import DmarcConfiguration from "../modules/dmarc/views/dmarc-configuration";
import { selectedPlatform$ } from "../state/platformselection";

export const Header: React.FC = () => {
  const user = useBehaviourSubject(user$);
  const [menuStatus, setMenuStatus] = React.useState<boolean>(
    useBehaviourSubject(sidebar$) || true
  );
  const [selectedApp, setSelectedApp] = React.useState(
    useBehaviourSubject(selectedPlatform$) || "hacker_view"
  );

  const [logoutModel, setLogoutModel] = React.useState<boolean>(false);
  useEffect(() => {
    sidebar$.next(menuStatus);
  }, [menuStatus]);
  useEffect(() => {
    const sub = sidebar$.subscribe((res) => {
      setMenuStatus(res);
    });
    return () => sub.unsubscribe();
  }, []);

  useEffect(() => {
    selectedPlatform$.next(selectedApp);
  }, [selectedApp]);

  useEffect(() => {
    const sub = selectedPlatform$.subscribe((res) => {
      setSelectedApp(res);
    });
    return () => sub.unsubscribe();
  }, []);

  return (
    <Fragment>
      {user ? (
        <AppBar position="sticky" color="inherit" className="app-header-container">
          <Toolbar style={{ minHeight: "100%" }}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"nowrap"}
              width={"100%"}
            >
              <Box>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <IconButton
                    className="menubar-icon"
                    aria-label="breadcrumbs-menu"
                    onClick={() => setMenuStatus(!menuStatus)}
                  >
                    <Icon>menu</Icon>
                  </IconButton>
                  <Box>
                    <AppProductSwitch />
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={1}
                  className="hv-header-right-controller-stack"
                >
                  <Box>{user.internal ? <BrandSelector /> : <BrandName />}</Box>

                  <Box>
                    <AppUserProfile />
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Toolbar>
        </AppBar>
      ) : null}
      {selectedApp == "dmarc" ? <DmarcConfiguration /> : null}

      {logoutModel && user ? (
        <LogOffModel user={user} onClose={() => setLogoutModel(false)} />
      ) : null}
    </Fragment>
  );
};
