import React, { Fragment, useEffect } from "react";
import { ITechGroups } from "../module.interface";
import { Box, Grid, Theme, useTheme } from "@mui/material";
import { EnvironmentLoading } from "./environment.loading";
import { FetchEnvironments } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { AppMD5Hash } from "../../util/md5.hash";
import { EmptyTableView } from "../views/EmptyTable";
import { EnvironmentCategory } from "./environment.category";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useObservable } from "../../hooks/use-observable";
import { BrandService } from "../../services/brand.service";
import { UUIDGenerator } from "../../util/uuid";

export const EnvironmentsModule: React.FC = () => {
  const theme = useTheme<Theme>();
  const [loading, setLoading] = React.useState(0);
  const [techData, setTechData] = React.useState<ITechGroups[]>([]);
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const [updateId, setUpdateId] = React.useState("");
  const fetchEnvironmentData = () => {
    setLoading((x) => x + 1);
    FetchEnvironments()
      .then((response) => {
        setTechData(response);
      })
      .catch(() => {
        toast("Error in loading environments", "error");
      })
      .finally(() => {
        setLoading((c) => c - 1);
      });
  };
  useEffect(fetchEnvironmentData, [updateId]);

  useDeepCompareEffect(() => {
    setUpdateId(UUIDGenerator());
  }, [selectedMemberIds]);
  return (
    <Fragment>
      {loading ? (
        <EnvironmentLoading />
      ) : techData.length ? (
        <Fragment>
          {techData.map((brand) => (
            <Box className="brand-container" key={AppMD5Hash(brand.brand)}>
              <Grid container spacing={5} justifyContent="center">
                {brand.technologies.map((category) => (
                  <Grid item key={AppMD5Hash(category.name)}>
                    <EnvironmentCategory theme={theme} category={category} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Fragment>
      ) : (
        <EmptyTableView />
      )}
    </Fragment>
  );
};
