import { Button, Theme, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";
interface IButtonFilterEmpty {
  name: string;
  title: string;
  onSelect: (e: any, name: string) => void;
}
export const ButtonFilterEmpty: React.FC<IButtonFilterEmpty> = ({ onSelect, title, name }) => {
  const theme = useTheme<Theme>();
  return (
    <Fragment>
      <Typography
        variant="subtitle1"
        className="button-filter-label"
        color="textPrimary"
      ></Typography>
      <Button
        variant="outlined"
        size="small"
        color="inherit"
        className="button-filter-empty-selection"
        style={{ borderColor: theme.palette.divider, textTransform: "capitalize" }}
        onClick={(e) => onSelect(e, name)}
      >
        {title}
      </Button>
    </Fragment>
  );
};
