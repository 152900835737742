import { Paper } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { FetchOrganizationScore } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { calculateGrade } from "../../util/score.grade.utils";
import { HackerViewGradeShield } from "../module.views/grade.shield";

export const OverallScoreCard: React.FC<{ updateId: string }> = ({ updateId }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [score, setScore] = React.useState(0);

  const fetchScores = () => {
    setLoading(true);
    FetchOrganizationScore()
      .then((response) => {
        setScore(_.round(response.score));
      })
      .catch(() => {
        toast("Error in fetching organization score", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchScores, [updateId]);
  return (
    <Fragment>
      <Paper className="dashboard-cards cyber-risk-score">
        <HackerViewGradeShield
          loading={loading}
          score={score}
          grade={calculateGrade(score)}
          shieldDescription={"Cyber Risk Score"}
        />
      </Paper>
    </Fragment>
  );
};
