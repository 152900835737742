import { Divider, Box, Button, Stack, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { IDrpPricingPlans, IEasmPlans } from "../module.interface";
import { EasmAddons } from "./easm/easm_addons";
import { DrpAddons } from "./drp/drp_addons";
import { SubsidiarySelector } from "./subsidiary";
import { IESDoc } from "../../App.interface";
import _ from "lodash";
interface ISubscriptionAddons {
  EasmPlan: IEasmPlans | undefined;
  DrpPlan: IDrpPricingPlans | undefined;
  tpL1: number;
  trainingSession: number;
  orgScoreCard: boolean;
  tpEnterpriseAddon: boolean;
  onChangeL1: (params: number) => void;

  onChangeTraining: (params: number) => void;
  onChangeScoreCard: (params: boolean) => void;
  executive: number;
  onChangeExecutive: (params: number) => void;
  selectedSubsidiary: IESDoc[];
  onChangeSubsidiary: (params: IESDoc[]) => void;
  onNext: () => void;
}
export const SubscriptionAddons: React.FC<ISubscriptionAddons> = ({
  onNext,
  DrpPlan,
  EasmPlan,
  trainingSession,
  orgScoreCard,
  tpL1,
  tpEnterpriseAddon,
  onChangeSubsidiary,
  selectedSubsidiary,
  onChangeL1,

  onChangeScoreCard,
  onChangeTraining,
  executive,
  onChangeExecutive,
}) => {
  const moveForward = () => {
    onNext();
  };
  return (
    <Fragment>
      <Divider />
      <Box padding={"8px 16px"}>
        {EasmPlan || DrpPlan ? (
          <Fragment>
            <SubsidiarySelector
              selectedSubsidiary={selectedSubsidiary}
              onChangeSubsidiary={onChangeSubsidiary}
            />
            {EasmPlan && _.includes(["advanced", "enterprise"], EasmPlan.name) ? (
              <EasmAddons
                currentPlan={EasmPlan}
                tpL1={tpL1}
                tpEnterpriseAddon={tpEnterpriseAddon}
                trainingSession={trainingSession}
                orgScoreCard={orgScoreCard}
                onChangeL1={onChangeL1}
                onChangeTraining={onChangeTraining}
                onChangeScoreCard={onChangeScoreCard}
              />
            ) : null}
            {DrpPlan ? (
              <DrpAddons executive={executive} onChangeExecutive={onChangeExecutive} />
            ) : null}
          </Fragment>
        ) : null}
      </Box>
      <Divider />
      <Box padding={"16px"}>
        <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"flex-end"}>
          <Button variant="contained" color="primary" onClick={moveForward}>
            <Typography fontWeight={600} textTransform={"capitalize"} fontSize={"14px"}>
              Continue
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );
};
