import React, { FC, useEffect } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { useBehaviourSubject } from "./hooks/use-observable";
import { IApplicationModules } from "./modules/module.interface";
import { receiveModules } from "./modules/module.register";
import { PricingModule } from "./modules/pricing/pricing.index";
import { selectedPlatform$ } from "./state/platformselection";
import { user$ } from "./state/user";

export const RootRouter: FC = () => {
  const user = useBehaviourSubject(user$);
  const platform = useBehaviourSubject(selectedPlatform$);
  const [modules, setModules] = React.useState<IApplicationModules[]>(
    receiveModules()[platform] || []
  );

  useEffect(() => {
    if (user) {
      setModules(receiveModules()[platform] || []);
    }
  }, [platform, user]);
  return (
    <Switch>
      <Redirect from="/" to={modules[0]?.path} exact />
      {modules.map((module) => (
        <Route key={module.name} path={module.path} component={module.controller} />
      ))}
      {modules.map((module) =>
        module.subModules.map((sub) => (
          <Route key={sub.name} path={sub.path} component={sub.controller} />
        ))
      )}
      <Route path={"/pricing"} component={PricingModule} />
      <Redirect to="/" />
    </Switch>
  );
};
