import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import has from "lodash/has";
import get from "lodash/get";
import { IESDoc } from "../../App.interface";
import { ColorOpacity } from "../../util/color_opacity";

export const RiskScore: React.FC<{ doc: IESDoc; field?: string }> = ({ doc, field }) => {
  const scoreColor = (): string => {
    const scoreVal = get(doc, field || "risk_score") as number;
    if (scoreVal >= 90) {
      return "#cc0000";
    } else if (scoreVal >= 50 && scoreVal < 90) {
      return "#ff8800";
    } else if (scoreVal >= 25 && scoreVal < 50) {
      return "#ffbb33";
    } else {
      return "#9e9e9e";
    }
  };
  return (
    <Fragment>
      {doc.risk_score || has(doc, field || "risk_score") ? (
        <Box
          width={"30px"}
          height={"30px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          bgcolor={ColorOpacity(scoreColor(), 0.15)}
        >
          <Typography variant="h6" fontSize={"16px"} fontWeight={600} color={scoreColor}>
            {get(doc, field || "risk_score")}
          </Typography>
        </Box>
      ) : (
        "-"
      )}
    </Fragment>
  );
};
