import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { FetchOrganizationAssetCount } from "../../../services/dashboard.service";
import { IEasmPlans } from "../../module.interface";
import { CustomSalesQuotation } from "../custom_quote";
interface IEASMPlans {
  currentPlan: string;
  onSelectPlan: (params: IEasmPlans) => void;
  totalAssetCount: (params: number) => void;
  tpEnterpriseAddon: boolean;
  onSelectTpEnterpriseAddon: (params: boolean) => void;
  onNext: () => void;
}

export const EASMPlans: React.FC<IEASMPlans> = ({
  onSelectPlan,
  totalAssetCount,
  currentPlan,
  onSelectTpEnterpriseAddon,
  tpEnterpriseAddon,
  onNext,
}) => {
  const [models] = React.useState([
    {
      name: "restricted",
      title: "Restricted",
      caption: "Know your Issues",
      annual_price: 3750,
      monthly_price: 3.5,
      user_count: 1,
      key_points: [
        "Limited External Attack Surface",
        "Cyber Security Risk profile",
        "Issue Checklist",
      ],
    },
    {
      name: "basic",
      title: "Basic",
      caption: "Fix your Issues",
      annual_price: 6500,
      monthly_price: 3.25,
      user_count: 3,
      key_points: ["Everything in Restricted", "Remediation Steps"],
    },
    {
      name: "advanced",
      title: "Advanced",
      caption: "Monitor your Third Parties",
      annual_price: 19750,
      monthly_price: 3,
      user_count: 5,
      key_points: ["Everything in Advanced", "Third party Risk Management", "Reporting"],
    },
    {
      name: "enterprise",
      title: "Enterprise",
      caption: "Your security, our passion",
      annual_price: 49000,
      monthly_price: 2.5,
      user_count: "Unlimited",
      key_points: [
        "Volume Discounts applied",
        "Ideal for Enterprise, Corporate + their Subsidiaries, Group Organizations",
      ],
      added_benefits: [
        "Volume Discounts applied",
        "Ideal for Enterprise, Corporate + their Subsidiaries, Group Organizations",
      ],
    },
  ]);
  const [selectedPlan, setSelectedPlan] = React.useState(
    currentPlan ? currentPlan : models[0].name
  );
  const [loading, setLoading] = React.useState(true);
  const [assetCount, setAssetCount] = React.useState({ host: 0, ip: 0, total: 0 });
  const [customQuote, setCustomQuote] = React.useState(false);
  const [enterpriseIssueDetails, setEnterpriseIssueDetails] = React.useState(tpEnterpriseAddon);

  const organizationAssetCount = () => {
    setLoading(true);
    FetchOrganizationAssetCount()
      .then((response) => {
        setAssetCount(response);
      })
      .catch(() => {
        setAssetCount({ host: 0, ip: 0, total: 0 });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onChangePlan = (plan: string) => {
    setSelectedPlan((p) => (p === plan ? "" : plan));
    setEnterpriseIssueDetails(false);
    onSelectTpEnterpriseAddon(false);
  };
  const onCloseModel = () => {
    setCustomQuote(false);
  };
  const onSuccess = () => {
    onCloseModel();
    setSelectedPlan("");
    onNext();
  };
  useEffect(organizationAssetCount, []);
  useEffect(() => {
    totalAssetCount(assetCount.total);
  }, [assetCount.total, totalAssetCount]);
  useDeepCompareEffect(() => {
    onSelectPlan(models.filter((f) => f.name === selectedPlan)[0]);
  }, [models, selectedPlan]);
  return (
    <Fragment>
      <Divider />
      <Box padding={"8px 16px"}>
        {models.map((x) => (
          <Box key={x.name} marginBottom={1}>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                  <Box>
                    <Checkbox
                      inputProps={{ "aria-label": `${x.name}-checkbox` }}
                      checked={selectedPlan === x.name}
                      color="primary"
                      onChange={() => onChangePlan(x.name)}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                      color={x.name === selectedPlan ? "primary" : "secondary"}
                    >
                      {x.title}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  fontSize={"10px"}
                  fontWeight={600}
                  color={"textSecondary"}
                >
                  STARTS AT
                </Typography>
                {loading ? (
                  <Skeleton variant="text" width={"80px"} />
                ) : assetCount.total ? (
                  <Typography variant="h6" fontSize={"14px"}>
                    <strong>
                      {`$${(
                        assetCount.total * x.monthly_price * 12 +
                        x.annual_price
                      ).toLocaleString()}`}
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        fontWeight={600}
                        fontSize={"10px"}
                      >
                        /yr
                      </Typography>
                    </strong>
                  </Typography>
                ) : (
                  "-"
                )}
              </Box>
            </Stack>
            <Box marginLeft={"42px"}>
              {x.name === "enterprise" ? (
                <Box>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={enterpriseIssueDetails}
                          onChange={(e, c) => {
                            setEnterpriseIssueDetails(c);
                            onSelectTpEnterpriseAddon(c);
                          }}
                          color="secondary"
                          size="small"
                        />
                      }
                      label={
                        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                          {`Add Third Party Issue Details $${(20000).toLocaleString()}`}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
              ) : null}
              <List dense disablePadding>
                <ListItem disableGutters disablePadding>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <Icon color="primary" style={{ fontSize: "20px" }}>
                      verified
                    </Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        color={"primary"}
                        fontSize={"12px"}
                        fontWeight={600}
                      >
                        {`${x.user_count} users`}
                      </Typography>
                    }
                  />
                </ListItem>
                {x.key_points.map((kp) => (
                  <ListItem key={kp} disableGutters disablePadding>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <Icon style={{ fontSize: "20px" }}>verified</Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                          {kp}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box padding={"16px"}>
        <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"space-between"}>
          <Typography
            variant="subtitle1"
            fontSize={"14px"}
            fontWeight={500}
            color={"textSecondary"}
          >
            Need a custom quote?
            <Button
              variant="text"
              onClick={() => {
                setCustomQuote(true);
                setSelectedPlan("");
              }}
            >
              <Typography
                variant="inherit"
                component={"span"}
                fontWeight={600}
                textTransform={"capitalize"}
                fontSize={"14px"}
              >
                Contact Us
              </Typography>
            </Button>
          </Typography>
          <Button variant="contained" color="primary" onClick={onNext}>
            <Typography fontWeight={600} textTransform={"capitalize"} fontSize={"14px"}>
              Continue
            </Typography>
          </Button>
        </Stack>
      </Box>
      {customQuote ? (
        <CustomSalesQuotation
          platform={"Attack Surface Management"}
          onClose={onCloseModel}
          onSuccess={onSuccess}
        />
      ) : null}
    </Fragment>
  );
};
