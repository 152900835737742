import { Box, Theme, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { calculateGrade } from "../../util/score.grade.utils";
interface IGradeView {
  theme: Theme;
  score: number;
}
export const GradeView: React.FC<IGradeView> = ({ score, theme }) => {
  const getBoxColor = (score: number): string => {
    const hex = theme.score[calculateGrade(score)];
    return `${hex}${Math.floor(0.4 * 255)
      .toString(16)
      .padStart(2)}`;
  };
  return (
    <Fragment>
      <Box
        className="platform-grade-wrapper"
        style={{
          color: theme.score[calculateGrade(score)],
          background: `linear-gradient(to top,${getBoxColor(score)}, ${
            theme.palette.background.paper
          })`,
        }}
      >
        <Typography variant="h6" color="inherit">
          {calculateGrade(score)}
        </Typography>
      </Box>
    </Fragment>
  );
};
