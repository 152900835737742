import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Skeleton,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { FetchIssuesSummary } from "../../../../../services/dmarc.service";
import { toast } from "../../../../../state/snackbar";
import { KFormatter } from "../../../../../util/k_formatter";
import { getSeverityColors } from "../../../util/severity-colors";

export const DmarcIssuesCard: React.FC<{ updateID: boolean }> = ({ updateID }) => {
  const theme = useTheme<Theme>();
  const [loadingStatus, setLoadingStatus] = React.useState<boolean>(true);
  const [openIssues, setOpenIssues] = React.useState(0);
  const [resolvedIssues, setResolvedIssues] = React.useState(0);
  const [severity, setSeverity] = React.useState<{ label: string; value: number; key: number }[]>([
    { label: "High", value: 0, key: 3 },
    { label: "Medium", value: 0, key: 2 },
    { label: "Low", value: 0, key: 1 },
  ]);
  const findBarWidth = (count: number): number => {
    const sum = _.sum(severity.map((x) => x.value));
    return (count / sum) * 100;
  };
  const fetchIssueStatus = () => {
    setLoadingStatus(true);
    setOpenIssues(0);
    setResolvedIssues(0);
    FetchIssuesSummary()
      .then((response) => {
        //   set status

        _.forEach(response.status, (x) => {
          if (x.key === "open") {
            setOpenIssues(x.doc_count);
          }
          if (x.key === "resolved") {
            setResolvedIssues(x.doc_count);
          }
        });

        // set severity
        const severityArray = response.severity.map((d: any) => {
          let key = 0;
          if (_.toLower(d.key) === "high") {
            key = 1;
          }
          if (_.toLower(d.key) === "medium") {
            key = 2;
          }
          if (_.toLower(d.key) === "low") {
            key = 3;
          }
          if (_.toLower(d.key) === "informational") {
            key = 4;
          }
          return {
            label: d.key,
            value: d.doc_count,
            key: key,
          };
        });
        setSeverity(_.orderBy(severityArray, "key", ["asc"]));
      })
      .catch(() => {
        toast("Error Loading Issues Summary", "error");
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  };

  React.useEffect(fetchIssueStatus, [updateID]);
  return (
    <Fragment>
      <Paper className="total-issues-card" id="issues_card_dashboard">
        <Box style={{ width: "100%" }}>
          <Box className="issues-wrapper">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Box className="issues-status-box">
                  {loadingStatus ? (
                    <CircularProgress size={32} />
                  ) : (
                    <Tooltip title={`Open Issues ${openIssues.toLocaleString()}`}>
                      <Typography variant="h5" color="primary" className="count-value">
                        {KFormatter(openIssues)}
                      </Typography>
                    </Tooltip>
                  )}
                  <Typography variant="h6" color="textPrimary" className="count-label">
                    Open Issues
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="issues-status-box">
                  {loadingStatus ? (
                    <CircularProgress size={32} />
                  ) : (
                    <Tooltip title={`Resolved Issues ${resolvedIssues.toLocaleString()}`}>
                      <Typography
                        variant="h5"
                        sx={{ color: "#6cbd45" }}
                        className="count-value resolved-issue-count"
                      >
                        {KFormatter(resolvedIssues)}
                      </Typography>
                    </Tooltip>
                  )}
                  <Typography variant="h6" color="textPrimary" className="count-label">
                    Resolved Issues
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {loadingStatus ? (
            <Skeleton height={100} />
          ) : severity.length ? (
            <Box className="severity-visualization-container">
              {severity.map((x) => (
                <Tooltip
                  key={x.key}
                  title={`${_.startCase(x.label)} severity issues ${x.value.toLocaleString()}`}
                >
                  <Box
                    key={x.key + x.label}
                    className={`severity-visualization-wrapper severity-${x.label.toLowerCase()}`}
                    style={{
                      width: `${findBarWidth(x.value)}%`,
                      color: getSeverityColors(x.label),
                      background: `linear-gradient(to top,${getSeverityColors(x.label)}${Math.floor(
                        0.65 * 255
                      )
                        .toString(16)
                        .padStart(2)}, ${theme.palette.background.paper})`,
                    }}
                  >
                    <Box>
                      <Typography variant="h5" className="severity-count">
                        {KFormatter(x.value)}
                      </Typography>
                      <Typography variant="subtitle1" component="p" className="severity-label">
                        {_.startCase(x.label)}
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              ))}
            </Box>
          ) : (
            <Box className="severity-empty-wrapper">
              <Typography variant="h6" color="inherit" className="good-health-text">
                no critical issues found
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Fragment>
  );
};
