import {
  Box,
  Chip,
  Divider,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { useBehaviourSubject } from "../hooks/use-observable";
import { receiveModules } from "../modules/module.register";
import { sidebar$ } from "../state/sidebar";
import { user$ } from "../state/user";
import { UpgradeModel } from "./upgrade.model";
import { selectedPlatform$ } from "../state/platformselection";
import { PlatformLogo } from "./PlatformLogo";
export const Sidebar: React.FC = () => {
  const location = useLocation();
  const isOpen = useBehaviourSubject(sidebar$);
  const theme = useTheme<Theme>();
  const user = useBehaviourSubject(user$);
  const platform = useBehaviourSubject(selectedPlatform$);
  const modules = receiveModules()[platform];
  const [currentPath, setCurrentPath] = React.useState<string>(window.location.pathname);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);
  return (
    <Fragment>
      {user ? (
        <Drawer
          variant="permanent"
          open={isOpen}
          onClose={() => sidebar$.next(false)}
          className="app-sidebar"
          style={{ backgroundColor: theme.palette.background.paper }}
        >
          <Box
            position={"sticky"}
            height={"54px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            bgcolor={theme.palette.background.paper}
            zIndex={1501}
            top={0}
            minHeight={"54px"}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box width={isOpen ? "160px" : "45px"} lineHeight={0}>
                <PlatformLogo />
              </Box>
            </Link>
          </Box>
          <Box
            height={"100%"}
            paddingTop={4}
            overflow={"hidden auto"}
            className="app-sidebar-modules-wrapper"
            bgcolor={theme.palette.background.paper}
            style={{ "--my-bg-clr": theme.palette.background.paper } as React.CSSProperties}
          >
            {_.map(
              _.groupBy(modules, (x) => x.groupName || ""),
              (groups, title) => (
                <Box key={title} marginBottom={2} className={"cbs-module-group"}>
                  <Typography
                    variant="subtitle2"
                    fontSize={"13px"}
                    fontWeight={600}
                    textTransform={"uppercase"}
                    color={"textSecondary"}
                    whiteSpace={"nowrap"}
                    gutterBottom
                    paddingLeft={2}
                    width={"100%"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    className={"cbs-module-title"}
                    style={{
                      color: isOpen ? theme.palette.text.secondary : theme.palette.background.paper,
                      paddingLeft: isOpen ? "16px" : "0px",
                      borderBottom: isOpen
                        ? "1px solid transparent"
                        : `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    {_.upperCase(title)}
                  </Typography>

                  <Box>
                    <List dense disablePadding>
                      {groups.map((module) => (
                        <Fragment key={module.name}>
                          {module.showInSidebar ? (
                            <ListItem
                              key={module.name}
                              dense
                              disableGutters
                              disablePadding
                              id={`menu_${module.name}`}
                            >
                              <ListItemButton
                                dense
                                disableGutters
                                style={{ padding: "0" }}
                                selected={currentPath.startsWith(module.path)}
                                component={Link}
                                to={`${module.path}`}
                                disabled={!module.enabled}
                              >
                                <ListItemIcon
                                  style={{
                                    width: "50px",
                                    justifyContent: "center",
                                    minWidth: "50px",
                                  }}
                                >
                                  <Icon
                                    color={
                                      currentPath.startsWith(module.path) ? "primary" : "inherit"
                                    }
                                    style={{ fontSize: "17px" }}
                                  >
                                    {module.icon}
                                  </Icon>
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          fontSize={"13px"}
                                          fontWeight={500}
                                          color={
                                            currentPath.startsWith(module.path)
                                              ? "primary"
                                              : "textPrimary"
                                          }
                                          whiteSpace={"nowrap"}
                                        >
                                          {module.title}
                                        </Typography>
                                      </Box>
                                      {module.isBeta ? (
                                        <Box>
                                          <Chip
                                            className="beta-module-label"
                                            color="primary"
                                            size="small"
                                            label="BETA"
                                          />
                                        </Box>
                                      ) : null}
                                    </Stack>
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          ) : null}
                          {module.subModules
                            .filter((f) => f.showInSidebar)
                            .map((subModule) => (
                              <Fragment key={subModule.name}>
                                <ListItem
                                  className="submodule-level-1"
                                  key={subModule.name}
                                  dense
                                  disableGutters
                                  disablePadding
                                  secondaryAction={
                                    <Box paddingRight={2}>
                                      <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                        {subModule.isBeta ? (
                                          <Chip
                                            className="beta-module-label"
                                            color="primary"
                                            size="small"
                                            label="BETA"
                                          />
                                        ) : null}
                                        {/* {subModule.subModules.length ? <SideBarArrowInfo /> : null} */}
                                      </Stack>
                                    </Box>
                                  }
                                >
                                  <ListItemButton
                                    dense
                                    disableGutters
                                    style={{ padding: "0" }}
                                    selected={_.includes(currentPath, subModule.path)}
                                    component={Link}
                                    to={`${subModule.path}`}
                                    disabled={!subModule.enabled}
                                  >
                                    <ListItemIcon
                                      style={{
                                        width: "50px",
                                        justifyContent: "center",
                                        minWidth: "50px",
                                      }}
                                    >
                                      <Icon
                                        color={
                                          _.includes(currentPath, subModule.path)
                                            ? "primary"
                                            : "inherit"
                                        }
                                        style={{ fontSize: "17px" }}
                                      >
                                        {subModule.icon}
                                      </Icon>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={
                                        <Typography
                                          variant="h6"
                                          fontSize={"13px"}
                                          fontWeight={500}
                                          color={
                                            _.includes(currentPath, subModule.path)
                                              ? "primary"
                                              : "textPrimary"
                                          }
                                          whiteSpace={"nowrap"}
                                        >
                                          {subModule.title}
                                        </Typography>
                                      }
                                    />
                                  </ListItemButton>
                                </ListItem>
                              </Fragment>
                            ))}
                        </Fragment>
                      ))}
                    </List>
                  </Box>
                </Box>
              )
            )}
          </Box>
          <Box
            position={"fixed"}
            bottom={0}
            left={0}
            width={isOpen ? "220px" : "50px"}
            paddingLeft={0}
            bgcolor={theme.palette.background.paper}
            borderRight={"1px solid"}
            borderColor={theme.palette.divider}
            height={"61px"}
            overflow={"hidden"}
            style={{ transition: "all 0.4s" }}
          >
            <Box>
              <Box padding={"0px 16px"}>
                <Divider />
              </Box>

              <Box padding={"8px 0"}>
                <List dense disablePadding>
                  <ListItemButton onClick={() => setOpen(true)}>
                    <ListItem>
                      <ListItemIcon style={{ minWidth: "35px" }}>
                        <Icon color="primary">bolt</Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            // color={"primary"}
                            variant="h6"
                            fontSize={"13px"}
                            fontWeight={600}
                            whiteSpace={"nowrap"}
                            color={"primary"}
                          >
                            Upgrade Now
                          </Typography>
                        }
                      />
                    </ListItem>
                  </ListItemButton>
                </List>
              </Box>
            </Box>
          </Box>
        </Drawer>
      ) : null}
      {open ? (
        <UpgradeModel
          onClose={() => {
            setOpen(false);
          }}
        />
      ) : null}
    </Fragment>
  );
};
