import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Paper,
  Tooltip,
  Button,
  Popover,
  useTheme,
  Theme,
} from "@mui/material";
import _, { get } from "lodash";
import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../App.interface";
import {
  FetchMobileAppsList,
  FetchThreatManagerFilterOptions,
} from "../../../services/cbs.service";
import { toast } from "../../../state/snackbar";
import { UUIDGenerator } from "../../../util/uuid";
import { AppDrawer } from "../../views/Drawer";
import { AppMasterTable } from "../../views/app.table";
import { RiskRules } from "../../views/risk_rules";
import { RiskScore } from "../../views/risk_score";
import useDeepCompareEffect from "use-deep-compare-effect";
import { BlurInfo } from "../../module.views/BlurInfo";
import { setInitialDateRanges } from "../../../util/date-range";
import { IDateFilterObj, ITableFilters } from "../../module.interface";
import moment from "moment";
import { DateRangeFilter } from "../../../components/date.filter";
import { AppButtonFilter } from "../../../components/button-filter/button.filter.index";
import { AppMD5Hash } from "../../../util/md5.hash";
import { useObservable } from "../../../hooks/use-observable";
import { BrandService } from "../../../services/brand.service";

export const RougeMobileAppsModule: React.FC = () => {
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const theme = useTheme<Theme>();
  const [requestCount, setRequestCount] = React.useState(0);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("desc");
  const [sortField, setSortField] = React.useState<string>("first_seen");
  const [totalDocs, setTotalDocs] = React.useState<number>(0);
  const [updateID, setUpdateID] = React.useState(UUIDGenerator());
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc>();
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [selectedDateRange, setSelectedDateRange] = React.useState<IDateFilterObj>(
    setInitialDateRanges()
  );
  const [dateRange, setDateRange] = React.useState<HTMLButtonElement | null>(null);
  const [filterList, setFilterList] = React.useState<ITableFilters[]>([]);
  const [searchVal, setSearchVal] = React.useState<string>("");
  const [loadingFilters, setLoadingFilters] = React.useState(true);
  const [filterOptions, setFilterOptions] = React.useState<{ [key: string]: string[] }>({});
  const onChangeSearchQuery = (val: string) => setSearchVal(val);
  const onSetSearchQuery = () => {
    setSearchQuery(searchVal.trim().replace(/\/(?=[^/]*$)/, "/"));
    setDocs([]);
  };

  const fetchList = () => {
    setRequestCount((c) => c + 1);
    FetchMobileAppsList(
      pageIndex,
      pageSize,
      searchQuery.replace(/[^a-zA-Z ]/g, ""),
      sortField,
      sortOrder,
      filterList,
      selectedDateRange.status ? selectedDateRange.startDate : undefined,
      selectedDateRange.status ? selectedDateRange.endDate : undefined
    )
      .then((response) => {
        setDocs(response.data);
        setTotalDocs(get(response, "metadata.total.value") as unknown as number);
      })
      .catch(() => {
        toast("Error in loading mobile app incidents", "error");
      })
      .finally(() => {
        setRequestCount((x) => x - 1);
      });
  };
  const FetchFilterOptions = () => {
    setLoadingFilters(true);

    FetchThreatManagerFilterOptions("mobile_apps")
      .then((response) => {
        const options: { [key: string]: string[] } = {};
        _.forEach(response, (v, k) => {
          options[k] = v.buckets.filter((f: any) => f.key).map((i: any) => i.key);
        });
        setFilterOptions(options);
      })
      .catch(() => {
        toast("Error in loading filters", "error");
      })
      .finally(() => {
        setLoadingFilters(false);
      });
  };
  useEffect(FetchFilterOptions, []);
  useDeepCompareEffect(fetchList, [
    filterList,
    pageIndex,
    pageSize,
    searchQuery,
    selectedDateRange,

    sortField,
    sortOrder,
    updateID,
  ]);

  useDeepCompareEffect(() => {
    setUpdateID(UUIDGenerator());
  }, [selectedMemberIds]);
  return (
    <Fragment>
      <Box className="app-module-container">
        <Box className="app-module-header">
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid item xs={8}>
              <Typography variant="h4" className="module-title" color="primary">
                Rogue Mobile Apps
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textPrimary"
                className="module-description"
              >
                Potential malicious mobile app threats for your organization
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box className="cbs-module-actions">
          <Grid
            spacing={2}
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
              <Grid
                spacing={1}
                container
                alignItems="center"
                justifyContent="flex-start"
                wrap="nowrap"
              >
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Typography
                    variant="subtitle1"
                    className="button-filter-label"
                    color="textPrimary"
                    height={"30px"}
                  />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      id="outlined-basic"
                      label={"Search..."}
                      value={searchVal}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Icon color="action">search</Icon>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      onKeyDown={(ev) => (ev.key === "Enter" ? onSetSearchQuery() : null)}
                      onChange={(e) => onChangeSearchQuery(e.target.value)}
                    />
                  </form>
                </Grid>
                <Grid item>
                  <AppButtonFilter
                    loading={loadingFilters}
                    name={"risks"}
                    title={"Risks"}
                    options={filterOptions["risks"] || []}
                    selection={filterList
                      .filter((f) => f.field === "risks")
                      .map((x) => x.value as string)}
                    onChangeOption={(params) => {
                      setFilterList((arr) => [
                        ...arr.filter((f) => f.field !== "risks"),
                        ...params.map((x) => ({
                          id: AppMD5Hash(`risks_risks_${x}`),
                          field: "risks",
                          title: "Risks",
                          operator: "is one of",
                          value: x,
                        })),
                      ]);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    className="button-filter-label"
                    color="textPrimary"
                    height={"30px"}
                  />
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      color: theme.palette.text.primary,
                      borderColor: theme.palette.divider,
                      fontSize: "14px",
                      height: "40px",
                      textTransform: "capitalize",
                    }}
                    className="ticket-filter-button-customized"
                    onClick={(event) => setDateRange(event.currentTarget)}
                  >
                    {selectedDateRange.mode === "quick"
                      ? selectedDateRange.value
                      : moment(selectedDateRange.startDate).format("MMMM Do YYYY") +
                        " - " +
                        moment(selectedDateRange.endDate).format("MMMM Do YYYY")}
                  </Button>
                  <Popover
                    id="date-range-popover"
                    open={Boolean(dateRange)}
                    anchorEl={dateRange}
                    onClose={() => setDateRange(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box>
                      <DateRangeFilter
                        currentDateObj={selectedDateRange}
                        publishDateObj={(params) => {
                          setSelectedDateRange(params);
                          setDateRange(null);
                        }}
                        onClose={() => setDateRange(null)}
                      />
                    </Box>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                className="button-filter-label"
                color="textPrimary"
                height={"30px"}
              />
              <Grid
                spacing={2}
                container
                alignItems="center"
                justifyContent="flex-start"
                wrap="nowrap"
              >
                <Grid item>
                  <Tooltip title="Refresh Data">
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={() => setUpdateID(UUIDGenerator())}
                    >
                      <Icon>refresh</Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <AppMasterTable
            loading={Boolean(requestCount > 0)}
            dataSource={""}
            name={""}
            title={""}
            colDefs={[
              {
                masterColumnName: "url",
                masterColumnTitle: "App URL",
                renderType: "url",
                // cellRender: (doc) => <PhishingInfo doc={doc} />,
              },

              {
                masterColumnName: "risks",
                masterColumnTitle: "Risks",
                renderType: "text",
                cellRender: (params: IESDoc) => <RiskRules doc={params} collapsed={true} />,
              },
              {
                masterColumnName: "risk_score",
                masterColumnTitle: "Score",
                renderType: "number",
                cellRender: (params: IESDoc) => <RiskScore doc={params} field="risk_score" />,
              },

              {
                masterColumnName: "first_seen",
                masterColumnTitle: "First Seen",
                renderType: "date",
              },

              {
                masterColumnName: "last_seen",
                masterColumnTitle: "Last Seen",
                renderType: "date",
              },
            ]}
            docs={docs}
            checkedDocs={[]}
            totalDocs={_.min([totalDocs, 500]) || 500}
            pageIndex={pageIndex}
            pageSize={pageSize}
            sortField={sortField}
            sortOrder={sortOrder}
            enablePagination={true}
            enableCheckBox={false}
            enableSelection={true}
            enableBrandColum={false}
            onChangeSortField={setSortField}
            onChangeSortOrder={setSortOrder}
            onChangePageIndex={setPageIndex}
            onChangePageSize={setPageSize}
            onChangeCheckedDocs={() => null}
            onChangeSelectedDoc={setSelectedDoc}
          />
        </Paper>
      </Box>
      {selectedDoc ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={selectedDoc.url}
          onClose={() => {
            setSelectedDoc(undefined);
          }}
        >
          <BlurInfo />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
