import { Box, Paper, Typography } from "@mui/material";
import React, { Fragment } from "react";

interface IAppAssetsSectionBox {
  type: string;
  title: string;
  children?: React.ReactNode;
  flatPaper?: boolean;
}
export const AppAssetsSectionBox: React.FC<IAppAssetsSectionBox> = ({
  title,
  type,
  children,
  flatPaper,
}) => {
  return (
    <Fragment>
      <Box className="app-section-box" id={`${type}_advanced_view_section_box_${title}`}>
        <Box className="app-section-box-header">
          <Typography variant="h6" color="secondary" className="section-title">
            {title}
          </Typography>
        </Box>
        <Paper className="app-section-box-contents" elevation={flatPaper ? 0 : undefined}>
          {children}
        </Paper>
      </Box>
    </Fragment>
  );
};
