import {
  Paper,
  Box,
  useTheme,
  Theme,
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { FetchOrganizationIssues } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { KFormatter } from "../../util/k_formatter";
import { getSeverityStr } from "../../util/severity-mappings";

import "./styles.scss";
export const IssuesCard: React.FC<{ updateId: string }> = ({ updateId }) => {
  const theme = useTheme<Theme>();
  const [loadingStatus, setLoadingStatus] = React.useState<boolean>(true);

  const [criticalIssues, setCriticalIssues] = React.useState(0);
  const [openIssues, setOpenIssues] = React.useState(0);
  const [resolvedIssues, setResolvedIssues] = React.useState(0);
  const [severity, setSeverity] = React.useState<{ label: string; value: number; key: number }[]>([
    { label: "Critical", value: 0, key: 4 },
    { label: "High", value: 0, key: 3 },
    { label: "Medium", value: 0, key: 2 },
    { label: "Low", value: 0, key: 1 },
  ]);
  const findBarWidth = (count: number): number => {
    const sum = _.sum(severity.map((x) => x.value));
    return (count / sum) * 100;
  };
  const fetchIssueStatus = () => {
    setLoadingStatus(true);
    FetchOrganizationIssues()
      .then((response) => {
        setCriticalIssues(response.filter((f) => f.issue_type === "active")[0]?.critical || 0);
        setOpenIssues(response.filter((f) => f.issue_type === "active")[0]?.count || 0);
        setResolvedIssues(response.filter((f) => f.issue_type === "inactive")[0]?.count || 0);
        setSeverity([
          {
            label: "Critical",
            value: response.filter((f) => f.issue_type === "active")[0]?.critical || 0,
            key: 4,
          },
          {
            label: "High",
            value: response.filter((f) => f.issue_type === "active")[0]?.high || 0,
            key: 3,
          },
          {
            label: "Medium",
            value: response.filter((f) => f.issue_type === "active")[0]?.medium || 0,
            key: 2,
          },
          {
            label: "Low",
            value: response.filter((f) => f.issue_type === "active")[0]?.low || 0,
            key: 1,
          },
        ]);
      })
      .catch(() => {
        toast("Error loading organization issues", "error");
      })
      .finally(() => {
        setLoadingStatus(false);
      });
  };
  useEffect(fetchIssueStatus, [updateId]);
  return (
    <Fragment>
      <Paper className="total-issues-card" id="issues_card_dashboard">
        <Box style={{ width: "100%" }}>
          <Box className="issues-wrapper">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <Box className="issues-status-box">
                  {loadingStatus ? (
                    <CircularProgress size={32} />
                  ) : (
                    <Tooltip title={`Critical Issues ${criticalIssues.toLocaleString()}`}>
                      <Typography
                        variant="h6"
                        color="textPrimary"
                        // style={{ color: theme.severity["critical"] }}
                        className="count-value"
                      >
                        {KFormatter(criticalIssues)}
                      </Typography>
                    </Tooltip>
                  )}
                  <Typography variant="h6" color="textPrimary" className="count-label">
                    Critical Issues
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="issues-status-box">
                  {loadingStatus ? (
                    <CircularProgress size={32} />
                  ) : (
                    <Tooltip title={`Open Issues ${openIssues.toLocaleString()}`}>
                      <Typography variant="h6" color="primary" className="count-value">
                        {KFormatter(openIssues)}
                      </Typography>
                    </Tooltip>
                  )}
                  <Typography variant="h6" color="textPrimary" className="count-label">
                    Open Issues
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="issues-status-box">
                  {loadingStatus ? (
                    <CircularProgress size={32} />
                  ) : (
                    <Tooltip title={`Resolved Issues ${resolvedIssues.toLocaleString()}`}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        className="count-value resolved-issue-count"
                      >
                        {KFormatter(resolvedIssues)}
                      </Typography>
                    </Tooltip>
                  )}
                  <Typography variant="h6" color="textPrimary" className="count-label">
                    Resolved Issues
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {loadingStatus ? (
            <Skeleton variant="rectangular" height={100} />
          ) : severity.length ? (
            <Box className="severity-visualization-container">
              {severity.map((x) => (
                <Tooltip
                  key={x.key}
                  title={`${_.startCase(x.label)} severity issues ${x.value.toLocaleString()}`}
                >
                  <Box
                    key={x.key + x.label}
                    className={`severity-visualization-wrapper severity-${x.label.toLowerCase()}`}
                    style={{
                      width: `${findBarWidth(x.value)}%`,
                      color: theme.severity[getSeverityStr(x.key)],
                      background: `linear-gradient(to top,${
                        theme.severity[getSeverityStr(x.key)]
                      }${Math.floor(0.65 * 255)
                        .toString(16)
                        .padStart(2)}, ${theme.palette.background.paper})`,
                    }}
                  >
                    <Box>
                      <Typography variant="h6" className="severity-count">
                        {KFormatter(x.value)}
                      </Typography>
                      <Typography variant="subtitle1" component="p" className="severity-label">
                        {_.startCase(x.label)}
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              ))}
            </Box>
          ) : (
            <Box className="severity-empty-wrapper">
              <Typography variant="h6" color="inherit" className="good-health-text">
                no critical issues found
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
    </Fragment>
  );
};
