import { Chip, Stack, Theme, useTheme } from "@mui/material";
import React, { Fragment } from "react";

import isEmpty from "lodash/isEmpty";
import { IESDoc } from "../../../App.interface";
import { ColorShades } from "../../../util/color-shades";
import { stringToColor } from "../../../util/color.generator";
import { ColorOpacity } from "../../../util/color_opacity";
import { AppMD5Hash } from "../../../util/md5.hash";

export const BreachSourceView: React.FC<{ doc: IESDoc }> = ({ doc }) => {
  const theme = useTheme<Theme>();
  const computeLabelColor = (lbl: string): string => {
    const intensity = theme.palette.mode === "dark" ? "40" : "700";
    const pallette = ColorShades(stringToColor(AppMD5Hash(lbl)), intensity);
    return pallette;
  };
  return (
    <Fragment>
      {!isEmpty(doc.sources) ? (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          {doc.sources
            .filter((f: { entity_name: string }) => f.entity_name)
            .map((i: { entity_name: string }) => (
              <Chip
                key={i.entity_name}
                variant="filled"
                label={i.entity_name}
                size={"small"}
                style={{
                  fontWeight: 600,
                  borderRadius: "5px",
                  color: computeLabelColor(i.entity_name),
                  backgroundColor: ColorOpacity(computeLabelColor(i.entity_name), 0.15),
                }}
              />
            ))}
        </Stack>
      ) : (
        "-"
      )}
    </Fragment>
  );
};
