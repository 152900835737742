import {
  Accordion,
  AccordionDetails,
  Alert,
  AlertTitle,
  Box,
  Button,
  Link,
  Paper,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { FetchIssueProfile } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { AppMD5Hash } from "../../util/md5.hash";
import { getSeverityStr } from "../../util/severity-mappings";
import { UUIDGenerator } from "../../util/uuid";
import { IIssueProfile } from "../module.interface";
import { EmptyTableView } from "../views/EmptyTable";
import { LoadingIssueNames } from "./issues.loading";
import { IssuesNamePanel } from "./issues.name.panel";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useObservable } from "../../hooks/use-observable";
import { BrandService } from "../../services/brand.service";
import { IssueList } from "./issue_list";

export const IssuesModule: React.FC = () => {
  const theme = useTheme<Theme>();
  const [loading, setLoading] = React.useState(true);
  const [riskProfile, setRiskProfile] = React.useState<IIssueProfile[]>([]);
  const [selectedIssueName, setSelectedIssueName] = React.useState<string[]>([]);
  const [categoryCount, setCategoryCount] = React.useState(0);
  const [totalIssues, setTotalIssues] = React.useState(0);
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const [updateId, setUpdateId] = React.useState("");

  const onToggleAccordion = (issueName: string) => {
    const index = _.includes(selectedIssueName, issueName);

    if (!index) {
      setSelectedIssueName((arr) => [...arr, issueName, `${issueName}_view_all`]);
    } else {
      setSelectedIssueName((arr) =>
        arr.filter((f) => !_.includes([issueName, `${issueName}_view_all`], f))
      );
    }
  };
  const fetchData = () => {
    setLoading(true);
    setCategoryCount(0);
    setTotalIssues(0);
    FetchIssueProfile()
      .then((response) => {
        setRiskProfile(response.issues);
        setCategoryCount(response.issue_category_count);
        setTotalIssues(response.total_issue_count);
      })
      .catch(() => {
        toast("Error in loading the risk profile", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const showCallOut = (): boolean => {
    if (loading) {
      return false;
    }
    const showCount = _.sum(riskProfile.map((x) => x.issueCount));
    if (showCount >= totalIssues && categoryCount >= riskProfile.length) {
      return false;
    }
    return true;
  };
  useEffect(fetchData, [updateId]);
  useDeepCompareEffect(() => {
    setUpdateId(UUIDGenerator());
  }, [selectedMemberIds]);
  return (
    <Fragment>
      <Box className="module-view-container">
        <Box className="header">
          <Typography color="primary" variant="h4" className="title">
            Issues
          </Typography>
          <Typography variant="body2" className="sub-title">
            Issues found by analyzing cyber assets, classified by their type, severity, category,
            and status.
          </Typography>
        </Box>
        {showCallOut() ? (
          <Box marginBottom={2} marginTop={2} maxWidth={720}>
            <Alert severity="warning" variant="outlined">
              <AlertTitle>
                <Typography variant="h6" color={"inherit"} fontSize={"16px"} fontWeight={600}>
                  Restricted View
                </Typography>
              </AlertTitle>
              <Typography
                variant="subtitle1"
                color={"inherit"}
                fontSize={"12px"}
                fontWeight={500}
                marginBottom={1.5}
              >
                We have detected a total of {totalIssues.toLocaleString()} issues across{" "}
                {categoryCount.toLocaleString()} categories for your assets.
                <br />
                To explore more information about these issues please upgrade your subscription.
              </Typography>
              <Button
                variant="contained"
                component={Link}
                href={`https://${
                  process.env.NODE_ENV === "development" ? "cyno.edxdev.link" : "start.ctm360.com"
                }/pricing`}
                target="_blank"
                size="small"
              >
                <Typography
                  variant="h6"
                  textTransform={"capitalize"}
                  fontWeight={600}
                  fontSize={"12px"}
                >
                  Upgrade Now
                </Typography>
              </Button>
            </Alert>
          </Box>
        ) : null}
        <Box marginTop={3}>
          {loading ? (
            <LoadingIssueNames />
          ) : riskProfile.length ? (
            riskProfile.map((x, index) => (
              <Accordion
                square
                onChange={() => onToggleAccordion(x.issueName)}
                expanded={_.includes(selectedIssueName, x.issueName)}
                TransitionProps={{ unmountOnExit: true }}
                key={AppMD5Hash(x.issueName)}
                className={"issues-accordion-wrapper"}
                style={{
                  borderLeft: `2px solid ${theme.severity[getSeverityStr(x.severity)]}`,
                }}
              >
                <IssuesNamePanel
                  issue={x}
                  expanded={_.includes(selectedIssueName, x.issueName)}
                  showIcon={true}
                  locked={index >= 5}
                />
                <AccordionDetails>
                  <Paper
                    variant="outlined"
                    square
                    style={{
                      backgroundColor: theme.palette.background.default,
                      width: "100%",
                      padding: "16px",
                    }}
                  >
                    <Box position={"relative"}>
                      <IssueList issue={x} enabled={index < 5} />
                    </Box>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <EmptyTableView />
          )}
        </Box>
      </Box>
    </Fragment>
  );
};
