import { Box, Theme, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import { sum, startCase } from "lodash";

export const DetectionPieChart: React.FC<{
  data: { key: string; doc_count: number }[];
}> = ({ data }) => {
  const theme = useTheme<Theme>();

  return (
    <Fragment>
      <Box>
        <ResponsiveContainer height={300}>
          <Chart
            options={{
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "10px",
                labels: {
                  colors: theme.palette.text.primary,
                },
                customLegendItems: [],
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: "80%",
                    background: "transparent",
                    labels: {
                      show: true,
                      name: {
                        show: true,
                        formatter: () => "Total",
                        color: theme.palette.primary.main,
                      },
                      value: {
                        show: true,
                        fontFamily: "Montserrat",
                        color: theme.palette.text.primary,
                        formatter: (val) => parseInt(val, 10).toLocaleString(),
                      },
                      total: {
                        show: true,
                        fontFamily: "Montserrat",
                        color: theme.palette.primary.main,
                        formatter: () => sum(data.map((x) => x.doc_count)).toLocaleString(),
                      },
                    },
                  },
                },
              },
              noData: {
                text: "Data not available",
                align: "center",
                verticalAlign: "middle",
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: theme.palette.text.primary,
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                },
              },
              dataLabels: {
                enabled: true,
                dropShadow: {
                  enabled: false,
                },
                style: {
                  colors: [theme.palette.text.primary],
                },
              },
              stroke: {
                show: false,
                curve: "smooth",
                lineCap: "butt",
                colors: undefined,
                width: 2,
                dashArray: 0,
              },
              labels: data.map((x) => startCase(x.key)),
            }}
            series={data.map((x) => x.doc_count)}
            type="donut"
          />
        </ResponsiveContainer>
      </Box>
    </Fragment>
  );
};
