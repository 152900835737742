import { LinearProgress, Paper, Divider, Grid, Typography, Box } from "@mui/material";
import React from "react";
import { Fragment } from "react";
import _ from "lodash";
import { AppMD5Hash } from "../../../../../util/md5.hash";
import { oldPieColors } from "../../../util/pie-colors";
import { EmptyTableView } from "../../../../views/EmptyTable";
import { DmarcProgressBarChart } from "../components/progress-bar";

interface IDashboardDnsRecordsScore {
  loading: boolean;
  rawData: any;
}
interface ChartItem {
  id: string;
  name: string;
  color: string;
  score: number;
  previous: number;
}

export default function DmarcDashboardDnsScore({ loading, rawData }: IDashboardDnsRecordsScore) {
  const [data, setData] = React.useState<ChartItem[]>([]);

  React.useEffect(() => {
    const data = [
      {
        name: "DMARC",
        color: oldPieColors[0],
        id: AppMD5Hash("dmarc"),
        score: rawData?.dmarc?.current > 100 ? 100 : rawData?.dmarc?.current,
        previous: rawData?.dmarc?.previous > 100 ? 100 : rawData?.dmarc?.previous,
      },
      {
        name: "DKIM",
        color: oldPieColors[4],
        id: AppMD5Hash("dkim"),
        score: rawData?.dkim?.current > 100 ? 100 : rawData?.dkim?.current,
        previous: rawData?.dkim?.previous > 100 ? 100 : rawData?.dkim?.previous,
      },
      {
        name: "SPF",
        color: oldPieColors[1],
        id: AppMD5Hash("spf"),
        score: rawData?.spf?.current > 100 ? 100 : rawData?.spf?.current,
        previous: rawData?.spf?.previous > 100 ? 100 : rawData?.spf?.previous,
      },
    ];

    setData(_.orderBy(data, "score", ["desc"]));
  }, [rawData]);

  return (
    <Fragment>
      <Paper style={{ height: "100%" }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item sx={{ paddingX: "8px" }}>
            <Grid container alignItems="center" style={{ margin: "10px 12px" }}>
              <Grid item xs={10}>
                <Typography color="primary" sx={{ fontSize: 13, fontWeight: 600 }}>
                  DNS Records Score
                </Typography>
                <Typography color="textSecondary" sx={{ fontSize: 11 }}>
                  Issues detected on your DNS records
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>

          <Grid item sx={{ padding: "8px" }}>
            {loading ? (
              <LinearProgress />
            ) : rawData?.dmarc === 0 && rawData?.dkim === 0 && rawData?.spf === 0 ? (
              <EmptyTableView />
            ) : (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box sx={{ width: "100%", my: 1.25 }}>
                  <Grid container spacing={1} sx={{ paddingTop: 4, overflow: "hidden" }}>
                    {data.map((item) => {
                      return (
                        <Grid item lg={4} md={12} sm={12} xs={12} key={item.id}>
                          <Grid container justifyContent={"center"}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <DmarcProgressBarChart
                                title={item.name}
                                value={item.score}
                                loading={loading}
                                size={210}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
}
