import React, { Fragment } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Popover,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";

export const DmarcIPInfoDialog: React.FC<{
  ipInfoData: any;
  isOpen: boolean;
  actionsEl: HTMLElement | null;
  loading: boolean;

  onClose: () => void;
}> = ({ ipInfoData, isOpen, onClose, actionsEl, loading }) => {
  const theme = useTheme();
  return (
    <Popover
      elevation={2}
      open={Boolean(isOpen)}
      sx={{ boxShadow: "none" }}
      anchorEl={actionsEl}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ padding: 2, width: 400, maxWidth: 400 }}>
        <Stack>
          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }} color="primary">
              IP Analysis
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Grid
            container
            rowSpacing={1}
            className="scroll-designed"
            sx={{ maxHeight: 570, overflow: "auto" }}
          >
            {loading ? (
              [1, 2].map((num) => {
                return (
                  <Grid item key={num} lg={12} md={12} sm={12} xs={12}>
                    <Skeleton variant="rectangular" height={50} />
                  </Grid>
                );
              })
            ) : (
              <Fragment>
                <Paper sx={{ padding: 1, backgroundColor: theme.palette.background.default }}>
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Paper sx={{ padding: 1 }}>
                        <Stack direction={"row"} spacing={1}>
                          <Typography fontSize={13} color="secondary" fontWeight={600}>
                            Country :{" "}
                          </Typography>

                          {_.startCase(ipInfoData?.location || "-")}
                        </Stack>
                      </Paper>{" "}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Paper sx={{ padding: 1 }}>
                        <Stack direction={"row"} spacing={1}>
                          <Typography fontSize={13} fontWeight={600} color="secondary">
                            Network Owner :
                          </Typography>{" "}
                          <Typography fontSize={13}> {ipInfoData?.netname || "-"}</Typography>
                        </Stack>
                      </Paper>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Box display="flex" alignItems="center">
                        <Divider sx={{ width: "40%", my: 1 }} />
                        <Button
                          sx={{ fontSize: "0.7rem" }}
                          size="small"
                          onClick={() => {
                            window.open(
                              `https://www.talosintelligence.com/reputation_center/lookup?search=${ipInfoData?.ip_address}`
                            );
                          }}
                          color="primary"
                        >
                          Find More
                        </Button>
                        <Divider sx={{ width: "40%", my: 1 }} />
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Fragment>
            )}
          </Grid>
        </Stack>
      </Box>
    </Popover>
  );
};
