import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { GenerateTakedownPlan } from "./takedown";
interface IDrpTakedownPlans {
  drpPlan: string;
  takedownCount: number;
  onChangeTakedownCount: (params: number) => void;
}
export const DrpTakedownPlans: React.FC<IDrpTakedownPlans> = ({
  drpPlan,
  onChangeTakedownCount,
  takedownCount,
}) => {
  const theme = useTheme<Theme>();
  const [takeDown, setTakeDown] = React.useState<number>(takedownCount);
  const [generatedPlan, setGeneratedPlan] = React.useState(GenerateTakedownPlan(takeDown));
  useEffect(() => {
    setGeneratedPlan(GenerateTakedownPlan(takeDown));
  }, [takeDown]);
  useEffect(() => {
    if (drpPlan) {
      setTakeDown(0);
      setGeneratedPlan(GenerateTakedownPlan(0));
      onChangeTakedownCount(0);
    }
  }, [drpPlan, onChangeTakedownCount]);
  return (
    <Fragment>
      <Box marginBottom={2} marginTop={2}>
        <Paper variant="outlined">
          <Box bgcolor={theme.palette.background.default} padding={"16px 24px 16px 4px"}>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                  <Box>
                    <Checkbox
                      inputProps={{ "aria-label": "takedown-checkbox" }}
                      checked={takeDown > 0}
                      color="primary"
                      onChange={(e, v) => {
                        setTakeDown(v ? 100 : 0);
                        onChangeTakedownCount(v ? 100 : 0);
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                      //   color={x.name === selectedPlan ? "primary" : "secondary"}
                    >
                      Takedown Only
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  fontSize={"10px"}
                  fontWeight={600}
                  color={"textSecondary"}
                >
                  STARTS AT
                </Typography>
                <Typography variant="h6" fontSize={"14px"}>
                  <strong>
                    {`$${(25000).toLocaleString()}`}
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      fontWeight={600}
                      fontSize={"10px"}
                    >
                      /yr
                    </Typography>
                  </strong>
                </Typography>
              </Box>
            </Stack>
            <Box marginLeft={"50px"}>
              <Box>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={takeDown.toString()}
                    onChange={(e, v) => {
                      setTakeDown(parseInt(v));
                      onChangeTakedownCount(parseInt(v));
                    }}
                  >
                    <FormControlLabel
                      value={"100"}
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                          100 Takedowns
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={"500"}
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                          500 Takedowns
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={"1000"}
                      control={<Radio size="small" />}
                      label={
                        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                          1000 Takedowns
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              {generatedPlan ? (
                <Box marginTop={0}>
                  <List dense disablePadding>
                    <ListItem disableGutters disablePadding>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <Icon color="primary" style={{ fontSize: "20px" }}>
                          verified
                        </Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            color={"primary"}
                            fontSize={"12px"}
                            fontWeight={600}
                          >
                            {`Free access to ${generatedPlan.freebies.join()}`}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disableGutters disablePadding>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <Icon style={{ fontSize: "20px" }}>verified</Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                            {`$${generatedPlan.annual_price.toLocaleString()} per annum`}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem disableGutters disablePadding>
                      <ListItemIcon style={{ minWidth: "30px" }}>
                        <Icon style={{ fontSize: "20px" }}>verified</Icon>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                            {`${generatedPlan.user_count} users`}
                          </Typography>
                        }
                      />
                    </ListItem>
                    {generatedPlan.features.map((kp) => (
                      <ListItem key={kp} disableGutters disablePadding>
                        <ListItemIcon style={{ minWidth: "30px" }}>
                          <Icon style={{ fontSize: "20px" }}>verified</Icon>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                              {kp}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};
