import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import _isArray from "lodash/isArray";
import _has from "lodash/has";
import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../App.interface";
import { AppDocView } from "../../views/app.doc.view";
import { AppAssetsSectionBox } from "../../views/app.section.box";

export const HostOverView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  const [parsedDoc, setParsedDoc] = React.useState<IESDoc>(doc);
  useEffect(() => {
    setParsedDoc(() => ({
      ...doc,
      parsed_contains_ssl: _has(doc, "ssl.status") && doc.ssl.status === "valid" ? true : false,
      parsed_contains_a:
        _has(doc, "dns.a_record") &&
        _isArray(_get(doc, "dns.a_record")) &&
        !_isEmpty(_get(doc, "dns.a_record"))
          ? true
          : false,
      parsed_contains_cname:
        _has(doc, "dns.cname_record") &&
        _isArray(_get(doc, "dns.cname_record")) &&
        !_isEmpty(_get(doc, "dns.cname_record"))
          ? true
          : false,
      parsed_contains_ns:
        _has(doc, "dns.ns_record") &&
        _isArray(_get(doc, "dns.ns_record")) &&
        !_isEmpty(_get(doc, "dns.ns_record"))
          ? true
          : false,
      parsed_contains_mx:
        _has(doc, "dns.mx_record") &&
        _isArray(_get(doc, "dns.mx_record")) &&
        !_isEmpty(_get(doc, "dns.mx_record"))
          ? true
          : false,
      parsed_contains_spf:
        _has(doc, "dns.spf_record") &&
        _isArray(_get(doc, "dns.spf_record")) &&
        !_isEmpty(_get(doc, "dns.spf_record"))
          ? true
          : false,
      parsed_contains_dkim:
        _has(doc, "dns.dkim_record") &&
        _isArray(_get(doc, "dns.dkim_record")) &&
        !_isEmpty(_get(doc, "dns.dkim_record"))
          ? true
          : false,
      parsed_contains_dmarc:
        _has(doc, "dns.dmarc_record") &&
        _isArray(_get(doc, "dns.dmarc_record")) &&
        !_isEmpty(_get(doc, "dns.dmarc_record"))
          ? true
          : false,
    }));
  }, [doc]);
  return (
    <Fragment>
      <AppAssetsSectionBox title="Overview" type="domain">
        <AppDocView
          colDefs={[
            {
              masterColumnName: "resolved_ip",
              masterColumnTitle: "IP",
              renderType: "url",
            },
            {
              masterColumnName: "ip.open_ports",
              masterColumnTitle: "Open Ports",
              renderType: "tags",
            },
            {
              masterColumnName: "tags.host",
              masterColumnTitle: "Host Type",
              renderType: "tags",
            },
            {
              masterColumnName: "parsed_contains_ssl",
              masterColumnTitle: "Contains SSL",
              renderType: "boolean",
            },
            {
              masterColumnName: "parsed_contains_a",
              masterColumnTitle: "Contains A",
              renderType: "boolean",
            },
            {
              masterColumnName: "parsed_contains_cname",
              masterColumnTitle: "Contains CNAME",
              renderType: "boolean",
            },
            {
              masterColumnName: "parsed_contains_ns",
              masterColumnTitle: "Contains NS",
              renderType: "boolean",
            },
            {
              masterColumnName: "parsed_contains_mx",
              masterColumnTitle: "Contains MX",
              renderType: "boolean",
            },
            {
              masterColumnName: "parsed_contains_spf",
              masterColumnTitle: "Contains SPF",
              renderType: "boolean",
            },
            {
              masterColumnName: "parsed_contains_dkim",
              masterColumnTitle: "Contains DKIM",
              renderType: "boolean",
            },
            {
              masterColumnName: "parsed_contains_dmarc",
              masterColumnTitle: "Contains DMARC",
              renderType: "boolean",
            },
            {
              masterColumnName: "meta.first_seen",
              masterColumnTitle: "First Seen",
              renderType: "date",
            },
            {
              masterColumnName: "meta.last_seen",
              masterColumnTitle: "Last Seen",
              renderType: "date",
            },
          ]}
          doc={parsedDoc}
        />
      </AppAssetsSectionBox>
    </Fragment>
  );
};
