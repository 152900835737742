import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import { IDrpPricingPlans, IEasmPlans } from "../module.interface";
import { DrpPlans } from "./drp/drp_plans";
import { EASMPlans } from "./easm/easm_plans";
import { SubscriptionAddons } from "./addons";
import { IESDoc } from "../../App.interface";
import { PricingReview } from "./pricing_review";
import { PriceHighlighter } from "./price_highlighter";

export const PricingModule: React.FC = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedEasm, setSelectedEasm] = React.useState<IEasmPlans>();
  const [selectedDrp, setSelectedDrp] = React.useState<IDrpPricingPlans>();
  const [totalAssets, setTotalAssets] = React.useState(0);
  const [orgScoreCard, setOrgScoreCard] = React.useState(false);
  const [tpL1, setTpL1] = React.useState(0);
  const [training, setTraining] = React.useState(0);
  const [selectedSubsidiary, setSelectedSubsidiary] = React.useState<IESDoc[]>([]);
  const [executives, setExecutives] = React.useState(0);
  const [takeDown, setTakeDown] = React.useState<number>(0);
  const [enterpriseIssueDetails, setEnterpriseIssueDetails] = React.useState(false);
  return (
    <Fragment>
      <Box margin={"auto"} width={"90%"} maxWidth={"1200px"}>
        <Box marginTop={3} marginBottom={"2%"}>
          <Typography variant="h4" color={"primary"} gutterBottom>
            <strong>Ready to get started?</strong>
          </Typography>
          <Typography variant="subtitle1">
            Pricing to suit all sizes of business. We allow you to take advantage of all of our
            features with no additional fees
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xl={8} lg={8} md={7} sm={6} xs={12}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              className="pricing-stepper-wrapper"
              style={{ backgroundColor: "inherit" }}
            >
              <Step>
                <Paper>
                  <Box padding={"8px 16px"}>
                    <StepLabel>
                      <Box className="step-label-wrapper">
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <Typography variant="h6" className="step-title">
                              Attack Surface Management
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              className="step-info"
                              component="p"
                              color="textSecondary"
                            >
                              Choose your plan for external attack surface management
                            </Typography>
                          </Box>
                          {activeStep !== 0 ? (
                            <Box>
                              <Button
                                variant="text"
                                color="secondary"
                                onClick={() => setActiveStep(0)}
                              >
                                <Typography
                                  fontWeight={600}
                                  textTransform={"capitalize"}
                                  fontSize={"14px"}
                                >
                                  edit
                                </Typography>
                              </Button>
                            </Box>
                          ) : null}
                        </Stack>
                      </Box>
                    </StepLabel>
                  </Box>
                  <StepContent style={{ margin: 0, padding: 0, border: "none" }}>
                    <EASMPlans
                      currentPlan={selectedEasm ? selectedEasm.name : ""}
                      tpEnterpriseAddon={enterpriseIssueDetails}
                      onSelectPlan={setSelectedEasm}
                      totalAssetCount={setTotalAssets}
                      onSelectTpEnterpriseAddon={setEnterpriseIssueDetails}
                      onNext={() => setActiveStep((c) => c + 1)}
                    />
                  </StepContent>
                </Paper>
              </Step>
              <Step>
                <Paper>
                  <Box padding={"8px 16px"}>
                    <StepLabel>
                      <Box className="step-label-wrapper">
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <Typography variant="h6" className="step-title">
                              Digital Risk Protection
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              className="step-info"
                              component="p"
                              color="textSecondary"
                            >
                              Choose your plan for DRP and Takedowns
                            </Typography>
                          </Box>
                          {activeStep !== 1 ? (
                            <Box>
                              <Button
                                variant="text"
                                color="secondary"
                                onClick={() => setActiveStep(1)}
                              >
                                <Typography
                                  fontWeight={600}
                                  textTransform={"capitalize"}
                                  fontSize={"14px"}
                                >
                                  edit
                                </Typography>
                              </Button>
                            </Box>
                          ) : null}
                        </Stack>
                      </Box>
                    </StepLabel>
                  </Box>
                  <StepContent style={{ margin: 0, padding: 0, border: "none" }}>
                    <DrpPlans
                      onNext={() => setActiveStep((c) => c + 1)}
                      currentPlan={selectedDrp ? selectedDrp.name : ""}
                      onSelectPlan={setSelectedDrp}
                      takedownCount={takeDown}
                      onChangeTakedownCount={setTakeDown}
                    />
                  </StepContent>
                </Paper>
              </Step>
              <Step>
                <Paper>
                  <Box padding={"8px 16px"}>
                    <StepLabel>
                      <Box className="step-label-wrapper">
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <Typography variant="h6" className="step-title">
                              On-Demand Add ons
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              className="step-info"
                              component="p"
                              color="textSecondary"
                            >
                              Enhance your security with added boosters
                            </Typography>
                          </Box>
                          {activeStep !== 2 ? (
                            <Box>
                              <Button
                                variant="text"
                                color="secondary"
                                onClick={() => setActiveStep(2)}
                              >
                                <Typography
                                  fontWeight={600}
                                  textTransform={"capitalize"}
                                  fontSize={"14px"}
                                >
                                  edit
                                </Typography>
                              </Button>
                            </Box>
                          ) : null}
                        </Stack>
                      </Box>
                    </StepLabel>
                  </Box>
                  <StepContent style={{ margin: 0, padding: 0, border: "none" }}>
                    <SubscriptionAddons
                      EasmPlan={selectedEasm}
                      DrpPlan={selectedDrp}
                      tpEnterpriseAddon={enterpriseIssueDetails}
                      onNext={() => setActiveStep((c) => c + 1)}
                      tpL1={tpL1}
                      trainingSession={training}
                      orgScoreCard={orgScoreCard}
                      onChangeL1={setTpL1}
                      onChangeTraining={setTraining}
                      onChangeScoreCard={setOrgScoreCard}
                      executive={executives}
                      onChangeExecutive={setExecutives}
                      selectedSubsidiary={selectedSubsidiary}
                      onChangeSubsidiary={setSelectedSubsidiary}
                    />
                  </StepContent>
                </Paper>
              </Step>

              <Step>
                <Paper>
                  <Box padding={"8px 16px"}>
                    <StepLabel>
                      <Box className="step-label-wrapper">
                        <Stack
                          direction={"row"}
                          spacing={1}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <Typography variant="h6" className="step-title">
                              Review & Confirm
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              gutterBottom
                              className="step-info"
                              component="p"
                              color="textSecondary"
                            >
                              Confirm your plans and benefits
                            </Typography>
                          </Box>
                          {activeStep !== 3 ? (
                            <Box>
                              <Button
                                variant="text"
                                color="secondary"
                                onClick={() => setActiveStep(3)}
                              >
                                <Typography
                                  fontWeight={600}
                                  textTransform={"capitalize"}
                                  fontSize={"14px"}
                                >
                                  edit
                                </Typography>
                              </Button>
                            </Box>
                          ) : null}
                        </Stack>
                      </Box>
                    </StepLabel>
                  </Box>
                  <StepContent style={{ margin: 0, padding: 0, border: "none" }}>
                    <PricingReview
                      onNext={() => setActiveStep((c) => c + 1)}
                      EasmPlan={selectedEasm}
                      DrpPlan={selectedDrp}
                      tpL1={tpL1}
                      trainingSession={training}
                      orgScoreCard={orgScoreCard}
                      executive={executives}
                      selectedSubsidiary={selectedSubsidiary}
                      assetCount={totalAssets}
                      takeDown={takeDown}
                    />
                  </StepContent>
                </Paper>
              </Step>
            </Stepper>
          </Grid>
          <Grid item xl={4} lg={4} md={5} sm={6} xs={12}>
            <PriceHighlighter
              tpEnterpriseAddon={enterpriseIssueDetails}
              EasmPlan={selectedEasm}
              DrpPlan={selectedDrp}
              tpL1={tpL1}
              trainingSession={training}
              orgScoreCard={orgScoreCard}
              executive={executives}
              selectedSubsidiary={selectedSubsidiary}
              assetCount={totalAssets}
              takeDown={takeDown}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
