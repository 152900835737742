import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { DmarcAggregateReportsIndex } from "./aggregate-reports/aggregate-reports.index";

export const DmarcAnalyticsController: React.FC = () => {
  return (
    <Fragment>
      <Switch>
        <Route path={"/dmarc/analytics*"}>
          <DmarcAggregateReportsIndex />
        </Route>
      </Switch>
    </Fragment>
  );
};
