import { Box, Icon, IconButton, Stack, Tooltip } from "@mui/material";
import React, { Fragment } from "react";
import { UpgradeModel } from "../../views/upgrade.model";
import { range } from "lodash";

export const UpgradeViewCell: React.FC = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Fragment>
      <Box>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Box>
            <Stack direction={"row"} spacing={0.5}>
              {range(3).map((x) => (
                <Icon style={{ fontSize: "6px" }} key={x.toString()}>
                  circle
                </Icon>
              ))}
            </Stack>
          </Box>
          <Tooltip title={"Show Password"}>
            <IconButton
              size="small"
              onClick={(e) => {
                setShowPassword((flag) => !flag);
                e.stopPropagation();
              }}
            >
              <Icon fontSize="small">visibility</Icon>
            </IconButton>
          </Tooltip>
        </Stack>
      </Box>
      {showPassword ? (
        <UpgradeModel
          onClose={() => {
            setShowPassword(false);
          }}
        />
      ) : null}
    </Fragment>
  );
};
