import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import React, { Fragment } from "react";
import { IUserActivityLog, IUserFingerPrint } from "../App.interface";
import { logout } from "../services/auth.service";
import { LogUserActivity } from "../services/query.service";
import { IUser } from "../state/user";
import { AppMD5Hash } from "../util/md5.hash";
import { getSessionSignature, getCurrentFingerPrint } from "../util/user.activity.fingerprint";

export const LogOffModel: React.FC<{
  user: IUser;
  onClose: () => void;
}> = ({ user, onClose }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const userLogout = async () => {
    setLoading(true);
    const payload: IUserActivityLog[] = [];
    const sessionPayload: IUserFingerPrint[] = [];
    const sessionSignature = getSessionSignature();
    const trackedSession = getCurrentFingerPrint();
    sessionPayload.push({
      _id: AppMD5Hash(`${user.email}`),
      session_signature: sessionSignature,
      user_email: user.email,
      user_name: user.name,
      user_organization: user.organization,
      tracked_session: trackedSession,
    });
    payload.push({
      doc_id: AppMD5Hash(user.email),
      doc_index: "USER_PREFERENCES",
      doc_subject: "USER_SESSION",
      user_email: user.email,
      user_name: user.name,
      user_organization: user.organization,
      remarks: "Logged out from the portal",
    });
    if (trackedSession.length) {
      // const fingerprintResponse = await LogUserFingerPrint(sessionPayload);
      // if (!fingerprintResponse.errors) {
      //   clearTrackedSession();
      // }
    }
    await LogUserActivity(payload);
    setLoading(false);
    logout();
    onClose();
  };
  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="simple-dialog-title"
        open={true}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="simple-dialog-title">Exit EDX Labs</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">Are you sure you want to Logout ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={userLogout} disabled={loading}>
            Logout
          </Button>
          <Button onClick={onClose} variant="contained" color="primary" disabled={loading}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
