import { Box, Divider, Drawer, Grid, Icon, IconButton, Theme, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { Fragment, ReactNode } from "react";
interface IAppDrawer {
  anchor: "right" | "left" | "top" | "bottom";
  open: boolean;
  title: ReactNode;
  onClose: () => void;
  children?: React.ReactNode;
}
export const AppDrawer: React.FC<IAppDrawer> = ({ anchor, open, onClose, title, children }) => {
  const theme = useTheme<Theme>();
  return (
    <Fragment>
      <Drawer
        anchor={anchor}
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { backgroundColor: theme.palette.background.default },
        }}
      >
        <Box className="app-master-drawer-wrapper" id={`${title}_advanced_view`}>
          <Box className="header">
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="h5" color="primary" className="main-heading">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={onClose} size="large">
                  <Icon>close</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box className="contents">{children}</Box>
        </Box>
      </Drawer>
    </Fragment>
  );
};
