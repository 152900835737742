import { ITakedownPricingPlans } from "../../module.interface";

export const GenerateTakedownPlan = (takeDown: number): ITakedownPricingPlans | undefined => {
  if (takeDown > 0 && takeDown <= 100) {
    return {
      name: "Takedown",
      title: "Takedown",
      scope: "1 Domain/Brand",
      user_count: 1,
      annual_price: 25000,
      features: ["Takedowns across all incident types"],
      freebies: ["Threat Manager"],
      take_down_count: 100,
    };
  } else if (takeDown > 100 && takeDown <= 500) {
    return {
      name: "Takedown+",
      title: "Takedown+",
      scope: "1 Domain/Brand",
      user_count: 1,
      annual_price: 75000,
      features: [
        "Fully managed Takedowns across all incident types",
        "Detection across selected incident types",
      ],
      freebies: ["Detection", "Threat Manager", "Tailored Intelligence"],
      take_down_count: 500,
    };
  } else if (takeDown > 500 && takeDown <= 1000) {
    return {
      name: "Takedown++",
      title: "Takedown++",
      scope: "1 Domain/Brand",
      user_count: 1,
      annual_price: 100000,
      features: [
        "Fully managed Takedowns across all incident types",
        "Detection across all incident types",
      ],
      freebies: ["Cyber Blindspot"],
      take_down_count: 1000,
    };
  } else {
    return undefined;
  }
};
