import {
  Alert,
  Box,
  Fab,
  Icon,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { TakedownSubjectModel } from "./takedown_subject.model";
import { IncidentTakedownList } from "../../../services/cbs.service";
import { toast } from "../../../state/snackbar";
import { IESDoc } from "../../../App.interface";
import { RenderUrl } from "../../../util/cell-render/renderers";
import moment from "moment";
import { TakedownProgress } from "./takedown_progress";
import { UUIDGenerator } from "../../../util/uuid";
import { AppCard } from "../../views/app.card";
import includes from "lodash/includes";

export const TakedownRequests: React.FC<{ updateId: string }> = ({ updateId }) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [approvedDocs, setApprovedDocs] = React.useState<IESDoc[]>([]);
  const [updateFlag, setUpdateFlag] = React.useState(UUIDGenerator());
  const fetchTakedownRequests = () => {
    setLoading(true);
    IncidentTakedownList()
      .then((response) => {
        setDocs(response.data);
        setApprovedDocs(
          response.data.filter((f) => !includes(["in_review", "rejected"], f.status))
        );
      })
      .catch(() => {
        toast("Error in loading takedown requests", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onClose = () => {
    setOpen(false);
  };
  const onSuccess = () => {
    onClose();
    setUpdateFlag(UUIDGenerator());
  };
  useEffect(fetchTakedownRequests, [updateId, updateFlag]);
  return (
    <Fragment>
      <AppCard
        name={""}
        title={"Takedown Stats"}
        description={"Potential threat mitigation"}
        actions={
          <Tooltip title={"Request Takedown"}>
            <span>
              <Fab
                color="primary"
                size="small"
                onClick={() => setOpen(true)}
                disabled={loading || approvedDocs.length >= 3}
                className={loading || approvedDocs.length >= 3 ? "" : "request-takedown-button"}
                sx={{
                  width: "30px",
                  height: "30px",
                  minHeight: "auto",
                }}
              >
                <Icon>add</Icon>
              </Fab>
            </span>
          </Tooltip>
        }
        className="takedown-box-highlight"
      >
        <Box position={"relative"} minHeight={"300px"}>
          {loading ? (
            <LinearProgress />
          ) : docs.length ? (
            <>
              <List>
                {docs.map((x) => (
                  <ListItem
                    key={x._id}
                    secondaryAction={
                      <TakedownProgress
                        id={x._id}
                        status={x.status}
                        comment={x.feedback_comment || ""}
                      />
                    }
                  >
                    <ListItemText
                      primary={RenderUrl(
                        x.subject,
                        true,
                        <Typography variant="subtitle1" fontSize={"14px"} fontWeight={600}>
                          {x.subject}
                        </Typography>
                      )}
                      secondary={
                        <Typography
                          variant="subtitle1"
                          fontSize={"10px"}
                          fontWeight={500}
                          color={"textSecondary"}
                        >
                          {`Submitted on ${moment(x["@timestamp"]).format("MMMM Do YYYY, h:mm A")}`}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
              {approvedDocs.length >= 3 ? (
                <Alert severity="warning">
                  <Typography
                    variant="subtitle1"
                    fontSize={"14px"}
                    fontWeight={600}
                    color={"inherit"}
                  >
                    You have reached the maximum limit of takedowns.
                  </Typography>
                </Alert>
              ) : null}
            </>
          ) : (
            <Box>
              <Alert severity="info">
                <Typography
                  variant="subtitle1"
                  fontSize={"14px"}
                  fontWeight={600}
                  color={"inherit"}
                >
                  Request upto 3 incident takedowns for FREE!
                </Typography>
              </Alert>
            </Box>
          )}
        </Box>
      </AppCard>

      {open ? <TakedownSubjectModel onClose={onClose} onSuccess={onSuccess} /> : null}
    </Fragment>
  );
};
