import { Paper, Box, Typography, Divider, Skeleton } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { FetchEnvironmentsOverview } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { AppDonutChart } from "../module.views/app-donut-chart";

export const EnvironmentsPieChart: React.FC<{ updateId: string }> = ({ updateId }) => {
  const [loading, setLoading] = React.useState(true);
  const [chartData, setChartData] = React.useState<{ label: string; value: number }[]>([]);
  const fetchData = () => {
    setLoading(true);
    FetchEnvironmentsOverview()
      .then((response) => {
        setChartData(response);
      })
      .catch(() => {
        toast("Error in loading Environments", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchData, [updateId]);
  return (
    <Fragment>
      <Paper className="dashboard-cards">
        <Box className="header">
          <Typography variant="h6" color="primary" className="main-heading">
            Environments
          </Typography>
          <Typography variant="body2" color="textSecondary" className="sub-heading">
            An overview of your environments
          </Typography>
        </Box>
        <Divider />
        <Box className="contents">
          {loading ? (
            <Skeleton variant="circular" width={250} height={250} style={{ margin: "auto" }} />
          ) : (
            <AppDonutChart
              chartHeight={250}
              data={chartData}
              total={_.sum(chartData.map((x) => x.value))}
            />
          )}
        </Box>
      </Paper>
    </Fragment>
  );
};
