import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    TableBody,
    Skeleton,
  } from "@mui/material";
  import _ from "lodash";
  import React from "react";
  import { Fragment } from "react";
import { IMasterColumns } from "../../../../module.interface";

  
  interface ITableSkeleton {
    colDefs: IMasterColumns[];
    enableSelection: boolean;
  }
  export const SkeletonTable: React.FC<ITableSkeleton> = ({ colDefs, enableSelection }) => {
    return (
      <Fragment>
        <Box className="module-table-container">
          <Box className="master-table-wrapper">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {colDefs.map((col, index) => (
                      <TableCell key={`${col.masterColumnName}_${col.masterColumnTitle}__${index}`}>
                        <Typography
                          color="secondary"
                          variant="subtitle2"
                          className="table-cell-header"
                        >
                          {_.startCase(col.masterColumnTitle)}
                        </Typography>
                      </TableCell>
                    ))}
                    {enableSelection ? <TableCell></TableCell> : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[1, 2, 3].map((item) => (
                    <TableRow key={`loading_skeleton_${item}`}>
                      {colDefs.map((row, pos) => (
                        <TableCell key={`loading_${row.masterColumnName}__${pos}`}>
                          <Skeleton />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Fragment>
    );
  };
  