import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { DmarcDashboard } from "./dashboard/dashboard.index";

export const SummaryController: React.FC = () => {
  return (
    <Fragment>
      <Switch>
        <Route path={"/dmarc/dashboard*"}>
          <Switch>
            <Route path={"/dmarc/dashboard"}>
              <DmarcDashboard />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </Fragment>
  );
};
