import { TabProps, Tab, styled } from "@mui/material";

import React from "react";
import { ColorOpacity } from "../util/color_opacity";
const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: ColorOpacity(theme.palette.primary.main, 0.1),
    color: theme.palette.text.primary,
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  },
}));
export default StyledTab;
