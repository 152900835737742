import {
  LinearProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Checkbox,
  TableSortLabel,
  Typography,
  TableBody,
  Button,
  TablePagination,
  Switch,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Fragment } from "react";
import { IMasterColumns } from "../../../../module.interface";
import { IESDoc } from "../../../../../App.interface";
import { SkeletonTable } from "./skeleton-table";
import CustomizedTooltips from "./customized-tooltip";
import { RenderCell } from "../../../../../util/render.cell";
import { EmptyTableView } from "../../../../views/EmptyTable";
import { ColorOpacity } from "../../../../../util/color_opacity";
import { UpgradeInfoTable } from "../../../../module.views/UpgradeTable";

interface IAppMasterTable {
  loading: boolean;
  dataSource: string;
  name: string;
  title: string;
  colDefs: IMasterColumns[];
  docs: IESDoc[];
  checkedDocs: IESDoc[];
  totalDocs: number;
  pageIndex: number;
  pageSize: number;
  sortField: string;
  sortOrder: "asc" | "desc";
  selectionButtonLabel?: string;
  enablePagination: boolean;
  enableCheckBox: boolean;
  enableSelection: boolean;
  enableBrandColum: boolean;
  enableSwitch?: boolean;
  switchTitle?: string;
  historicalDNS?: boolean;
  disabledSwitch?: boolean;
  showToolTip?: boolean;
  tooltipCellName?: string;
  tooltipChildren?: ReactNode;
  onChangeSortField: (field: string) => void;
  onChangeSortOrder: (order: "asc" | "desc") => void;
  onChangePageIndex: (index: number) => void;
  onChangePageSize: (size: number) => void;
  onChangeCheckedDocs: (docs: IESDoc[]) => void;
  onSelectHistory?: (docs: IESDoc[]) => void;
  onChangeSelectedDoc: (doc: IESDoc | undefined) => void;
  onChangeSwitch?: (
    event: React.ChangeEvent<HTMLInputElement> | undefined,
    doc: IESDoc | undefined
  ) => void;
  selectionNode?: ReactNode;
  isConstantSize?: boolean;
  isCellMaxWidthFlexiable?: boolean;
  showBlur?: boolean;
}
export const OfflineAppMasterTable: React.FC<IAppMasterTable> = ({
  loading,
  dataSource,
  name,
  title,
  colDefs,
  docs,
  checkedDocs,
  totalDocs,
  pageIndex,
  pageSize,
  sortField,
  sortOrder,
  selectionButtonLabel,
  enablePagination,
  enableCheckBox,
  enableSelection,
  enableSwitch,
  switchTitle,
  historicalDNS,
  disabledSwitch,
  showToolTip,
  tooltipCellName,
  tooltipChildren,
  onChangePageIndex,
  onChangeCheckedDocs,
  onChangeSelectedDoc,
  onChangeSwitch,
  onSelectHistory,
  selectionNode,
  isConstantSize,
  isCellMaxWidthFlexiable,
  showBlur,
}) => {
  const [offlineSortField, setOfflineSortField] = React.useState(sortField);
  const [offlineSortOrder, setOfflineSortOrder] = React.useState(sortOrder);
  const [offlinePageIndex, setOfflinePageIndex] = React.useState(pageIndex);
  const [offlinePageSize, setOfflinePageSize] = React.useState(pageSize);
  const masterColumns = colDefs;
  const theme = useTheme();

  React.useEffect(() => setOfflinePageIndex(pageIndex), [pageIndex]);

  const onChangeCheckedDocument = (doc: IESDoc) => {
    const index = _.findIndex(checkedDocs, (d) => {
      return d._id === doc._id;
    });
    if (index === -1) {
      onChangeCheckedDocs([...checkedDocs, doc]);
    } else {
      onChangeCheckedDocs(checkedDocs.filter((d) => d._id !== doc._id));
    }
  };

  type Order = "asc" | "desc";

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (_.get(b, orderBy) < _.get(a, orderBy)) {
      return -1;
    }
    if (_.get(b, orderBy) > _.get(a, orderBy)) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === "desc"
      ? (a, b) => {
          return descendingComparator(a, b, orderBy);
        }
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const getData = () => {
    let res = docs;
    if (offlineSortField) {
      const sortArray = res.sort(getComparator(offlineSortOrder, offlineSortField));
      res = sortArray;
    }

    if (enablePagination) {
      const dataBeforePagination = res;
      res = dataBeforePagination.slice(
        offlinePageIndex * offlinePageSize,
        offlinePageIndex * offlinePageSize + offlinePageSize
      );
    }

    return res;
  };

  if (loading) {
    return <SkeletonTable colDefs={masterColumns} enableSelection={enableSelection} />;
  }

  return (
    <Fragment>
      <Box className="module-table-container" id={dataSource} key={title}>
        {loading ? <LinearProgress /> : null}
        <Box className={"master-table-wrapper"}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {enableCheckBox ? (
                    <TableCell padding="checkbox">
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                      >
                        <Grid item>
                          <Checkbox
                            color="primary"
                            indeterminate={
                              checkedDocs.length > 0 && checkedDocs.length < docs.length
                            }
                            checked={checkedDocs.length > 0 && checkedDocs.length === docs.length}
                            onChange={() =>
                              checkedDocs.length !== docs.length
                                ? onChangeCheckedDocs(docs)
                                : onChangeCheckedDocs([])
                            }
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : null}
                  {enableSwitch ? (
                    <TableCell>
                      <TableSortLabel
                        direction={offlineSortOrder}
                        onClick={() => {
                          setOfflineSortOrder(offlineSortOrder === "asc" ? "desc" : "asc");
                        }}
                      >
                        <Typography
                          color="secondary"
                          variant="subtitle2"
                          className="table-cell-header"
                        >
                          {switchTitle?.length ? switchTitle : "Verified"}
                        </Typography>
                        {showToolTip && tooltipCellName === "Verified" ? (
                          <CustomizedTooltips>{tooltipChildren}</CustomizedTooltips>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ) : null}
                  {masterColumns.map((col) => (
                    <TableCell key={`${col.masterColumnName}_${col.masterColumnTitle}`}>
                      <TableSortLabel
                        active={offlineSortField === col.masterColumnName}
                        direction={offlineSortOrder}
                        onClick={() => {
                          setOfflineSortField(col.masterColumnName);
                          setOfflineSortOrder(offlineSortOrder === "asc" ? "desc" : "asc");
                        }}
                      >
                        <Typography
                          color="secondary"
                          variant="subtitle2"
                          className="table-cell-header"
                        >
                          {_.startCase(col.masterColumnTitle)}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {historicalDNS ? <TableCell></TableCell> : null}
                  {enableSelection ? <TableCell></TableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? (
                  !_.isEmpty(docs) ? (
                    <>
                      {getData().map((row: any, pos) => (
                        <TableRow
                          hover
                          key={row._id}
                          sx={{ filter: `blur(${row.dummy_data ? "2.5px" : "0px"})` }}
                        >
                          {enableCheckBox ? (
                            <TableCell padding="checkbox">
                              <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                alignContent="center"
                                wrap="nowrap"
                              >
                                <Grid item>
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      _.findIndex(checkedDocs, (d) => d._id === row._id) !== -1
                                    }
                                    onChange={() => onChangeCheckedDocument(row)}
                                    inputProps={{
                                      "aria-label": `primary checkbox ${row._id}`,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>
                          ) : null}
                          {enableSwitch ? (
                            <TableCell padding="checkbox">
                              <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                alignContent="center"
                                wrap="nowrap"
                              >
                                <Grid item>
                                  <Switch
                                    disabled={disabledSwitch || row.dummy_data}
                                    checked={
                                      row.verify === "true" ||
                                      row.is_active ||
                                      row.verify ||
                                      row.active ||
                                      row.verify === "Verified"
                                    }
                                    onChange={(event) => {
                                      if (onChangeSwitch) {
                                        onChangeSwitch(event, row);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>
                          ) : null}
                          {masterColumns.map((col) => (
                            <TableCell
                              key={`${col.masterColumnName}_${col.masterColumnTitle}`}
                              className={`cell-${col.renderType}`}
                              style={{
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                overflowWrap: isCellMaxWidthFlexiable ? "anywhere" : undefined,
                              }}
                              sx={{
                                maxWidth: isCellMaxWidthFlexiable ? "1000px !important" : undefined,
                              }}
                            >
                              {col.masterColumnTitle === "brand"
                                ? RenderCell(_.get(row, col.masterColumnName), "world_country")
                                : null}
                              <span style={{ fontSize: 14 }} className="data-cell">
                                {_.has(row, col.masterColumnName) &&
                                !_.isNull(_.get(row, col.masterColumnName)) &&
                                !_.isEmpty(_.get(row, col.masterColumnName)?.toString()) &&
                                !_.isUndefined(_.get(row, col.masterColumnName)) &&
                                !_.isNaN(_.get(row, col.masterColumnName))
                                  ? col.cellRender
                                    ? col.cellRender(row)
                                    : RenderCell(
                                        _.get(row, col.masterColumnName),
                                        col.renderType,
                                        theme
                                      )
                                  : col.cellRender
                                  ? col.cellRender(row)
                                  : "-"}
                              </span>
                            </TableCell>
                          ))}
                          {historicalDNS ? (
                            <TableCell id={`${name}_details_button_${pos}`} align="right">
                              {selectionNode ? (
                                selectionNode
                              ) : (
                                <Button
                                  disabled={
                                    row.dummy_data
                                      ? true
                                      : row.title === "domain_info_details" && row.is_subdomain
                                      ? !row.active
                                      : false
                                  }
                                  className="view-more-btn-cell"
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    if (onSelectHistory) {
                                      onSelectHistory(row);
                                    }
                                  }}
                                >
                                  Historical DNS
                                </Button>
                              )}
                            </TableCell>
                          ) : null}
                          {enableSelection ? (
                            <TableCell id={`${name}_details_button_${pos}`} align="right">
                              {selectionNode ? (
                                selectionNode
                              ) : (
                                <Button
                                  disabled={
                                    row.dummy_data
                                      ? true
                                      : row.title === "domain_info_details" && row.is_subdomain
                                      ? !row.active
                                      : false
                                  }
                                  className="view-more-btn-cell"
                                  color="primary"
                                  size="small"
                                  onClick={() => onChangeSelectedDoc(row)}
                                >
                                  {selectionButtonLabel || "Details"}
                                </Button>
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      ))}
                      {showBlur ? (
                        <TableRow>
                          <TableCell
                            colSpan={
                              masterColumns.length +
                              (enableCheckBox ? 1 : 0) +
                              (enableSelection ? 1 : 0)
                            }
                            padding="none"
                          >
                            <Box position={"relative"} height={"150px"} margin={"0 -16px"}>
                              <Box
                                position={"absolute"}
                                bottom={0}
                                width={"100%"}
                                height={"150px"}
                                style={{
                                  backdropFilter: "blur(2.5px)",
                                  background: `linear-gradient(to top,${ColorOpacity(
                                    theme.palette.primary.main,
                                    0.3
                                  )}, transparent)`,
                                }}
                              >
                                <UpgradeInfoTable />
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={
                          masterColumns.length +
                          (enableCheckBox ? 1 : 0) +
                          (enableSelection ? 1 : 0)
                        }
                      >
                        <EmptyTableView />
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
              </TableBody>
            </Table>
            {enablePagination ? (
              <TablePagination
                component="div"
                count={loading ? 0 : totalDocs}
                page={loading ? 0 : offlinePageIndex}
                rowsPerPage={offlinePageSize}
                rowsPerPageOptions={isConstantSize ? [offlinePageSize] : [5, 10, 25, 100]}
                onPageChange={(event, page) => {
                  onChangePageIndex(page);
                  setOfflinePageIndex(page);
                }}
                onRowsPerPageChange={(event) => {
                  setOfflinePageIndex(0);
                  setOfflinePageSize(parseInt(event.target.value));
                }}
              />
            ) : null}
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  );
};
