import _get from "lodash/get";
import _has from "lodash/has";
import _isArray from "lodash/isArray";

import React, { Fragment, useEffect } from "react";
import { AppAssetsSectionBox } from "../../views/app.section.box";
import { AppDocView } from "../../views/app.doc.view";
import { IESDoc } from "../../../App.interface";
import _ from "lodash";

export const HostDetailsView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  const [parsedDoc, setParsedDoc] = React.useState(doc);
  useEffect(() => {
    setParsedDoc({
      ...doc,
      parsed_a_record:
        _has(doc, "dns.a_record") && _isArray(_get(doc, "dns.a_record"))
          ? _get(doc, "dns.a_record").map((x: any) => x.a_record)
          : [],
      parsed_cname_record:
        _has(doc, "dns.cname_record") && _isArray(_get(doc, "dns.cname_record"))
          ? _get(doc, "dns.cname_record").map(
              (x: any) => `alias_from ${x.cname_alias_from} - alias_to ${x.cname_alias_to}`
            )
          : [],
      parsed_ns_record:
        _has(doc, "dns.ns_record") && _isArray(_get(doc, "dns.ns_record"))
          ? _get(doc, "dns.ns_record").map((x: any) => x.nameserver)
          : [],
      parsed_mx_record:
        _has(doc, "dns.mx_record") && _isArray(_get(doc, "dns.mx_record"))
          ? _get(doc, "dns.mx_record").map((x: any) => x.mx)
          : [],
      parsed_spf_record:
        _has(doc, "dns.spf_record") && _isArray(_get(doc, "dns.spf_record"))
          ? _get(doc, "dns.spf_record").map((x: any) => x.spf_record)
          : [],
      parsed_dkim_record:
        _has(doc, "dns.dkim_record") && _isArray(_get(doc, "dns.dkim_record"))
          ? _get(doc, "dns.dkim_record").map((x: any) => x.dkim_raw)
          : [],
      parsed_dmarc_record:
        _has(doc, "dns.dmarc_record") && _isArray(_get(doc, "dns.dmarc_record"))
          ? _get(doc, "dns.dmarc_record").map((x: any) => x.dmarc_raw)
          : [],
      parsed_txt_record:
        _has(doc, "dns.other_txt_record") && _isArray(_get(doc, "dns.other_txt_record"))
          ? _get(doc, "dns.other_txt_record").map((x: any) => x.txt_record)
          : [],
      parsed_soa_record:
        _has(doc, "dns.soa_record") && _isArray(_get(doc, "dns.soa_record"))
          ? _get(doc, "dns.soa_record").map((x: any) => x.mname)
          : [],
    });
  }, [doc]);
  return (
    <Fragment>
      {_has(doc, "dns") ? (
        <AppAssetsSectionBox title="DNS Information" type="domain">
          <AppDocView
            colDefs={[
              {
                masterColumnName: "parsed_a_record",
                masterColumnTitle: "A Record",
                renderType: "url_array",
              },
              {
                masterColumnName: "parsed_cname_record",
                masterColumnTitle: "CNAME Record",
                renderType: "array",
              },
              {
                masterColumnName: "parsed_ns_record",
                masterColumnTitle: "NS Record",
                renderType: "array",
              },
              {
                masterColumnName: "parsed_mx_record",
                masterColumnTitle: "MX Record",
                renderType: "array",
              },
              {
                masterColumnName: "parsed_spf_record",
                masterColumnTitle: "SPF Record",
                renderType: "array",
              },
              {
                masterColumnName: "parsed_dkim_record",
                masterColumnTitle: "DKIM Record",
                renderType: "array",
              },
              {
                masterColumnName: "parsed_dmarc_record",
                masterColumnTitle: "DMARC Record",
                renderType: "array",
              },
              {
                masterColumnName: "parsed_txt_record",
                masterColumnTitle: "TXT Record",
                renderType: "array",
              },
              {
                masterColumnName: "parsed_soa_record",
                masterColumnTitle: "SOA Record",
                renderType: "array",
              },
            ]}
            doc={parsedDoc}
          />
        </AppAssetsSectionBox>
      ) : null}
      {_has(doc, "ip") ? (
        <AppAssetsSectionBox title="IP Information" type="domain">
          <AppDocView
            colDefs={[
              { masterColumnName: "value", masterColumnTitle: "IP", renderType: "url" },
              {
                masterColumnName: "tags.ip",
                masterColumnTitle: "IP Type",
                renderType: "tags",
              },
              {
                masterColumnName: "whois.netname",
                masterColumnTitle: "NET Name",
                renderType: "array",
              },
              {
                masterColumnName: "location.country_name",
                masterColumnTitle: "Country",
                renderType: "title",
              },

              {
                masterColumnName: "shodan.isp",
                masterColumnTitle: "ISP",
                renderType: "text",
              },
            ]}
            doc={{ ...doc.ip, tags: _has(doc, "tags") ? doc.tags : [] }}
          />
        </AppAssetsSectionBox>
      ) : null}
      {_has(doc, "ssl") ? (
        <AppAssetsSectionBox title="SSL Information" type="domain">
          <AppDocView
            colDefs={[
              {
                masterColumnName: "ssl.issued_date",
                masterColumnTitle: "Issued Date",
                renderType: "date",
              },
              {
                masterColumnName: "ssl.expiry_date",
                masterColumnTitle: "Expiry Date",
                renderType: "date",
              },
              {
                masterColumnName: "ssl.issued_to",
                masterColumnTitle: "Issued To",
                renderType: "text",
              },
              {
                masterColumnName: "ssl.issued_by",
                masterColumnTitle: "Issued By",
                renderType: "text",
              },

              {
                masterColumnName: "ssl.public_key",
                masterColumnTitle: "Public Key",
                renderType: "text",
              },
              {
                masterColumnName: "ssl.supported_protocol_versions",
                masterColumnTitle: "Protocol Versions",
                renderType: "tags",
              },
              {
                masterColumnName: "ssl.status",
                masterColumnTitle: "SSL Certificate Status",
                renderType: "tags",
              },
              {
                masterColumnName: "ssl.fingerprint_md5",
                masterColumnTitle: "Fingerprint MD5",
                renderType: "text",
              },
              {
                masterColumnName: "ssl.fingerprint_sha1",
                masterColumnTitle: "Fingerprint SHA1",
                renderType: "text",
              },
              {
                masterColumnName: "ssl.fingerprint_sha256",
                masterColumnTitle: "Fingerprint SHA256",
                renderType: "text",
              },
            ]}
            doc={parsedDoc}
          />
        </AppAssetsSectionBox>
      ) : null}
      {_has(doc, "web.header_response") ? (
        <AppAssetsSectionBox title="Website Headers" type="domain">
          <AppDocView
            colDefs={_.map(doc.web.header_response, (v, k) => ({
              masterColumnName: k,
              masterColumnTitle: k,
              renderType: "text",
            }))}
            doc={doc.web.header_response}
          />
        </AppAssetsSectionBox>
      ) : null}
    </Fragment>
  );
};
