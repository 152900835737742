import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Popover,
  Tabs,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../App.interface";
import { UUIDGenerator } from "../../../util/uuid";
import { get, has, startCase } from "lodash";
import StyledTab from "../../../components/styled.tab";
import { CredentialLeaksStats } from "./credential_leaks.stats";
import {
  FetchCredentialLeakList,
  FetchThreatManagerFilterOptions,
} from "../../../services/cbs.service";
import { toast } from "../../../state/snackbar";
import { RenderDate } from "../../../util/cell-render/renderers";
import { AppMasterTable } from "../../views/app.table";
import { BreachSourceView } from "./breach_source";
import { LeakTypeView } from "./leak_type";
import { LeakPasswordMasker } from "./password_masker";
import { StealerFamilyView } from "./stealer_family";
import { AppDrawer } from "../../views/Drawer";
import _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";
import { BlurInfo } from "../../module.views/BlurInfo";
import moment from "moment";
import { setInitialDateRanges } from "../../../util/date-range";
import { IDateFilterObj, ITableFilters } from "../../module.interface";
import { DateRangeFilter } from "../../../components/date.filter";
import { AppButtonFilter } from "../../../components/button-filter/button.filter.index";
import { AppMD5Hash } from "../../../util/md5.hash";
import { useObservable } from "../../../hooks/use-observable";
import { BrandService } from "../../../services/brand.service";

export const CredentialLeaksModule: React.FC = () => {
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const theme = useTheme<Theme>();
  const [tabs] = React.useState(["malware_logs", "breached_credentials"]);
  const [selectedTab, setSelectedTab] = React.useState(tabs[0]);
  const [requestCount, setRequestCount] = React.useState(0);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchVal, setSearchVal] = React.useState<string>("");
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("desc");
  const [sortField, setSortField] = React.useState<string>("last_seen");
  const [totalDocs, setTotalDocs] = React.useState<number>(0);
  const [updateID, setUpdateID] = React.useState(UUIDGenerator());
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc>();
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [selectedDateRange, setSelectedDateRange] = React.useState<IDateFilterObj>(
    setInitialDateRanges()
  );
  const [dateRange, setDateRange] = React.useState<HTMLButtonElement | null>(null);
  const [filterList, setFilterList] = React.useState<ITableFilters[]>([]);
  const [loadingFilters, setLoadingFilters] = React.useState(true);
  const [filterOptions, setFilterOptions] = React.useState<{ [key: string]: string[] }>({});
  const onChangeSearchQuery = (val: string) => setSearchVal(val);
  const onSetSearchQuery = () => {
    setSearchQuery(searchVal.trim().replace(/\/(?=[^/]*$)/, "/"));
    setDocs([]);
  };
  useEffect(() => {
    setFilterList([]);
  }, [selectedTab]);
  const fetchList = () => {
    setRequestCount((c) => c + 1);
    FetchCredentialLeakList(
      selectedTab,
      pageIndex,
      pageSize,
      searchQuery.replace(/[^a-zA-Z ]/g, ""),
      sortField,
      sortOrder,
      filterList,
      selectedDateRange.status ? selectedDateRange.startDate : undefined,
      selectedDateRange.status ? selectedDateRange.endDate : undefined
    )
      .then((response) => {
        setDocs(response.data);
        setTotalDocs(get(response, "metadata.total.value") as unknown as number);
      })
      .catch(() => {
        toast(`Error in loading ${_.startCase(selectedTab)}`, "error");
      })
      .finally(() => {
        setRequestCount((x) => x - 1);
      });
  };
  const FetchFilterOptions = () => {
    setLoadingFilters(true);

    FetchThreatManagerFilterOptions(selectedTab)
      .then((response) => {
        const options: { [key: string]: string[] } = {};
        _.forEach(response, (v, k) => {
          options[k] = v.buckets.filter((f: any) => f.key).map((i: any) => i.key);
        });
        setFilterOptions(options);
      })
      .catch(() => {
        toast("Error in loading filters", "error");
      })
      .finally(() => {
        setLoadingFilters(false);
      });
  };
  useEffect(FetchFilterOptions, [selectedTab]);
  useDeepCompareEffect(fetchList, [
    pageIndex,
    pageSize,
    searchQuery,
    selectedDateRange,
    selectedTab,
    sortField,
    sortOrder,
    updateID,
    filterList,
  ]);
  useDeepCompareEffect(() => {
    setUpdateID(UUIDGenerator());
  }, [selectedMemberIds]);
  return (
    <Fragment>
      <Box className="app-module-container">
        <Box className="app-module-header">
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid item xs={8}>
              <Typography variant="h4" className="module-title" color="primary">
                Credential Leaks
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textPrimary"
                className="module-description"
              >
                Summary of your exposed user credentials
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box marginBottom={2} paddingLeft={2} maxWidth={720}>
          <Alert severity="warning" variant="outlined">
            <AlertTitle>
              <Typography variant="h6" color={"inherit"} fontSize={"16px"} fontWeight={600}>
                Restricted View
              </Typography>
            </AlertTitle>
            <Typography
              variant="subtitle1"
              color={"inherit"}
              fontSize={"12px"}
              fontWeight={500}
              marginBottom={1.5}
            >
              This is not a comprehensive list of {startCase(selectedTab)} and is only limited to
              100 daily results. For a comprehensive list, please upgrade your plan
            </Typography>
            <Button
              variant="contained"
              component={Link}
              href={`https://${
                process.env.NODE_ENV === "development" ? "cyno.edxdev.link" : "start.ctm360.com"
              }/pricing`}
              target="_blank"
              size="small"
            >
              <Typography
                variant="h6"
                textTransform={"capitalize"}
                fontWeight={600}
                fontSize={"12px"}
              >
                Upgrade Now
              </Typography>
            </Button>
          </Alert>
        </Box>
        <Box>
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_e, v) => setSelectedTab(v)}
            aria-label="disabled tabs example"
          >
            {tabs.map((x) => (
              <StyledTab
                label={
                  <Typography
                    variant="h6"
                    fontSize={"13px"}
                    fontWeight={600}
                    textTransform={"capitalize"}
                  >
                    {startCase(x)}
                  </Typography>
                }
                key={x}
                value={x}
              />
            ))}
          </Tabs>
          <Divider />
        </Box>
        <CredentialLeaksStats type={selectedTab} />
        <Box className="cbs-module-actions">
          <Grid
            spacing={2}
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
              <Grid
                spacing={1}
                container
                alignItems="center"
                justifyContent="flex-start"
                wrap="nowrap"
              >
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Typography
                    variant="subtitle1"
                    className="button-filter-label"
                    color="textPrimary"
                    height={"30px"}
                  />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      id="outlined-basic"
                      label={"Search..."}
                      value={searchVal}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Icon color="action">search</Icon>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      onKeyDown={(ev) => (ev.key === "Enter" ? onSetSearchQuery() : null)}
                      onChange={(e) => onChangeSearchQuery(e.target.value)}
                    />
                  </form>
                </Grid>
                {selectedTab === "malware_logs" ? (
                  <Fragment>
                    <Grid item>
                      <AppButtonFilter
                        loading={loadingFilters}
                        name={"leak_type"}
                        title={"Leak Type"}
                        options={filterOptions.leak_type || []}
                        selection={filterList
                          .filter((f) => f.field === "leak_type")
                          .map((x) => x.value as string)}
                        onChangeOption={(params) => {
                          setFilterList((arr) => [
                            ...arr.filter((f) => f.field !== "leak_type"),
                            ...params.map((x) => ({
                              id: AppMD5Hash(`leak_type${x}`),
                              field: "leak_type",
                              title: "Leak Type",
                              operator: "is one of",
                              value: x,
                            })),
                          ]);
                        }}
                      />
                    </Grid>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Grid item>
                      <AppButtonFilter
                        loading={loadingFilters}
                        name={"sources.entity_name"}
                        title={"Source"}
                        options={filterOptions["sources.entity_name"] || []}
                        selection={filterList
                          .filter((f) => f.field === "sources.entity_name")
                          .map((x) => x.value as string)}
                        onChangeOption={(params) => {
                          setFilterList((arr) => [
                            ...arr.filter((f) => f.field !== "sources.entity_name"),
                            ...params.map((x) => ({
                              id: AppMD5Hash(`sources.entity_name${x}`),
                              field: "sources.entity_name",
                              title: "Source",
                              operator: "is one of",
                              value: x,
                            })),
                          ]);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <AppButtonFilter
                        loading={loadingFilters}
                        name={"sources.category"}
                        title={"Category"}
                        options={filterOptions["sources.category"] || []}
                        selection={filterList
                          .filter((f) => f.field === "sources.category")
                          .map((x) => x.value as string)}
                        onChangeOption={(params) => {
                          setFilterList((arr) => [
                            ...arr.filter((f) => f.field !== "sources.category"),
                            ...params.map((x) => ({
                              id: AppMD5Hash(`sources.category${x}`),
                              field: "sources.category",
                              title: "Category",
                              operator: "is one of",
                              value: x,
                            })),
                          ]);
                        }}
                      />
                    </Grid>
                  </Fragment>
                )}

                <Grid item>
                  <Typography
                    variant="subtitle1"
                    className="button-filter-label"
                    color="textPrimary"
                    height={"30px"}
                  />
                  <Button
                    variant="outlined"
                    size="small"
                    style={{
                      color: theme.palette.text.primary,
                      borderColor: theme.palette.divider,
                      fontSize: "14px",
                      height: "40px",
                      textTransform: "capitalize",
                    }}
                    className="ticket-filter-button-customized"
                    onClick={(event) => setDateRange(event.currentTarget)}
                  >
                    {selectedDateRange.mode === "quick"
                      ? selectedDateRange.value
                      : moment(selectedDateRange.startDate).format("MMMM Do YYYY") +
                        " - " +
                        moment(selectedDateRange.endDate).format("MMMM Do YYYY")}
                  </Button>
                  <Popover
                    id="date-range-popover"
                    open={Boolean(dateRange)}
                    anchorEl={dateRange}
                    onClose={() => setDateRange(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box>
                      <DateRangeFilter
                        currentDateObj={selectedDateRange}
                        publishDateObj={(params) => {
                          setSelectedDateRange(params);
                          setDateRange(null);
                        }}
                        onClose={() => setDateRange(null)}
                      />
                    </Box>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                className="button-filter-label"
                color="textPrimary"
                height={"30px"}
              />
              <Grid
                spacing={2}
                container
                alignItems="center"
                justifyContent="flex-start"
                wrap="nowrap"
              >
                <Grid item>
                  <Tooltip title="Refresh Data">
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={() => setUpdateID(UUIDGenerator())}
                    >
                      <Icon>refresh</Icon>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <AppMasterTable
            loading={Boolean(requestCount > 0)}
            dataSource={""}
            name={""}
            title={""}
            colDefs={
              selectedTab === "breached_credentials"
                ? [
                    {
                      masterColumnName: "email",
                      masterColumnTitle: "Email Address",
                      renderType: "text",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      masterColumnName: "domain",
                      masterColumnTitle: "Domain",
                      renderType: "url",
                      sortable: false,
                      filterable: false,
                    },
                    {
                      masterColumnName: "source_count",
                      masterColumnTitle: "Breach Source",
                      renderType: "text",
                      sortable: false,
                      filterable: false,

                      cellRender: (params) => <BreachSourceView doc={params} />,
                    },
                    // {
                    //   masterColumnName: "breach_date",
                    //   masterColumnTitle: "Breach Date",
                    //   renderType: "date",
                    // },
                    {
                      masterColumnName: "last_seen",
                      masterColumnTitle: "Discovery Date",
                      renderType: "date",
                    },
                  ]
                : [
                    {
                      masterColumnName: "user",
                      masterColumnTitle: "User Name",
                      renderType: "text",
                      cellRender: (params) => (
                        <Typography
                          display={"block"}
                          maxWidth={"200px"}
                          overflow={"hidden"}
                          fontSize={"inherit"}
                          fontWeight={"inherit"}
                          component="span"
                          textOverflow={"ellipsis"}
                        >
                          {params.user || "-"}
                        </Typography>
                      ),
                    },
                    {
                      masterColumnName: "password",
                      masterColumnTitle: "Password",
                      renderType: "text",
                      cellRender: (params) => <LeakPasswordMasker doc={params} />,
                    },
                    {
                      masterColumnName: "website",
                      masterColumnTitle: "Website",
                      renderType: "multiline_url",
                    },
                    {
                      masterColumnName: "leak_type",
                      masterColumnTitle: "Leak Type",
                      renderType: "title",
                      cellRender: (params) => <LeakTypeView doc={params} />,
                    },
                    {
                      masterColumnName: "chr",
                      masterColumnTitle: "Stealer Family",
                      renderType: "title",
                      cellRender: (params) => <StealerFamilyView doc={params} />,
                    },
                    {
                      masterColumnName: "last_seen",
                      masterColumnTitle: "Last Seen",
                      renderType: "date",
                    },
                    {
                      masterColumnName: "chr.date_compromised",
                      masterColumnTitle: "Compromised Date",
                      renderType: "date",
                      cellRender: (params) => {
                        const parsedSource: { [key: string]: any } = has(params, "chr[0]")
                          ? get(params, "chr[0]")
                          : undefined;
                        return (
                          <span>
                            {parsedSource && parsedSource.date_compromised
                              ? RenderDate(parsedSource.date_compromised)
                              : "-"}
                          </span>
                        );
                      },
                    },
                  ]
            }
            docs={docs}
            checkedDocs={[]}
            totalDocs={_.min([totalDocs, 100]) || 100}
            pageIndex={pageIndex}
            pageSize={pageSize}
            sortField={sortField}
            sortOrder={sortOrder}
            enablePagination={true}
            enableCheckBox={false}
            enableSelection={true}
            enableBrandColum={false}
            onChangeSortField={setSortField}
            onChangeSortOrder={setSortOrder}
            onChangePageIndex={setPageIndex}
            onChangePageSize={setPageSize}
            onChangeCheckedDocs={() => null}
            onChangeSelectedDoc={setSelectedDoc}
          />
        </Paper>
      </Box>
      {selectedDoc ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={_.startCase(selectedTab)}
          onClose={() => {
            setSelectedDoc(undefined);
          }}
        >
          <BlurInfo />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
