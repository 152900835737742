import { Box, Button, ButtonGroup, Icon, LinearProgress } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { INetworkGraphSlug } from "../module.interface";
import { IESDoc } from "../../App.interface";
import { DomainOverView } from "../cyber_assets/views/overview.domain";
import { EmptyChart } from "../views/chart.empty";
import { FetchOrganizationNetworkGraphDetails } from "../../services/dashboard.service";
import toLower from "lodash/toLower";
import { toast } from "../../state/snackbar";
import { HostOverView } from "../cyber_assets/views/overview.hosts";
import { IPAddressOverView } from "../cyber_assets/views/overview.ip_address";
import { DomainDetailsView } from "../cyber_assets/views/details.domain";
import { HostDetailsView } from "../cyber_assets/views/details.host";
import { IpAddressDetailsView } from "../cyber_assets/views/details.ip_address";

export const NetworkDetails: React.FC<{ node: INetworkGraphSlug; value: string }> = ({
  node,
  value,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [views] = React.useState<string[]>(["overview", "details", "issues"]);
  const [selectedView, setSelectedView] = React.useState(views[0]);
  const [doc, setDoc] = React.useState<IESDoc>();
  const fetchDetails = () => {
    if (!value) {
      return setLoading(false);
    }
    setLoading(true);
    FetchOrganizationNetworkGraphDetails(toLower(node.title), value)
      .then((response) => {
        setDoc(response.data[0]);
      })
      .catch(() => {
        toast(`Error in loading details for ${node.title} ${value}`, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchDetails, [node.title, value]);

  return (
    <Fragment>
      <Box marginTop={3} position={"relative"}>
        <Box textAlign={"center"} marginBottom={2}>
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            {views.map((x) => (
              <Button
                key={x}
                variant={selectedView === x ? "contained" : "outlined"}
                onClick={() => setSelectedView(x)}
                disabled={x === "issues"}
                endIcon={x === "issues" ? <Icon>lock</Icon> : null}
              >
                {x}
              </Button>
            ))}
          </ButtonGroup>
        </Box>
        <Box maxWidth={"600px"} margin={"auto"}>
          {loading ? (
            <LinearProgress />
          ) : doc ? (
            <>
              {selectedView === "overview" ? (
                <Box position={"relative"}>
                  {node.index.indexName === "ds_domain" ? <DomainOverView doc={doc} /> : null}
                  {node.index.indexName === "ds_host" ? <HostOverView doc={doc} /> : null}
                  {node.index.indexName === "ds_ip" ? <IPAddressOverView doc={doc} /> : null}
                </Box>
              ) : (
                <Box position={"relative"}>
                  {node.index.indexName === "ds_domain" ? <DomainDetailsView doc={doc} /> : null}
                  {node.index.indexName === "ds_host" ? <HostDetailsView doc={doc} /> : null}
                  {node.index.indexName === "ds_ip" ? <IpAddressDetailsView doc={doc} /> : null}
                </Box>
              )}
            </>
          ) : (
            <EmptyChart title={""} />
          )}
        </Box>
      </Box>
    </Fragment>
  );
};
