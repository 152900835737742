import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment, ReactNode } from "react";
import { INetworkGraphSlug } from "../module.interface";

interface INetworkGraphLegends {
  graphSlugs: INetworkGraphSlug[];
}
export const NetworkGraphLegends: React.FC<INetworkGraphLegends> = ({ graphSlugs }) => {
  const theme = useTheme();
  const [grades] = React.useState<string[]>(["A+", "A", "B", "C", "D", "F", "N/A"]);
  const findIcon = (title: string): ReactNode => {
    switch (title) {
      case "Brand":
        return (
          <g>
            <g>
              <path d="m501.333 96h-490.666c-5.888 0-10.667 4.779-10.667 10.667v298.667c0 5.887 4.779 10.666 10.667 10.666h490.667c5.888 0 10.667-4.779 10.667-10.667v-298.666c-.001-5.888-4.78-10.667-10.668-10.667z" />
            </g>
          </g>
        );
      case "Domain":
        return (
          <g>
            <g>
              <path d="m485.291 129.408-224-128c-3.285-1.877-7.296-1.877-10.581 0l-224 128c-3.328 1.899-5.376 5.44-5.376 9.259v234.667c0 3.819 2.048 7.36 5.376 9.259l224 128c1.643.939 3.456 1.408 5.291 1.408s3.648-.469 5.291-1.408l224-128c3.328-1.899 5.376-5.44 5.376-9.259v-234.667c-.001-3.819-2.049-7.36-5.377-9.259z" />
            </g>
          </g>
        );
      case "Host":
        return <path d="M490,474.459H0L245.009,15.541L490,474.459z" />;
      case "IP":
        return (
          <path d="M256,0C115.39,0,0,115.39,0,256s115.39,256,256,256s256-115.39,256-256S396.61,0,256,0z" />
        );
      default:
        return (
          <path d="M256,0C115.39,0,0,115.39,0,256s115.39,256,256,256s256-115.39,256-256S396.61,0,256,0z" />
        );
    }
  };
  return (
    <Fragment>
      <Paper
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          margin: "16px",
          padding: "16px",
          zIndex: 3,
        }}
        variant="outlined"
      >
        <Box className="legend-box">
          <Typography variant="subtitle2" color="primary" component="p">
            Legend
          </Typography>
          <List dense>
            {graphSlugs.map((x) => (
              <ListItem key={x.title} disableGutters>
                <ListItemIcon style={{ color: "#2196f3" }}>
                  <SvgIcon color="inherit" viewBox="0 0 600 600">
                    {findIcon(x.title)}
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText primary={x.title} />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box className="legend-box">
          <Typography variant="subtitle2" color="primary" component="p">
            Grade
          </Typography>
          <List dense>
            {grades.map((x) => (
              <ListItem key={x} disableGutters>
                <ListItemIcon>
                  <Box
                    className="color-badge"
                    style={{
                      width: "25px",
                      height: "25px",
                      backgroundColor: theme.score[x],
                      borderRadius: "50%",
                    }}
                  ></Box>
                </ListItemIcon>
                <ListItemText primary={x} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Paper>
    </Fragment>
  );
};
