export const SelectedMemberId = (): number[] => {
  let selectedBrands: number[] = [];
  try {
    if (sessionStorage.getItem("brand_selection")) {
      selectedBrands = JSON.parse(sessionStorage.getItem("brand_selection") || "[]");
    } else if (localStorage.getItem("brand_selection")) {
      selectedBrands = JSON.parse(localStorage.getItem("brand_selection") || "[]");
    } else {
      selectedBrands = [];
    }
  } catch (error) {
    selectedBrands = [];
  }
  return selectedBrands;
};
