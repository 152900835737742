import { Theme, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import Chart from "react-apexcharts";
import { ResponsiveContainer } from "recharts";
import { calculateGrade } from "../../util/score.grade.utils";

export const GradeGauge: React.FC<{
  score: number;
  height: number;
  width: number;
}> = ({ score, height, width }) => {
  const theme = useTheme<Theme>();

  return (
    <Fragment>
      <ResponsiveContainer height={height} width={width}>
        <Chart
          options={{
            chart: {
              height: height,
              type: "radialBar",
            },
            dataLabels: {
              enabled: false,
            },
            colors: [theme.score[calculateGrade(score)]],
            plotOptions: {
              radialBar: {
                inverseOrder: false,
                startAngle: 0,
                endAngle: 360,
                offsetX: 0,
                offsetY: 0,

                track: {
                  show: true,
                  startAngle: undefined,
                  endAngle: undefined,
                  background: theme.palette.background.default,
                  strokeWidth: "97%",
                  opacity: 1,
                  margin: 5,
                  dropShadow: {
                    enabled: false,
                    top: 0,
                    left: 0,
                    blur: 3,
                    opacity: 0.5,
                  },
                },
                dataLabels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: undefined,
                    fontWeight: 600,
                    color: undefined,
                    offsetY: -10,
                  },
                  value: {
                    show: true,
                    fontSize: "18px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    color: theme.score[calculateGrade(score)],
                    offsetY: 6,
                    formatter: () => calculateGrade(score),
                  },
                  total: {
                    show: false,
                  },
                },
              },
            },
            labels: [calculateGrade(score)],
          }}
          series={[score]}
          type="radialBar"
        />
      </ResponsiveContainer>
    </Fragment>
  );
};
