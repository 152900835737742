import { Fragment } from "react";
import React from "react";
import { Box, Typography, Button, Grid, Link } from "@mui/material";
import { IUser } from "../../state/user";
import animationData from "../../assets/lottie/code-debugging.lottie.json";

import Lottie from "react-lottie";
import { LogRunTimeErrors } from "../../services/query.service";
import { AppMD5Hash } from "../../util/md5.hash";
import { captureException } from "@sentry/react";

export class ErrorBoundary extends React.Component<any> {
  public state = {
    error: false,
    errorMessage: "",
    componentStack: [],
  };
  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const user: IUser = JSON.parse(
      localStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.sso.user`) || "{}"
    );
    const errorLog = `${error.name} ${error.message} ${errorInfo?.componentStack?.toString()}`;

    LogRunTimeErrors([
      {
        doc_id: AppMD5Hash(`${error.name}_${error.message}_${new Date()}`),
        doc_index: "ERROR_LOG",
        doc_subject: "RUN_TIME_ERROR",
        user_email: user.email,
        user_name: user.name,
        user_organization: user.organization,
        remarks: `Encountered an error  ${errorLog} in ${window.location.pathname}`,
      },
    ]);
    captureException(error, {
      tags: {
        handler: "ErrorBoundary",
      },
    });
    this.setState({
      error: true,
      errorMessage: error.toString(),
      componentStack: errorInfo?.componentStack?.split("\n").filter((f) => f),
    });
  }
  public render() {
    return (
      <Fragment>
        {this.state.error ? (
          <Box className="error-boundary-container">
            <Box className="error-boundary-wrapper">
              <Box className="error-image">
                <Lottie
                  options={{
                    animationData: animationData,
                    loop: true,
                    autoplay: true,
                    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
                  }}
                  width={350}
                  height={350}
                />
              </Box>
              <Box className="error-text">
                <Typography variant="h2" gutterBottom color="primary">
                  <strong>{"Error :("}</strong>
                </Typography>
                <Typography variant="h6" gutterBottom color="secondary">
                  <strong>Look like we encountered an error</strong>
                </Typography>
                <Box className="error-component-stack">
                  <pre>
                    {this.state.componentStack.map((x) => (
                      <span key={Math.random().toString()} style={{ display: "block" }}>
                        {x}
                      </span>
                    ))}
                  </pre>
                </Box>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Link
                      href="/"
                      onClick={(e: React.SyntheticEvent) => e.preventDefault()}
                      variant="body2"
                      color="secondary"
                    >
                      <strong>Back to Home page</strong>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => window.location.reload()}
                    >
                      Reload
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        ) : (
          this.props.children
        )}
      </Fragment>
    );
  }
}
