import { Box, Theme, useTheme } from "@mui/material";
import React, { Fragment } from "react";
interface IHackerViewShield {
  loading: boolean;
  grade: string;
  shieldSize?: number;
}
export const HackerViewShield: React.FC<IHackerViewShield> = ({ grade, loading, shieldSize }) => {
  const theme = useTheme<Theme>();

  return (
    <Fragment>
      <Box
        style={{
          fill: loading ? theme.palette.background.default : theme.score[grade],
          color: loading ? theme.palette.background.default : theme.score[grade],
        }}
        className="grade-shield"
      >
        <svg
          version="1.1"
          width={shieldSize || 120}
          height={shieldSize || 120}
          viewBox="0 0 432.102 432.102"
        >
          <g>
            <g>
              <path
                d="M328.05,88.15c-12.857,4.505-26.377,6.83-40,6.88c-25.776-0.078-50.907-8.064-72-22.88
			c-20.976,14.758-45.954,22.767-71.6,22.96c-13.762-0.032-27.42-2.385-40.4-6.96v152c0,11.28,40,76.32,112,120
			c71.44-43.92,112-108.96,112-120C327.49,207.51,328.05,120.15,328.05,88.15z"
              />
              <text
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="8em"
                fontWeight={700}
                fill={theme.palette.background.paper}
              >
                {loading ? "" : grade}
              </text>
            </g>
          </g>
          <g>
            <g>
              <path
                d="M385.81,27.67c0.035-4.418-3.518-8.028-7.936-8.063c-1.152-0.009-2.293,0.231-3.344,0.703
			c-19.384,8.887-40.436,13.55-61.76,13.68c-33.154-0.408-65.266-11.646-91.44-32c-3.02-2.653-7.54-2.653-10.56,0
			c-26.128,20.439-58.269,31.687-91.44,32c-21.324-0.13-42.376-4.793-61.76-13.68c-4.03-1.812-8.765-0.013-10.577,4.016
			c-0.472,1.051-0.712,2.191-0.703,3.344c0,1.68,0.56,166.64,0,221.28c0,29.28,67.84,124,165.68,182.08c2.475,1.429,5.525,1.429,8,0
			c98-58.08,166.16-152.88,165.84-182.16C385.25,194.23,385.81,29.27,385.81,27.67z M220.13,377.03c-2.475,1.429-5.525,1.429-8,0
			c-73.2-43.36-124.08-114.56-124.08-136.88c0.4-40.48,0-162.56,0-163.84c-0.035-4.418,3.518-8.028,7.936-8.063
			c1.152-0.009,2.293,0.231,3.344,0.703c14.082,6.476,29.38,9.885,44.88,10c24.081-0.126,47.459-8.134,66.56-22.8
			c3.02-2.653,7.54-2.653,10.56,0c18.992,14.736,42.283,22.856,66.32,23.12c15.5-0.115,30.798-3.524,44.88-10
			c4.03-1.812,8.765-0.013,10.577,4.016c0.472,1.051,0.712,2.191,0.703,3.344c0.24,1.2-0.4,123.52,0.24,163.52
			C344.05,262.47,293.17,333.67,220.13,377.03z"
              />
            </g>
          </g>
        </svg>
        {/* <Box className="grade-text-wrapper" style={{ color: theme.palette.background.paper }}>
          <Typography variant="h6" color="inherit" className="grade-text">
            {loading ? null : grade}
          </Typography>
        </Box> */}
      </Box>
    </Fragment>
  );
};
