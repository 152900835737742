import moment from "moment";

export const setLatestBuildHash = (hash: string) => {
  localStorage.setItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.build`, hash);
};
export const setCodeRefreshTimeStamp = () => {
  if (!localStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.code.signature`)) {
    localStorage.setItem(
      `${process.env.REACT_APP_SSO_CLIENT_ID}.code.signature`,
      moment(new Date()).utc().valueOf().toString()
    );
  }
};

// const simulateHardRefresh = () => {
//   const evt = new KeyboardEvent("keydown", {
//     key: "F5",
//     ctrlKey: true,
//     bubbles: true,
//     cancelable: true,
//     code: "F5",
//     composed: true,
//     keyCode: 116,
//   });
//   document.dispatchEvent(evt);
// };
