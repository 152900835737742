export const fixingEffortLabel = (value: number): string => {
  switch (value) {
    case 1:
      return "Basic";
    case 2:
      return "Moderate";
    case 3:
      return "Advanced";
    default:
      return "-";
  }
};
export const fixingEffortColor = (value: number): string => {
  switch (value) {
    case 0:
      return "#b4b4be";

    case 1:
      return "#6cbd45";

    case 2:
      return "#fecb02";

    case 3:
      return "#f04323";

    case 4:
      return "#ed1e26";

    default:
      return "#b4b4be";
  }
};
