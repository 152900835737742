import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../App.interface";
import { AppDocView } from "../../views/app.doc.view";
import { AppAssetsSectionBox } from "../../views/app.section.box";

export const OpenPortsOverView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  const [parsedDoc, setParsedDoc] = React.useState<IESDoc>(doc);
  useEffect(() => {
    setParsedDoc(() => ({
      ...doc,
    }));
  }, [doc]);
  return (
    <Fragment>
      <AppAssetsSectionBox title="Overview" type="domain">
        <AppDocView
          colDefs={[
            { masterColumnName: "ip", masterColumnTitle: "IP Address", renderType: "url" },
            {
              masterColumnName: "port",
              masterColumnTitle: "Port",
              renderType: "text",
            },
            { masterColumnName: "product", masterColumnTitle: "Product", renderType: "text" },
            {
              masterColumnName: "tags",
              masterColumnTitle: "Tags",
              renderType: "tags",
            },
            {
              masterColumnName: "data",
              masterColumnTitle: "Banner Response",
              renderType: "text",
            },
            {
              masterColumnName: "meta.first_seen",
              masterColumnTitle: "First Seen",
              renderType: "date",
            },
            {
              masterColumnName: "meta.last_seen",
              masterColumnTitle: "Last Seen",
              renderType: "date",
            },
          ]}
          doc={parsedDoc}
        />
      </AppAssetsSectionBox>
    </Fragment>
  );
};
