import { Box, LinearProgress } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { FetchOrganizationNetworkGraph } from "../../services/dashboard.service";
import { INetworkGraphSlug } from "../module.interface";
import { HackerViewNetworkGraph } from "./network";
import "./styles.scss";
import { Node } from "vis";
import { AppDrawer } from "../views/Drawer";
import { NetworkDetails } from "./network-details";
import { useObservable } from "../../hooks/use-observable";
import { BrandService } from "../../services/brand.service";
import useDeepCompareEffect from "use-deep-compare-effect";
import { UUIDGenerator } from "../../util/uuid";

export const NetworkGraphController: React.FC<any> = () => {
  const [makingGraph, setMakingGraph] = React.useState<boolean>(false);

  const [data, setData] = React.useState<any>({});
  const [graphSlugs] = React.useState<INetworkGraphSlug[]>([
    {
      title: "Brand",
      aggField: "meta.brand",
      color: "#00d392",
      shape: "box",
      index: { indexName: "ds_host", dataField: "meta.brand" },
    },
    {
      title: "Domain",
      aggField: "domain",
      color: "#3699dc",
      shape: "hexagon",
      index: { indexName: "ds_domain", dataField: "domain" },
    },
    {
      title: "Host",
      aggField: "value",
      color: "#ea7b28",
      shape: "triangle",
      index: { indexName: "ds_host", dataField: "value" },
    },
    {
      title: "IP",
      aggField: "resolved_ip",
      color: "#d807d8",
      shape: "dot",
      index: { indexName: "ds_ip", dataField: "value" },
    },
  ]);
  const [slug, setSlug] = React.useState<INetworkGraphSlug | undefined>(undefined);
  const [selectedNodeId, setSelectedNodeId] = React.useState<string | undefined>(undefined);
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const [updateId, setUpdateId] = React.useState("");
  const fetchNetworkGraph = () => {
    setMakingGraph(true);
    FetchOrganizationNetworkGraph()
      .then((response) => {
        setData(_.isEmpty(response) ? { "No Data": 0 } : response);
      })
      .catch(() => {
        setData({ "Error loading Network": 0 });
      })
      .finally(() => {
        setMakingGraph(false);
      });
  };
  useEffect(fetchNetworkGraph, [updateId]);
  useDeepCompareEffect(() => {
    setUpdateId(UUIDGenerator());
  }, [selectedMemberIds]);

  return (
    <Fragment>
      {
        <Box className="network-graph-container" id="hacker_view_network_graph">
          {makingGraph ? (
            <LinearProgress variant="indeterminate" />
          ) : !_.isEmpty(data) ? (
            <HackerViewNetworkGraph
              data={data}
              graphSlugs={graphSlugs}
              onSelection={(node: Node | undefined) => {
                setSlug(undefined);
                if (node) {
                  if (node.level && node.id) {
                    const level = node.level;
                    setSelectedNodeId(node.id as string);

                    setSlug(graphSlugs[level]);
                  }
                } else {
                  setSelectedNodeId(undefined);

                  setSlug(undefined);
                }
              }}
            />
          ) : null}
        </Box>
      }
      {slug && selectedNodeId ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={selectedNodeId}
          onClose={() => {
            setSelectedNodeId(undefined);
            setSlug(undefined);
          }}
        >
          <NetworkDetails node={slug} value={selectedNodeId} />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
