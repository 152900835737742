import {
  Box,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import { ResponsiveContainer } from "recharts";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useBehaviourSubject } from "../../../../../hooks/use-observable";
import { BrandService } from "../../../../../services/brand.service";
import { KFormatter } from "../../../../../util/k_formatter";
import { oldPieColors } from "../../../util/pie-colors";
import { toast } from "../../../../../state/snackbar";
import { FetchAnalyticsEmailsAuthScores } from "../../../../../services/dmarc.service";
import { PaperTiledWithIcon } from "../components/tiled-paper-icon";
import { IESDoc } from "../../../../../App.interface";

interface Type {
  category: string;
  sourcesLoading: boolean;
  docs: IESDoc[];
}
interface ITopSource {
  source_name: string;
  doc_count: number;
}

interface ITileData {
  label: string;
  count: number;
  color:
    | "primary"
    | "secondary"
    | "info"
    | "disabled"
    | "action"
    | "inherit"
    | "error"
    | "success"
    | "warning";
  bgColor: string;
  icon: string;
  titleColor: string;
}

export const DmarcAggregateTopSources: React.FC<Type> = ({ category, sourcesLoading, docs }) => {
  const theme = useTheme();
  const errorBg = alpha(theme.palette.error.main, 0.2);
  const primaryBg = alpha(theme.palette.primary.main, 0.2);
  const warningBg = alpha(theme.palette.warning.main, 0.2);
  const successBg = alpha(theme.palette.success.main, 0.2);

  const [data, setData] = React.useState<ITopSource[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [emailType, setEmailType] = React.useState<ITileData[]>([
    {
      label: "Total Emails",
      count: 0,
      icon: "email",
      color: "primary",
      bgColor: primaryBg,
      titleColor: theme.palette.primary.main,
    },

    {
      label: "Recieved",
      count: 0,
      icon: "check_circle_outline",
      color: "success",
      bgColor: successBg,
      titleColor: theme.palette.success.main,
    },
    {
      label: "Quarantined",
      count: 0,
      icon: "pause_circle",
      color: "warning",
      bgColor: warningBg,
      titleColor: theme.palette.warning.main,
    },
    {
      label: "Rejected",
      count: 0,
      icon: "block",
      color: "error",
      bgColor: errorBg,
      titleColor: theme.palette.error.main,
    },
  ]);
  const selectedMemberIds = useBehaviourSubject<number[]>(BrandService.selectedMemberIds$) || [];

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
      stacked: false,
      type: "bar",
      stackType: "100%",
    },
    dataLabels: {
      enabled: true,
      textAnchor: "end",
      distributed: true,
      offsetX: 30,
      style: {
        fontSize: "10px",
      },
      formatter(val) {
        return KFormatter(parseInt(val.toString()));
      },
    },
    stroke: {
      show: false,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "60%",
        dataLabels: {},
      },
    },
    xaxis: {
      labels: {
        // offsetY: "-8px",
        style: {
          fontFamily: "Montserrat",
          fontSize: "10px",
          colors: [theme.palette.text.secondary],
        },
        formatter: (val) => `${KFormatter(parseInt(val))}`,
      },
      axisBorder: {
        show: true,
        color: theme.palette.background.default,
      },
      axisTicks: {
        show: true,
        color: theme.palette.background.default,
      },
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: "Montserrat",
          fontSize: "12px",
          colors: theme.palette.text.primary,
        },
      },
      axisBorder: {
        show: true,
        color: theme.palette.background.default,
      },
      axisTicks: {
        show: true,
        color: theme.palette.background.default,
      },
    },
    grid: {
      show: false,
      row: {
        colors: [theme.palette.background.paper, theme.palette.background.default],
        opacity: 1,
      },
    },
    colors: [theme.palette.primary.main],
    tooltip: {
      x: {
        show: false,
      },
      fillSeriesColor: true,
      theme: "false",
      style: {
        fontSize: "12px",
        fontFamily: "Montserrat",
      },
    },
    noData: {
      text: loading ? "Loading..." : "Data not available",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: theme.palette.text.primary,
        fontSize: "14px",
        fontFamily: "Montserrat",
      },
    },
    labels: _.take(_.orderBy(data, "doc_count", "desc"), 6).map((i) => i.source_name),
    fill: {
      colors: [oldPieColors[3]],
      type: "solid",
      opacity: 0.8,
    },
  };

  const chartSeries = [
    {
      name: "Total Emails",
      data: _.take(_.orderBy(data, "doc_count", "desc"), 6).map((i) => i.doc_count),
    },
  ];

  const fetchComlianceLevel = () => {
    setLoading(true);

    FetchAnalyticsEmailsAuthScores(category)
      .then((res) => {
        setEmailType([
          {
            label: "Total Emails",
            count: res.total_emails,
            icon: "email",
            color: "primary",
            bgColor: primaryBg,
            titleColor: theme.palette.primary.main,
          },

          {
            label: "Recieved",
            count: res.recieved_emails,
            icon: "check_circle_outline",
            color: "success",
            bgColor: successBg,
            titleColor: theme.palette.success.main,
          },
          {
            label: "Quarantined",
            count: res.quarantined_emails,
            icon: "pause_circle",
            color: "warning",
            bgColor: warningBg,
            titleColor: theme.palette.warning.main,
          },
          {
            label: "Rejected",
            count: res.rejected_emails,
            icon: "block",
            color: "error",
            bgColor: errorBg,
            titleColor: theme.palette.error.main,
          },
        ]);
      })
      .catch(() => {
        toast("Error fetching categories", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useDeepCompareEffect(fetchComlianceLevel, [category, selectedMemberIds]);

  React.useEffect(() => {
    setData(
      docs.map((e: any) => {
        return {
          source_name: e?.source_name || "",
          doc_count: e?.count || 0,
        };
      })
    );
  }, [docs]);

  return (
    <Fragment>
      <Stack height={"100%"} spacing={1} sx={{ minHeight: "450px" }}>
        <Paper style={{ height: "66%" }}>
          <Grid container direction="column" wrap="nowrap">
            <Grid item sx={{ paddingX: "8px" }}>
              <Grid
                container
                alignItems="center"
                justifyContent={"space-between"}
                style={{ margin: "10px 12px" }}
              >
                <Grid item xs={8}>
                  <Typography color="primary" sx={{ fontSize: 13, fontWeight: 600 }}>
                    Top Email Sources
                  </Typography>
                  <Typography color="textSecondary" sx={{ fontSize: 11 }}>
                    An Overview of the top Email sources
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ padding: 1 }}>
              {sourcesLoading ? (
                <Skeleton variant="rectangular" height={250} />
              ) : (
                <ResponsiveContainer height={250}>
                  <ReactApexChart options={chartOptions} series={chartSeries} type="bar" />
                </ResponsiveContainer>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Box style={{ height: "34%" }}>
          <Grid container display={"flex"} justifyContent="center" spacing={1}>
            {emailType.map((item, index) => (
              <Grid item lg={6} md={6} xs={6} key={`${index}__${item.label}`}>
                <PaperTiledWithIcon
                  title={item.label}
                  value={KFormatter(item.count)}
                  loading={loading || sourcesLoading}
                  color={item.color}
                  bgColor={item.bgColor}
                  icon={item.icon}
                  isDefault={false}
                  titleColor={item.titleColor}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </Fragment>
  );
};
