import { Grid, Icon, Typography } from "@mui/material";
import React, { Fragment } from "react";

interface IProps {
  icon: {
    name: string;
    color: any;
  };
  title: string;
  value: string;
}

export const IssueStatusTile: React.FC<IProps> = ({ icon, title, value }) => {
  return (
    <Fragment>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid
          item
          alignSelf={"center"}
          sx={{ minWidth: "70px", maxWidth: "70px", textAlign: "center" }}
        >
          <Icon style={{ fontSize: "2rem", color: icon.color }}>{icon.name}</Icon>
        </Grid>
        <Grid
          item
          alignSelf={"center"}
          sx={{ minWidth: "120px", maxWidth: "120px", textAlign: "start" }}
        >
          <Typography variant="h6" sx={{ fontSize: "14px", fontWeight: "normal" }}>
            {title}
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: "12px", fontWeight: "normal" }}
            color={"text.secondary"}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};
