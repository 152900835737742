import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Icon,
  Paper,
  Skeleton,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../../App.interface";
import { IMasterColumns } from "../../../module.interface";
import { toast } from "../../../../state/snackbar";
import { EmptyTableView } from "../../../views/EmptyTable";
import { OfflineAppMasterTable } from "../domains/components/offline-table";
import { FetchDomainEmailSources } from "../../../../services/dmarc.service";
import { SkeletonTable } from "../domains/components/skeleton-table";
export interface Params {
  id: any;
}

export const DmarcDomainSourceDetails: React.FC<any> = () => {
  // USE STATE HOOK
  const [loading, setLoading] = React.useState(false);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [sortField, setSortField] = React.useState("verify");
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">("desc");
  const [domainName, setDomainName] = React.useState<string>("");

  const theme = useTheme<Theme>();
  const colDefs: IMasterColumns[] = [
    {
      masterColumnName: "source_name",
      masterColumnTitle: "Source",
      renderType: "Title",
      filterable: true,
      sortable: true,
      cellRender: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"}>
            {params?.service_name != params?.source_name ? (
              <Tooltip title={params?.source_name}>{params?.service_name}</Tooltip>
            ) : (
              params?.service_name
            )}
            {params?.verify ? (
              <Icon style={{ fontSize: 22, margin: "0px 5px" }} color="primary">
                verified
              </Icon>
            ) : null}
          </Box>
        );
      },
    },
    {
      masterColumnName: "email_count",
      masterColumnTitle: "Email Volume",
      renderType: "number",
    },
    {
      masterColumnName: "ip_count",
      masterColumnTitle: "IP Count",
      renderType: "number",
    },
    {
      masterColumnName: "dkim",
      masterColumnTitle: "DKIM",
      sortable: true,
      renderType: "risk_grade",
    },
    {
      masterColumnTitle: "SPF",
      masterColumnName: "spf",
      sortable: true,
      renderType: "risk_grade",
    },
  ];

  //   FETCH DATA
  const fetchSources = () => {
    setLoading(true);
    FetchDomainEmailSources()
      .then((response) => {
        setDocs(response.data);
        setDomainName(response.domain_name);
      })
      .catch(() => {
        toast("Error fetching sources", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(fetchSources, []);

  return (
    <Fragment>
      <Grid container alignItems="center">
        <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 30 }}>
          <Typography style={{ color: theme.palette.text.primary }}>Domain Sources</Typography>
          {loading ? (
            <Skeleton width={200} height={30} variant="rectangular" />
          ) : (
            <Typography color={theme.palette.text.disabled}>{domainName}</Typography>
          )}
        </Breadcrumbs>
      </Grid>

      <Box>
        <Box className="app-module-container">
          <Box className="app-module-header">
            <Box className="header">
              <Stack direction="row" justifyContent="space-between" alignItems="cneter">
                <Stack>
                  <Typography variant="h4" className="module-title" color="primary">
                    Email Sources
                  </Typography>
                  <Typography
                    variant="body2"
                    component="p"
                    color="textPrimary"
                    className="module-description"
                  >
                    Verify the email sources of your domain(s)
                  </Typography>
                </Stack>
                <Stack alignItems="center" direction="row">
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Tooltip title="Not available for freemium users">
                        <span>
                          <Button
                            className="action-btns range"
                            color="primary"
                            variant="outlined"
                            size="small"
                            disabled
                            startIcon={<Icon>lock</Icon>}
                          >
                            LAST 1 MONTH
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Box>
        {loading ? (
          <Paper>
            <SkeletonTable colDefs={colDefs} enableSelection={false} />
          </Paper>
        ) : _.isEmpty(docs) ? (
          <Paper>
            <EmptyTableView />
          </Paper>
        ) : (
          <Paper>
            <OfflineAppMasterTable
              loading={loading}
              dataSource=""
              name=""
              title=""
              colDefs={colDefs}
              docs={docs}
              totalDocs={docs.length}
              pageIndex={pageIndex}
              pageSize={pageSize}
              sortField={sortField}
              sortOrder={sortOrder}
              onChangeSortField={setSortField}
              onChangeSortOrder={setSortOrder}
              onChangePageIndex={(page) => setPageIndex(page)}
              onChangePageSize={setPageSize}
              enablePagination={true}
              enableCheckBox={false}
              enableSelection={false}
              enableBrandColum={false}
              checkedDocs={[]}
              onChangeCheckedDocs={() => null}
              onChangeSelectedDoc={() => null}
              showBlur={false}
            />
          </Paper>
        )}
      </Box>
    </Fragment>
  );
};
