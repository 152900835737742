import { useSnackbar } from "notistack";
import React, { Fragment, useEffect } from "react";
import { $snackBar } from "../state/snackbar";

export const Snackbar: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return $snackBar.subscribe((sb) => {
      if (sb.message) {
        enqueueSnackbar(sb.message, {
          variant: sb.severity,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
    }).unsubscribe;
  }, [enqueueSnackbar]);
  return <Fragment />;
};
