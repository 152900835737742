import {
  LinearProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Checkbox,
  TableSortLabel,
  Typography,
  TableBody,
  Button,
  TablePagination,
  Switch,
  Icon,
} from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Fragment } from "react";
import { IMasterColumns } from "../../../../module.interface";
import { IESDoc } from "../../../../../App.interface";
import { SkeletonTable } from "./skeleton-table";
import { RenderCell } from "../../../../../util/render.cell";
import { EmptyTableView } from "../../../../views/EmptyTable";

interface IAppMasterTable {
  loading: boolean;
  dataSource: string;
  name: string;
  title: string;
  colDefs: IMasterColumns[];
  docs: IESDoc[];
  checkedDocs: IESDoc[];
  totalDocs: number;
  pageIndex: number;
  pageSize: number;
  sortField: string;
  sortOrder: "asc" | "desc";
  selectionButtonLabel?: string;
  enablePagination: boolean;
  enableCheckBox: boolean;
  enableSelection: boolean;
  enableBrandColum: boolean;
  enableSwitch?: boolean;
  showVerifiedIcon?: boolean;
  switchTitle?: string;
  historicalDNS?: boolean;
  disabledSwitch?: boolean;
  onChangeSortField: (field: string) => void;
  onChangeSortOrder: (order: "asc" | "desc") => void;
  onChangePageIndex: (index: number) => void;
  onChangePageSize: (size: number) => void;
  onChangeCheckedDocs: (docs: IESDoc[]) => void;
  onChangeSelectedDoc: (doc: IESDoc | undefined) => void;
  onChangeSwitch?: (
    event: React.ChangeEvent<HTMLInputElement> | undefined,
    doc: IESDoc | undefined
  ) => void;
  onSelectHistory?: (docs: IESDoc[]) => void;
  selectionNode?: ReactNode;
}
export const DomainHostTable: React.FC<IAppMasterTable> = ({
  loading,
  dataSource,
  name,
  title,
  colDefs,
  docs,
  checkedDocs,
  totalDocs,
  pageIndex,
  pageSize,
  sortField,
  sortOrder,
  selectionButtonLabel,
  enablePagination,
  enableCheckBox,
  enableSelection,
  enableSwitch,
  showVerifiedIcon,
  switchTitle,
  historicalDNS,
  disabledSwitch,
  onChangeCheckedDocs,
  onChangeSortField,
  onChangeSortOrder,
  onChangePageIndex,
  onChangePageSize,
  onChangeSelectedDoc,
  onChangeSwitch,
  onSelectHistory,
  selectionNode,
}) => {
  const masterColumns = colDefs;
  const onChangeCheckedDocument = (doc: IESDoc) => {
    const index = _.findIndex(checkedDocs, (d) => {
      return d._id === doc._id;
    });
    if (index === -1) {
      onChangeCheckedDocs([...checkedDocs, doc]);
    } else {
      onChangeCheckedDocs(checkedDocs.filter((d) => d._id !== doc._id));
    }
  };
  if (loading) {
    return <SkeletonTable colDefs={masterColumns} enableSelection={enableSelection} />;
  }

  return (
    <Fragment>
      <Box className="module-table-container" id={dataSource} key={title}>
        {loading ? <LinearProgress /> : null}
        <Box className="master-table-wrapper">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {enableCheckBox ? (
                    <TableCell padding="checkbox">
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                      >
                        <Grid item>
                          <Checkbox
                            color="primary"
                            indeterminate={
                              checkedDocs.length > 0 && checkedDocs.length < docs.length
                            }
                            checked={checkedDocs.length > 0 && checkedDocs.length === docs.length}
                            onChange={() =>
                              checkedDocs.length !== docs.length
                                ? onChangeCheckedDocs(docs)
                                : onChangeCheckedDocs([])
                            }
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : null}
                  {enableSwitch ? (
                    <TableCell>
                      <TableSortLabel
                        direction={sortOrder}
                        onClick={() => {
                          onChangeSortOrder(sortOrder === "asc" ? "desc" : "asc");
                        }}
                      >
                        <Typography
                          color="secondary"
                          variant="subtitle2"
                          className="table-cell-header"
                        >
                          {switchTitle?.length ? switchTitle : "Verified"}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  ) : null}
                  {masterColumns.map((col, post) => (
                    <TableCell key={`${col.masterColumnName}_${col.masterColumnTitle}__${post}`}>
                      <TableSortLabel
                        active={sortField === col.masterColumnName}
                        direction={sortOrder}
                        onClick={() => {
                          onChangeSortField(col.masterColumnName);
                          onChangeSortOrder(sortOrder === "asc" ? "desc" : "asc");
                        }}
                      >
                        <Typography
                          color="secondary"
                          variant="subtitle2"
                          className="table-cell-header"
                        >
                          {_.startCase(col.masterColumnTitle)}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {historicalDNS ? <TableCell></TableCell> : null}
                  {enableSelection ? <TableCell></TableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? (
                  !_.isEmpty(docs) ? (
                    docs.map((row: any, pos) => (
                      <TableRow hover key={`${row._id}__${pos}`}>
                        {enableCheckBox ? (
                          <TableCell padding="checkbox">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              alignContent="center"
                              wrap="nowrap"
                            >
                              <Grid item>
                                <Checkbox
                                  color="primary"
                                  checked={
                                    _.findIndex(checkedDocs, (d) => d._id === row._id) !== -1
                                  }
                                  onChange={() => onChangeCheckedDocument(row)}
                                  inputProps={{
                                    "aria-label": `primary checkbox ${row._id}`,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                        ) : null}
                        {enableSwitch ? (
                          <TableCell padding="checkbox">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              alignContent="center"
                              wrap="nowrap"
                            >
                              <Grid item>
                                <Switch
                                  disabled={disabledSwitch}
                                  checked={
                                    row.verify === "true" ||
                                    row.verify ||
                                    row.active ||
                                    row.verify === "Verified"
                                  }
                                  onChange={(event) => {
                                    if (onChangeSwitch) {
                                      onChangeSwitch(event, row);
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                        ) : null}
                        {masterColumns.map((col, index) => (
                          <TableCell
                            key={`${col.masterColumnName}_${col.masterColumnTitle}__${index}`}
                            className={`cell-${col.renderType}`}
                          >
                            <span className="data-cell" style={{ fontSize: "14px" }}>
                              {_.has(row, col.masterColumnName) &&
                              !_.isNull(_.get(row, col.masterColumnName)) &&
                              !_.isEmpty(_.get(row, col.masterColumnName).toString()) &&
                              !_.isUndefined(_.get(row, col.masterColumnName)) &&
                              !_.isNaN(_.get(row, col.masterColumnName))
                                ? col.cellRender
                                  ? col.cellRender(row)
                                  : RenderCell(_.get(row, col.masterColumnName), col.renderType)
                                : "-"}
                              {showVerifiedIcon && col.masterColumnTitle === "Domain" ? (
                                row.verify ? (
                                  <Icon style={{ fontSize: 22, margin: "0 5px" }} color="primary">
                                    verified
                                  </Icon>
                                ) : null
                              ) : null}
                            </span>
                          </TableCell>
                        ))}
                        {historicalDNS ? (
                          <TableCell id={`${name}_details_button_${pos}`} align="right">
                            {selectionNode ? (
                              selectionNode
                            ) : (
                              <Button
                                disabled={
                                  row.title === "domain_info_details" && row.is_subdomain
                                    ? !row.active
                                    : false
                                }
                                className="view-more-btn-cell"
                                color="primary"
                                size="small"
                                sx={{ fontSize: 13 }}
                                onClick={() => {
                                  if (onSelectHistory) {
                                    onSelectHistory(row);
                                  }
                                }}
                              >
                                Historical DNS
                              </Button>
                            )}
                          </TableCell>
                        ) : null}
                        {enableSelection ? (
                          <TableCell
                            id={`${name}_details_button_${pos}`}
                            align="right"
                            onClick={() => onChangeSelectedDoc(row)}
                          >
                            {selectionNode ? (
                              selectionNode
                            ) : (
                              <Button
                                className="view-more-btn-cell"
                                color="primary"
                                size="small"
                                sx={{ fontSize: 13 }}
                              >
                                {selectionButtonLabel || "Details"}
                              </Button>
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={
                          masterColumns.length +
                          (enableCheckBox ? 1 : 0) +
                          (enableSelection ? 1 : 0)
                        }
                      >
                        <EmptyTableView />
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
              </TableBody>
            </Table>
            {enablePagination ? (
              <TablePagination
                component="div"
                count={loading ? 0 : totalDocs}
                page={loading ? 0 : pageIndex}
                rowsPerPage={pageSize}
                rowsPerPageOptions={[10, 25, 100]}
                onPageChange={(event, page) => onChangePageIndex(page)}
                onRowsPerPageChange={(event) => {
                  onChangePageIndex(0);
                  onChangePageSize(parseInt(event.target.value));
                }}
              />
            ) : null}
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  );
};
