export const getSeverityColors = (severity: string) => {
  switch (severity.toLowerCase()) {
    case "low":
      return "#6cbd45";

    case "medium":
      return "#fecb02";

    case "high":
      return "#ed1e26";

    default:
      return "#e3e3e3";
  }
};

export const severityColors = {
  Low: "#4bbe1a",
  Medium: "#ffec00",
  High: "#ff3000",
};
