import { Box, Skeleton, Typography, Grid, Icon, useTheme } from "@mui/material";

import React, { Fragment, useEffect } from "react";
import "../../styles.scss";

export const calculateGrade = (score: number): string => {
  switch (true) {
    case score >= 100:
      return "A+";
    case score === -1:
      return "N/A";
    case score >= 90 && score < 100:
      return "A";
    case score >= 80 && score < 90:
      return "B";
    case score >= 70 && score < 80:
      return "C";
    case score >= 60 && score < 70:
      return "D";
    case score < 60:
      return "F";
    default:
      return "N/A";
  }
};

export const findScoreColor = (score: number): string => {
  switch (true) {
    case score === 100:
      return "#097b63";
    case score === -1:
      return "#dbdbdb";
    case score >= 90 && score < 100:
      return "#86ab3c";
    case score >= 80 && score < 90:
      return "#f3cf1b";
    case score >= 70 && score < 80:
      return "#f7a336";
    case score >= 60 && score < 70:
      return "#f4602f";
    default:
      return "#ea1a1e";
  }
};

export const findStarCount = (score: number): string[] => {
  let startConfig: string[] = ["E", "E", "E", "E", "E"];
  switch (true) {
    case score === 100:
      startConfig = ["F", "F", "F", "F", "F"];
      break;
    case score >= 90 && score < 100:
      startConfig = ["F", "F", "F", "F", "E"];
      break;
    case score >= 80 && score < 90:
      startConfig = ["F", "F", "F", "E", "E"];
      break;
    case score >= 60 && score < 80:
      startConfig = ["F", "F", "E", "E", "E"];
      break;
    case score >= 40 && score < 60:
      startConfig = ["F", "E", "E", "E", "E"];
      break;
    case score >= 1 && score < 39:
      startConfig = ["E", "E", "E", "E", "E"];
      break;
    default:
      startConfig = ["E", "E", "E", "E", "E"];
      break;
  }
  return startConfig;
};

export const Shield: React.FC<{
  loading: boolean;
  score: number;
  title: string;
  height?: number;
  width?: number;
}> = ({ score, loading, title, height, width }) => {
  const theme = useTheme();
  const [gradePosition, setGradePosition] = React.useState<number>(25);
  const gradePositionMapping = (height: number) => {
    switch (height) {
      case 160:
        return 25;
      case 120:
        return 20;
      default:
        return 25;
    }
  };
  useEffect(() => {
    if (height) {
      setGradePosition(gradePositionMapping(height));
    }
  }, [height]);
  return (
    <Fragment>
      <Box
        style={{ fill: theme.palette.background.default }}
        className={`dmarc-grade-container ${loading ? "loading-grade" : ""} grade-${calculateGrade(
          score
        )}`}
      >
        <Box className="grade-shield">
          <svg
            version="1.1"
            width={width || 160}
            height={height || 160}
            viewBox="0 0 432.102 432.102"
          >
            <g>
              <g>
                <path
                  d="M328.05,88.15c-12.857,4.505-26.377,6.83-40,6.88c-25.776-0.078-50.907-8.064-72-22.88
			c-20.976,14.758-45.954,22.767-71.6,22.96c-13.762-0.032-27.42-2.385-40.4-6.96v152c0,11.28,40,76.32,112,120
			c71.44-43.92,112-108.96,112-120C327.49,207.51,328.05,120.15,328.05,88.15z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M385.81,27.67c0.035-4.418-3.518-8.028-7.936-8.063c-1.152-0.009-2.293,0.231-3.344,0.703
			c-19.384,8.887-40.436,13.55-61.76,13.68c-33.154-0.408-65.266-11.646-91.44-32c-3.02-2.653-7.54-2.653-10.56,0
			c-26.128,20.439-58.269,31.687-91.44,32c-21.324-0.13-42.376-4.793-61.76-13.68c-4.03-1.812-8.765-0.013-10.577,4.016
			c-0.472,1.051-0.712,2.191-0.703,3.344c0,1.68,0.56,166.64,0,221.28c0,29.28,67.84,124,165.68,182.08c2.475,1.429,5.525,1.429,8,0
			c98-58.08,166.16-152.88,165.84-182.16C385.25,194.23,385.81,29.27,385.81,27.67z M220.13,377.03c-2.475,1.429-5.525,1.429-8,0
			c-73.2-43.36-124.08-114.56-124.08-136.88c0.4-40.48,0-162.56,0-163.84c-0.035-4.418,3.518-8.028,7.936-8.063
			c1.152-0.009,2.293,0.231,3.344,0.703c14.082,6.476,29.38,9.885,44.88,10c24.081-0.126,47.459-8.134,66.56-22.8
			c3.02-2.653,7.54-2.653,10.56,0c18.992,14.736,42.283,22.856,66.32,23.12c15.5-0.115,30.798-3.524,44.88-10
			c4.03-1.812,8.765-0.013,10.577,4.016c0.472,1.051,0.712,2.191,0.703,3.344c0.24,1.2-0.4,123.52,0.24,163.52
			C344.05,262.47,293.17,333.67,220.13,377.03z"
                />
              </g>
            </g>
          </svg>
          <Box
            className="grade-text-wrapper"
            style={{ color: theme.palette.background.paper }}
            sx={{ top: `${gradePosition}%` }}
          >
            <Typography variant="h6" color="inherit" className="grade-text">
              {loading ? null : calculateGrade(score)}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box className="grade-score-wrapper">
            {loading ? (
              <Skeleton variant="text" width={170} height={40}></Skeleton>
            ) : (
              <Typography
                variant={height ? "h4" : "h3"}
                className="grade-score-text"
                color="textPrimary"
              >
                {Math.floor(score)}
                <Typography
                  variant="caption"
                  component="span"
                  className="grade-score-full"
                  color="textPrimary"
                >
                  /100
                </Typography>
              </Typography>
            )}
          </Box>
          <Box className="grade-star-wrapper">
            {loading ? (
              <Grid container spacing={1}>
                {[1, 2, 3, 4, 5].map((s) => (
                  <Grid item key={`${s}`}>
                    <Skeleton variant="circular" width={15} height={15}></Skeleton>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid container spacing={1}>
                {findStarCount(score).map((s) => (
                  <Grid item key={Math.random().toString()}>
                    <Icon color={s === "E" ? "disabled" : "inherit"}>star</Icon>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
          <Box className="grade-comment-wrapper">
            {loading ? (
              <Skeleton variant="text"></Skeleton>
            ) : (
              <Typography
                variant="subtitle2"
                gutterBottom
                className="grade-comment-text"
                color="textSecondary"
              >
                <b style={{ fontWeight: 600 }}>{title}</b>
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
