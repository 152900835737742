import { Icon, Grid, Chip, Box, Theme, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import { ReactNode } from "react";
import { Severity } from "./severity";
import ReactJson from "react-json-view";
import "./render.styles.scss";
import { UrlRenderer } from "./url.render";
import React from "react";
import { calculateGrade } from "../score.grade.utils";
import { getSeverityNumber, getSeverityStr } from "../severity-mappings";
export enum RenderType {
  text,
  keyword,
  number,
  long,
  url,
  icon,
  chips,
}
export const RenderTimeSince = (value: string | number): ReactNode => {
  if (_.isNull(value)) {
    return "";
  }
  if (_.isUndefined(value)) {
    return "";
  }
  if (_.isNaN(value)) {
    return "";
  }
  if (_.isEmpty(value.toString())) {
    return "";
  }
  const regex = /^[0-9]*$/gm;
  let parsedValue = value;
  if (typeof value === "string" && regex.test(value)) {
    parsedValue = parseInt(value, 10);
  }
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      {moment(parsedValue).isValid() ? moment(parsedValue).fromNow() : parsedValue}
    </span>
  );
};
export const RenderDateTime = (value: string | number): ReactNode => {
  if (_.isNull(value)) {
    return "";
  }
  if (_.isUndefined(value)) {
    return "";
  }
  if (_.isNaN(value)) {
    return "";
  }
  if (_.isEmpty(value.toString())) {
    return "";
  }
  const regex = /^[0-9]*$/gm;
  let parsedValue = value;
  if (typeof value === "string" && regex.test(value)) {
    parsedValue = parseInt(value, 10);
  }
  return (
    <span style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
      {moment(parsedValue).isValid() ? moment(parsedValue).format("LLL") : parsedValue}
    </span>
  );
};
export const RenderText = (value: string): ReactNode => {
  let parsedValue = value;
  try {
    parsedValue = decodeURIComponent(value);
  } catch (error) {
    parsedValue = value;
  }
  return (<span>{parsedValue}</span>) as ReactNode;
};
export const RenderDate = (value: string | number): ReactNode => {
  const regex = /^[0-9]*$/gm;
  let parsedValue = value;
  if (typeof value === "string" && regex.test(value)) {
    parsedValue = parseInt(value, 10);
  }
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      <strong>
        {moment(parsedValue).isValid() ? moment(parsedValue).format("MMMM Do YYYY") : parsedValue}
      </strong>
    </span>
  ) as ReactNode;
};
export const RenderTime = (value: string | number): ReactNode => {
  const regex = /^[0-9]*$/gm;
  let parsedValue = value;
  if (typeof value === "string" && regex.test(value)) {
    parsedValue = parseInt(value, 10);
  }
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      <strong>
        {moment(parsedValue).isValid()
          ? moment(parsedValue).format("MMMM Do YYYY, h:mm:ss A")
          : parsedValue}
      </strong>
    </span>
  ) as ReactNode;
};
export const RenderEventStatus = (value: string): ReactNode => {
  return (
    <span style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
      {value.toLowerCase() === "added to incident" ? "Incident" : value}
    </span>
  ) as ReactNode;
};
export const RenderSeverity = (value: string): ReactNode => {
  return (<Severity value={value} />) as ReactNode;
};
export const RenderMonitoringStatus = (value: boolean): ReactNode => {
  if (value) {
    return (
      <span>
        <Icon fontSize="small">visibility</Icon>
      </span>
    );
  } else {
    return (
      <span>
        <Icon fontSize="small" color="disabled">
          visibility_off
        </Icon>
      </span>
    );
  }
};
export const RenderKeyword = (value: string): ReactNode => {
  return (<span>{value}</span>) as ReactNode;
};

export const RenderNumber = (value: number): ReactNode => {
  return (<span>{value.toLocaleString("en")}</span>) as ReactNode;
};

export const RenderLong = (value: string): ReactNode => {
  return (<span>{value}</span>) as ReactNode;
};

export const RenderUrl = (
  value: string,
  multiline?: boolean,
  renderComponent?: ReactNode
): ReactNode => {
  let parsedValue = value;

  try {
    parsedValue = decodeURIComponent(value);
  } catch (error) {
    parsedValue = value;
  }
  return (
    <UrlRenderer
      url={parsedValue}
      multiline={multiline ? true : false}
      renderComponent={renderComponent}
    />
  );
};

export const RenderTitle = (name: string): ReactNode => {
  return _.startCase(name);
};
export const RenderBoolean = (
  value: null | undefined | string | number | boolean | string[] | number[]
): ReactNode => {
  if (_.isNull(value)) {
    return "";
  }
  if (_.isUndefined(value)) {
    return "";
  }
  if (_.isNaN(value)) {
    return "";
  }
  if (_.isEmpty(value.toString())) {
    return "";
  }
  switch (typeof value) {
    case "boolean":
      return value ? <Icon color="primary">done</Icon> : <Icon color="primary">clear</Icon>;
    case "string":
      return value && value === "true" ? (
        <Icon color="primary">done</Icon>
      ) : (
        <Icon color="primary">clear</Icon>
      );
    case "number":
      return value ? <Icon color="primary">done</Icon> : <Icon color="primary">clear</Icon>;
    default:
      return value ? <Icon color="primary">done</Icon> : <Icon color="primary">clear</Icon>;
  }
};

export const RenderJSON = (value: any = {}): ReactNode => {
  return (<span>{JSON.stringify(value)}</span>) as ReactNode;
};

export const RenderArray = (
  params: any[],
  type: "url" | "title" | "tags" | "title_tags" | "severity" | "none",
  limit?: number,
  inline?: boolean
): ReactNode => {
  if (_.isNull(params)) {
    return "-";
  }
  if (_.isUndefined(params)) {
    return "-";
  }
  if (_.isNaN(params)) {
    return "-";
  }
  if (_.isEmpty(params)) {
    return "-";
  }
  const uniqueItems = _.uniqWith(params, _.isEqual);
  const stackLength = uniqueItems.length;
  const renderingItems = limit
    ? stackLength === limit
      ? uniqueItems
      : uniqueItems.filter((f, index) => index < limit)
    : uniqueItems;

  const remainingItems = limit
    ? stackLength === limit
      ? 0
      : Math.abs(uniqueItems.length - limit)
    : 0;
  if (_.isEmpty(renderingItems)) {
    return "-";
  }
  switch (type) {
    case "tags":
      return (
        <Grid container spacing={1}>
          {renderingItems.map((t, index) => (
            <Grid item key={t} xs={inline ? 12 : undefined}>
              <Chip size="small" variant="outlined" label={t}></Chip>
              {limit ? (
                index === limit - 1 && remainingItems > 0 ? (
                  <Chip
                    label={`+${remainingItems.toLocaleString()}`}
                    color="primary"
                    size="small"
                    className="remaining-count-chip"
                  />
                ) : null
              ) : null}
            </Grid>
          ))}
        </Grid>
      );
    case "title":
      return (
        <Grid container spacing={1}>
          {renderingItems.map((t, index) => (
            <Grid item key={t} xs={inline ? 12 : undefined}>
              {_.startCase(t)}
              {limit ? (
                index === limit - 1 && remainingItems > 0 ? (
                  <Chip
                    label={`+${remainingItems.toLocaleString()}`}
                    color="primary"
                    size="small"
                    className="remaining-count-chip"
                  />
                ) : null
              ) : null}
            </Grid>
          ))}
        </Grid>
      );
    case "url":
      return (
        <Grid container spacing={1}>
          {renderingItems.map((t, index) => (
            <Grid item key={t} xs={12}>
              <UrlRenderer url={t} multiline={false} />
              {limit ? (
                index === limit - 1 && remainingItems > 0 ? (
                  <Chip
                    label={`+${remainingItems.toLocaleString()}`}
                    color="primary"
                    size="small"
                    className="remaining-count-chip"
                  />
                ) : null
              ) : null}
            </Grid>
          ))}
        </Grid>
      );
    case "title_tags":
      return (
        <Grid container spacing={1}>
          {renderingItems.map((t, index) => (
            <Grid item key={t} xs={inline ? 12 : undefined}>
              <Chip size="small" variant="outlined" label={_.startCase(t)}></Chip>
              {limit ? (
                index === limit - 1 && remainingItems > 0 ? (
                  <Chip
                    label={`+${remainingItems.toLocaleString()}`}
                    color="primary"
                    size="small"
                    className="remaining-count-chip"
                  />
                ) : null
              ) : null}
            </Grid>
          ))}
        </Grid>
      );

    default:
      return (
        <Grid container spacing={1}>
          {renderingItems.map((t, index) => (
            <Grid item key={t} xs={inline ? 12 : undefined}>
              {t}
              {limit ? (
                index === limit - 1 && remainingItems > 0 ? (
                  <Chip
                    label={`+${remainingItems.toLocaleString()}`}
                    color="primary"
                    size="small"
                    className="remaining-count-chip"
                  />
                ) : null
              ) : null}
            </Grid>
          ))}
        </Grid>
      );
  }
};
export const RenderToolJobStatus = (value: string): ReactNode => {
  return (
    <Box
      className="job-status-box"
      style={{
        borderColor: value === "started" ? "#0088fe" : "#1d8102",
        color: value === "started" ? "#0088fe" : "#1d8102",
      }}
    >
      {RenderTitle(_.upperCase(value))}
    </Box>
  );
};
export const RenderEntityVerificationStatus = (value: string): ReactNode => {
  return (
    <Chip
      className={`entity_verification-status_wrapper keyword-status-${value}`}
      label={_.startCase(value)}
    />
  ) as ReactNode;
};
export const RenderComplexJSON = (value: any = {}, colName: string): ReactNode => {
  return (
    <ReactJson
      src={value}
      collapsed={false}
      name={colName}
      displayDataTypes={false}
      displayObjectSize={false}
      onEdit={false}
      onDelete={false}
      enableClipboard={false}
      style={{ fontFamily: "inherit", color: "inherit" }}
    />
  ) as ReactNode;
};

export const RenderThemedComplexJSON = (value: any = {}): ReactNode => {
  const theme = useTheme();
  return (
    <ReactJson
      src={value}
      iconStyle="square"
      displayObjectSize={false}
      displayDataTypes={false}
      name={"details"}
      theme="google"
      style={{
        fontFamily: "inherit",
        color: "inherit",
        backgroundColor: theme.palette.background.default,
        fontSize: "12px",
      }}
      enableClipboard={false}
    />
  ) as ReactNode;
};

export const RenderHTTPStatusCode = (value: string): ReactNode => {
  const code = parseInt(value, 10);
  if (value === "200" || code === 200) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-200" />
        <span className="status-code-label">
          <strong>200 OK</strong>
        </span>
      </span>
    );
  } else if (value === "201" || code === 201) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-200" />
        <span className="status-code-label">
          <strong>201 Created</strong>
        </span>
      </span>
    );
  } else if (value === "204" || code === 204) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-200" />
        <span className="status-code-label">
          <strong>204 No Content</strong>
        </span>
      </span>
    );
  } else if (value === "300" || code === 300) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-300" />
        <span className="status-code-label">
          <strong>300 Multiple Choices</strong>
        </span>
      </span>
    );
  } else if (value === "301" || code === 301) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-300" />
        <span className="status-code-label">
          <strong>301 Moved Permanently</strong>
        </span>
      </span>
    );
  } else if (value === "302" || code === 302) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-300" />
        <span className="status-code-label">
          <strong>302 Found</strong>
        </span>
      </span>
    );
  } else if (value === "304" || code === 304) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-300" />
        <span className="status-code-label">
          <strong>304 Not Modified</strong>
        </span>
      </span>
    );
  } else if (value === "305" || code === 305) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-300" />
        <span className="status-code-label">
          <strong>305 Use Proxy</strong>
        </span>
      </span>
    );
  } else if (value === "307" || code === 307) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-300" />
        <span className="status-code-label">
          <strong>307 Temporary Redirect</strong>
        </span>
      </span>
    );
  } else if (value === "308" || code === 308) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-300" />
        <span className="status-code-label">
          <strong>308 Permanent Redirect</strong>
        </span>
      </span>
    );
  } else if (value === "400" || code === 400) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-400" />
        <span className="status-code-label">
          <strong>400 Bad Request</strong>
        </span>
      </span>
    );
  } else if (value === "401" || code === 401) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-400" />
        <span className="status-code-label">
          <strong>401 Unauthorized</strong>
        </span>
      </span>
    );
  } else if (value === "403" || code === 403) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-400" />
        <span className="status-code-label">
          <strong>403 Forbidden</strong>
        </span>
      </span>
    );
  } else if (value === "404" || code === 404) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-400" />
        <span className="status-code-label">
          <strong>404 Not Found</strong>
        </span>
      </span>
    );
  } else if (value === "405" || code === 405) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-400" />
        <span className="status-code-label">
          <strong>405 Method Not Allowed</strong>
        </span>
      </span>
    );
  } else if (value === "406" || code === 406) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-400" />
        <span className="status-code-label">
          <strong>406 Not Acceptable</strong>
        </span>
      </span>
    );
  } else if (value === "407" || code === 407) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-400" />
        <span className="status-code-label">
          <strong>407 Proxy Authentication Required</strong>
        </span>
      </span>
    );
  } else if (value === "408" || code === 408) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-400" />
        <span className="status-code-label">
          <strong>408 Request Timeout</strong>
        </span>
      </span>
    );
  } else if (value === "409" || code === 409) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-400" />
        <span className="status-code-label">
          <strong>409 Conflict</strong>
        </span>
      </span>
    );
  } else if (value === "410" || code === 410) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-400" />
        <span className="status-code-label">
          <strong>410 Gone</strong>
        </span>
      </span>
    );
  } else if (value === "500" || code === 500) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-500" />
        <span className="status-code-label">
          <strong>500 Internal Server Error</strong>
        </span>
      </span>
    );
  } else if (value === "501" || code === 501) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-500" />
        <span className="status-code-label">
          <strong>501 Not Implemented</strong>
        </span>
      </span>
    );
  } else if (value === "502" || code === 502) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-500" />
        <span className="status-code-label">
          <strong>502 Bad Gateway</strong>
        </span>
      </span>
    );
  } else if (value === "503" || code === 503) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-500" />
        <span className="status-code-label">
          <strong>503 Service Unavailable</strong>
        </span>
      </span>
    );
  } else if (value === "504" || code === 504) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-500" />
        <span className="status-code-label">
          <strong>504 Gateway Timeout</strong>
        </span>
      </span>
    );
  } else if (value === "505" || code === 505) {
    return (
      <span className="http-status-code">
        <span className="http-status-code-bulb code-500" />
        <span className="status-code-label">
          <strong>505 HTTP Version Not Supported</strong>
        </span>
      </span>
    );
  } else {
    if (code >= 200 && code < 300) {
      return (
        <span className="http-status-code">
          <span className="http-status-code-bulb code-200" />
          <span className="status-code-label">
            <strong>{code}</strong>
          </span>
        </span>
      );
    } else if (code >= 300 && code < 400) {
      return (
        <span className="http-status-code">
          <span className="http-status-code-bulb code-300" />
          <span className="status-code-label">
            <strong>{code}</strong>
          </span>
        </span>
      );
    } else if (code >= 400) {
      return (
        <span className="http-status-code">
          <span className="http-status-code-bulb code-400" />
          <span className="status-code-label">
            <strong>{code}</strong>
          </span>
        </span>
      );
    }
  }
};
export const RenderRiskGrade = (value: number, theme?: Theme): ReactNode => {
  if (_.isNull(value)) {
    return "";
  }
  if (_.isUndefined(value)) {
    return "";
  }
  if (_.isNaN(value)) {
    return "";
  }
  if (_.isEmpty(value.toString())) {
    return "";
  }
  const grade = calculateGrade(value);

  return (
    <span className={"risk-score-grade-wrapper"} style={{ color: theme?.score[grade] }}>
      <span className="risk-grade-circe">{grade}</span>
    </span>
  ) as ReactNode;
};
export const RenderSeverityIssueCount = (
  value: { [key: string]: number },
  theme?: Theme
): ReactNode => {
  if (_.isNull(value)) {
    return "";
  }
  if (_.isUndefined(value)) {
    return "";
  }
  if (_.isNaN(value)) {
    return "";
  }
  if (_.isEmpty(value.toString())) {
    return "";
  }
  const issueSeverity = _.orderBy(
    _.map(value, (v, k) => ({
      severity: getSeverityNumber(k.toLowerCase()),
      count: v,
    })).filter((f) => f.severity > 2 && f.count > 0),
    ["severity"],
    ["desc"]
  );

  return issueSeverity.length ? (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      {issueSeverity.map((x) => (
        <Grid item key={x.severity.toString()}>
          <Box className="issue-summary-box">
            <Box
              className="icon"
              style={{
                color: theme?.severity?.[getSeverityStr(x.severity)],
              }}
            >
              <Icon color="inherit" fontSize="inherit">
                signal_cellular_alt
              </Icon>
            </Box>
            <Box className="data">
              <Typography variant="h6" color="textPrimary" className="count">
                {x.count.toLocaleString()}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary" className="label" component="p">
                {_.startCase(getSeverityStr(x.severity))}
              </Typography>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  ) : (
    ((<span>-</span>) as ReactNode)
  );
};
export const RenderHostType = (value: string[]): ReactNode => {
  if (_.isNull(value)) {
    return "";
  }
  if (_.isUndefined(value)) {
    return "";
  }
  if (_.isNaN(value)) {
    return "";
  }
  if (_.isEmpty(value)) {
    return "";
  }
  if (_.isArray(value)) {
    return value
      .filter((f) => !_.includes(["active", "inactive"], f.toLowerCase()))
      .map((t: any) => _.startCase(t))
      .join(", ");
  } else {
    return _.startCase(value as string);
  }
};
