import { Box, Button, Grid, Icon, IconButton, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";

import React, { useEffect } from "react";
import { Fragment } from "react";
import { toast } from "../../state/snackbar";
import { SizeConvertor } from "../../util/size-convertor";
interface IAppAttachments {
  name: string;
  accept: string;
  multiple: boolean;
  updateFlag: string;
  showFiles: boolean;
  iconButton: boolean;
  selectedFiles: (files: FileList) => void;
  buttonLabel?: string;
  fileSize?: number;
}
export const AppAttachments: React.FC<IAppAttachments> = ({
  accept,
  multiple,
  updateFlag,
  showFiles,
  iconButton,
  selectedFiles,
  buttonLabel,
  fileSize,
}) => {
  const [files, setFiles] = React.useState<string[]>([]);
  const onChangeFile = (e: any) => {
    setFiles([]);
    const validFiles: any[] = [];
    const attachments: FileList = e.target.files || [];
    for (let index = 0; index < attachments.length; index++) {
      const element = attachments[index];
      if ((fileSize && element.size < fileSize) || !fileSize) {
        validFiles.push(element);
        setFiles((arr) => [...arr, element.name]);
      } else {
        if (fileSize) {
          toast(`${element.name} should be less than ${SizeConvertor(fileSize)}`, "warning");
        }
      }
    }
    selectedFiles(validFiles as unknown as FileList);
  };
  useEffect(() => {
    setFiles([]);
  }, [updateFlag]);
  return (
    <Fragment>
      <Box className="app-attachment-wrapper">
        <Grid container alignItems="center" wrap="nowrap">
          <Grid item>
            {iconButton ? (
              <IconButton size="small" className="attachment-controller" color="secondary">
                <Icon>attachment</Icon>
                <input
                  type="file"
                  accept={accept}
                  multiple={multiple}
                  onChange={onChangeFile}
                  className="file-selector"
                />
              </IconButton>
            ) : (
              <Button
                className="attachment-controller"
                color="secondary"
                size="small"
                variant="outlined"
                endIcon={<Icon>add</Icon>}
              >
                <input
                  type="file"
                  accept={accept}
                  multiple={multiple}
                  onChange={onChangeFile}
                  className="file-selector"
                />
                {buttonLabel ? buttonLabel : "Attachment"}
              </Button>
            )}
          </Grid>
          <Grid item>
            {!isEmpty(files) && showFiles ? (
              <Typography variant="h6" color="primary" className="attached-file-name">
                {files.length === 1 ? files[0] : `${files.length} files`}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
