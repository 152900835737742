export const ISSUE_PROGRESS_STATUS: { label: string; value: number }[] = [
  { label: "New", value: 1 },
  { label: "Investigating", value: 2 },
  { label: "In Progress", value: 3 },
  { label: "Fixed", value: 4 },
  { label: "Acceptable Risk", value: 5 },
  { label: "False Positive", value: 6 },
];
export const issueProgressStatusLabel = (progressStatus: number): string => {
  switch (progressStatus) {
    case 1:
      return "New";

    case 2:
      return "Investigating";

    case 3:
      return "In Progress";

    case 4:
      return "Fixed";

    case 5:
      return "Acceptable Risk";

    case 6:
      return "False Positive";

    default:
      return "-";
  }
};

export const issueProgressStatusColor = (progressStatus: number): string => {
  switch (progressStatus) {
    case 1:
      return "#db3b3f";

    case 2:
      return "#f7bb24";

    case 3:
      return "#027fb9";

    case 4:
      return "#2faa48";

    case 5:
      return "#019e92";

    case 6:
      return "#f8c291";

    default:
      return "#f8c291";
  }
};
