import {
  LinearProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Checkbox,
  TableSortLabel,
  Typography,
  TableBody,
  Button,
  TablePagination,
  useTheme,
  Theme,
} from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Fragment } from "react";
import { IESDoc } from "../../App.interface";
import { RenderCell } from "../../util/render.cell";
import { IMasterColumns } from "../module.interface";
import { EmptyTableView } from "./EmptyTable";
import useDeepCompareEffect from "use-deep-compare-effect";
import { UpgradeInfoTable } from "../module.views/UpgradeTable";
import { ColorOpacity } from "../../util/color_opacity";
interface IAppBlurTable {
  loading: boolean;
  dataSource: string;
  name: string;
  title: string;
  colDefs: IMasterColumns[];
  docs: IESDoc[];
  checkedDocs: IESDoc[];
  totalDocs: number;
  pageIndex: number;
  pageSize: number;
  sortField: string;
  sortOrder: "asc" | "desc";
  enablePagination: boolean;
  enableCheckBox: boolean;
  enableSelection: boolean;
  showBlur: boolean;
  onChangeSortField: (field: string) => void;
  onChangeSortOrder: (order: "asc" | "desc") => void;
  onChangePageIndex: (index: number) => void;
  onChangePageSize: (size: number) => void;
  onChangeCheckedDocs: (docs: IESDoc[]) => void;
  onChangeSelectedDoc: (doc: IESDoc | undefined) => void;
  selectionNode?: ReactNode;
}
export const AppBlurTable: React.FC<IAppBlurTable> = ({
  loading,
  dataSource,
  name,
  title,
  colDefs,
  docs,
  checkedDocs,
  totalDocs,
  pageIndex,
  pageSize,
  sortField,
  sortOrder,
  enablePagination,
  enableCheckBox,
  enableSelection,
  onChangeCheckedDocs,
  onChangeSortField,
  onChangeSortOrder,
  onChangePageIndex,
  onChangePageSize,
  onChangeSelectedDoc,
  selectionNode,
  showBlur,
}) => {
  const theme = useTheme<Theme>();
  const [masterColumns, setMasterColumns] = React.useState<IMasterColumns[]>(colDefs);
  const onChangeCheckedDocument = (doc: IESDoc) => {
    const index = _.findIndex(checkedDocs, (d) => {
      return d._id === doc._id;
    });
    if (index === -1) {
      onChangeCheckedDocs([...checkedDocs, doc]);
    } else {
      onChangeCheckedDocs(checkedDocs.filter((d) => d._id !== doc._id));
    }
  };

  useDeepCompareEffect(() => {
    setMasterColumns(colDefs);
  }, [colDefs]);

  return (
    <Fragment>
      <Box className="module-table-container" id={dataSource} key={title}>
        {loading ? <LinearProgress /> : null}
        <Box className="master-table-wrapper" width={"100%"} overflow={"hidden"}>
          <TableContainer sx={{ maxHeight: 1000 }}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  {enableCheckBox ? (
                    <TableCell padding="checkbox">
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                      >
                        <Grid item>
                          <Checkbox
                            color="primary"
                            indeterminate={
                              checkedDocs.length > 0 && checkedDocs.length < docs.length
                            }
                            checked={checkedDocs.length > 0 && checkedDocs.length === docs.length}
                            onChange={() =>
                              checkedDocs.length !== docs.length
                                ? onChangeCheckedDocs(docs)
                                : onChangeCheckedDocs([])
                            }
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : null}
                  {masterColumns.map((col) => (
                    <TableCell key={`${col.masterColumnName}_${col.masterColumnTitle}`}>
                      <TableSortLabel
                        active={sortField === col.masterColumnName}
                        direction={sortOrder}
                        onClick={() => {
                          onChangeSortField(col.masterColumnName);
                          onChangeSortOrder(sortOrder === "asc" ? "desc" : "asc");
                        }}
                      >
                        <Typography
                          color="secondary"
                          variant="subtitle2"
                          className="table-cell-header"
                        >
                          {_.startCase(col.masterColumnTitle)}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {enableSelection ? <TableCell></TableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? (
                  !_.isEmpty(docs) ? (
                    <>
                      {docs.map((row, pos) => (
                        <TableRow
                          hover
                          key={row._id}
                          sx={{ filter: `blur(${row.dummy_data ? "2.5px" : "0px"})` }}
                        >
                          {enableCheckBox ? (
                            <TableCell padding="checkbox">
                              <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                alignContent="center"
                                wrap="nowrap"
                              >
                                <Grid item>
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      _.findIndex(checkedDocs, (d) => d._id === row._id) !== -1
                                    }
                                    onChange={() => onChangeCheckedDocument(row)}
                                    inputProps={{
                                      "aria-label": `primary checkbox ${row._id}`,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </TableCell>
                          ) : null}
                          {masterColumns.map((col) => (
                            <TableCell
                              key={`${col.masterColumnName}_${col.masterColumnTitle}`}
                              className={`cell-${col.renderType}`}
                            >
                              <span className="data-cell">
                                {col.cellRender
                                  ? col.cellRender(row)
                                  : _.has(row, col.masterColumnName) &&
                                    !_.isNull(_.get(row, col.masterColumnName)) &&
                                    !_.isEmpty(_.get(row, col.masterColumnName).toString()) &&
                                    !_.isUndefined(_.get(row, col.masterColumnName)) &&
                                    !_.isNaN(_.get(row, col.masterColumnName))
                                  ? RenderCell(_.get(row, col.masterColumnName), col.renderType)
                                  : "-"}
                              </span>
                            </TableCell>
                          ))}
                          {enableSelection ? (
                            <TableCell
                              id={`${name}_details_button_${pos}`}
                              align="right"
                              onClick={() => onChangeSelectedDoc(row)}
                            >
                              {selectionNode ? (
                                selectionNode
                              ) : (
                                <Button className="view-more-btn-cell" color="primary" size="small">
                                  Details
                                </Button>
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      ))}
                      {showBlur ? (
                        <TableRow>
                          <TableCell
                            colSpan={
                              masterColumns.length +
                              (enableCheckBox ? 1 : 0) +
                              (enableSelection ? 1 : 0)
                            }
                            padding="none"
                          >
                            <Box position={"relative"} height={"150px"} margin={"0 -16px"}>
                              <Box
                                position={"absolute"}
                                bottom={0}
                                width={"100%"}
                                height={"150px"}
                                style={{
                                  backdropFilter: "blur(2.5px)",
                                  background: `linear-gradient(to top,${ColorOpacity(
                                    theme.palette.primary.main,
                                    0.3
                                  )}, transparent)`,
                                }}
                              >
                                <UpgradeInfoTable />
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={
                          masterColumns.length +
                          (enableCheckBox ? 1 : 0) +
                          (enableSelection ? 1 : 0)
                        }
                      >
                        <EmptyTableView />
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
              </TableBody>
            </Table>

            {enablePagination ? (
              <TablePagination
                component="div"
                count={loading ? 0 : totalDocs}
                page={loading ? 0 : pageIndex}
                rowsPerPage={pageSize}
                rowsPerPageOptions={_.sortBy(_.uniq([10, 25, 100, pageSize]))}
                onPageChange={(event, page) => onChangePageIndex(page)}
                onRowsPerPageChange={(event) => {
                  onChangePageIndex(0);
                  onChangePageSize(parseInt(event.target.value));
                }}
              />
            ) : null}
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  );
};
