import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../App.interface";
import { AppDocView } from "../../views/app.doc.view";
import { AppAssetsSectionBox } from "../../views/app.section.box";

export const IPAddressOverView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  const [parsedDoc, setParsedDoc] = React.useState<IESDoc>(doc);
  useEffect(() => {
    setParsedDoc(() => ({
      ...doc,
    }));
  }, [doc]);
  return (
    <Fragment>
      <AppAssetsSectionBox title="Overview" type="domain">
        <AppDocView
          colDefs={[
            {
              masterColumnName: "type",
              masterColumnTitle: "IP Type",
              renderType: "title",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "whois.ip_range",
              masterColumnTitle: "IP Range",
              renderType: "text",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "other_resolving_hostnames",
              masterColumnTitle: "Host Names",
              renderType: "array",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "whois.country_name",
              masterColumnTitle: "Country Name",
              renderType: "title",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "whois.asn.asn",
              masterColumnTitle: "ASN",
              renderType: "text",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "whois.asn.asn.name",
              masterColumnTitle: "ASN Name",
              renderType: "text",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "whois.asn.asn.type",
              masterColumnTitle: "ASN Type",
              renderType: "text",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "whois.asn.asn.route",
              masterColumnTitle: "ASN Route",
              renderType: "text",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "whois.org",
              masterColumnTitle: "Organization",
              renderType: "text",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "cloud_platform",
              masterColumnTitle: "Cloud Platform",
              renderType: "text",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "meta.first_seen",
              masterColumnTitle: "First Seen",
              renderType: "date",
              filterable: true,
              sortable: true,
            },
            {
              masterColumnName: "meta.last_seen",
              masterColumnTitle: "Last Seen",
              renderType: "date",
              filterable: true,
              sortable: true,
            },
          ]}
          doc={parsedDoc}
        />
      </AppAssetsSectionBox>
    </Fragment>
  );
};
