import _startCase from "lodash/startCase";
import { Box, Grid, Icon, Theme, Typography, useTheme } from "@mui/material";

import { Skeleton } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { getSeverityStr } from "../../util/severity-mappings";
import { fixingEffortColor, fixingEffortLabel } from "./fixing-effort.mapping";
import { IESDoc } from "../../App.interface";
import { FetchIssueMetaData } from "../../services/dashboard.service";
interface IIssuesOverView {
  selectedIssue: string;
  severity: number;
  fixingEffort: number;
  issueType: string;
  issueCategory: string;
}
export const IssuesMetaData: React.FC<IIssuesOverView> = ({
  selectedIssue,
  fixingEffort,
  issueCategory,
  issueType,
  severity,
}) => {
  const theme = useTheme<Theme>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [issueMetaData, setIssueMetaData] = React.useState<IESDoc>();
  const [statsList] = React.useState([
    {
      icons: "signal_cellular_alt",
      label: "Severity",
      valueField: "severity_max",
      value: severity,
    },
    {
      icons: "construction",
      label: "Fixing Effort",
      valueField: "fixing_effort",
      value: fixingEffort,
    },
    {
      icons: "category",
      label: "Issue Type",
      valueField: "issue_type",
      value: issueType,
    },
    {
      icons: "bubble_chart",
      label: "Issue Category",
      valueField: "issue_category",
      value: issueCategory,
    },
  ]);
  const fetchIssueMetaData = (issueName: string) => {
    setLoading(true);
    FetchIssueMetaData(issueName)
      .then((response) => {
        setIssueMetaData(response.data[0]);
      })
      .catch(() => {
        //
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const findStatValue = (fieldName: string, value: string | number): string => {
    switch (fieldName) {
      case "severity_max":
        return _startCase(getSeverityStr(parseInt(value.toString(), 10)));
      case "fixing_effort":
        return _startCase(fixingEffortLabel(parseInt(value.toString(), 10)));
      default:
        return value.toString();
    }
  };
  const findIconColor = (fieldName: string, value: string | number): string => {
    switch (fieldName) {
      case "severity_max":
        return theme.severity[getSeverityStr(parseInt(value.toString(), 10))];
      case "fixing_effort":
        return fixingEffortColor(parseInt(value.toString(), 10));
      default:
        return theme.palette.primary.main;
    }
  };
  useEffect(() => {
    if (selectedIssue) {
      fetchIssueMetaData(selectedIssue);
    }
  }, [selectedIssue]);
  return (
    <Fragment>
      <Grid container spacing={4} alignItems="center" alignContent="center" justifyContent="center">
        <Grid item sm={6} xs={12}>
          <Box className="impact-solution-wrapper">
            <Typography variant="h6" gutterBottom color="secondary" className="title">
              Impact
            </Typography>
            {loading ? (
              <Fragment>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Fragment>
            ) : issueMetaData ? (
              <Typography
                variant="subtitle2"
                gutterBottom
                className="description"
                component="p"
                color="textSecondary"
              >
                {issueMetaData.impact ? issueMetaData.impact : "-"}
              </Typography>
            ) : null}
          </Box>
          <Box className="impact-solution-wrapper">
            <Typography variant="h6" gutterBottom color="secondary" className="title">
              Solution
            </Typography>
            {loading ? (
              <Fragment>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Fragment>
            ) : issueMetaData ? (
              <Typography
                variant="subtitle2"
                gutterBottom
                className="description"
                component="p"
                color="textSecondary"
              >
                {issueMetaData.solution ? issueMetaData.solution : "-"}
              </Typography>
            ) : null}
          </Box>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            {statsList.map((x) => (
              <Grid item key={x.icons} xs={6}>
                <Box className="issue-overview-stats">
                  <Box
                    className="icons"
                    style={{
                      color: findIconColor(x.valueField, x.value),
                    }}
                  >
                    <Icon color="inherit" fontSize="inherit">
                      {x.icons}
                    </Icon>
                  </Box>
                  <Box className="status-value">
                    <Typography variant="h6" gutterBottom color="textPrimary" className="label">
                      {x.label}
                    </Typography>
                    <Typography variant="h6" gutterBottom color="textSecondary" className="value">
                      {findStatValue(x.valueField, x.value)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
