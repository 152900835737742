export const calculateGrade = (score: number): string => {
  switch (true) {
    case score === 100:
      return "A+";
    case score === -1:
      return "N/A";
    case score >= 90 && score < 100:
      return "A";
    case score >= 80 && score < 90:
      return "B";
    case score >= 70 && score < 80:
      return "C";
    case score >= 60 && score < 70:
      return "D";
    default:
      return "F";
  }
};
export const findStarCount = (score: number): string[] => {
  let startConfig: string[] = ["E", "E", "E", "E", "E"];
  switch (true) {
    case score === 100:
      startConfig = ["F", "F", "F", "F", "F"];
      break;
    case score >= 80 && score < 100:
      startConfig = ["F", "F", "F", "F", "E"];
      break;
    // case score >= 80 && score < 90:
    //   startConfig = ["F", "F", "F", "E", "E"];
    //   break;
    case score >= 60 && score < 80:
      startConfig = ["F", "F", "F", "E", "E"];
      break;
    case score >= 40 && score < 60:
      startConfig = ["F", "F", "E", "E", "E"];
      break;
    case score >= 1 && score < 39:
      startConfig = ["F", "E", "E", "E", "E"];
      break;
    default:
      startConfig = ["E", "E", "E", "E", "E"];
      break;
  }
  return startConfig;
};
export const calculateComment = (score: number): string => {
  switch (true) {
    case score === 100:
      return "Excellent";
    case score === -1:
      return "N/A";
    case score >= 90 && score < 100:
      return "Very Good";
    case score >= 80 && score < 90:
      return "Good";
    case score >= 60 && score < 80:
      return "Average";
    case score >= 40 && score < 60:
      return "Satisfactory";
    case score >= 1 && score < 39:
      return "Poor";
    default:
      return "Poor";
  }
};

// export const gradeColorMap: Record<string, string> = {
//   "A+": "#036859",
//   A: "#288203",
//   B: "#ffec00",
//   C: "#ff8b00",
//   D: "#ff3000",
//   F: "#ed1e26",
//   "N/A": "#dcdcdc",
// };
