import _ from "lodash";
import {
  IESBulkResponse,
  IESDoc,
  IESParsedMapping,
  IESQueryResponse,
  IQuery,
  IQueryBody,
  ISnsResponse,
  IUserActivityLog,
  IUserFingerPrint,
} from "../App.interface";
import axios from "./axios.service";
export const fetchAllOrganizations = async <T>(
  searchQuery?: string
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/global/organizations", { searchQuery });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const LogUserActivity = async (
  activityLog: IUserActivityLog[] = []
): Promise<IESBulkResponse> => {
  try {
    const response = await axios.post("/global/activity", {
      activity_log: activityLog,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const LogRunTimeErrors = async (
  errorLog: IUserActivityLog[] = []
): Promise<IESBulkResponse> => {
  try {
    const response = await axios.post("/global/errors/client/runtime", {
      error_log: errorLog,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const LogUserFingerPrint = async (
  sessionLog: IUserFingerPrint[] = []
): Promise<IESBulkResponse> => {
  try {
    const response = await axios.post("/global/session", {
      session_log: sessionLog,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const pushOn2MeetNotification = async (message: string): Promise<any> => {
  try {
    const response = await axios.post("/global/on2meet", {
      message,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const esQuery = async <T>(
  index: string,
  query: IQuery = { query: { bool: {} } },
  memberIds?: any[]
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post(`/search/${index}`, { query, memberIds });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const esMapping: (index: string) => Promise<IESParsedMapping> = async (index: string) => {
  try {
    const response = await axios.post<IESParsedMapping>(`/mapping/${index}`);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const termsAgg = async (
  index: string,
  field: string,
  query: IQueryBody = { bool: {} },
  member_ids?: string[],
  maxSize = 500
) => {
  const response = await esQuery(index, {
    aggs: { termCount: { terms: { field, size: maxSize } } },
    size: 0,
    query,
  });
  const buckets = response.aggs.termCount.buckets;

  const terms = _.mapValues(_.mapKeys(buckets, "key"), "doc_count");
  return terms;
};
export const getRemoteImage = async (image_url: string): Promise<{ image: string }> => {
  try {
    const response = await axios.post("/global/remoteimage", {
      image_url,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const pushSNSMessage = async (payload: any): Promise<ISnsResponse> => {
  const response = await axios.post("/aws/sns", { payload });
  return response.data;
};
export const esBulkDelete = async (
  index: string,
  documents: { _id: string }[] = []
): Promise<IESBulkResponse> => {
  try {
    const response = await axios.post(`/delete/${index}`, { documents });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const esBulkAdd = async (
  index: string,
  documents: { _id?: string; [key: string]: any }[] = []
): Promise<IESBulkResponse> => {
  try {
    const response = await axios.post(`/add/${index}`, { documents });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const esBulkUpdate = async (
  index: string,
  documents: { _id: string; [key: string]: any }[] = []
): Promise<IESBulkResponse> => {
  try {
    const response = await axios.post(`/update/${index}`, { documents });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const GenerateReadMeToken = async (
  session_state: string
): Promise<{ readme_auth_token: string }> => {
  try {
    const response = await axios.post("/global/readme_token", { session_state });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchBrandName = async (): Promise<IESDoc> => {
  try {
    const response = await axios.post("/global/company_information");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const SubmitAppFeedback = async (rating: number, message: string): Promise<any> => {
  try {
    const response = await axios.post("/global/app_feedback", { rating, message });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
