import {
  Alert,
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  ListSubheader,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { has, isEmpty, isUndefined } from "lodash";
import { LoadingButton } from "@mui/lab";
import "../styles.scss";
import { toast } from "../../../state/snackbar";
import { ColorOpacity } from "../../../util/color_opacity";
import { DmarcVerifyDnsRecord } from "../../../services/dmarc.service";
import { copyToClipboard } from "../util/copy-to-clipboard";
interface IProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  handleRefresh: () => void;
  data: {
    member_id: number;
    member_name: string;
    member_configured: boolean;
    member_domains: {
      domain: string;
      rua_list: string[];
      suggested_record: string;
      dmarc_record: string;
    }[];
  }[];
  loading: boolean;
}

interface IParsedData {
  meta: {
    member_id: number;
    member_name: string;
    member_configured: boolean;
  };
  domain: string;
  rua_list: string[];
  suggested_record: string;
  dmarc_record: string;
}

export const DmarcConfigurationModal: React.FC<IProps> = ({
  isOpen,
  handleCloseModal,
  data,
  loading,
  handleRefresh,
}) => {
  const theme = useTheme();
  const [selectedDomain, setSelectedDomain] = React.useState<IParsedData>();
  const [verifyLoading, setVerifyLoading] = React.useState<boolean>(false);
  const [parsedData, setParsedData] = React.useState<IParsedData[]>([]);

  const verifyDnsRecord = () => {
    if (selectedDomain) {
      setVerifyLoading(true);
      DmarcVerifyDnsRecord(selectedDomain.domain, selectedDomain.meta.member_id)
        .then((res) => {
          if (res.is_verified == true) {
            handleCloseModal();
            handleRefresh();
            toast("Your DNS record now have our record", "success");
          } else {
            toast("Your DNS record is not updated yet", "warning");
          }
        })
        .catch(() => {
          toast("Error happened while verifying your DNS record, please try again later", "error");
        })
        .finally(() => {
          setVerifyLoading(false);
        });
    }
  };

  const [isCopied, setIsCopied] = React.useState<string>("");

  const toggleCopy = () => {
    setTimeout(() => {
      setIsCopied("");
    }, 3000); // Reset isCopied after 3 second
  };

  useEffect(() => {
    const parsed_data: IParsedData[] = [];
    if (!isEmpty(data)) {
      data.forEach((i) => {
        const m_id = i.member_id;
        const m_name = i.member_name;
        const is_configured = i.member_configured;

        i.member_domains.forEach((domain) => {
          if (is_configured == false) {
            parsed_data.push({
              meta: {
                member_id: m_id,
                member_name: m_name,
                member_configured: is_configured,
              },
              domain: domain.domain,
              rua_list: domain.rua_list,
              dmarc_record: domain.dmarc_record,
              suggested_record: domain.suggested_record,
            });
          }
        });
      });
      setParsedData(parsed_data);
      setSelectedDomain(parsed_data[0]);
    }
  }, [data]);
  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth={isUndefined(selectedDomain) && isEmpty(parsedData) ? "sm" : "lg"}
        open={isOpen}
        onClose={() => {
          handleCloseModal();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography color="secondary" sx={{ fontSize: 20, fontWeight: 600 }}>
                DNS Configuration Required
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: 2 }}>
          <Divider sx={{ mb: 1 }} />
          {loading ? (
            <Skeleton width={500} height={500} variant="rectangular" />
          ) : isUndefined(selectedDomain) && isEmpty(parsedData) ? (
            <Stack spacing={2} justifyContent={"center"} sx={{ mt: 2 }}>
              <Box>
                <Stack direction={"row"} spacing={1} justifyContent={"center"}>
                  <Icon color="warning">warning</Icon>
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 600, textAlign: "center" }}
                    color={"warning"}
                  >
                    An error happened while searching for your active domains.
                  </Typography>
                </Stack>
              </Box>

              <Alert severity="info">Kindly contact the admin to fix this issue</Alert>
            </Stack>
          ) : (
            <Grid container spacing={2} sx={{ my: 1 }}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15, fontWeight: 500, mb: 2 }}>
                  Your DMARC record does not authorize <strong>DMARC360</strong> to receive your
                  aggregate reports.
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography color="text.secondary" sx={{ fontSize: 15, fontWeight: 500 }}>
                  You will need to make the change on one of your sending domains.
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6} sx={{ mb: 2 }}>
                <Autocomplete
                  key="domain-selector"
                  id="domain-selector"
                  size="small"
                  options={parsedData}
                  getOptionLabel={(op) => op.domain}
                  renderOption={(props, option) => (
                    <li {...props} key={option.meta.member_id}>
                      <Grid container justifyContent="flex-start" wrap="nowrap" alignItems="center">
                        <Grid item alignSelf={"center"}>
                          <Typography
                            variant="subtitle1"
                            component={"span"}
                            className="option-brand-name"
                          >
                            {option.domain}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      key="domainTextFieldKey"
                      id="domainTextFieldKey"
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Domain"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
                        ),
                      }}
                    />
                  )}
                  groupBy={(option) => option.meta.member_name}
                  renderGroup={(group) => (
                    <Box sx={{ margin: 0, padding: 0 }} key={group.key}>
                      <ListSubheader>
                        <Grid container justifyContent={"space-between"}>
                          <Grid item alignSelf={"center"}>
                            <Box className="title">
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 600,
                                  color: theme.palette.info.main,
                                }}
                              >
                                {group.group}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListSubheader>
                      <Box className="organizations-options">{group.children}</Box>
                    </Box>
                  )}
                  noOptionsText="No Active Domains"
                  multiple={false}
                  value={selectedDomain}
                  onChange={(e, value) => {
                    if (value) {
                      setSelectedDomain(value);
                    }
                  }}
                  ChipProps={{
                    size: "small",
                  }}
                  loading={loading}
                  loadingText="Loading..."
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography sx={{ fontSize: 15, fontWeight: 500, mb: 2 }}>
                  To view the full DMARC reports data, please update your DMARC record on your
                  public DNS for the hostname{" "}
                  <span style={{ color: theme.palette.primary.main }}>
                    {selectedDomain ? ` _dmarc.${selectedDomain.domain}` : "selected"}{" "}
                  </span>{" "}
                  :
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  <span style={{ color: theme.palette.secondary.main }}>Current</span> DMARC Record
                </Typography>
                <Box
                  sx={{
                    width: 560,
                    maxWidth: 560,
                    height: 300,
                    borderRadius: "5px",
                    border: "1px solid",
                    borderColor: theme.palette.text.secondary,
                    padding: 1,
                    textAlign: "-webkit-center",
                    display: "table-cell",
                    verticalAlign: "middle",
                    wordWrap: "break-word",
                    position: "relative",
                  }}
                >
                  {selectedDomain?.dmarc_record == "" ? (
                    <Box
                      sx={{
                        padding: 1,
                        backgroundColor: ColorOpacity(theme.palette.warning.main, 0.2),

                        width: 200,
                      }}
                    >
                      <Typography
                        sx={{ color: theme.palette.warning.main, fontSize: 16, fontWeight: 600 }}
                      >
                        No DMARC Record Configured
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <Box sx={{ position: "absolute", bottom: "10px", right: "10px" }}>
                        {isCopied == "current" ? (
                          <Stack direction={"row"} spacing={0.5}>
                            <Icon color="success">check</Icon>
                            <Typography
                              sx={{
                                color: theme.palette.success.main,
                                fontWeight: 600,
                                fontSize: 16,
                              }}
                            >
                              Copied
                            </Typography>
                          </Stack>
                        ) : (
                          <IconButton
                            onClick={() => {
                              copyToClipboard(selectedDomain?.dmarc_record || "");
                              toggleCopy();
                              setIsCopied("current");
                            }}
                            disabled={isCopied != ""}
                          >
                            <Icon color="primary">content_copy</Icon>{" "}
                          </IconButton>
                        )}
                      </Box>
                      <Typography sx={{ fontSize: 15, fontWeight: 500, wordWrap: "break-word" }}>
                        {selectedDomain?.dmarc_record}
                      </Typography>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <Typography sx={{ fontWeight: 600 }}>
                  <span style={{ color: theme.palette.primary.main }}>Suggested</span> DMARC Record
                </Typography>
                <Box
                  sx={{
                    width: 560,
                    maxWidth: 560,
                    height: 300,
                    borderRadius: "5px",
                    border: "1px solid",
                    borderColor: theme.palette.text.secondary,
                    padding: 1,
                    textAlign: "-webkit-center",
                    display: "table-cell",
                    verticalAlign: "middle",
                    position: "relative",
                    wordWrap: "break-word",
                  }}
                >
                  <Box sx={{ position: "absolute", bottom: "10px", right: "10px" }}>
                    {isCopied == "suggested" ? (
                      <Stack direction={"row"} spacing={0.5}>
                        <Icon color="success">check</Icon>
                        <Typography
                          sx={{ color: theme.palette.success.main, fontWeight: 600, fontSize: 16 }}
                        >
                          Copied
                        </Typography>
                      </Stack>
                    ) : (
                      <IconButton
                        onClick={() => {
                          copyToClipboard(selectedDomain?.suggested_record || "");
                          toggleCopy();
                          setIsCopied("suggested");
                        }}
                        disabled={isCopied != ""}
                      >
                        <Icon color="primary">content_copy</Icon>{" "}
                      </IconButton>
                    )}
                  </Box>
                  <Typography sx={{ fontSize: 15, fontWeight: 500, wordWrap: "break-word" }}>
                    {selectedDomain && has(selectedDomain, "suggested_record")
                      ? selectedDomain.suggested_record
                          .split(
                            /(rua@dmarc360.com|rua_uae@dmarc360.com|rua@sa.dmarc360.com|rua@pk.dmarc360.com|ruf@dmarc360.com)/
                          )
                          .map((text, index) =>
                            index % 2 === 0 ? (
                              <span key={index}>{text}</span>
                            ) : (
                              <span
                                key={index}
                                style={{
                                  color: text.includes("ruf")
                                    ? theme.palette.warning.main
                                    : theme.palette.primary.main,
                                  fontWeight: 600,
                                }}
                              >
                                {text}
                              </span>
                            )
                          )
                      : null}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {isUndefined(selectedDomain) && isEmpty(parsedData) ? null : (
          <DialogActions>
            <Tooltip
              title={"Verify if the suggested DMARC record has been published on your public DNS"}
            >
              <LoadingButton
                size="small"
                variant="outlined"
                color="secondary"
                onClick={verifyDnsRecord}
                loading={verifyLoading}
              >
                Verify Record
              </LoadingButton>
            </Tooltip>
          </DialogActions>
        )}
      </Dialog>
    </Fragment>
  );
};
