import { LinearProgress } from "@mui/material";
import React, { Fragment, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { AttackSurfaceNetworkGraph } from "../network-graph/attack.surface.network";
import { EnvironmentsModule } from "../enviornments/environments.index";
import { TechnologiesModule } from "../technologies/technologies.index";
import { CyberAssetsModule } from "../cyber_assets/cyber_assets.index";

export const AttackSurfaceModule: React.FC = () => {
  return (
    <Fragment>
      <Switch>
        <Route path="/hackerview/attack_surface*">
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              <Route path={"/hackerview/attack_surface/asset_map"}>
                <AttackSurfaceNetworkGraph />
              </Route>
              <Route path={"/hackerview/attack_surface/environments"}>
                <EnvironmentsModule />
              </Route>
              <Route path={"/hackerview/attack_surface/technologies"}>
                <TechnologiesModule />
              </Route>
              <Route path={"/hackerview/attack_surface/assets"}>
                <CyberAssetsModule />
              </Route>
              <Route
                render={() => (
                  <Redirect to={{ pathname: "/hackerview/attack_surface/asset_map" }} />
                )}
              />
            </Switch>
          </Suspense>
        </Route>
      </Switch>
    </Fragment>
  );
};
