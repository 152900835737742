import { Grid } from "@mui/material";
import { Skeleton } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";

export const ScoreCardSkeleton: React.FC = () => {
  return (
    <Fragment>
      <Grid container spacing={2}>
        {_.range(11).map((x) => (
          <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={x}>
            <Skeleton variant="rectangular" height={125}></Skeleton>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};
