import { Box, Chip, Stack, Theme, Typography, useTheme } from "@mui/material";
import startCase from "lodash/startCase";
import React, { Fragment } from "react";
import { AppMD5Hash } from "../../util/md5.hash";
import { IESDoc } from "../../App.interface";
import { ColorShades } from "../../util/color-shades";
import { stringToColor } from "../../util/color.generator";
import { ColorOpacity } from "../../util/color_opacity";

interface IRiskRules {
  doc: IESDoc;
  collapsed?: boolean;
}
export const RiskRules: React.FC<IRiskRules> = ({ doc, collapsed }) => {
  const theme = useTheme<Theme>();
  const [matchedTags] = React.useState<string[]>(doc.risks || []);
  const computeLabelColor = (lbl: string): string => {
    const intensity = theme.palette.mode === "dark" ? "40" : "700";
    const pallette = ColorShades(stringToColor(AppMD5Hash(lbl)), intensity);
    return pallette;
  };

  return (
    <Fragment>
      <Box>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          flexWrap={collapsed ? undefined : "wrap"}
        >
          {matchedTags
            .filter((f, index) => (collapsed ? index < 2 : true))
            .map((i: any) => (
              <Box key={i} marginTop={collapsed ? undefined : "8px !important"}>
                <Chip
                  variant="filled"
                  label={
                    <Typography variant="subtitle1" fontSize={"12px"} fontWeight={500}>
                      {startCase(i)}
                    </Typography>
                  }
                  size={"small"}
                  style={{
                    fontWeight: 600,
                    borderRadius: "5px",
                    color: computeLabelColor(startCase(i)),
                    backgroundColor: ColorOpacity(computeLabelColor(startCase(i)), 0.15),
                  }}
                />
              </Box>
            ))}
          {matchedTags.length > 2 && collapsed ? (
            <Box>
              <Typography variant="subtitle1" fontWeight={600} color={"textSecondary"}>{`+${
                matchedTags.length - 2
              }`}</Typography>
            </Box>
          ) : null}
        </Stack>
      </Box>
    </Fragment>
  );
};
