import { Box, Button, Grid, Icon, Paper, Tooltip, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";

import moment from "moment";
import { FetchDNSScore, FetchDmarcScore } from "../../../../services/dmarc.service";
import { toast } from "../../../../state/snackbar";
import { useBehaviourSubject } from "../../../../hooks/use-observable";
import { user$ } from "../../../../state/user";
import { Shield } from "./components/shield";
import { DmarcIssuesCard } from "./views/issues-summary";
import { DmarcDashboardCards } from "./components/dashboard-cards";
import { DmarcDashboardSendStats } from "./views/sending-stats";
import DmarcDashboardDnsScore from "./views/dns-score";

export interface ChartItem {
  id: string;
  name: string;
  color: string;
  score: number;
}

export const DmarcDashboard: React.FC = () => {
  const [dnsRecrods, setDnsRecrods] = React.useState<any>([]);
  const [loadingDNS, setLoadingDNS] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [loadingScore, setloadingScore] = React.useState(false);

  const [score, setScore] = React.useState(-1);
  const user = useBehaviourSubject(user$);

  const fetchScore = () => {
    setloadingScore(true);
    FetchDmarcScore()
      .then((response) => {
        setScore(response?.score || 0);
      })
      .catch(() => {
        toast("Error fetching client data", "error");
      })
      .finally(() => {
        setloadingScore(false);
      });
  };
  const fetchDNSData = () => {
    setloading(true);
    setLoadingDNS(true);
    FetchDNSScore()
      .then((response) => {
        const dnsRecords: any = {
          dmarc: {
            current: response?.score?.total_domains_dmarc || 0,
            previous: response?.score?.last_month_dmarc || 0,
          },
          dkim: {
            current: response?.score?.dkim_percentage || 0,
            previous: response?.score?.last_month_dkim || 0,
          },
          spf: {
            current: response?.score?.spf_percentage || 0,
            previous: response?.score?.last_month_spf || 0,
          },
        };

        setDnsRecrods(dnsRecords);
      })
      .catch(() => {
        toast("Error fetching client data", "error");
      })
      .finally(() => {
        setloading(false);
        setLoadingDNS(false);
      });
  };

  useEffect(fetchScore, []);
  useEffect(fetchDNSData, []);

  const generateGreeting = (): string => {
    const hours = parseInt(moment().format("HH"));
    if (hours >= 3 && hours < 12) {
      return "Good Morning";
    } else if (hours >= 12 && hours < 15) {
      return "Good Afternoon";
    } else if (hours >= 15 && hours < 20) {
      return "Good Evening";
    } else {
      return "Welcome back,";
    }
  };
  return (
    <Fragment>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          margin: "auto",
          maxWidth: "1200px !important",
        }}
      >
        <Grid
          container
          flexWrap="nowrap"
          direction={"column"}
          spacing={2}
          className="dashboard-container"
        >
          {/* row 1 */}
          <Grid item>
            <Grid
              container
              spacing={2}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography sx={{ fontSize: 20 }}>
                  {" "}
                  {`${generateGreeting()} ${user?.given_name} !`}
                </Typography>
                <Typography variant="subtitle1" component={"p"} color="textSecondary">
                  {"Here's your organization's outbound emails summary."}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Not available for freemium users">
                  <span>
                    <Button
                      className="action-btns range"
                      color="primary"
                      variant="outlined"
                      size="small"
                      disabled
                      startIcon={<Icon>lock</Icon>}
                    >
                      LAST 1 MONTH
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xl={3.5} lg={6} md={12} xs={12}>
                <Paper style={{ height: "100%" }} id="overall_scorecard_dashboard">
                  <Shield
                    loading={loadingScore}
                    score={score < 0 ? 0 : score > 100 ? 100 : score}
                    title={"DMARC Implementation"}
                    height={120}
                    width={120}
                  />
                </Paper>
              </Grid>
              <Grid item xl={3.5} lg={6} md={12} xs={12}>
                <DmarcIssuesCard updateID={loading} />
              </Grid>
              <Grid item xl={5} lg={12} md={12} xs={12}>
                <DmarcDashboardCards updateID={loading} />
              </Grid>
            </Grid>
          </Grid>
          {/* row 2 */}
          <Grid item>
            <Grid container spacing={2} direction="row">
              <Grid item lg={6} md={12} xs={12}>
                <DmarcDashboardSendStats />
              </Grid>
              <Grid item lg={6} md={12} xs={12}>
                <DmarcDashboardDnsScore loading={loadingDNS} rawData={dnsRecrods} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
