import { Skeleton } from "@mui/material";

import React from "react";
import { Fragment } from "react";
import { FetchDomainAuthSources } from "../../../../../services/dmarc.service";
import { AppMD5Hash } from "../../../../../util/md5.hash";
import { toast } from "../../../../../state/snackbar";
import OverviewTextArray from "../components/overview.array";

export const DmarcDomainAuthorizedSources: React.FC = () => {
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  const fetchData = () => {
    setLoading(true);
    FetchDomainAuthSources()
      .then((response) => {
        if (response?.aggs?.COUNT?.buckets?.length) {
          setData(
            response?.aggs?.COUNT?.buckets?.map((e: any, index: number) => {
              return {
                label: e?.key || "",
                value: e?.SUM?.value.toLocaleString() || "0",
                id: AppMD5Hash(e?.key || `${index}`),
              };
            }) || undefined
          );
        } else {
          setData([]);
        }
      })
      .catch(() => {
        toast("Error getting sources", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(fetchData, []);

  return (
    <Fragment>
      {loading ? (
        <Skeleton variant="rectangular" height={200} style={{ marginTop: 10 }} />
      ) : (
        <OverviewTextArray title="Authorized Sources" array={data} />
      )}
    </Fragment>
  );
};
