import { Dialog, DialogContent } from "@mui/material";
import React, { Fragment } from "react";
import { PricingUpgrade } from "../modules/module.views/PricingUpgrade";
interface IUpgradeModel {
  onClose: () => void;
}
export const UpgradeModel: React.FC<IUpgradeModel> = ({ onClose }) => {
  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <PricingUpgrade onClose={onClose} />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
