import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { Box, Icon } from "@mui/material";

export default function CustomizedTooltips({ children }: { children: any }) {
  return (
    <Box sx={{ alignItems: "center", display: "flex", ml: 1 }}>
      <Tooltip title={children}>
        <Icon style={{ fontSize: "17px", alignSelf: "center" }} color="secondary">
          info_outlined
        </Icon>
      </Tooltip>
    </Box>
  );
}
