import React, { Fragment, useEffect } from "react";
import { AppAssetsSectionBox } from "../../views/app.section.box";
import { AppDocView } from "../../views/app.doc.view";
import { IESDoc } from "../../../App.interface";
import { IPDiscovery } from "./ip.discovery";

export const IpAddressDetailsView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  const [parsedDoc, setParsedDoc] = React.useState(doc);
  useEffect(() => {
    setParsedDoc({
      ...doc,
    });
  }, [doc]);
  return (
    <Fragment>
      <AppAssetsSectionBox title="Port Information" type="domain">
        <AppDocView
          colDefs={[
            {
              masterColumnName: "products",
              masterColumnTitle: "Services / Product",
              renderType: "tags",
            },
            {
              masterColumnName: "open_ports",
              masterColumnTitle: "Open Ports",
              renderType: "tags",
            },
            {
              masterColumnName: "whois.netname",
              masterColumnTitle: "NET Name",
              renderType: "array",
            },
            {
              masterColumnName: "whois.country_name",
              masterColumnTitle: "Country Name",
              renderType: "title",
            },

            {
              masterColumnName: "whois.asn.name",
              masterColumnTitle: "ASN Name",
              renderType: "text",
            },
          ]}
          doc={parsedDoc}
        />
      </AppAssetsSectionBox>
      <AppAssetsSectionBox title="Source Path" type="domain">
        <IPDiscovery doc={parsedDoc} />
      </AppAssetsSectionBox>
    </Fragment>
  );
};
