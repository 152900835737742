import { IconButton, Icon, Popover, Box, Typography, Divider } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import Lottie from "react-lottie";
import { user$ } from "../state/user";
import { UserPreferences } from "../util/user.preferences";
import animationData from "../assets/lottie/user-profile.json";
import { PreBuildThemes } from "./appearance/themes.prebuilt";
import { Accessibility } from "./accessibility/accessibility";
import { useBehaviourSubject, useObservable } from "../hooks/use-observable";
import { IESDoc } from "../App.interface";
import { FetchBrandName } from "../services/query.service";

export const AppUserProfile: React.FC = () => {
  const user = useBehaviourSubject(user$);
  const userSelectedTheme = useObservable(
    UserPreferences.local(`${process.env.REACT_APP_SSO_CLIENT_ID}.theme`)
  );
  const [userMenuEl, setUserMenuEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState(true);
  const [doc, setDoc] = React.useState<IESDoc>();
  const FetchName = () => {
    setLoading(true);
    FetchBrandName()
      .then((response) => {
        setDoc(response);
      })
      .catch(() => {
        //
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(FetchName, []);
  return (
    <Fragment>
      {user ? (
        <Fragment>
          <IconButton
            aria-label="account_circle"
            onClick={(e) => setUserMenuEl(e.currentTarget)}
            size="large"
          >
            <Icon>account_circle</Icon>
          </IconButton>
          <Popover
            open={Boolean(userMenuEl)}
            anchorEl={userMenuEl}
            onClose={() => setUserMenuEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box className="user-popover-container">
              <Box className="user-profile-header">
                <Box className="image">
                  <Lottie
                    options={{
                      animationData: animationData,
                      loop: true,
                      autoplay: true,
                      rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
                    }}
                    width={60}
                    height={60}
                  />
                </Box>
                <Box className="user-basic-info">
                  <Typography variant="h5" className="user-name" color="primary">
                    {user.name}
                  </Typography>
                  <Typography variant="h6" className="user-email" color="textPrimary">
                    {user.email}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className="user-organization"
                    color="secondary"
                  >
                    {user.internal ? "CTM 360" : loading ? "" : doc ? doc.brand : user.organization}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box className="user-control-panel">
                <PreBuildThemes theme={userSelectedTheme} />
              </Box>
              <Box className="user-control-panel">
                <Accessibility onClose={() => setUserMenuEl(null)} />
              </Box>
            </Box>
          </Popover>
        </Fragment>
      ) : null}
    </Fragment>
  );
};
