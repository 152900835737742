import { Box, Paper, Theme, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import _ from "lodash";
import moment from "moment";
import { ColorShades } from "../../util/color-shades";
import { UUIDGenerator } from "../../util/uuid";
import { UpgradeInfo } from "./Upgrade";
import { DashboardMasterTable } from "./dashboard.master.table";

export const BlurInfo: React.FC = () => {
  const theme = useTheme<Theme>();

  return (
    <Fragment>
      <Box position={"relative"}>
        <Paper>
          <DashboardMasterTable
            loading={false}
            dataSource="ds_asset_feed_dashboard"
            name="dashboard_asset_feed"
            title="Asset Feed"
            colDefs={[
              {
                masterColumnName: "brand",
                masterColumnTitle: "Brand",
                renderType: "text",
                filterable: true,
                sortable: true,
              },
              {
                masterColumnName: "asset",
                masterColumnTitle: "Asset",
                renderType: "url",
                filterable: true,
                sortable: true,
              },

              {
                masterColumnName: "first_seen",
                masterColumnTitle: "First Seen",
                renderType: "date",
                filterable: true,
                sortable: true,
              },
            ]}
            docs={_.range(25).map(() => ({
              _id: UUIDGenerator(),
              brand: UUIDGenerator(),
              asset: UUIDGenerator(),
              first_seen: moment(new Date()).utc().valueOf(),
            }))}
          />
          <Box
            width={"500px"}
            margin={"auto"}
            position={"absolute"}
            top={"150px"}
            left={"calc(50% - 250px)"}
            boxShadow={`9px 13px 82px 46px ${ColorShades(
              theme.palette.background.paper,
              theme.palette.mode === "dark" ? "800" : "300"
            )}`}
          >
            <Paper>
              <UpgradeInfo />
            </Paper>
          </Box>
        </Paper>
      </Box>
    </Fragment>
  );
};
