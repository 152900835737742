import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";
import Lottie from "react-lottie";
import animationData from "../../assets/lottie/scanning.json";

interface IEmptyChart {
  title: string;
}
export const EmptyChart: React.FC<IEmptyChart> = () => {
  return (
    <Fragment>
      <Box className="empty-chart-wrapper">
        <Box>
          <Box className="empty-chart-animation">
            <Lottie
              options={{
                animationData: animationData,
                loop: true,
                autoplay: true,
                rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
              }}
              width={250}
              height={250}
            />
          </Box>
          <Box className="empty-chart-text">
            <Typography variant="subtitle1" component="p" color="textSecondary">
              No data found
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
