import { Box, CircularProgress, Icon, Paper, Stack, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { IEasmPlans, IDrpPricingPlans } from "../module.interface";
import { IESDoc } from "../../App.interface";
import _ from "lodash";
import {
  FetchBrandSubsidiaryAssetCount,
  FetchOrganizationAssetCount,
} from "../../services/dashboard.service";
import useDeepCompareEffect from "use-deep-compare-effect";
import { AppDocView } from "../views/app.doc.view";
import { AppAssetsSectionBox } from "../views/app.section.box";
import { GenerateTakedownPlan } from "./drp/takedown";
interface IPriceHighlighter {
  EasmPlan: IEasmPlans | undefined;
  DrpPlan: IDrpPricingPlans | undefined;
  assetCount: number;
  tpL1: number;
  trainingSession: number;
  orgScoreCard: boolean;
  executive: number;
  takeDown: number;
  selectedSubsidiary: IESDoc[];
  tpEnterpriseAddon: boolean;
}
export const PriceHighlighter: React.FC<IPriceHighlighter> = ({
  DrpPlan,
  EasmPlan,
  executive,
  orgScoreCard,
  selectedSubsidiary,
  tpL1,
  trainingSession,
  takeDown,
  tpEnterpriseAddon,
}) => {
  const [loadingMasterAssets, setLoadingMasterAssets] = React.useState(true);
  const [loadingChildAssets, setLoadingChildAssets] = React.useState(true);
  const [masterAssetCount, setMasterAssetCount] = React.useState({ host: 0, ip: 0, total: 0 });
  const [childAssetCount, setChildAssetCount] = React.useState({ host: 0, ip: 0, total: 0 });
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [generatedPlan, setGeneratedPlan] = React.useState(GenerateTakedownPlan(takeDown));
  useEffect(() => {
    setGeneratedPlan(GenerateTakedownPlan(takeDown));
  }, [takeDown]);
  const organizationAssetCount = () => {
    setLoadingMasterAssets(true);
    FetchOrganizationAssetCount()
      .then((response) => {
        setMasterAssetCount(response);
      })
      .catch(() => {
        setMasterAssetCount({ host: 0, ip: 0, total: 0 });
      })
      .finally(() => {
        setLoadingMasterAssets(false);
      });
  };
  const subsidiaryAssetCount = () => {
    if (_.isEmpty(selectedSubsidiary)) {
      setLoadingChildAssets(false);
      setChildAssetCount({ host: 0, ip: 0, total: 0 });

      return;
    }
    setLoadingChildAssets(true);
    FetchBrandSubsidiaryAssetCount(selectedSubsidiary.map((x) => x._id))
      .then((response) => {
        setChildAssetCount(response);
      })
      .catch(() => {
        setChildAssetCount({ host: 0, ip: 0, total: 0 });
      })
      .finally(() => {
        setLoadingChildAssets(false);
      });
  };
  useDeepCompareEffect(() => {
    let total_price = 0;

    if (EasmPlan) {
      total_price =
        total_price + masterAssetCount.total * EasmPlan.monthly_price * 12 + EasmPlan.annual_price;

      if (childAssetCount.total) {
        total_price = total_price + childAssetCount.total * 3.5 * 12 + 3750;
      }
      if (tpEnterpriseAddon) {
        total_price = total_price + 20000;
      }
      if (orgScoreCard) {
        total_price = total_price + 20000;
      }
      if (tpL1) {
        total_price =
          total_price +
          tpL1 * (EasmPlan.name === "advance" ? 150 : tpEnterpriseAddon ? 3000 : 1500);
      }
      if (trainingSession) {
        total_price = total_price + trainingSession * 500;
      }
    }
    if (DrpPlan) {
      total_price = total_price + DrpPlan.annual_price;
      total_price = total_price + executive * 2500;
      total_price = total_price + selectedSubsidiary.length * 10000;
    }
    if (generatedPlan) {
      total_price = total_price + generatedPlan.annual_price;
    }
    setTotalPrice(total_price);
  }, [
    masterAssetCount,
    childAssetCount,
    EasmPlan,
    DrpPlan,
    orgScoreCard,
    tpL1,
    trainingSession,
    executive,
    selectedSubsidiary,
    generatedPlan,
    tpEnterpriseAddon,
  ]);
  useEffect(organizationAssetCount, []);
  useDeepCompareEffect(subsidiaryAssetCount, [selectedSubsidiary]);

  return (
    <Fragment>
      <Paper style={{ position: "sticky", top: "120px" }}>
        <Box padding={4}>
          <Box textAlign={"center"} marginBottom={5} padding={"24px 0"}>
            {loadingChildAssets || loadingMasterAssets ? (
              <CircularProgress />
            ) : (
              <Stack direction={"row"} justifyContent={"center"}>
                <Icon style={{ fontSize: "40px", fontWeight: "bold" }} color="primary">
                  attach_money
                </Icon>
                <Typography variant="h4" color={"primary"} marginLeft={"-8px"}>
                  <strong>{`${totalPrice.toLocaleString()}`}</strong>
                  <Typography
                    variant="subtitle2"
                    fontSize={"12px"}
                    fontWeight={600}
                    component={"span"}
                    color={"textPrimary"}
                  >
                    /year
                  </Typography>
                </Typography>
              </Stack>
            )}
          </Box>
          <Box>
            <AppAssetsSectionBox type={"overview"} title={"Overview"} flatPaper>
              <AppDocView
                doc={{
                  _id: "overview",
                  subsidiaries: selectedSubsidiary.map((x) => x.name),
                  trainingSession: `${trainingSession} sessions`,
                }}
                colDefs={_.uniqWith(
                  [
                    {
                      masterColumnName: "subsidiaries",
                      masterColumnTitle: "Subsidiaries",
                      renderType: "array",
                    },
                    trainingSession
                      ? {
                          masterColumnName: "trainingSession",
                          masterColumnTitle: "Training Sessions",
                          renderType: "text",
                        }
                      : {
                          masterColumnName: "subsidiaries",
                          masterColumnTitle: "Subsidiaries",
                          renderType: "array",
                        },
                  ],
                  _.isEqual
                )}
              />
            </AppAssetsSectionBox>
            {EasmPlan ? (
              <AppAssetsSectionBox type={"easm"} title={"Attack Surface Management"} flatPaper>
                <AppDocView
                  doc={{
                    _id: EasmPlan.name,
                    ...EasmPlan,
                    assetCount: masterAssetCount.total + childAssetCount.total,
                    orgScoreCard,
                    tpL1: `${tpL1} companies`,
                  }}
                  colDefs={_.uniqWith(
                    [
                      {
                        masterColumnName: "title",
                        masterColumnTitle: "Plan Name",
                        renderType: "text",
                      },
                      {
                        masterColumnName: "user_count",
                        masterColumnTitle: "User Count",
                        renderType: "text",
                      },
                      {
                        masterColumnName: "assetCount",
                        masterColumnTitle: "Total Assets",
                        renderType: "text",
                      },
                      orgScoreCard
                        ? {
                            masterColumnName: "orgScoreCard",
                            masterColumnTitle: "Any Organization Scorecard",
                            renderType: "boolean",
                          }
                        : {
                            masterColumnName: "assetCount",
                            masterColumnTitle: "Total Assets",
                            renderType: "text",
                          },
                      tpL1
                        ? {
                            masterColumnName: "tpL1",
                            masterColumnTitle: "Third Party Risk Monitoring",
                            renderType: "text",
                          }
                        : {
                            masterColumnName: "assetCount",
                            masterColumnTitle: "Total Assets",
                            renderType: "text",
                          },
                    ],
                    _.isEqual
                  )}
                />
              </AppAssetsSectionBox>
            ) : null}
            {DrpPlan ? (
              <AppAssetsSectionBox type={"easm"} title={"Digital Risk Protection"} flatPaper>
                <AppDocView
                  doc={{
                    _id: DrpPlan.name,
                    ...DrpPlan,
                    executive: `${executive} executives`,
                  }}
                  colDefs={_.uniqWith(
                    [
                      {
                        masterColumnName: "title",
                        masterColumnTitle: "Plan Name",
                        renderType: "text",
                      },
                      {
                        masterColumnName: "user_count",
                        masterColumnTitle: "User Count",
                        renderType: "text",
                      },

                      executive
                        ? {
                            masterColumnName: "executive",
                            masterColumnTitle: "Executive Monitoring",
                            renderType: "text",
                          }
                        : {
                            masterColumnName: "user_count",
                            masterColumnTitle: "User Count",
                            renderType: "text",
                          },
                    ],
                    _.isEqual
                  )}
                />
              </AppAssetsSectionBox>
            ) : null}
            {generatedPlan ? (
              <AppAssetsSectionBox type={"easm"} title={"Digital Risk Protection"} flatPaper>
                <AppDocView
                  doc={{
                    _id: generatedPlan.name,
                    ...generatedPlan,
                  }}
                  colDefs={_.uniqWith(
                    [
                      {
                        masterColumnName: "title",
                        masterColumnTitle: "Plan Name",
                        renderType: "text",
                      },
                      {
                        masterColumnName: "user_count",
                        masterColumnTitle: "User Count",
                        renderType: "text",
                      },
                      {
                        masterColumnName: "take_down_count",
                        masterColumnTitle: "Takedown Count",
                        renderType: "text",
                      },
                      {
                        masterColumnName: "features",
                        masterColumnTitle: "Features",
                        renderType: "array",
                      },
                      {
                        masterColumnName: "freebies",
                        masterColumnTitle: "Freebies",
                        renderType: "array",
                      },
                    ],
                    _.isEqual
                  )}
                />
              </AppAssetsSectionBox>
            ) : null}
          </Box>
        </Box>
      </Paper>
    </Fragment>
  );
};
