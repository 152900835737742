import React, { Fragment } from "react";
import { Icon, IconButton, Stack, Tooltip } from "@mui/material";
import { IESDoc } from "../../../App.interface";
import { toast } from "../../../state/snackbar";
import { RenderUrl } from "../../../util/cell-render/renderers";

export const DomainRender: React.FC<{ doc: IESDoc }> = ({ doc }) => {
  return (
    <Fragment>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        {RenderUrl(doc.subject, true)}
        <Tooltip title={"Copy URL"}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigator.clipboard.writeText(doc.subject);
              toast("Domain copied to clipboard", "info");
            }}
          >
            <Icon fontSize="inherit" style={{ fontSize: "16px", opacity: 0.5 }}>
              content_copy
            </Icon>
          </IconButton>
        </Tooltip>
      </Stack>
    </Fragment>
  );
};
