import { Grid, Box, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { useBehaviourSubject } from "../../../../hooks/use-observable";
import { BrandService } from "../../../../services/brand.service";
import { AppMD5Hash } from "../../../../util/md5.hash";
import { UUIDGenerator } from "../../../../util/uuid";
import { toast } from "../../../../state/snackbar";
import { IssuesAccordion } from "./components/issues.accordion";
import Lottie from "react-lottie";
import animationData from "../../../../assets/lottie/target.lottie.json";
import { FetchDmarcIssuesList } from "../../../../services/dmarc.service";
import useDeepCompareEffect from "use-deep-compare-effect";

export interface IIssueName {
  id: string;
  issue_id: string;
  issueName: string;
  severity: string;
  fixing_effort: string;
  total: number;
  general_impact: string;
  general_solution: string;
  detection_source: string;
}

export const DmarcIssuesController: React.FC<any> = () => {
  const [loadingIssues, setLoadingIssues] = React.useState(false);
  const [issueNames, setIssueNames] = React.useState<IIssueName[]>([]);

  const selectedMemberIds = useBehaviourSubject<number[]>(BrandService.selectedMemberIds$) || [];

  const sortPriority: string[] = ["high", "medium", "low", "informational"];

  const fetchIssueNames = () => {
    setLoadingIssues(true);

    // repalce it

    FetchDmarcIssuesList()
      .then((response) => {
        const issues =
          response.data.map((e: any, index: number) => {
            return {
              issue_id: e?.ID?.buckets?.[0]?.key || AppMD5Hash(`${e?.key}__${index}`),
              id: UUIDGenerator(),
              issueName: e?.key || "",
              severity: e?.SEVERITY?.buckets?.[0]?.key || "",
              fixing_effort: e?.FIXING_EFFORT?.buckets?.[0]?.key || "",
              total: e?.doc_count || 0,
              general_impact: e?.general_impact || "-",
              general_solution: e?.general_solution || "-",
              detection_source: e?.DETECTION_SOURCE?.buckets?.[0]?.key || "-",
            } as IIssueName;
          }) || [];

        setIssueNames(
          _.orderBy(issues, (item) => sortPriority.findIndex((s) => s === _.toLower(item.severity)))
        );
      })
      .catch(() => {
        toast("Error fetching documents", "error");
      })
      .finally(() => {
        setLoadingIssues(false);
      });
  };

  useDeepCompareEffect(fetchIssueNames, [selectedMemberIds, sortPriority]);
  return (
    <Fragment>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Box className="app-module-container">
            <Box className="app-module-header">
              <Box className="header">
                <Typography variant="h4" className="module-title" color="primary">
                  Issues
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="textPrimary"
                  className="module-description"
                >
                  Issues found by analyzing DMARC aggregate reports and DNS records.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* tabs */}
      {loadingIssues ? (
        [1, 2, 3, 4, 5].map((e) => (
          <Box key={`domain_info_loading_${e}`}>
            <IssuesAccordion
              parentLoading={true}
              issueName={{
                id: "",
                issue_id: "",
                issueName: "",
                severity: "",
                fixing_effort: "",
                total: 0,
                general_impact: "",
                general_solution: "",
                detection_source: "",
              }}
            />
          </Box>
        ))
      ) : _.isEmpty(issueNames) ? (
        <Fragment>
          <Lottie
            options={{
              animationData: animationData,
              loop: true,
              autoplay: true,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
            }}
            width={150}
            height={150}
          />

          <Typography
            variant="subtitle2"
            component="p"
            color="textSecondary"
            style={{ textAlign: "center" }}
          >
            No issues found
            <br /> System is actively scanning for issues
          </Typography>
        </Fragment>
      ) : (
        issueNames.map((item) => (
          <IssuesAccordion key={item.id} issueName={item} parentLoading={loadingIssues} />
        ))
      )}
    </Fragment>
  );
};
