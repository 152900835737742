import _round from "lodash/round";
import _sum from "lodash/sum";
import { Grid, Theme, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { ITechCategory } from "../module.interface";
import { AppMD5Hash } from "../../util/md5.hash";
import { GradeView } from "./grade.view";
import { EnvironmentPlatform } from "./environment.platform";

interface IEnvironmentCategory {
  theme: Theme;

  category: ITechCategory;
}
export const EnvironmentCategory: React.FC<IEnvironmentCategory> = ({ category, theme }) => {
  const [categoryScore, setCategoryScore] = React.useState<number>(0);

  useEffect(() => {
    const sum = _sum(category.techName.map((x) => x.score));
    setCategoryScore(_round(sum / category.techName.length));
  }, [category]);
  return (
    <Fragment>
      <Box className="technology-category-container">
        <Box className="technology-category-wrapper">
          <Box className="technology-category-name">
            <Box>
              <GradeView theme={theme} score={categoryScore} />
              <Typography color="primary" variant="h6" className="name-title">
                {category.name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          className="horizontal-braces"
          style={{
            borderColor: theme.palette.background.paper,
            backgroundImage: `radial-gradient(circle at 0 0, rgba(255, 255, 255, 0) 14.5px, ${theme.palette.background.paper} 15.5px, ${theme.palette.background.paper} 19.5px, rgba(255, 255, 255, 0) 20.5px),radial-gradient(circle at 35px 0,rgba(255, 255, 255, 0) 14.5px,${theme.palette.background.paper} 15.5px, ${theme.palette.background.paper} 19.5px,rgba(255, 255, 255, 0) 20.5px)`,
          }}
        ></Box>
        <Box className="technology-name-container">
          <Grid container spacing={2} justifyContent="center">
            {category.techName.map((technology) => (
              <Grid item key={AppMD5Hash(technology.name)}>
                <EnvironmentPlatform theme={theme} technology={technology} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
};
