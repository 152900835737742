import Keycloak from "keycloak-js";
interface RealmAccess {
  roles: string[];
}

interface Account {
  roles: string[];
}

interface ResourceAccess {
  account: Account;
}

interface IModulePermission {
  Add: string[];
  Delete: string[];
  Edit: string[];
  View: string[];
}

interface Policy {
  [key: string]: IModulePermission;
}

export interface AppPortalPolicy {
  role: string;
  policy: Policy;
}
export interface IKeyCloakToken {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  session_state: string;
  acr: string;
  "allowed-origins": string[];
  realm_access: RealmAccess;
  resource_access: ResourceAccess;
  scope: string;
  email_verified: boolean;
  ted_ids: number[];
  organization: string;
  name: string;
  member_groups: number[];
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
  designation: string;
  [key: string]: any;
}
export const AppKeyCloak = Keycloak({
  clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  realm: process.env.REACT_APP_SSO_REALM,
  url: process.env.REACT_APP_SSO_URL,
});
