import React, { Fragment } from "react";
import { IESDoc } from "../../App.interface";
import { findBrandLogo } from "../../util/brand.logo";

export const TechnologiesLogoRender: React.FC<{ doc: IESDoc }> = ({ doc }) => {
  const [brandLogo] = React.useState(findBrandLogo(doc.library) || "javascript.svg");
  return (
    <Fragment>
      <img
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        src={require(`../../assets/images/svg-logos/${brandLogo}`)}
        alt={`logo-${brandLogo}`}
        style={{ width: 30, height: "100%" }}
      />
    </Fragment>
  );
};
