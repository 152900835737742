import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../../App.interface";
import { AppDocView } from "../../views/app.doc.view";
import { AppAssetsSectionBox } from "../../views/app.section.box";

export const IpRangesOverView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  const [parsedDoc, setParsedDoc] = React.useState<IESDoc>(doc);
  useEffect(() => {
    setParsedDoc(() => ({
      ...doc,
    }));
  }, [doc]);
  return (
    <Fragment>
      <AppAssetsSectionBox title="Overview" type="domain">
        <AppDocView
          colDefs={[
            {
              masterColumnName: "cidr",
              masterColumnTitle: "IP CIDR",
              renderType: "text",
            },
            {
              masterColumnName: "whois.asn.asn",
              masterColumnTitle: "ASN",
              renderType: "text",
            },
            {
              masterColumnName: "whois.asn.name",
              masterColumnTitle: "ASN Name",
              renderType: "text",
            },
            {
              masterColumnName: "whois.asn.type",
              masterColumnTitle: "ASN Type",
              renderType: "text",
            },
            {
              masterColumnName: "whois.org",
              masterColumnTitle: "Organization",
              renderType: "text",
            },
            {
              masterColumnName: "whois.country_name",
              masterColumnTitle: "Country Name",
              renderType: "title",
            },
            {
              masterColumnName: "whois.region",
              masterColumnTitle: "Region",
              renderType: "text",
            },
            {
              masterColumnName: "whois.city",
              masterColumnTitle: "City",
              renderType: "text",
            },
            {
              masterColumnName: "whois.address",
              masterColumnTitle: "Address",
              renderType: "text",
            },
          ]}
          doc={parsedDoc}
        />
      </AppAssetsSectionBox>
    </Fragment>
  );
};
