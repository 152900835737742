import { Box, Button, ButtonGroup, Icon } from "@mui/material";
import React, { Fragment } from "react";
import { DomainOverView } from "./views/overview.domain";
import { IESDoc } from "../../App.interface";
import { HostOverView } from "./views/overview.hosts";
import { WebsiteOverView } from "./views/overview.website";
import { IPAddressOverView } from "./views/overview.ip_address";
import { SslCertificatesOverView } from "./views/overview.ssl_certs";
import { IpRangesOverView } from "./views/overview.ip_ranges";
import { OpenPortsOverView } from "./views/overview.open_ports";
import { DomainDetailsView } from "./views/details.domain";
import { HostDetailsView } from "./views/details.host";
import { WebsiteDetailsView } from "./views/details.website";
import { IpAddressDetailsView } from "./views/details.ip_address";
const findViews = (params: string): string[] => {
  switch (params) {
    case "ssl_certificates":
    case "ip_ranges":
    case "open_ports":
      return ["overview"];

    default:
      return ["overview", "details", "issues"];
  }
};
export const CyberAssetsAdvancedView: React.FC<{ type: string; doc: IESDoc }> = ({ type, doc }) => {
  const [views] = React.useState(findViews(type));
  const [selectedView, setSelectedView] = React.useState("overview");
  return (
    <Fragment>
      <Box margin={"auto"} marginTop={2.5} marginBottom={2.5} textAlign={"center"}>
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          {views.map((x) => (
            <Button
              key={x}
              variant={selectedView === x ? "contained" : "outlined"}
              onClick={() => setSelectedView(x)}
              disabled={x === "issues"}
              endIcon={x === "issues" ? <Icon>lock</Icon> : null}
            >
              {x}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <Box>
        {selectedView === "overview" ? (
          <Fragment>
            {type === "domains" ? <DomainOverView doc={doc} /> : null}
            {type === "hosts" ? <HostOverView doc={doc} /> : null}
            {type === "websites" ? <WebsiteOverView doc={doc} /> : null}
            {type === "ip_addresses" ? <IPAddressOverView doc={doc} /> : null}
            {type === "ssl_certificates" ? <SslCertificatesOverView doc={doc} /> : null}
            {type === "ip_ranges" ? <IpRangesOverView doc={doc} /> : null}
            {type === "open_ports" ? <OpenPortsOverView doc={doc} /> : null}
          </Fragment>
        ) : (
          <Fragment>
            {type === "domains" ? <DomainDetailsView doc={doc} /> : null}
            {type === "hosts" ? <HostDetailsView doc={doc} /> : null}
            {type === "websites" ? <WebsiteDetailsView doc={doc} /> : null}
            {type === "ip_addresses" ? <IpAddressDetailsView doc={doc} /> : null}
          </Fragment>
        )}
      </Box>
    </Fragment>
  );
};
