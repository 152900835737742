import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";

import "../../styles.scss";
import { RenderCell } from "../../../../../util/render.cell";
import { IMasterColumns } from "../../../../module.interface";

interface Type {
  doc: any;
  colDefs: IMasterColumns[];
  title: string;
}
export default function OverviewTab({ title, colDefs, doc }: Type) {
  return (
    <Fragment>
      <Box className="cyber-assets-section-box" id={`${title}_advanced_view_section_box_${title}`}>
        <Box className="section-box-header">
          <Typography variant="h6" color="secondary" className="section-title">
            {title}
          </Typography>
        </Box>
        <Paper className="section-box-contents">
          <Fragment>
            <TableContainer>
              <Table size="small" className="info-table">
                <TableBody>
                  {colDefs.map((x) => {
                    return x.cellRender ? (
                      x.cellRender(doc)
                    ) : (
                      <TableRow key={x.masterColumnName}>
                        <TableCell>
                          <Typography variant="h6" className="item-label" color="textSecondary">
                            {x.masterColumnTitle}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <span className="item-value">
                            {_.has(doc, x.masterColumnName) &&
                            !_.isNull(_.get(doc, x.masterColumnName)) &&
                            !_.isEmpty(_.get(doc, x.masterColumnName).toString()) &&
                            !_.isUndefined(_.get(doc, x.masterColumnName)) &&
                            !_.isNaN(_.get(doc, x.masterColumnName))
                              ? RenderCell(_.get(doc, x.masterColumnName), x.renderType)
                              : x.renderType === "risk_grade"
                              ? RenderCell(-1, "risk_grade")
                              : "-"}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        </Paper>
      </Box>
    </Fragment>
  );
}
