import { Box, Button, Grid, Link, Typography } from "@mui/material";
import React, { Fragment } from "react";
import RocketLogo from "../../assets/images/rocket.png";

export const UpgradeInfoTable: React.FC = () => {
  return (
    <Fragment>
      <Box padding={2} maxWidth={720} margin={"auto"}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
            <Box width={"100px"} margin={"auto"} marginRight={3}>
              <img src={RocketLogo} alt="freemium-logo" width={"100%"} />
            </Box>
          </Grid>
          <Grid item xl={9} lg={9} md={8} sm={6} xs={6}>
            <Box whiteSpace={"break-spaces"}>
              <Typography
                variant="h5"
                color={"secondary"}
                fontWeight={600}
                // textTransform={"capitalize"}
                marginBottom={1}
              >
                Make yourself a harder target
              </Typography>
              <Typography variant="subtitle1" fontSize={"13px"} fontWeight={600} textAlign={"left"}>
                CTM360 has much more to offer, by upgrading your plan you can access to advanced
                tools and features that will make your digital presence more safer
              </Typography>
              <Box margin={"8px 0"}>
                <Button
                  variant="contained"
                  component={Link}
                  href={`https://${
                    process.env.NODE_ENV === "development" ? "cyno.edxdev.link" : "start.ctm360.com"
                  }/pricing`}
                  target="_blank"
                >
                  <Typography
                    variant="h6"
                    textTransform={"uppercase"}
                    fontWeight={600}
                    fontSize={"12px"}
                  >
                    pricing plan
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
