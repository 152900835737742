import {
  Box,
  Button,
  Checkbox,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { IDrpPricingPlans } from "../../module.interface";
import { CustomSalesQuotation } from "../custom_quote";
import { DrpTakedownPlans } from "./drp_takedown_plans";
interface IDrpPlans {
  takedownCount: number;
  onChangeTakedownCount: (params: number) => void;
  currentPlan: string;
  onSelectPlan: (params: IDrpPricingPlans) => void;
  onNext: () => void;
}
export const DrpPlans: React.FC<IDrpPlans> = ({
  onNext,
  currentPlan,
  onSelectPlan,
  takedownCount,
  onChangeTakedownCount,
}) => {
  const [models] = React.useState([
    {
      name: "phishing_protection",
      title: "Phishing Protection",
      annual_price: 15000,
      user_count: 1,
      scope: "1 Key Domain/Brand",
      detection: [
        "Domain/Subdomain infringement",
        "Breached Credentials",
        "Malware Stealer Logs",
        "Phishing",
      ],
      takedowns: ["30 takedowns per annum", "Support via platform"],
    },
    {
      name: "phishing_social_media_protection",
      title: "Phishing & Social Media Protection",
      annual_price: 25000,
      user_count: 1,
      scope: "1 Key Domain/Brand",
      detection: ["Everything in Phishing Protection", "Social Media Brand Impersonations"],
      takedowns: ["50 takedowns per annum"],
    },
    {
      name: "brand_protection",
      title: "Brand Protection",
      annual_price: 35000,
      user_count: 3,
      scope: "1 Key Domain/Brand 1 Executive",
      detection: [
        "Everything in Phishing & Social Media Protection",
        "Social Media Executive Impersonations",
        "Compromised Cards",
        "Suspicious Mobile Apps",
      ],
      takedowns: ["75 takedowns per annum", "Suspicious Mobile Apps (UNLIMITED)"],
    },
    {
      name: "advanced",
      title: "Advanced DRP",
      annual_price: 42500,
      user_count: 5,
      scope: "2 Key Domain/Brand 2 Executive",
      detection: ["Everything in Brand Protection", "Job Scams", "Cyber Evil-Twin Sites"],
      takedowns: [
        "ALL USE CASES (45+)",
        "150 takedowns per annum",
        "Support via platform and email",
      ],
    },
    {
      name: "enterprise",
      title: "Enterprise",
      annual_price: 67500,
      user_count: "Unlimited",
      scope: "Unlimited Domain/Brand Unlimited Executive",
      detection: ["ALL USE CASES (45+)", "Entire Surface Deep and Dark web analysis"],
      takedowns: ["UNLIMITED", "Support via platform and email and phone"],
    },
  ]);
  const [selectedPlan, setSelectedPlan] = React.useState(
    takedownCount > 0 ? "" : currentPlan ? currentPlan : models[0].name
  );
  const [customQuote, setCustomQuote] = React.useState(false);
  const onChangePlan = (plan: string) => {
    setSelectedPlan((p) => (p === plan ? "" : plan));
  };
  const onCloseModel = () => {
    setCustomQuote(false);
  };
  const onSuccess = () => {
    onCloseModel();
    setSelectedPlan("");
    onNext();
  };
  const moveForward = () => {
    onNext();
  };
  useDeepCompareEffect(() => {
    onSelectPlan(models.filter((f) => f.name === selectedPlan)[0]);
  }, [models, selectedPlan]);
  useEffect(() => {
    if (takedownCount > 0) {
      setSelectedPlan("");
    }
  }, [takedownCount]);
  return (
    <Fragment>
      <Divider />
      <Box padding={"8px 16px"}>
        <DrpTakedownPlans
          drpPlan={selectedPlan}
          takedownCount={takedownCount}
          onChangeTakedownCount={onChangeTakedownCount}
        />
        {models.map((x) => (
          <Box key={x.name}>
            <Stack
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                  <Box>
                    <Checkbox
                      inputProps={{ "aria-label": `${x.name}-checkbox` }}
                      checked={selectedPlan === x.name}
                      color="primary"
                      onChange={() => onChangePlan(x.name)}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      fontSize={"16px"}
                      fontWeight={600}
                      color={x.name === selectedPlan ? "primary" : "secondary"}
                    >
                      {x.title}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  fontSize={"10px"}
                  fontWeight={600}
                  color={"textSecondary"}
                >
                  STARTS AT
                </Typography>
                <Typography variant="h6" fontSize={"14px"}>
                  <strong>
                    {`$${x.annual_price.toLocaleString()}`}
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      fontWeight={600}
                      fontSize={"10px"}
                    >
                      /yr
                    </Typography>
                  </strong>
                </Typography>
              </Box>
            </Stack>
            <Box marginLeft={"42px"}>
              <Typography variant="subtitle1" fontSize={"14px"} fontWeight={500} gutterBottom>
                {x.scope}
              </Typography>
              <List dense disablePadding>
                <ListItem disableGutters disablePadding>
                  <ListItemIcon style={{ minWidth: "30px" }}>
                    <Icon color="primary" style={{ fontSize: "20px" }}>
                      verified
                    </Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        color={"primary"}
                        fontSize={"12px"}
                        fontWeight={600}
                      >
                        {`${x.user_count} users`}
                      </Typography>
                    }
                  />
                </ListItem>
                {x.detection.map((kp) => (
                  <ListItem key={kp} disableGutters disablePadding>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <Icon style={{ fontSize: "20px" }}>verified</Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                          {kp}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
                {x.takedowns.map((kp) => (
                  <ListItem key={kp} disableGutters disablePadding>
                    <ListItemIcon style={{ minWidth: "30px" }}>
                      <Icon style={{ fontSize: "20px" }}>verified</Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                          {kp}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        ))}
      </Box>
      <Divider />
      <Box padding={"16px"}>
        <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"space-between"}>
          <Typography
            variant="subtitle1"
            fontSize={"14px"}
            fontWeight={500}
            color={"textSecondary"}
          >
            Need a custom quote?
            <Button
              variant="text"
              onClick={() => {
                setCustomQuote(true);
                setSelectedPlan("");
              }}
            >
              <Typography
                variant="inherit"
                component={"span"}
                fontWeight={600}
                textTransform={"capitalize"}
                fontSize={"14px"}
              >
                Contact Us
              </Typography>
            </Button>
          </Typography>
          <Button variant="contained" color="primary" onClick={moveForward}>
            <Typography fontWeight={600} textTransform={"capitalize"} fontSize={"14px"}>
              Continue
            </Typography>
          </Button>
        </Stack>
      </Box>
      {customQuote ? (
        <CustomSalesQuotation
          platform={"Digital Risk Protection"}
          onClose={onCloseModel}
          onSuccess={onSuccess}
        />
      ) : null}
    </Fragment>
  );
};
