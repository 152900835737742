import { Box, Typography, RadioGroup, Grid, FormControlLabel, Radio } from "@mui/material";
import _ from "lodash";
import React, { useEffect, Fragment } from "react";
import { useBehaviourSubject } from "../../hooks/use-observable";
import { LogUserActivity } from "../../services/query.service";
import { user$ } from "../../state/user";
import { UserPreferences } from "../../util/user.preferences";

interface IPreBuildThemes {
  theme: string;
}

export const PreBuildThemes: React.FC<IPreBuildThemes> = ({ theme }) => {
  const user = useBehaviourSubject(user$);
  const [themeOptions] = React.useState<{ label: string; value: string }[]>([
    { label: "Dark", value: "default-theme" },
    { label: "Light", value: "default-light" },
    { label: "Curiosity", value: "curiosity" },
  ]);
  const [selectedTheme, setSelectedTheme] = React.useState<string>("");
  useEffect(() => {
    if (selectedTheme && user) {
      LogUserActivity([
        {
          doc_id: selectedTheme,
          doc_index: "USER_PREFERENCES",
          doc_subject: "THEME",
          user_email: user.email,
          user_name: user.name,
          user_organization: user.organization,
          remarks: `Switched to ${_.startCase(selectedTheme)} theme`,
        },
      ]);
    }
  }, [selectedTheme, user]);
  return (
    <Fragment>
      <Box className="app-settings-item hv-themes-wrapper">
        <Typography variant="h6" color="primary" className="title">
          Themes
        </Typography>
        <Box className="contents">
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={theme ? theme : "default-theme"}
            onChange={(ev, val) => {
              setSelectedTheme(val);
              UserPreferences.setLocal(`${process.env.REACT_APP_SSO_CLIENT_ID}.theme`, val);
            }}
          >
            {themeOptions.map((t) => (
              <Grid container spacing={2} alignItems="center" key={t.label}>
                <Grid item>
                  <FormControlLabel
                    key={t.value}
                    value={t.value}
                    control={<Radio color="primary" size="small" />}
                    label={
                      <Typography
                        variant="subtitle1"
                        component="p"
                        className="theme-title"
                        whiteSpace={"nowrap"}
                      >
                        {t.label}
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item>
                  <Box className={`theme-preview-box theme-${t.value}`}>
                    <Box className="color-swatch-wrapper">
                      <Box className="primary-color color-swatch"></Box>
                      <Box className="secondary-color color-swatch"></Box>
                      <Box className="bg-color color-swatch"></Box>
                      <Box className="paper-color color-swatch"></Box>
                      <Box className="text-primary-color color-swatch"></Box>
                      <Box className="text-secondary-color color-swatch"></Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </RadioGroup>
        </Box>
      </Box>
    </Fragment>
  );
};
