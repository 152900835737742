import { Box, Theme, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import useDeepCompareEffect from "use-deep-compare-effect";

export const DetectionOvertimeChart: React.FC<{
  data: any[];
  seriesName: string;
  axisTitle: string;
}> = ({ data, axisTitle, seriesName }) => {
  const theme = useTheme<Theme>();
  const [seriesData, setSeriesData] = React.useState(data);

  useDeepCompareEffect(() => {
    setSeriesData(data);
  }, [data]);
  return (
    <Fragment>
      <Box>
        <ResponsiveContainer height={300}>
          <Chart
            type="bar"
            series={[{ name: seriesName, data: seriesData.map((x) => x.doc_count) }]}
            options={{
              chart: {
                toolbar: {
                  show: false,
                },
                type: "bar",
                stacked: true,
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 3,
                colors: [theme.palette.secondary.main],
                strokeColors: [theme.palette.background.paper],
                strokeWidth: 2,
              },
              xaxis: {
                type: "datetime",
                axisTicks: {
                  show: false,
                },
                axisBorder: {
                  show: false,
                },
                title: {
                  text: "Date",
                  style: {
                    color: theme.palette.secondary.main,
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                  },
                },
                labels: {
                  style: {
                    colors: theme.palette.text.secondary,
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                  },
                },
                categories: seriesData.map((i) => i.timestamp),
              },
              yaxis: {
                title: {
                  text: axisTitle,
                  style: {
                    color: theme.palette.secondary.main,
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                  },
                },
                labels: {
                  style: {
                    colors: theme.palette.text.secondary,
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                  },
                  formatter: (val) => val.toLocaleString(),
                },
              },
              grid: {
                show: false,
              },
              legend: {
                fontSize: "12px",
                fontFamily: "Montserrat",
                labels: {
                  colors: theme.palette.text.primary,
                },
              },
              tooltip: {
                theme: "false",
                style: {
                  fontSize: "12px",
                  fontFamily: "Montserrat",
                },
              },
              fill: {
                opacity: 1,
              },
            }}
          />
        </ResponsiveContainer>
      </Box>
    </Fragment>
  );
};
