import React, { Fragment } from "react";

import { Box, Theme, useTheme } from "@mui/material";
import brandLogoDark from "../assets/brand_logo_dark.svg";
import brandLogoLight from "../assets/brand_logo_light.svg";
import { Link } from "react-router-dom";
import cbsLogo from "../assets/product_icon/CTM360.svg";
import { useBehaviourSubject } from "../hooks/use-observable";
import { sidebar$ } from "../state/sidebar";

export const PlatformLogo: React.FC = () => {
  const theme = useTheme<Theme>();
  const isOpen = useBehaviourSubject(sidebar$);

  return (
    <Fragment>
      <Box width={"100%"} textAlign={"center"}>
        <Link to="https://ctm360.com/" style={{ textDecoration: "none", textAlign: "center" }}>
          <Box
            lineHeight={0}
            width={isOpen ? "110px" : "40px"}
            height={50}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            margin={"auto"}
          >
            <img
              src={
                isOpen ? (theme.palette.mode === "dark" ? brandLogoDark : brandLogoLight) : cbsLogo
              }
              alt="ctm360-logo"
              width={"100%"}
              height={"auto"}
            />
          </Box>
        </Link>
      </Box>
    </Fragment>
  );
};
