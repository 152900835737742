import { Box, Popover } from "@mui/material";
import isEmpty from "lodash/isEmpty";

import React from "react";
import { Fragment } from "react";
import { ButtonFilterEmpty } from "./button.filter.empty";
import { ButtonFilterOptions } from "./button.filter.options";
import { ButtonFilterSelected } from "./button.filter.selected";
interface IAppButtonFilter {
  loading: boolean;
  name: string;
  title: string;
  options: string[];
  selection: string[];
  onChangeOption: (options: string[]) => void;
  disableSearch?: boolean;
}
export const AppButtonFilter: React.FC<IAppButtonFilter> = ({
  name,
  title,
  options,
  selection,
  onChangeOption,
  disableSearch,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const onClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <Box className="button-filter-container">
        {isEmpty(selection) ? (
          <Fragment>
            <ButtonFilterEmpty
              name={name}
              title={title}
              onSelect={(e) => setAnchorEl(e.currentTarget)}
            />
          </Fragment>
        ) : (
          <Fragment>
            <ButtonFilterSelected
              name={name}
              title={title}
              selection={selection}
              onSelect={(e) => setAnchorEl(e.currentTarget)}
              onDelete={onChangeOption}
            />
          </Fragment>
        )}
      </Box>
      <Popover
        id={"incident_type_options"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ButtonFilterOptions
          options={options}
          loading={false}
          currentSelection={selection}
          disableSearch={disableSearch}
          onChangeOption={onChangeOption}
          onClose={onClose}
        />
      </Popover>
    </Fragment>
  );
};
