import {
  Box,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import StyledTab from "../../../components/styled.tab";
import { FetchConfigurationKeywords } from "../../../services/cbs.service";
import { toast } from "../../../state/snackbar";
import { get, min, range } from "lodash";
import { IESDoc } from "../../../App.interface";
import { UUIDGenerator } from "../../../util/uuid";
import { AppMasterTable } from "../../views/app.table";
import _ from "lodash";
import { BrandLogoThumbnail } from "./brand.logo.thumbnail";
import { EmptyTableView } from "../../views/EmptyTable";
const fetchKeywords = (params: string): { name: string; title: string; locked: boolean }[] => {
  switch (params) {
    case "social_media_fraud":
      return [];
    case "mobile_apps":
      return [];
    case "domain_protection":
      return [
        {
          name: "sensitive_keyword",
          title: "Sensitive Keyword",
          locked: false,
        },
        {
          name: "regex",
          title: "Regex",
          locked: false,
        },
        {
          name: "regex_whitelist",
          title: "Regex Whitelist",
          locked: true,
        },
        {
          name: "sensitive_domains",
          title: "Sensitive Domains",
          locked: true,
        },
      ];
    case "phishing":
      return [];
    case "card_leaks":
      return [
        {
          name: "bin_numbers",
          title: "BIN Numbers",
          locked: false,
        },
      ];
    case "credential_leaks":
      return [];
    default:
      return [];
  }
};
export const EntityConfigurationList: React.FC<{ name: string; title: string }> = ({
  title,
  name,
}) => {
  const [selectedModule, setSelectedModule] = React.useState(name);
  const [allowedKeywords, setAllowedKeywords] = React.useState(fetchKeywords(name));
  const [selectedTab, setSelectedTab] = React.useState(allowedKeywords[0]?.name || "");
  const [loading, setLoading] = React.useState(true);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("desc");
  const [sortField, setSortField] = React.useState<string>("@timestamp");
  const [totalDocs, setTotalDocs] = React.useState<number>(0);
  const [updateID, setUpdateID] = React.useState(UUIDGenerator());
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [searchVal, setSearchVal] = React.useState<string>("");
  const onChangeSearchQuery = (val: string) => setSearchVal(val);
  const onSetSearchQuery = () => {
    setSearchQuery(searchVal.trim().replace(/\/(?=[^/]*$)/, "/"));
    setDocs([]);
  };
  const fetchDocs = () => {
    if (!selectedModule || !selectedTab) {
      return;
    }
    setLoading(true);
    FetchConfigurationKeywords(
      selectedModule,
      selectedTab,
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder
    )
      .then((response) => {
        setDocs(response.data);
        setTotalDocs(get(response, "metadata.total.value") as unknown as number);
      })
      .catch(() => {
        toast("Error in fetching keywords", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setSelectedModule(name);
    setAllowedKeywords([]);
    setSelectedTab("");
  }, [name]);
  useEffect(() => {
    const keywords = fetchKeywords(selectedModule);
    setAllowedKeywords(keywords);
    setSelectedTab(keywords[0]?.name || "");
  }, [selectedModule]);
  useEffect(fetchDocs, [
    pageIndex,
    pageSize,
    searchQuery,
    selectedModule,
    selectedTab,
    sortField,
    sortOrder,
    updateID,
  ]);
  return (
    <Fragment>
      <Box className="cbs-module-container">
        <Box className="cbs-module-header" marginBottom={"16px !important"}>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Box>
              <Typography
                variant="h4"
                fontSize={"28px"}
                fontWeight={600}
                color="secondary"
                gutterBottom
              >
                {title}
              </Typography>
              <Typography variant="body2" component="p" color="textPrimary" fontSize={"13px"}>
                {`Configure the Identifiers used to detect for ${title}`}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Box marginBottom={5}>
            <Tabs
              textColor="primary"
              value={selectedTab}
              onChange={(e, v) => setSelectedTab(v)}
              aria-label="Vertical tabs example"
            >
              {allowedKeywords.map((modules) => (
                <StyledTab
                  key={modules.name}
                  disabled={modules.locked}
                  icon={
                    modules.locked ? (
                      <Icon sx={{ fontSize: "14px !important" }}>lock</Icon>
                    ) : undefined
                  }
                  iconPosition={modules.locked ? "end" : undefined}
                  label={
                    <Typography
                      variant="h6"
                      fontSize={"12px"}
                      fontWeight={600}
                      textTransform={"none"}
                      textAlign={"left"}
                    >
                      {modules.title}
                    </Typography>
                  }
                  value={modules.name}
                />
              ))}
            </Tabs>
          </Box>
          <Box className="cbs-module-actions">
            <Grid
              spacing={2}
              container
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                <Grid
                  spacing={1}
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  wrap="nowrap"
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Typography
                      variant="subtitle1"
                      className="button-filter-label"
                      color="textPrimary"
                      height={"30px"}
                    />
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        id="outlined-basic"
                        label={"Search..."}
                        value={searchVal}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Icon color="action">search</Icon>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        onKeyDown={(ev) => (ev.key === "Enter" ? onSetSearchQuery() : null)}
                        onChange={(e) => onChangeSearchQuery(e.target.value)}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  className="button-filter-label"
                  color="textPrimary"
                  height={"30px"}
                />
                <Grid
                  spacing={2}
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  wrap="nowrap"
                >
                  <Grid item>
                    <Tooltip title="Refresh Data">
                      <IconButton
                        color="secondary"
                        size="small"
                        onClick={() => setUpdateID(UUIDGenerator())}
                      >
                        <Icon>refresh</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Paper>
            {_.includes(["brand_logos", "logos"], selectedTab) ? (
              <>
                <Box padding={2} marginTop={2}>
                  {loading ? (
                    <Grid container spacing={2} alignItems="center">
                      {range(10).map((i) => (
                        <Grid item key={i.toString()}>
                          <Paper
                            className="brand-logo-wrapper"
                            variant="outlined"
                            sx={{
                              padding: "8px",
                              width: "150px",
                              height: "150px",
                              position: "relative",
                            }}
                          >
                            <Box
                              className="brand-logo-thumbnail-wrapper"
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"center"}
                              height={"100%"}
                              overflow={"hidden"}
                            >
                              <CircularProgress />
                            </Box>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  ) : docs.length ? (
                    <Grid container spacing={2} alignItems="center">
                      {docs.map((x) => (
                        <Grid item key={x._id}>
                          <BrandLogoThumbnail
                            doc={x}
                            name={selectedModule}
                            selectedTab={selectedTab}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <EmptyTableView />
                  )}
                </Box>
              </>
            ) : (
              <AppMasterTable
                loading={loading}
                dataSource={""}
                name={""}
                title={""}
                colDefs={
                  selectedTab === "regex"
                    ? [
                        {
                          masterColumnName: "@timestamp",
                          masterColumnTitle: "Created on",
                          renderType: "date",
                        },
                        {
                          masterColumnName: "original_keyword",
                          masterColumnTitle: "Sensitive Keyword",
                          renderType: "text",
                        },
                        {
                          masterColumnName: "regex",
                          masterColumnTitle: "Regex Pattern",
                          renderType: "text",
                        },
                      ]
                    : selectedTab === "webpages"
                    ? [
                        {
                          masterColumnName: "@timestamp",
                          masterColumnTitle: "Created on",
                          renderType: "date",
                        },
                        {
                          masterColumnName: "website",
                          masterColumnTitle: "Website",
                          renderType: "text",
                        },
                      ]
                    : selectedTab === "bin_numbers"
                    ? [
                        {
                          masterColumnName: "bank_name",
                          masterColumnTitle: "Bank Name",
                          renderType: "text",
                        },
                        {
                          masterColumnName: "bin",
                          masterColumnTitle: "BIN Number",
                          renderType: "text",
                        },
                        {
                          masterColumnName: "vendor",
                          masterColumnTitle: "Vendor",
                          renderType: "text",
                        },
                        {
                          masterColumnName: "card_type",
                          masterColumnTitle: "Card Type",
                          renderType: "text",
                        },
                        {
                          masterColumnName: "card_level",
                          masterColumnTitle: "Card Level",
                          renderType: "text",
                        },
                      ]
                    : selectedTab === "domains"
                    ? [
                        {
                          masterColumnName: "@timestamp",
                          masterColumnTitle: "Created on",
                          renderType: "date",
                        },
                        {
                          masterColumnName: "domain",
                          masterColumnTitle: "Domain",
                          renderType: "url",
                        },
                        {
                          masterColumnName: "tags.domain",
                          masterColumnTitle: "Tags",
                          renderType: "tags",
                        },
                      ]
                    : selectedTab === "mobile_apps"
                    ? [
                        {
                          masterColumnName: "app_name",
                          masterColumnTitle: "App Name",
                          renderType: "text",
                        },
                        {
                          masterColumnName: "url",
                          masterColumnTitle: "App URL",
                          renderType: "url",
                        },
                        {
                          masterColumnName: "platform",
                          masterColumnTitle: "Platform",
                          renderType: "text",
                        },
                        {
                          masterColumnName: "store",
                          masterColumnTitle: "Store",
                          renderType: "text",
                        },
                        {
                          masterColumnName: "publisher",
                          masterColumnTitle: "Publisher",
                          renderType: "text",
                        },
                        {
                          masterColumnName: "app_version",
                          masterColumnTitle: "Version",
                          renderType: "text",
                        },
                      ]
                    : [
                        {
                          masterColumnName: "@timestamp",
                          masterColumnTitle: "Created on",
                          renderType: "date",
                        },
                        {
                          masterColumnName: "keyword",
                          masterColumnTitle: "Keyword",
                          renderType: "text",
                        },
                      ]
                }
                docs={docs}
                checkedDocs={[]}
                totalDocs={min([totalDocs, 500]) || 500}
                pageIndex={pageIndex}
                pageSize={pageSize}
                sortField={sortField}
                sortOrder={sortOrder}
                enablePagination={true}
                enableCheckBox={false}
                enableSelection={false}
                enableBrandColum={false}
                onChangeSortField={setSortField}
                onChangeSortOrder={setSortOrder}
                onChangePageIndex={setPageIndex}
                onChangePageSize={setPageSize}
                onChangeCheckedDocs={() => null}
                onChangeSelectedDoc={() => null}
              />
            )}
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
};
