import _ from "lodash";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Brand } from "../App.interface";

import { $keyCloak } from "../state/keycloak";
import { findParentName } from "../util/find.parent";
import { SessionStorageBehaviorSubject } from "../util/session-storage.behavior.subject";
import { fetchAllOrganizations } from "./query.service";
import { toast } from "../state/snackbar";

export const BrandService = new (class BrandServiceMonolith {
  protected brands$ = new BehaviorSubject<{ [memberId: string]: Brand }>({});
  public selectedMemberIds$ = new SessionStorageBehaviorSubject<number[]>("brand_selection", []);
  public allBrands$: Observable<Brand[]>;
  public clientBrands$: Observable<Brand[]>;
  public potentialClientsBrands$: Observable<Brand[]>;
  public selectedBrands$: Observable<Brand[]>;
  public loadingBrands$ = new BehaviorSubject<boolean>(false);
  constructor() {
    $keyCloak.subscribe((token) => {
      if (token) {
        this.loadingBrands$.next(true);
        fetchAllOrganizations()
          .then(({ data: response }) => {
            this.brands$.next(
              response
                .map(({ member_id, brand, parent_organization, potential_client, status }) => ({
                  [member_id]: {
                    brandName: brand,
                    memberId: member_id,
                    isPotential:
                      potential_client === "1" ||
                      potential_client === 1 ||
                      potential_client === true
                        ? true
                        : false,
                    parent_organization,
                    parent_name: findParentName(response, parent_organization),
                    status,
                  },
                }))
                .reduce((acc, cur) => ({ ...acc, ...cur }), {})
            );
          })
          .catch(() => {
            //
            toast("Error loading organizations", "error");
          })
          .finally(() => {
            this.loadingBrands$.next(false);
          });
      }
    });

    this.allBrands$ = this.brands$.pipe(
      map<{ [key: string]: Brand }, Brand[]>((brandsObject) =>
        _.sortBy(Object.values(brandsObject), ({ memberId }) => memberId)
      )
    );
    this.clientBrands$ = this.allBrands$.pipe(
      map((brands) => brands.filter(({ isPotential }) => !isPotential))
    );
    this.potentialClientsBrands$ = this.allBrands$.pipe(
      map((brands) => brands.filter(({ isPotential }) => isPotential))
    );
    this.selectedBrands$ = combineLatest([this.selectedMemberIds$, this.brands$]).pipe(
      map(
        ([selectedMemberIds, allBrands]) =>
          selectedMemberIds.map((id) => allBrands[id]).filter((f) => f) || []
      )
    ) as Observable<Brand[]>;
  }
  public setAllBrands(brands: any[]) {
    this.brands$.next(
      brands
        .map(({ member_id, brand, potential_client, parent_organization, status }) => ({
          [member_id]: {
            brandName: brand,
            memberId: member_id,
            isPotential:
              potential_client === "1" || potential_client === 1 || potential_client === true
                ? true
                : false,
            parent_organization,
            parent_name: findParentName(brands, parent_organization),
            status,
          },
        }))
        .reduce((acc, cur) => ({ ...acc, ...cur }), {})
    );
    this.allBrands$ = this.brands$.pipe(
      map<{ [key: string]: Brand }, Brand[]>((brandsObject) =>
        _.sortBy(Object.values(brandsObject), ({ memberId }) => memberId)
      )
    );
    this.selectedBrands$ = combineLatest([this.selectedMemberIds$, this.brands$]).pipe(
      map(
        ([selectedMemberIds, allBrands]) =>
          selectedMemberIds.map((id) => allBrands[id]).filter((f) => f) || []
      )
    ) as Observable<Brand[]>;
  }
  public setSelectedBrands(brands: Brand[]) {
    this.selectedMemberIds$.next(
      _.sortBy(brands, ({ memberId }) => memberId).map(({ memberId }) => memberId)
    );
  }

  public getSelectedBrands() {
    return this.selectedMemberIds$.getValue().map((memberId) => this.brands$.getValue()[memberId]);
  }
})();
