import {
  Box,
  Button,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import RocketLogo from "../../assets/images/rocket.png";
import _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useBehaviourSubject } from "../../hooks/use-observable";
import { selectedPlatform$ } from "../../state/platformselection";
import Carousel from "nuka-carousel";

export const UpgradeInfo: React.FC = () => {
  const [selectedApp, setSelectedApp] = React.useState(
    useBehaviourSubject(selectedPlatform$) || "hacker_view"
  );
  const [freemiumPoints] = React.useState([
    {
      platform: "hacker_view",
      name: "easm",
      product: "HackerView",
      title: "External Attack Surface Management",
      features: [
        "Identification and comprehensive mapping of your External Attack Surface",
        "A detailed inventory of your digital assets",
        "Issue details along with remediation guidelines",
        "Increased data refresh frequency",
        "Digital Risk Profile and Score Breakdown",
        "Third Party Risk Monitoring",
        "Global Benchmarking amongst industry peers",
        "Inbound Cloud Connectors",
      ],
    },
    {
      platform: "cbs",
      name: "drp",
      product: "Cyber Blindspot",
      title: "Digital Risk Protection",
      features: [
        "Managed Detection across the Surface, Deep, and Dark Web covering 40+ use cases.",
        "Track incident details and their status",
        "Fully Managed Takedowns",
        "Ad-Hoc Reporting",
        "Integrations with SIEM/SOAR Solutions",
        "Analysis and Investigations",
        "24/7/365 Support",
        "Cyber News, Alerts, Advisories, Global Threat Actor Claims and more",
      ],
    },
    {
      platform: "dmarc",
      name: "email_security",
      product: "DMARC360",
      title: "Intelligent Email Security",
      features: [
        "Monitor email-sending domains across your Brands",
        "Monitor all emailing sources",
        "Access remediation details of your DMARC issues",
        "Unlimited data visibility across time",
        "Full DMARC reporting capabilities",
        "Global and 3rd Party Risk Monitoring",
      ],
    },
  ]);
  const [selectedPoints, setSelectedPoints] = React.useState(0);
  useDeepCompareEffect(() => {
    setSelectedPoints(_.findIndex(freemiumPoints, (i) => i.platform === selectedApp) || 0);
  }, [freemiumPoints, selectedApp]);

  useEffect(() => {
    const sub = selectedPlatform$.subscribe((res) => {
      setSelectedApp(res);
    });
    return () => sub.unsubscribe();
  }, []);
  return (
    <Fragment>
      <Box padding={2} textAlign={"center"} maxWidth={500} margin={"auto"}>
        <Typography
          variant="h5"
          color={"secondary"}
          fontWeight={600}
          // textTransform={"capitalize"}
          marginBottom={1.5}
        >
          Make yourself a harder target
        </Typography>
        <Typography variant="subtitle1" fontSize={"13px"} fontWeight={600} textAlign={"left"}>
          CTM360 has much more to offer, by upgrading your plan you will have access to:
        </Typography>

        <Box width={"150px"} margin={"auto"} marginTop={4} marginBottom={4}>
          <img src={RocketLogo} alt="freemium-logo" width={"100%"} />
        </Box>
        <Box marginBottom={3} maxHeight={"250px"} overflow={"auto"}>
          <Carousel
            slideIndex={selectedPoints}
            renderBottomCenterControls={() => null}
            renderCenterLeftControls={({ previousSlide, previousDisabled }) => (
              <IconButton onClick={previousSlide} disabled={previousDisabled}>
                <Icon sx={{ fontSize: "40px !important" }}>arrow_left</Icon>
              </IconButton>
            )}
            renderCenterRightControls={({ nextSlide, nextDisabled }) => (
              <IconButton onClick={nextSlide} disabled={nextDisabled}>
                <Icon sx={{ fontSize: "40px !important" }}>arrow_right</Icon>
              </IconButton>
            )}
          >
            {freemiumPoints.map((x) => (
              <Box key={x.name} marginBottom={1} padding={"0px 0px 0px 40px"}>
                <Typography
                  variant="subtitle1"
                  fontSize={"13px"}
                  fontWeight={600}
                  color={"secondary"}
                  marginBottom={1}
                >
                  {`${x.title} (${x.product})`}
                </Typography>
                <Box paddingLeft={1}>
                  <List dense disablePadding>
                    {x.features.map((kp) => (
                      <ListItem key={kp} disableGutters disablePadding>
                        <ListItemIcon style={{ minWidth: "30px" }}>
                          <Icon style={{ fontSize: "20px" }}>verified</Icon>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600}>
                              {kp}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Box>
        <Box textAlign={"center"} margin={"16px 0"}>
          <Button
            variant="contained"
            component={Link}
            href={`https://${
              process.env.NODE_ENV === "development" ? "cyno.edxdev.link" : "start.ctm360.com"
            }/pricing`}
            target="_blank"
          >
            <Typography variant="h6" textTransform={"uppercase"} fontWeight={600} fontSize={"12px"}>
              pricing plan
            </Typography>
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
};
