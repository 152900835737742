import { Box, Divider, Grid, Paper, Skeleton, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import ReactApexChart from "react-apexcharts";
import useDeepCompareEffect from "use-deep-compare-effect";
import ApexCharts from "apexcharts";
// import "../styles.scss";

import moment from "moment";
import { FetchAnalyticsCategoriesCompliance } from "../../../../../services/dmarc.service";
import { useBehaviourSubject } from "../../../../../hooks/use-observable";
import { BrandService } from "../../../../../services/brand.service";
import { toast } from "../../../../../state/snackbar";
import { KFormatter } from "../../../../../util/k_formatter";
import { ResponsiveContainer } from "recharts";

interface Type {
  category: string;
  sourcesLoading: boolean;
}

export const DmarcAggregateCategories: React.FC<Type> = ({ category, sourcesLoading }) => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(true);
  const [categories, setCategories] = React.useState<string[]>([]);
  const [histogram, setHistogram] = React.useState<ApexAxisChartSeries>([]);
  const selectedMemberIds = useBehaviourSubject<number[]>(BrandService.selectedMemberIds$) || [];
  const ID = "EmailVolumeGraph_new";

  const fetchHistogram = () => {
    setLoading(true);

    FetchAnalyticsCategoriesCompliance(category)
      .then((response) => {
        const compliant: number[] = [];
        const forwarded: number[] = [];
        const unauthenticated: number[] = [];

        response.response.forEach((doc: any) => {
          const categories = doc.CATEGORIES.buckets;
          let compliantValue = 0;
          let forwardedValue = 0;
          let unauthenticatedValue = 0;

          categories.forEach((catg: any) => {
            const count = _.has(catg, "COUNT.value") ? catg.COUNT.value : 0;

            if (catg.key == "compliant") {
              compliantValue = count;
            }
            if (catg.key == "forwarded") {
              forwardedValue = count;
            }
            if (catg.key == "unauthenticated") {
              unauthenticatedValue += count;
            }
            if (catg.key == "non-compliant") {
              unauthenticatedValue += count;
            }
          });

          compliant.push(compliantValue);
          forwarded.push(forwardedValue);
          unauthenticated.push(unauthenticatedValue);
        });

        if (category == "") {
          setHistogram([
            {
              name: "Compliant",
              data: compliant,
            },

            {
              name: "Forwarded",
              data: forwarded,
            },
            {
              name: "Unauthenticated",
              data: unauthenticated,
            },
          ]);
        } else if (category == "forwarded") {
          setHistogram([
            {
              name: "Forwarded",
              data: forwarded,
            },
          ]);
        } else if (category == "unauthenticated") {
          setHistogram([
            {
              name: "Unauthenticated",
              data: unauthenticated,
            },
          ]);
        }

        setCategories(
          response.response.map((e: any) => moment(parseInt(e?.key)).format("L")) || []
        );
      })
      .catch(() => {
        toast("Error fetching DMAR compliance", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      id: ID,
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          customIcons: [],
        },
      },
      type: "area",
      stacked: false,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 2,
        animateGradually: {
          enabled: true,
          delay: 1,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    // theme: {
    //   palette: "palette3",
    // },
    fill: {
      opacity: 0.7,
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.8,
        opacityFrom: 0.2,
        opacityTo: 0.5,
        inverseColors: true,
        stops: [0, 100],
      },
    },
    colors:
      category == "unauthenticated"
        ? ["#D4526E"]
        : category == "forwarded"
        ? ["#13D8AA"]
        : ["#33B2DF", "#13D8AA", "#D4526E"],

    stroke: {
      curve: "smooth",
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: categories,
      type: "datetime",
      labels: {
        style: {
          fontFamily: "Montserrat",
          fontSize: "10px",
          colors: theme.palette.text.secondary,
        },
      },
      axisBorder: {
        show: true,
        color: theme.palette.background.default,
      },
      axisTicks: {
        show: true,
        color: theme.palette.background.default,
      },
    },
    yaxis: {
      title: {
        style: {
          color: theme.palette.text.primary,
        },
      },
      axisBorder: {
        show: true,
        color: theme.palette.background.default,
      },
      axisTicks: {
        show: true,
        color: theme.palette.background.default,
      },
      labels: {
        style: {
          fontFamily: "Montserrat",
          fontSize: "10px",
          colors: theme.palette.text.secondary,
        },
        formatter: (e) => KFormatter(e),
      },
    },
    grid: {
      show: false,
      row: {
        colors: [theme.palette.background.paper, theme.palette.background.default],
        opacity: 1,
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      fillSeriesColor: true,
      x: {
        show: false,
      },
    },

    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "10px",
      offsetY: 10,
      labels: {
        colors: theme.palette.text.secondary,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "10px",
            labels: {
              colors: theme.palette.text.secondary,
            },
            customLegendItems: [],
          },
        },
      },
    ],
  };

  useDeepCompareEffect(fetchHistogram, [category, selectedMemberIds]);
  return (
    <Fragment>
      <Paper style={{ height: "100%" }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item sx={{ paddingX: "8px" }}>
            <Grid container alignItems="center" style={{ margin: "10px 12px" }}>
              <Grid item xs={10}>
                <Typography color="primary" sx={{ fontSize: 13, fontWeight: 600 }}>
                  Sending Stats
                </Typography>
                <Typography color="textSecondary" sx={{ fontSize: 11 }}>
                  Your daily DMARC compliance statistics
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            {" "}
            <Divider />
          </Grid>

          <Grid item sx={{ padding: "8px" }}>
            {loading || sourcesLoading ? (
              <Skeleton variant="rectangular" height={380} />
            ) : (
              <Box
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",
                }}
              >
                <ResponsiveContainer height={345}>
                  <ReactApexChart options={chartOptions} series={histogram} type="area" />
                </ResponsiveContainer>
              </Box>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
