import { Box, CircularProgress, Grid, Paper, Typography, Icon, Tooltip } from "@mui/material";
import { Skeleton } from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { FetchOrganizationAssets } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";

export const HackerViewAssets: React.FC<{ updateId: string }> = ({ updateId }) => {
  const [loading, setLoading] = React.useState<string[]>([]);
  const [assets, setAssets] = React.useState<
    {
      title: string;
      count: number;
      latest_count: number;
      name: string;
      icon: string;
    }[]
  >([
    {
      title: "Domain",
      count: 0,
      icon: "domain",
      latest_count: 0,
      name: "domain",
    },
    {
      title: "Hosts",
      count: 0,
      icon: "dns",
      latest_count: 0,
      name: "host",
    },
    {
      title: "IPs",
      count: 0,
      icon: "language",
      latest_count: 0,
      name: "ips",
    },
    {
      title: "Websites",
      count: 0,
      icon: "monitor",
      latest_count: 0,
      name: "website",
    },
    {
      title: "Open Ports",
      count: 0,
      icon: "wifi_tethering_error_rounded",
      latest_count: 0,
      name: "open_ports",
    },
    {
      title: "Technologies",
      count: 0,
      icon: "developer_mode",
      latest_count: 0,
      name: "technologies",
    },
  ]);
  const fetchDomain = () => {
    setLoading((arr) => [...arr, "Domain"]);
    FetchOrganizationAssets()
      .then((response) => {
        _.forEach(response, (i) => {
          setAssets((arr) =>
            arr.map((x) =>
              x.name === i.name ? { ...x, count: i.count, latest_count: i.latest_hits } : x
            )
          );
        });
      })
      .catch(() => {
        toast("Error loading data", "error");
      })
      .finally(() => {
        setLoading((arr) => arr.filter((f) => !_.includes(["Domain"], f)));
      });
  };

  useEffect(() => {
    setAssets((arr) => arr.map((x) => ({ ...x, count: 0 })));
    fetchDomain();
  }, [updateId]);
  return (
    <Fragment>
      <Grid container spacing={2}>
        {assets.map((d) => (
          <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={_.startCase(d.title)}>
            <Paper className="dashboard-asset-card">
              <Box className="data-wrapper">
                <Box className="title-wrapper">
                  <Typography variant="caption" color="textSecondary">
                    {d.title}
                  </Typography>
                </Box>
                <Typography variant="h6" fontSize={19} color="textPrimary">
                  {_.includes(loading, "Domain") ? (
                    <CircularProgress size={28} />
                  ) : (
                    d.count.toLocaleString()
                  )}
                </Typography>
                {_.includes(loading, "Domain") ? (
                  <Skeleton width={90} />
                ) : !_.includes(["open_ports", "technologies"], d.name) ? (
                  <Tooltip title={`${d.title} found in last 7 days`}>
                    <Box className="new-hits">
                      <Icon color="inherit" className="icon">
                        {d.latest_count > 0 ? "trending_up" : "trending_flat"}
                      </Icon>
                      <Typography
                        variant="subtitle1"
                        component="p"
                        color="inherit"
                        className="count"
                      >
                        {d.latest_count.toLocaleString()}
                      </Typography>
                      <Typography variant="subtitle1" component="span" className="timestamp">
                        {"( 7 days )"}
                      </Typography>
                    </Box>
                  </Tooltip>
                ) : null}
              </Box>
              <Box className="icon">
                <Icon color="primary" fontSize="large">
                  {d.icon}
                </Icon>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};
