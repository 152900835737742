import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Link,
  Paper,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment, useCallback, useRef, useState } from "react";
import { DataSet, Edge, Network, Node, Options } from "vis";
import _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

import { NetworkGraphLegends } from "./network.legends";
import { calculateGrade } from "../../util/score.grade.utils";
import { INetworkGraphSlug, IResult } from "../module.interface";

interface IProps {
  data: any;
  graphSlugs: INetworkGraphSlug[];
  onSelection?: (node: Node | undefined) => void;
}

export const HackerViewNetworkGraph: React.FC<IProps> = ({
  data,
  graphSlugs,
  onSelection: onSelectionRaw,
}) => {
  const theme = useTheme<Theme>();
  const [networkGraph, setNetworkGraph] = React.useState<Network>();
  const onSelection = (node: Node | undefined) => {
    if (!onSelectionRaw) return;

    if (!node) {
      onSelectionRaw(node);
    } else {
      const id = node && node.id ? node.id.toString() : "";
      onSelectionRaw({ ...node, id: id.slice(id.indexOf("-") + 1) });
    }
  };

  const networkGraphRef = useRef(null);
  const zoomIn = () => {
    if (networkGraph) {
      const currentScale = networkGraph.getScale();
      networkGraph.moveTo({ scale: currentScale + 0.1 });
    }
  };
  const zoomOut = () => {
    if (networkGraph) {
      const currentScale = networkGraph.getScale();
      if (currentScale - 0.1 > 0) {
        networkGraph.moveTo({ scale: currentScale - 0.1 });
      }
    }
  };
  const fitNetwork = () => {
    if (networkGraph) {
      networkGraph.fit();
    }
  };
  const getGroupConfig = useCallback(
    (groupId: number) => {
      const value = 4 / (groupId + 4);
      const level = groupId % graphSlugs.length;
      const groupAttr = {
        shape: graphSlugs[level].shape,
        value,
        font: {
          size: value * 20,
          background: level === 0 ? "transparent" : theme.palette.background.paper,
          color: theme.palette.text.primary,
        },
        borderWidth: 2,
      };
      return groupAttr;
    },
    [graphSlugs, theme]
  );

  const options: Options = {
    autoResize: true,
    height: "100%",
    width: "100%",
    clickToUse: false,
    physics: {
      solver: "forceAtlas2Based",
      stabilization: true,
    },
    interaction: {
      hover: true,
    },
  };
  options.groups = Array.from(Array(10))
    .map((__, group) => ({ [`group-${group}`]: getGroupConfig(group) }))
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});
  const nodes = useState(new DataSet<Node>([]))[0];
  const edges = useState(new DataSet<Edge>([]))[0];

  useDeepCompareEffect(() => {
    const net = new Network(networkGraphRef.current as any, { nodes, edges }, options);
    setNetworkGraph(net);
    net.moveTo({ scale: nodes.length < 150 ? 0.7 : 0.25 });
    net.fit();
    if (_.isFunction(onSelection))
      net.on("selectNode", ({ nodes: selection }) => onSelection(nodes.get(selection)[0]));
    net.on("hoverNode", ({ node }) => {
      const nodesInfo = nodes.get(node) as any;
      if (nodesInfo) {
        nodes.update({
          id: node,
          font: { background: "inherit" },
        });
      }
    });
    net.on("blurNode", ({ node }) => {
      nodes.update({ id: node, font: undefined });
    });
    setTimeout(() => {
      net.fit();
    }, 3000);
  }, [nodes, edges, theme]);

  useDeepCompareEffect(() => {
    const flatData: { [key: string]: string } = {};
    const nodesToAdd: Node[] = [];
    const edgesToAdd: Edge[] = [];
    const traverse = (obj: IResult, parentId = "root", level = 0) => {
      if (!_.isObject(obj)) return;
      _.forEach(obj, (val, key) => {
        const id = `${level}-${key}`;
        if (key) {
          flatData[id] = id;
          nodesToAdd.push({
            id,
            level,
            group: `group-${level}`,
            label: key,
            shadow: true,
            color: {
              background: level === 0 ? "#2196f3" : theme.score[calculateGrade(val.grade)],
              border: level === 0 ? "#2196f3" : theme.score[calculateGrade(val.grade)],
              hover: level === 0 ? "#2196f3" : theme.score[calculateGrade(val.grade)],
              highlight: level === 0 ? "#2196f3" : theme.score[calculateGrade(val.grade)],
            },
            margin: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            },
          });
          if (parentId !== "root") {
            edgesToAdd.push({ from: parentId, to: id, id: `${parentId}-${id}` });
          }
        }
        traverse(val.values, id, level + 1);
      });

      // Object.keys(obj).forEach((propName) => {
      //   const id = `${level}-${propName}`;
      //   flatData[id] = id;
      //   nodesToAdd.push({
      //     id,
      //     level,
      //     group: `group-${level}`,
      //     label: propName,
      //     shadow: true,
      //     margin: {
      //       top: 10,
      //       bottom: 10,
      //       left: 10,
      //       right: 10,
      //     },
      //   });
      // if (parentId !== "root") {
      //   edgesToAdd.push({ from: parentId, to: id, id: `${parentId}-${id}` });
      // }

      //   traverse((obj as any)[propName], id, level + 1);
      // });
    };
    traverse(data);
    nodes.update(nodesToAdd);
    edges.update(edgesToAdd);

    const nodeIdsToRemove: string[] = nodes.getIds().filter((id) => !flatData[id]) as string[];
    const edgeIdsToRemove: string[] = edges
      .get()
      .filter((edge) => !flatData[edge.from as string] || !flatData[edge.to as string])
      .map((edge) => edge.id as string);

    nodes.remove(nodeIdsToRemove);
    edges.remove(edgeIdsToRemove);
  }, [data, edges, nodes]);
  return (
    <Fragment>
      <Box marginBottom={2} paddingLeft={2} maxWidth={720}>
        <Alert severity="warning" variant="outlined">
          <AlertTitle>
            <Typography variant="h6" color={"inherit"} fontSize={"16px"} fontWeight={600}>
              Restricted View
            </Typography>
          </AlertTitle>
          <Typography
            variant="subtitle1"
            color={"inherit"}
            fontSize={"12px"}
            fontWeight={500}
            marginBottom={1.5}
          >
            You are viewing a free version of our Network Graph with 5 Domains, 5 Hosts per domain
            and Resolving IP Addresses.
            <br />
            To explore more information about your attack surface please upgrade your subscription.
          </Typography>
          <Button
            variant="contained"
            component={Link}
            href={`https://${
              process.env.NODE_ENV === "development" ? "cyno.edxdev.link" : "start.ctm360.com"
            }/pricing`}
            target="_blank"
            size="small"
          >
            <Typography
              variant="h6"
              textTransform={"capitalize"}
              fontWeight={600}
              fontSize={"12px"}
            >
              Upgrade Now
            </Typography>
          </Button>
        </Alert>
      </Box>
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <NetworkGraphLegends graphSlugs={graphSlugs} />
        <Paper className="network-graph-controls" variant="outlined">
          <ButtonGroup orientation="vertical">
            <IconButton onClick={zoomIn} id="network_graph_zoom_in">
              <Icon>add</Icon>
            </IconButton>
            <IconButton onClick={fitNetwork} id="network_graph_fit_screen">
              <Icon>fit_screen</Icon>
            </IconButton>
            <IconButton onClick={zoomOut} id="network_graph_zoom_out">
              <Icon>remove</Icon>
            </IconButton>
          </ButtonGroup>
        </Paper>
        <div
          id="network-graph"
          ref={networkGraphRef}
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        />
      </div>
    </Fragment>
  );
};
