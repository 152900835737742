import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { IEasmPlans } from "../../module.interface";
import useDeepCompareEffect from "use-deep-compare-effect";
interface IEasmAddons {
  currentPlan: IEasmPlans;
  tpL1: number;
  trainingSession: number;
  orgScoreCard: boolean;
  tpEnterpriseAddon: boolean;
  onChangeL1: (params: number) => void;

  onChangeTraining: (params: number) => void;
  onChangeScoreCard: (params: boolean) => void;
}
export const EasmAddons: React.FC<IEasmAddons> = ({
  currentPlan,
  tpL1,
  trainingSession,
  orgScoreCard,
  onChangeL1,
  tpEnterpriseAddon,
  onChangeScoreCard,
  onChangeTraining,
}) => {
  useDeepCompareEffect(() => {
    if (!_.includes(["advanced", "enterprise"], currentPlan.name)) {
      onChangeL1(0);

      onChangeScoreCard(false);
      onChangeTraining(0);
    }
  }, [currentPlan]);
  return (
    <Fragment>
      {_.includes(["advanced", "enterprise"], currentPlan.name) ? (
        <Box>
          <Typography variant="subtitle1" color={"secondary"} fontWeight={600}>
            Attack Surface Management
          </Typography>
          <Box padding={"4px 16px"}>
            <Box>
              {currentPlan.name === "advanced" ? (
                <Box className="easm-radio-group">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={orgScoreCard}
                          onChange={(e, c) => onChangeScoreCard(c)}
                        />
                      }
                      label={
                        <Stack
                          direction={"row"}
                          spacing={2}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <Typography variant="subtitle1" fontSize={"14px"} fontWeight={600}>
                              Global Scorecard
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              fontSize={"12px"}
                              color={"textSecondary"}
                            >
                              You can search and view the scorecard of any organization that is
                              profiled in our platform
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="h6" fontSize={"14px"}>
                              <strong>
                                {`$${(20000).toLocaleString()}`}
                                <Typography
                                  variant="subtitle2"
                                  component={"span"}
                                  fontWeight={600}
                                  fontSize={"10px"}
                                >
                                  /yr
                                </Typography>
                              </strong>
                            </Typography>
                          </Box>
                        </Stack>
                      }
                    />
                  </FormGroup>
                </Box>
              ) : null}
              <Box marginTop={1} padding={"0px 8px"}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  marginBottom={1}
                >
                  <Box>
                    <Typography variant="subtitle1" fontSize={"14px"} fontWeight={600}>
                      Third Party Risk Monitoring
                    </Typography>
                    <Typography variant="subtitle1" fontSize={"12px"} color={"textSecondary"}>
                      Monitor your third parties
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" fontSize={"14px"}>
                      <strong>
                        {`$${(currentPlan.name === "advance"
                          ? 150
                          : tpEnterpriseAddon
                          ? 3000
                          : 1500
                        ).toLocaleString()}`}
                        <Typography
                          variant="subtitle2"
                          component={"span"}
                          fontWeight={600}
                          fontSize={"10px"}
                        >
                          /third party
                        </Typography>
                      </strong>
                    </Typography>
                  </Box>
                </Stack>
                <Slider
                  aria-label="TPRML1"
                  value={tpL1}
                  onChange={(e, v) => onChangeL1(v as number)}
                  valueLabelDisplay={"auto"}
                  min={0}
                  max={1000}
                />
              </Box>
              <Box marginTop={1} padding={"0px 8px"}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  marginBottom={1}
                >
                  <Box>
                    <Typography variant="subtitle1" fontSize={"14px"} fontWeight={600}>
                      Training Session
                    </Typography>
                    <Typography variant="subtitle1" fontSize={"12px"} color={"textSecondary"}>
                      A detailed walkthrough to our platform
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h6" fontSize={"14px"}>
                      <strong>
                        {`$${(500).toLocaleString()}`}
                        <Typography
                          variant="subtitle2"
                          component={"span"}
                          fontWeight={600}
                          fontSize={"10px"}
                        >
                          /session
                        </Typography>
                      </strong>
                    </Typography>
                  </Box>
                </Stack>
                <Slider
                  aria-label="TPRML1"
                  value={trainingSession}
                  onChange={(e, v) => onChangeTraining(v as number)}
                  min={0}
                  max={10}
                  step={1}
                  valueLabelDisplay={"auto"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Alert severity="warning">{`No addons available for ${currentPlan.title} Plan`}</Alert>
      )}
    </Fragment>
  );
};
