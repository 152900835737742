import { Box, Grid, Skeleton } from "@mui/material";

import React, { Fragment, useEffect } from "react";
import { toast } from "../../../state/snackbar";
import { AppCard } from "../../views/app.card";
import { FetchCardLeakStats } from "../../../services/cbs.service";
import { DetectionOvertimeChart } from "../../views/ModulesOverTimeChart";
import { DetectionPieChart } from "../../views/ModulesPieChart";

export const CardLeakStats: React.FC = () => {
  const [requestCount, setRequestCount] = React.useState<number>(0);
  const [histogram, setHistogram] = React.useState<any[]>([]);
  const [cardExpiry, setCardExpiry] = React.useState([
    {
      name: "True",
      title: "Cards Expired",
      doc_count: 0,
    },
    {
      name: "False",
      title: "Active Cards",
      doc_count: 0,
    },
    {
      name: "Unknown",
      title: "Unknown",
      doc_count: 0,
    },
  ]);
  const fetchStatus = () => {
    setRequestCount((count) => count + 1);
    setCardExpiry([
      {
        name: "True",
        title: "Cards Expired",
        doc_count: 0,
      },
      {
        name: "False",
        title: "Active Cards",
        doc_count: 0,
      },
      {
        name: "Unknown",
        title: "Unknown",
        doc_count: 0,
      },
    ]);

    FetchCardLeakStats()
      .then((response) => {
        setCardExpiry(response.EXPIRED);
        setHistogram(
          response.HISTOGRAM.map((i) => ({
            timestamp: i.key,
            doc_count: i.doc_count,
          }))
        );
      })
      .catch(() => {
        toast("Error loading stats for card leaks", "error");
      })
      .finally(() => {
        setRequestCount((count) => count - 1);
      });
  };

  useEffect(fetchStatus, []);

  return (
    <Fragment>
      <Box margin={"16px 0"}>
        <Box marginBottom={2}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xl={8} lg={8} md={6} sm={6} xs={12}>
                <AppCard
                  name={"sources"}
                  title={"Leaks Detected"}
                  description={"Card leaks detected overtime"}
                >
                  {requestCount > 0 ? (
                    <Skeleton width={"100%"} height={300} />
                  ) : (
                    <DetectionOvertimeChart
                      data={histogram}
                      axisTitle="Card Leaks"
                      seriesName="Card Leaks"
                    />
                  )}
                </AppCard>
              </Grid>
              <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
                <AppCard
                  name={"leak_type"}
                  title={"Leak Summary"}
                  description={"Summary of card leaks detected"}
                >
                  {requestCount > 0 ? (
                    <Skeleton
                      variant="circular"
                      width={300}
                      height={300}
                      style={{ margin: "auto" }}
                    />
                  ) : (
                    <DetectionPieChart
                      data={cardExpiry.map((x) => ({
                        key: x.title,
                        doc_count: x.doc_count,
                      }))}
                    />
                  )}
                </AppCard>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
