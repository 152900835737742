import { io } from "socket.io-client";
import moment from "moment";
import { IUserProfile } from "../App.interface";
import { getActiveTheme, getSourceVersion, getUserAttributes } from "../util/user.attributes";
import { AppEncryption } from "../util/aes.encryption";

export const SocketService = new (class SocketServiceMonolith {
  protected BASE_URL = process.env.REACT_APP_API_URL;
  public onSocketToken = (token: string) => {
    if (token) {
      const userProfile = (): IUserProfile => {
        const theme = getActiveTheme();
        const sourceVersion = getSourceVersion();
        const navigator = window.navigator;
        const userAttributes = getUserAttributes();
        return {
          browser: navigator.vendor ? navigator.vendor : "Mozilla Firefox",
          platform: navigator.platform,
          code_version: sourceVersion,
          user_theme: theme,
          utc_offset: moment(new Date()).utcOffset(),
          user_designation: userAttributes ? userAttributes.designation : "",
          user_organization: userAttributes ? userAttributes.organization : "",
        };
      };
      io(this.BASE_URL, {
        path: "/api/socket",
        transports: ["websocket"],
        query: {
          user: AppEncryption(JSON.stringify(userProfile()), "CTM LITE"),
        },
        auth: {
          token,
        },
      });
      // socket.on("connect", () => {
      //   console.log("connected");
      // });
      // socket.on("disconnect", () => {
      //   console.log("disconnect");
      // });
    }
  };
})();
