import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../App.interface";
import { FetchBrandSubsidiaries } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import useDeepCompareEffect from "use-deep-compare-effect";
interface ISubsidiarySelector {
  selectedSubsidiary: IESDoc[];
  onChangeSubsidiary: (params: IESDoc[]) => void;
}
export const SubsidiarySelector: React.FC<ISubsidiarySelector> = ({
  onChangeSubsidiary,
  selectedSubsidiary,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [selectedDocs, setSelectedDocs] = React.useState<IESDoc[]>(selectedSubsidiary);
  const fetchSubsidiaries = () => {
    setLoading(true);
    FetchBrandSubsidiaries()
      .then((response) => {
        setDocs(response);
      })
      .catch(() => {
        toast("Error in loading subsidiaries", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchSubsidiaries, []);
  useDeepCompareEffect(() => {
    onChangeSubsidiary(selectedDocs);
  }, [selectedDocs]);
  return (
    <Fragment>
      <Box marginBottom={2}>
        <Typography variant="subtitle1" color={"secondary"} fontWeight={600} marginBottom={1.5}>
          Subsidiaries
        </Typography>
        <Autocomplete
          multiple
          value={selectedDocs}
          id="entity-db-import"
          loading={loading}
          size="small"
          loadingText="Searching organization in our systems"
          options={docs}
          fullWidth
          getOptionLabel={(option) => option.name}
          onChange={(e, v) => setSelectedDocs(v)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search across your subsidiaries "
              variant="outlined"
              fullWidth
              size="small"
            />
          )}
        />
      </Box>
    </Fragment>
  );
};
