import _ from "lodash";
import moment from "moment";
import { AppMD5Hash } from "./md5.hash";

export interface ISessionFingerPrint {
  _id: string;
  timeStamp: number;
  utcOffset: number;
  path: string;
  formattedPath: string;
}

export const userPerformAction = (
  module: string,
  action: "FILTER" | "SEARCH",
  attributes: { field: string; value: any }[]
) => {
  const sessionFingerPrint: ISessionFingerPrint[] = JSON.parse(
    sessionStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.session.fingerprint`) || "[]"
  );
  sessionFingerPrint.push({
    _id: AppMD5Hash(
      `${moment(new Date())
        .utc()
        .format("DD-MM-YYYY hh:mm:A")}_${action}_${module}_${JSON.stringify(attributes)}`
    ),
    timeStamp: moment(new Date()).utc().valueOf(),
    utcOffset: moment(new Date()).utcOffset(),
    path: action,
    formattedPath: JSON.stringify(attributes),
  });
  setSessionFingerPrint(sessionFingerPrint);
};
export const userChangeRoute = (path: string, customPath?: string) => {
  const sessionFingerPrint: ISessionFingerPrint[] = JSON.parse(
    sessionStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.session.fingerprint`) || "[]"
  );
  sessionFingerPrint.push({
    _id: AppMD5Hash(`${moment(new Date()).utc().format("DD-MM-YYYY hh:mm:A")}_${path}`),
    timeStamp: moment(new Date()).utc().valueOf(),
    utcOffset: moment(new Date()).utcOffset(),
    path: path,
    formattedPath: customPath ? customPath : path,
  });
  setSessionFingerPrint(sessionFingerPrint);
};
export const getSessionFingerPrint = (): ISessionFingerPrint[] => {
  const sessionFingerPrint: ISessionFingerPrint[] = JSON.parse(
    sessionStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.session.fingerprint`) || "[]"
  );
  return sessionFingerPrint;
};
export const setSessionFingerPrint = (fingerprint: ISessionFingerPrint[]) => {
  sessionStorage.setItem(
    `${process.env.REACT_APP_SSO_CLIENT_ID}.session.fingerprint`,
    JSON.stringify(_.uniqBy(fingerprint, (x) => x._id))
  );
};
export const clearSessionFingerPrint = () => {
  sessionStorage.removeItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.session.fingerprint`);
};
export const getUserFingerPrint = (): ISessionFingerPrint[] => {
  const userFingerPrint: ISessionFingerPrint[] = JSON.parse(
    localStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.user.fingerprint`) || "[]"
  );
  return userFingerPrint;
};
export const setUserFingerPrint = (fingerprint: ISessionFingerPrint[]) => {
  localStorage.setItem(
    `${process.env.REACT_APP_SSO_CLIENT_ID}.user.fingerprint`,
    JSON.stringify(_.uniqBy(fingerprint, (x) => x._id))
  );
};
export const clearUserFingerPrint = () => {
  localStorage.removeItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.user.fingerprint`);
};
export const getCurrentFingerPrint = (): ISessionFingerPrint[] => {
  const sessionFingerPrint = getSessionFingerPrint();
  const userFingerPrint = getUserFingerPrint();
  return _.uniqBy([...userFingerPrint, ...sessionFingerPrint], (x) => x._id);
};
export const getSessionSignature = (): number => {
  return parseInt(
    sessionStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.session.signature`) ||
      localStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.session.signature`) ||
      moment(new Date()).utc().valueOf().toString()
  );
};
export const setSessionSignature = () => {
  const signature = getSessionSignature();
  if (!sessionStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.session.signature`)) {
    sessionStorage.setItem(
      `${process.env.REACT_APP_SSO_CLIENT_ID}.session.signature`,
      signature.toString()
    );
  }
  saveSessionSignature(signature);
};
export const saveSessionSignature = (signature: number) => {
  localStorage.setItem(
    `${process.env.REACT_APP_SSO_CLIENT_ID}.session.signature`,
    signature.toString()
  );
};
export const clearSessionSignature = () => {
  sessionStorage.removeItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.session.signature`);
  localStorage.removeItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.session.signature`);
};
export const lastSyncDuration = (): number => {
  const sessionSignature = getSessionSignature();
  return moment(new Date()).utc().diff(moment(sessionSignature), "minutes");
};

export const clearTrackedSession = () => {
  clearSessionFingerPrint();
  clearUserFingerPrint();
  clearSessionSignature();
  setSessionSignature();
};
