import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { Fragment } from "react";
import { EntityConfigurationList } from "./entity_configuration.list";

export const EntityConfigurationModule: React.FC = () => {
  const [configModules] = React.useState([
    {
      name: "identifiers",
      title: "Identifiers",
      modules: [
        {
          name: "card_leaks",
          title: "Card Leaks",
          locked: false,
        },
        {
          name: "domain_protection",
          title: "Domain Protection",
          locked: false,
        },
        {
          name: "credential_leaks",
          title: "Credential Leaks",
          locked: true,
        },
        {
          name: "phishing",
          title: "Phishing",
          locked: true,
        },
        {
          name: "mobile_apps",
          title: "Mobile Apps",
          locked: true,
        },

        {
          name: "data_leaks",
          title: "Data Leaks",
          locked: true,
        },
        {
          name: "social_media_fraud",
          title: "Social Media Fraud",
          locked: true,
        },
        // {
        //   name: "hacker_chatter",
        //   title: "Hacker Chatter",
        //   locked: true,
        // },
      ],
    },
    {
      name: "settings",
      title: "Settings",
      modules: [
        {
          name: "notifications",
          title: "Notifications",
          locked: true,
        },
        {
          name: "integrations",
          title: "Integrations",
          locked: true,
        },
        {
          name: "pre_authorization",
          title: "Pre Authorization",
          locked: true,
        },
        {
          name: "documents",
          title: "Documents",
          locked: true,
        },
      ],
    },
  ]);
  const [selectedModule, setSelectedModule] = React.useState({
    name: "credential_leaks",
    title: "Credential Leaks",
    locked: false,
  });
  return (
    <Fragment>
      <Box className="cbs-module-container" margin={"-32px -32px -16px -32px"}>
        <Box className="cbs-module-contents">
          <Grid container wrap="nowrap">
            <Grid item>
              <Paper className="shell-app-box" style={{ height: "100%", minHeight: "100vh" }}>
                <Box minWidth={210} padding={"32px 8px 24px 8px"}>
                  {configModules.map((x) => (
                    <Box key={x.name} marginBottom={2}>
                      <Stack direction={"row"} spacing={1}>
                        <Typography
                          variant="subtitle2"
                          fontSize={"13px"}
                          fontWeight={600}
                          textTransform={"uppercase"}
                          color={"textSecondary"}
                          whiteSpace={"nowrap"}
                          gutterBottom
                          paddingLeft={2}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          marginBottom={2}
                        >
                          {x.title}
                        </Typography>
                        {x.name === "identifiers" ? (
                          <Tooltip
                            title={
                              <Fragment>
                                <Typography
                                  variant="subtitle1"
                                  fontSize={"10px"}
                                  fontWeight={600}
                                  marginBottom={0.5}
                                >
                                  Key elements such as keywords, logos, and images that are used to
                                  detect findings for your organization in our large datasets.
                                </Typography>
                              </Fragment>
                            }
                            placement="right"
                          >
                            <Icon style={{ fontSize: "18px" }}>help_outline</Icon>
                          </Tooltip>
                        ) : null}
                      </Stack>
                      <Box paddingLeft={3}>
                        <List dense disablePadding>
                          {x.modules.map((i) => (
                            <ListItem
                              key={i.name}
                              dense
                              disableGutters
                              disablePadding
                              secondaryAction={
                                i.locked ? (
                                  <Icon color="disabled" sx={{ fontSize: "18px !important" }}>
                                    lock
                                  </Icon>
                                ) : null
                              }
                            >
                              <ListItemButton
                                disabled={i.locked}
                                dense
                                disableGutters
                                style={{ padding: "0", paddingLeft: "8px", borderRadius: "6px" }}
                                selected={selectedModule.name === i.name}
                                onClick={() => setSelectedModule(i)}
                              >
                                <ListItemText
                                  primary={
                                    <Stack
                                      direction={"row"}
                                      spacing={1}
                                      alignItems={"center"}
                                      justifyContent={"space-between"}
                                    >
                                      <Box>
                                        <Typography
                                          variant="h6"
                                          fontSize={"13px"}
                                          fontWeight={500}
                                          color={
                                            selectedModule.name === i.name
                                              ? "primary"
                                              : "textPrimary"
                                          }
                                          whiteSpace={"nowrap"}
                                        >
                                          {i.title}
                                        </Typography>
                                      </Box>
                                    </Stack>
                                  }
                                />
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={10}>
              <Box marginLeft={2.5} padding={"32px 8px 16px 16px"}>
                <EntityConfigurationList name={selectedModule.name} title={selectedModule.title} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
};
