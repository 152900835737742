import React from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Popover,
  Skeleton,
  Stack,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import Lottie from "react-lottie";
import animationData from "../../../../../assets/lottie/empty-search.json";
import { PaperTiledWithIcon } from "./tiled-paper-icon";
import { IESDoc } from "../../../../../App.interface";

export const DkimListPopover: React.FC<{
  doc: IESDoc;
  actionsEl: HTMLElement | null;
  onClose: () => void;
  dkimList: {
    result: string;
    domain: string;
    selector: string;
  }[];
  loading: boolean;
}> = ({ doc, dkimList, actionsEl, onClose, loading }) => {
  const theme = useTheme();
  const errorBg = alpha(theme.palette.error.main, 0.2);
  const primaryBg = alpha(theme.palette.primary.main, 0.2);
  const successBg = alpha(theme.palette.success.main, 0.2);

  return (
    <Popover
      open={Boolean(actionsEl)}
      elevation={2}
      anchorEl={actionsEl}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      sx={{ ml: 1 }}
    >
      <Box sx={{ padding: 2, width: 500, maxWidth: 500, minHeight: 400, maxHeight: 650 }}>
        <Stack>
          <Box>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }} color="primary">
              All DKIM signature
            </Typography>
            <Typography sx={{ fontSize: 12, fontWeight: 600 }} color="text.secondary">
              List of all DKIM signatures applied to these emails
            </Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Grid
            container
            rowSpacing={1}
            className="scroll-designed"
            sx={{ maxHeight: 570, overflow: "auto" }}
          >
            {loading ? (
              [1, 2, 3].map((num) => {
                return (
                  <Grid item key={num} lg={12} md={12} sm={12} xs={12}>
                    <Skeleton variant="rectangular" height={100} />
                  </Grid>
                );
              })
            ) : _.isEmpty(dkimList) ? (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ height: "100%", width: "100%" }}>
                <Lottie
                  options={{
                    animationData: animationData,
                    loop: true,
                    autoplay: true,
                    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
                  }}
                  width={250}
                  height={250}
                />
                <Grid container justifyContent={"center"}>
                  <Grid item>
                    <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="secondary">
                      No Data Found
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              dkimList.map((value) => {
                return (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    key={`${doc._id}_${value.domain}_${value.selector}`}
                  >
                    <Paper sx={{ padding: 1, backgroundColor: theme.palette.background.default }}>
                      <Grid container spacing={1}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <PaperTiledWithIcon
                            title="Domain"
                            value={value.domain}
                            loading={false}
                            color={"primary"}
                            bgColor={primaryBg}
                            icon={"domain"}
                            titleColor={theme.palette.primary.main}
                            isDefault={false}
                            isSmall={true}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <PaperTiledWithIcon
                            title="Result"
                            value={value.result}
                            loading={false}
                            color={value.result == "pass" ? "success" : "error"}
                            bgColor={value.result == "pass" ? successBg : errorBg}
                            icon={value.result == "pass" ? "check" : "clear"}
                            titleColor={
                              value.result == "pass"
                                ? theme.palette.success.main
                                : theme.palette.error.main
                            }
                            isDefault={false}
                            isSmall={true}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                          <PaperTiledWithIcon
                            title="Selector"
                            value={value.selector}
                            loading={false}
                            color={"primary"}
                            bgColor={primaryBg}
                            icon={"local_offer"}
                            titleColor={theme.palette.primary.main}
                            isDefault={false}
                            isSmall={true}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Stack>
      </Box>
    </Popover>
  );
};
