import { Theme } from "@mui/material";
import { Skeleton } from "@mui/material";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import React, { useEffect } from "react";
import { Fragment } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import { EmptyChart } from "../../views/chart.empty";
import { FetchDomainProtectionType } from "../../../services/cbs.service";
import { toast } from "../../../state/snackbar";

export const DomainProtectionType: React.FC<{ updateId: string }> = ({ updateId }) => {
  const theme = useTheme<Theme>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [incidentTypes, setIncidentTypes] = React.useState<
    { id: string; label: string; value: number }[]
  >([]);
  const fetchTypeAggregation = () => {
    setLoading(true);
    FetchDomainProtectionType()
      .then((response) => {
        setIncidentTypes(response);
      })
      .catch(() => {
        toast("Error in loading typo squatted domain type", "error");
        setIncidentTypes([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchTypeAggregation, [updateId]);
  return (
    <Fragment>
      {loading ? (
        <Skeleton variant="circular" width={300} height={300} style={{ margin: "auto" }}></Skeleton>
      ) : !_.isEmpty(incidentTypes) ? (
        <ResponsiveContainer height={300}>
          <Chart
            options={{
              legend: {
                show: true,
                position: "bottom",
                horizontalAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "10px",
                labels: {
                  colors: theme.palette.text.primary,
                },
                customLegendItems: [],
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: "80%",
                    background: "transparent",
                    labels: {
                      show: true,
                      name: {
                        show: true,
                        formatter: () => "Total",
                        color: theme.palette.primary.main,
                      },
                      value: {
                        show: true,
                        fontFamily: "Montserrat",
                        color: theme.palette.text.primary,
                        formatter: () => _.sum(incidentTypes.map((x) => x.value)).toLocaleString(),
                      },
                      total: {
                        show: true,
                        fontFamily: "Montserrat",
                        color: theme.palette.primary.main,
                        formatter: () => _.sum(incidentTypes.map((x) => x.value)).toLocaleString(),
                      },
                    },
                  },
                },
              },
              noData: {
                text: loading ? "Loading..." : "Data not available",
                align: "center",
                verticalAlign: "middle",
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: theme.palette.text.primary,
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                },
              },
              dataLabels: {
                enabled: true,
                dropShadow: {
                  enabled: false,
                },
                style: {
                  colors: [theme.palette.text.primary],
                },
              },
              stroke: {
                show: false,
                curve: "smooth",
                lineCap: "butt",
                colors: undefined,
                width: 2,
                dashArray: 0,
              },
              labels: incidentTypes.map((x) => _.startCase(x.label)),
            }}
            series={incidentTypes.map((x) => x.value)}
            type="donut"
          />
        </ResponsiveContainer>
      ) : (
        <EmptyChart title="Incident by Type" />
      )}
    </Fragment>
  );
};
