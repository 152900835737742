import {
  Icon,
  Popover,
  Box,
  List,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider,
  useTheme,
  Theme,
  Button,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import HVIcon from "../assets/product_icon/hackervieww.svg";
import CBSIcon from "../assets/product_icon/cbs.svg";
import DMIcon from "../assets/product_icon/dmarc.svg";
import HVMonochrome from "../assets/product_icon/hv_white.svg";
import CbsMonochrome from "../assets/product_icon/cbs_white.svg";
import DmarcMonochrome from "../assets/product_icon/dmarc_white.svg";
import { useBehaviourSubject } from "../hooks/use-observable";
import { selectedPlatform$ } from "../state/platformselection";
import useDeepCompareEffect from "use-deep-compare-effect";
const drpStack = [
  {
    name: "hacker_view",
    title: "HackerView",
    description: "Attack Surface Management",
    logo: HVIcon,
    logo_monochrome: HVMonochrome,
  },
  {
    name: "cbs",
    title: "Cyber Blindspot",
    description: "Digital Risk Protection",
    logo: CBSIcon,
    logo_monochrome: CbsMonochrome,
  },
  {
    name: "dmarc",
    title: "DMARC360",
    description: "Intelligent Email Security",
    logo: DMIcon,
    logo_monochrome: DmarcMonochrome,
  },
];
export const AppProductSwitch: React.FC = () => {
  const theme = useTheme<Theme>();
  const selectedProduct = useBehaviourSubject(selectedPlatform$) || "hacker_view";
  const [userMenuEl, setUserMenuEl] = React.useState<null | HTMLElement>(null);

  const [selectedApp, setSelectedApp] = React.useState(
    drpStack.filter((f) => f.name === selectedProduct)[0]
  );
  useDeepCompareEffect(() => {
    selectedPlatform$.next((selectedApp.name as any) || "hacker_view");
  }, [selectedApp]);
  useEffect(() => {
    const sub = selectedPlatform$.subscribe((res) => {
      setSelectedApp(drpStack.filter((f) => f.name === res)[0]);
    });
    return () => sub.unsubscribe();
  }, []);
  return (
    <Fragment>
      <Fragment>
        <Button
          variant="text"
          size="small"
          endIcon={<Icon>keyboard_arrow_down</Icon>}
          onClick={(e) => setUserMenuEl(e.currentTarget)}
        >
          <Typography variant="subtitle1" fontSize={"14px"} fontWeight={600} textTransform={"none"}>
            {selectedApp.title}
          </Typography>
        </Button>

        <Popover
          open={Boolean(userMenuEl)}
          anchorEl={userMenuEl}
          onClose={() => setUserMenuEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box padding={"32px"} width={"350px"}>
            <Typography
              variant="subtitle1"
              fontSize={"14px"}
              color={"primary"}
              fontWeight={600}
              gutterBottom
            >
              Switch To
            </Typography>
            <Divider />
            <Box marginTop={0.5}>
              <List dense disablePadding>
                {drpStack.map((x) => (
                  <ListItemButton
                    key={x.name}
                    disableGutters
                    dense
                    onClick={() => {
                      setSelectedApp(x);
                      setUserMenuEl(null);
                    }}
                  >
                    <ListItemAvatar style={{ minWidth: "45px" }}>
                      <Avatar
                        style={{ width: "30px", height: "30px" }}
                        src={theme.palette.mode === "dark" ? x.logo_monochrome : x.logo}
                        alt={`${x.title}-logo`}
                      ></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          color={selectedApp.name === x.name ? "primary" : "textPrimary"}
                          variant="subtitle1"
                          fontSize={"14px"}
                          fontWeight={600}
                        >
                          {x.title}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          color={selectedApp.name === x.name ? "secondary" : "textSecondary"}
                          variant="subtitle1"
                          fontSize={"12px"}
                          fontWeight={500}
                        >
                          {x.description}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                ))}
              </List>
            </Box>
          </Box>
        </Popover>
      </Fragment>
    </Fragment>
  );
};
