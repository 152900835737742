import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Box,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { IESDoc } from "../../App.interface";
import { RenderCell } from "../../util/render.cell";
import { IMasterColumns } from "../module.interface";

interface IAppDocView {
  doc: IESDoc | { [key: string]: any };
  colDefs: IMasterColumns[];
  keyColor?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
}
export const AppDocView: React.FC<IAppDocView> = ({ colDefs, doc, keyColor }) => {
  return (
    <Fragment>
      <Box className="app-master-document-view">
        <TableContainer>
          <Table size="small" className="doc-view-table">
            <TableBody>
              {colDefs.map((x) => (
                <TableRow key={x.masterColumnName}>
                  <TableCell>
                    <Typography
                      variant="h6"
                      className="item-label"
                      color={keyColor ? keyColor : "textSecondary"}
                    >
                      {x.masterColumnTitle}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <span className="item-value">
                      {x.cellRender
                        ? x.cellRender(doc as IESDoc)
                        : _.has(doc, x.masterColumnName) &&
                          !_.isNull(_.get(doc, x.masterColumnName)) &&
                          !_.isEmpty(_.get(doc, x.masterColumnName).toString()) &&
                          !_.isUndefined(_.get(doc, x.masterColumnName)) &&
                          !_.isNaN(_.get(doc, x.masterColumnName))
                        ? RenderCell(_.get(doc, x.masterColumnName), x.renderType)
                        : "-"}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Fragment>
  );
};
