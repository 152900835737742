import { Chip, Theme, useTheme } from "@mui/material";
import startCase from "lodash/startCase";

import React, { Fragment } from "react";
import { IESDoc } from "../../../App.interface";
import { ColorShades } from "../../../util/color-shades";
import { ColorOpacity } from "../../../util/color_opacity";
import { AppMD5Hash } from "../../../util/md5.hash";
import { stringToColor } from "../../../util/color.generator";

interface ILeakTypeView {
  doc: IESDoc;
}
export const LeakTypeView: React.FC<ILeakTypeView> = ({ doc }) => {
  const theme = useTheme<Theme>();
  const computeLabelColor = (lbl: string): string => {
    const intensity = theme.palette.mode === "dark" ? "40" : "700";
    const pallette = ColorShades(stringToColor(AppMD5Hash(lbl)), intensity);
    return pallette;
  };

  return (
    <Fragment>
      {doc.leak_type ? (
        <Chip
          variant="filled"
          label={startCase(doc.leak_type)}
          size={"small"}
          style={{
            fontWeight: 600,
            borderRadius: "5px",
            color: computeLabelColor(startCase(doc.leak_type)),
            backgroundColor: ColorOpacity(computeLabelColor(startCase(doc.leak_type)), 0.15),
          }}
        />
      ) : (
        "-"
      )}
    </Fragment>
  );
};
