export const AuthResultColorMapping = (value: string) => {
  switch (value) {
    case "pass":
      return "success";

    case "aligned":
      return "success";

    case "fail":
      return "error";

    case "softfail":
      return "error";

    case "permerror":
      return "error";

    case "un-aligned":
      return "error";

    case "none":
      return "warning";

    default:
      return "warning";
  }
};
