import { IESBulkResponse, IESQueryResponse } from "../App.interface";
import { IAttachments, ITableFilters } from "../modules/module.interface";
import axios from "./axios.service";
import { Buffer } from "buffer";

export const FetchIncidentStatus = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/cbs/status_histogram");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchIncidentTypes = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/cbs/incident_types");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const FetchMemberFeedbackIncidents = async <T>(): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/cbs/member_feedback_incidents");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchIncidentOverview = async (): Promise<any> => {
  try {
    const response = await axios.post("/cbs/incident_overview");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchIncidentSeverity = async (): Promise<any> => {
  try {
    const response = await axios.post("/cbs/incident_severity");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchIncidentSource = async (): Promise<any> => {
  try {
    const response = await axios.post("/cbs/incident_source");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDomainProtectionType = async (): Promise<any> => {
  try {
    const response = await axios.post("/cbs/domain_protection/type");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDomainProtectionRisks = async (): Promise<any> => {
  try {
    const response = await axios.post("/cbs/domain_protection/risks");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchCardLeakPreCheck = async (): Promise<boolean> => {
  try {
    const response = await axios.post("/cbs/card_leaks/pre_check");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchCardLeakStats = async (): Promise<{ HISTOGRAM: any[]; EXPIRED: any[] }> => {
  try {
    const response = await axios.post("/cbs/card_leaks/stats");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchCredentialLeakType = async (): Promise<any> => {
  try {
    const response = await axios.post("/cbs/credential_leaks/type");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchMalwareLogLeakType = async (): Promise<any> => {
  try {
    const response = await axios.post("/cbs/malware_logs/leak_type");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchCredentialLeakStats = async (
  type: string
): Promise<{ HISTOGRAM: any[]; [key: string]: any[] }> => {
  try {
    const response = await axios.post(`/cbs/credential_leaks/${type}/stats`);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchCardLeakList = async <T>(
  pageIndex: number,
  pageSize: number,
  searchQuery: string,
  sortField: string,
  sortOrder: string,
  filters?: ITableFilters[],
  startDate?: string | number | Date,
  endDate?: string | number | Date
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/cbs/card_leaks/list", {
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder,
      startDate,
      endDate,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchCredentialLeakList = async <T>(
  type: string,
  pageIndex: number,
  pageSize: number,
  searchQuery: string,
  sortField: string,
  sortOrder: string,
  filters?: ITableFilters[],
  startDate?: string | number | Date,
  endDate?: string | number | Date
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post(`/cbs/credential_leaks/${type}/list`, {
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder,
      startDate,
      endDate,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDomainProtectionStats = async (
  type: string
): Promise<{ HISTOGRAM: any[]; RISKS: any[] }> => {
  try {
    const response = await axios.post(`/cbs/domain_protection/${type}/stats`);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDomainProtectionList = async <T>(
  type: string,
  pageIndex: number,
  pageSize: number,
  searchQuery: string,
  sortField: string,
  sortOrder: string,
  filters?: ITableFilters[],
  startDate?: string | number | Date,
  endDate?: string | number | Date
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post(`/cbs/domain_protection/${type}/list`, {
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder,
      startDate,
      endDate,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchThreatManagerFilterOptions = async (type: string): Promise<any> => {
  try {
    const response = await axios.post(`/cbs/threat_manager/${type}/filter_options`);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const RequestIncidentTakedown = async (
  subject: string,
  attachments: IAttachments[]
): Promise<IESBulkResponse> => {
  try {
    const response = await axios.post("/cbs/threat_manager/takedown_request", {
      subject,
      attachments,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const IncidentTakedownList = async <T>(): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/cbs/threat_manager/takedown_request_list");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const IncidentTakedownProgress = async <T>(id: string): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/cbs/threat_manager/takedown_request_progress", { id });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchOpenSourceFeedsList = async <T>(
  pageIndex: number,
  pageSize: number,
  searchQuery: string,
  sortField: string,
  sortOrder: string,
  filters?: ITableFilters[],
  startDate?: string | number | Date,
  endDate?: string | number | Date
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/cbs/situational_awareness/open_source_feeds/list", {
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder,
      startDate,
      endDate,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchThreatActorClaimsList = async <T>(
  pageIndex: number,
  pageSize: number,
  searchQuery: string,
  sortField: string,
  sortOrder: string,
  filters?: ITableFilters[],
  startDate?: string | number | Date,
  endDate?: string | number | Date
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/cbs/situational_awareness/threat_actor_claims/list", {
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder,
      startDate,
      endDate,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchCtmFeedsList = async <T>(
  pageIndex: number,
  pageSize: number,
  searchQuery: string,
  sortField: string,
  sortOrder: string,
  filters?: ITableFilters[],
  startDate?: string | number | Date,
  endDate?: string | number | Date
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/cbs/situational_awareness/ctm360_feeds/list", {
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder,
      startDate,
      endDate,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchPhishingStats = async (): Promise<{ HISTOGRAM: any[]; LEAK_TYPE: any[] }> => {
  try {
    const response = await axios.post("/cbs/phishing/stats");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchPhishingList = async <T>(
  pageIndex: number,
  pageSize: number,
  searchQuery: string,
  sortField: string,
  sortOrder: string,
  filters?: ITableFilters[],
  startDate?: string | number | Date,
  endDate?: string | number | Date
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/cbs/phishing/list", {
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder,
      startDate,
      endDate,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchSocialMediaList = async <T>(
  type: string,
  pageIndex: number,
  pageSize: number,
  searchQuery: string,
  sortField: string,
  sortOrder: string,
  filters?: ITableFilters[],
  startDate?: string | number | Date,
  endDate?: string | number | Date
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post(`/cbs/social_media_fraud/${type}/list`, {
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder,
      startDate,
      endDate,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchSocialMediaStats = async (type: string): Promise<{ PLATFORM: any[] }> => {
  try {
    const response = await axios.post(`/cbs/social_media_fraud/${type}/stats`);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchMobileAppsList = async <T>(
  pageIndex: number,
  pageSize: number,
  searchQuery: string,
  sortField: string,
  sortOrder: string,
  filters?: ITableFilters[],
  startDate?: string | number | Date,
  endDate?: string | number | Date
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/cbs/mobile_apps/list", {
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder,
      startDate,
      endDate,
      filters,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchConfigurationKeywords = async <T>(
  module_name: string,
  keyword_name: string,
  pageIndex: number,
  pageSize: number,
  searchQuery: string,
  sortField: string,
  sortOrder: string
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post(`/cbs/entity_configuration/${module_name}/${keyword_name}`, {
      pageIndex,
      pageSize,
      searchQuery,
      sortField,
      sortOrder,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchConfigurationBrandLogos = async (
  module_name: string,
  keyword_name: string,
  file_name: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `/cbs/entity_configuration/${module_name}/${keyword_name}/logo`,
      {
        file_name,
      }
    );
    let buf: Buffer;
    let base64 = "";
    if (response.data.success) {
      buf = Buffer.from(response.data.data.Body.data);
      base64 = buf.toString("base64");
    }
    return base64;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
