import { ThemeOptions } from "@mui/material";
declare module "@mui/material/styles" {
  interface Theme {
    severity: {
      [index: string]: any;
      informational: string;
      low: string;
      medium: string;
      high: string;
      critical: string;
    };
    score: {
      [index: string]: any;
      "A+": string;
      A: string;
      B: string;
      C: string;
      D: string;
      F: string;
      "N/A": string;
    };
    piechart_palette: string[];
  }

  interface ThemeOptions {
    severity: {
      [index: string]: any;
      informational: string;
      low: string;
      medium: string;
      high: string;
      critical: string;
    };
    score: {
      [index: string]: any;
      "A+": string;
      A: string;
      B: string;
      C: string;
      D: string;
      F: string;
      "N/A": string;
    };
    piechart_palette: string[];
  }
}

const oldPieColors = ["#008efa", "#01e395", "#fdb018", "#fe455f", "#775ccf"];

export const DefaultTheme: ThemeOptions = {
  score: {
    "A+": "#097B63",
    A: "#86AB3C",
    B: "#F3CF1B",
    C: "#F7A336",
    D: "#F4602F",
    F: "#EA1A1E",
    "N/A": "#868686",
  },
  severity: {
    critical: "#ed1e26",
    high: "#f04323",
    medium: "#fecb02",
    //low: "#6cbd45",
    low: "#86AB3C",
    informational: "#b4b4be",
  },
  piechart_palette: oldPieColors,
  palette: {
    mode: "dark",
    background: {
      default: "#222c30",
      paper: "#28353a",
    },
    text: {
      primary: "#ffffff",
      secondary: "#a8a8a8",
    },
    primary: {
      main: "#00bcd4",
      dark: "#ffffff",
    },
    secondary: {
      main: "#fe6f5e",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: 12,
          height: 24,
        },
        deleteIcon: {
          fontSize: 17,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 11,
          whiteSpace: "nowrap",
          ".MuiTypography-button": {
            whiteSpace: "nowrap",
            marginBottom: 0,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundImage: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "12px",
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          lineHeight: ".9em",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          fontSize: 12,
        },
        tag: {
          fontSize: 10,
        },
        groupUl: { fontSize: 12 },
        option: {
          fontSize: 12,
        },
        root: {
          fontSize: 12,
        },
        inputRoot: {
          fontSize: 12,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },
  },
};

export const CuriosityTheme: ThemeOptions = {
  score: DefaultTheme.score,
  severity: DefaultTheme.severity,
  //piechart_palette: ["#1B6055", "#237A6C", "#2A9484", "#6FC6B9", "#ADDFD7", "#EAF7F5"],
  piechart_palette: oldPieColors,
  palette: {
    mode: "dark",
    background: {
      default: "#242938",
      paper: "#1E212F",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#B1B1B1",
    },
    primary: {
      main: "#32AE9B",
    },
    secondary: {
      main: "#FF5874",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  components: {
    ...DefaultTheme.components,
  },
};

export const SweatLeaf: ThemeOptions = {
  severity: {
    low: "#86ab3b",
    informational: "#868686",
    critical: "#e9191d",
    medium: "#f3cf1a",
    high: "#f7a336",
  },
  //piechart_palette: ["#1D2F52", "#273E6D", "#647AA6", "#8E9EBE", "#ADB8CF", "#CBD3E1"],
  piechart_palette: oldPieColors,
  score: {
    "A+": "#056858",
    A: "#61A61C",
    B: "#E4BB18",
    C: "#FF8205",
    D: "#F24526",
    F: "#D71434",
    "N/A": "#939393",
  },
  palette: {
    mode: "light",
    background: {
      default: "#F4F2F3",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#333333",
      secondary: "#707070",
    },
    primary: {
      main: "#a80000",
    },
    secondary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  components: {
    ...DefaultTheme.components,
  },
};
