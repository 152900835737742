import {
  Autocomplete,
  Box,
  CircularProgress,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../App.interface";
import { FetchBrandScore, FetchBrandSuggestions } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { calculateGrade } from "../../util/score.grade.utils";
import { HackerViewGradeShield } from "../module.views/grade.shield";
import { RequestEntityScore } from "./request_entity_score";
import { UUIDGenerator } from "../../util/uuid";
import { AppDocView } from "../views/app.doc.view";
import { HistoryLookups } from "./history_lookups";
const filter = createFilterOptions<IESDoc>();

export const SecurityRatingModule: React.FC = () => {
  const [searching, setSearching] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [selectedEntity, setSelectedEntity] = React.useState<IESDoc>();
  const [entityScore, setEntityScore] = React.useState<IESDoc>();
  const [addEntityModel, setAddEntityModel] = React.useState(false);
  const [updateId, setUpdateId] = React.useState("");

  const onChangeEntity = (event: any, value: IESDoc | null) => {
    if (value?.inputValue) {
      setAddEntityModel(true);
    } else {
      setSelectedEntity(value || undefined);
    }
  };
  const fetchSearchSuggestions = () => {
    setSearching(true);
    setEntityScore(undefined);
    setSelectedEntity(undefined);
    FetchBrandSuggestions(searchQuery)
      .then((response) => {
        setDocs(response);
      })
      .catch(() => {
        toast(`Failed to search for ${searchQuery}`, "error");
      })
      .finally(() => {
        setSearching(false);
      });
  };
  const fetchEntityScore = () => {
    if (selectedEntity) {
      setLoading(true);
      FetchBrandScore(selectedEntity._id)
        .then((response) => {
          setEntityScore(response[0]);
          setUpdateId(UUIDGenerator());
        })
        .catch(() => {
          toast(`Error in loading the score of ${selectedEntity.name}`, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  useEffect(fetchSearchSuggestions, [searchQuery]);
  useEffect(fetchEntityScore, [selectedEntity]);

  return (
    <Fragment>
      <Box maxWidth={"720px"} margin={"auto"} marginTop={5}>
        <Box textAlign={"center"} marginBottom={3}>
          <Typography variant="h4" color={"primary"} fontWeight={600} marginBottom={1.5}>
            Security Rating
          </Typography>
          <Typography variant="subtitle1" fontSize={"14px"} fontWeight={500} textAlign={"justify"}>
            Security Ratings are a data-driven, dynamic and objective measurement of an
            organization’s security posture based on their External Attack Surface. You can use the
            search interface below to view the Security Rating of the vast number of companies
            monitored by our Platform.
          </Typography>
        </Box>
        <Box>
          <Box marginBottom={2}>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box width={"650px"}>
                <Autocomplete
                  // defaultValue={selectedEntity}
                  value={selectedEntity}
                  id="entity-db-import"
                  loading={searching}
                  loadingText="Searching organization in our systems"
                  options={docs}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option) => selectedEntity?._id === option._id}
                  onInputChange={_.debounce((event, value) => setSearchQuery(value), 300)}
                  onChange={onChangeEntity}
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      <Box>
                        <Typography
                          variant="subtitle1"
                          fontSize={"14px"}
                          fontWeight={600}
                          color={"primary"}
                        >
                          {option.name}
                        </Typography>
                        <Typography variant="subtitle1" fontSize={"13px"} color="secondary">
                          {option.domain}
                        </Typography>
                      </Box>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search using an organizations name or domain"
                      variant="outlined"
                      value={searchQuery}
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {searching ? <CircularProgress color="primary" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      (option) =>
                        _.includes(_.toLower(option.domain), _.toLower(inputValue)) ||
                        _.includes(_.toLower(option.name), _.toLower(inputValue))
                    );
                    if (isExisting) {
                      filtered.push(...options);
                    }
                    if (inputValue !== "" && !isExisting && !searching) {
                      const labelID = UUIDGenerator();
                      filtered.push({
                        _id: labelID,
                        inputValue,
                        name: `Request "${inputValue}"`,
                        domain: "",
                      });
                    }

                    return _.uniqBy(filtered, (uq) => uq._id);
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  noOptionsText={"Please provide an organization name"}
                />
              </Box>
              <HistoryLookups updateId={updateId} />
            </Stack>
          </Box>
          {selectedEntity || entityScore ? (
            <Box marginTop={3}>
              <Box textAlign={"center"} marginBottom={2}>
                {loading ? (
                  <Skeleton width={"350px"} style={{ margin: "auto" }} />
                ) : entityScore ? (
                  <Typography variant="h4" color={"primary"} fontWeight={600} marginBottom={2}>
                    {entityScore.name}
                  </Typography>
                ) : null}
              </Box>
              <HackerViewGradeShield
                loading={loading}
                score={entityScore ? entityScore.score : 0}
                grade={calculateGrade(entityScore ? entityScore.score : 0)}
                shieldDescription={"Cyber Risk Score"}
              />
              {loading ? null : entityScore ? (
                <Box marginTop={2}>
                  <Paper>
                    <AppDocView
                      doc={entityScore}
                      keyColor="secondary"
                      colDefs={[
                        {
                          masterColumnName: "country",
                          masterColumnTitle: "Country",
                          renderType: "text",
                        },
                        {
                          masterColumnName: "regions",
                          masterColumnTitle: "Region",
                          renderType: "tags",
                        },
                        {
                          masterColumnName: "industry",
                          masterColumnTitle: "Industry",
                          renderType: "tags",
                        },
                        {
                          masterColumnName: "sector",
                          masterColumnTitle: "Sector",
                          renderType: "tags",
                        },
                      ]}
                    />
                  </Paper>
                </Box>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Box>
      {addEntityModel ? (
        <RequestEntityScore
          searchQuery={searchQuery}
          onClose={() => {
            setAddEntityModel(false);
          }}
        />
      ) : null}
    </Fragment>
  );
};
