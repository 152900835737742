import { IESDoc, IESQueryResponse } from "../App.interface";
import {
  IOrgScore,
  IOrgIssues,
  IRiskProfile,
  IIssueProfile,
  IOrgAssetCount,
} from "../modules/module.interface";
import { AppEncryption } from "../util/aes.encryption";
import axios from "./axios.service";
export const FetchOrganizationAssetCount = async (): Promise<IOrgAssetCount> => {
  try {
    const response = await axios.post("/dashboard/asset_count");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const FetchOrganizationScore = async (): Promise<IOrgScore> => {
  try {
    const response = await axios.post("/dashboard/score");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const FetchOrganizationIssues = async (): Promise<IOrgIssues[]> => {
  try {
    const response = await axios.post("/dashboard/issue_breakdown");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchOrganizationAssets = async (): Promise<IOrgIssues[]> => {
  try {
    const response = await axios.post("/dashboard/cyber_assets");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchOrganizationNetworkGraph = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/dashboard/network_graph");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchOrganizationNetworkGraphDetails = async <T>(
  asset_type: string,
  value: string
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post(`/dashboard/network_graph/${asset_type}/details`, { value });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchHostOverview = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/dashboard/host_overview");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchWebServerOverview = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/dashboard/webserver");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchEnvironmentsOverview = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/dashboard/environments_overview");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchIPOverview = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/dashboard/ip_overview");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchAssetFeed = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/dashboard/asset_feed");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchIssueFeed = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/dashboard/issue_feed");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchRiskProfile = async (): Promise<IRiskProfile[]> => {
  try {
    const response = await axios.post("/dashboard/risk_profile");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchRiskProfileIssues = async <T>(
  issue_category: string
): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/dashboard/risk_profile/issues", { issue_category });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchIssueProfile = async (): Promise<{
  issues: IIssueProfile[];
  issue_category_count: number;
  total_issue_count: number;
}> => {
  try {
    const response = await axios.post("/dashboard/issue_profile");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchBrandSuggestions = async (search_query: string): Promise<IESDoc[]> => {
  try {
    const response = await axios.post("/dashboard/entity_suggestions", { search_query });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchBrandScore = async (brand: string): Promise<IESDoc[]> => {
  try {
    const response = await axios.post("/dashboard/entity_score", { brand });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchBrandSubsidiaries = async (): Promise<IESDoc[]> => {
  try {
    const response = await axios.post("/dashboard/subsidiaries");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchBrandSubsidiaryAssetCount = async (
  subsidiary: string[]
): Promise<IOrgAssetCount> => {
  try {
    const response = await axios.post("/dashboard/subsidiaries/asset_count", { subsidiary });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchRiskProfileIssueTypes = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/dashboard/risk_profile/issue_breakdown");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchRiskProfileSeverityTypes = async (): Promise<any[]> => {
  try {
    const response = await axios.post("/dashboard/risk_profile/severity_breakdown");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchRiskProfileIssueCategories = async (issue_category: string): Promise<any[]> => {
  try {
    const response = await axios.post("/dashboard/risk_profile/issue_categories", {
      issue_category,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const SubmitProfilingRequest = async (search_query: string): Promise<any> => {
  try {
    const response = await axios.post("/dashboard/entity_request", { search_query });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchEnvironments = async (): Promise<any> => {
  try {
    const response = await axios.post("/dashboard/environments");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchTechnologies = async (): Promise<any> => {
  try {
    const response = await axios.post("/dashboard/technologies");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchCyberAssets = async <T>(resource: string): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post(`/dashboard/cyber_assets/${resource}`);
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchLookupHistory = async (): Promise<IESDoc[]> => {
  try {
    const response = await axios.post("/dashboard/entity_score_log");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchIssueList = async <T>(issue_name: string): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/dashboard/issue_list", {
      issue_name: AppEncryption(issue_name),
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchIssueMetaData = async <T>(issue_name: string): Promise<IESQueryResponse<T>> => {
  try {
    const response = await axios.post("/dashboard/issue_meta_data", {
      issue_name: AppEncryption(issue_name),
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
