import React, { Fragment, useEffect, useCallback } from "react";

import moment from "moment";
import {
  Box,
  Typography,
  Divider,
  Tabs,
  Tab,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
} from "@mui/material";
import useDeepCompareEffect from "use-deep-compare-effect";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { IDateFilterObj } from "../modules/module.interface";

export interface IDateRangeFilter {
  currentDateObj: IDateFilterObj;
  publishDateObj: (params: IDateFilterObj) => void;
  onClose: () => void;
}
export const DateRangeFilter: React.FC<IDateRangeFilter> = ({ currentDateObj, publishDateObj }) => {
  const timeQuickFilters: Array<{ label: string; value: string }> = [
    {
      label: "Last 30 minutes",
      value: "30min",
    },

    {
      label: "Last 1 hour",
      value: "1hr",
    },
    {
      label: "Last 4 hours",
      value: "4hr",
    },
    {
      label: "Last 12 hours",
      value: "12hr",
    },
    ////
    {
      label: "Last 24 hours",
      value: "24hr",
    },

    {
      label: "Last 7 days",
      value: "7day",
    },
    {
      label: "Last 30 days",
      value: "30day",
    },
    {
      label: "Last 90 days",
      value: "90day",
    },
    ////
    {
      label: "Last 6 months",
      value: "6mon",
    },

    {
      label: "Last 1 year",
      value: "1yr",
    },
    {
      label: "Last 2 years",
      value: "2yr",
    },
    {
      label: "All Time",
      value: "all_time",
    },
  ];
  const [tabValue, setTabValue] = React.useState(0);
  const [quickRange, setQuickRange] = React.useState<{
    label: string;
    value: string;
  }>();
  const [currentDateRange, setCurrentDateRange] = React.useState<IDateFilterObj>(currentDateObj);
  const applyNewDateRange = useCallback(() => {
    const currentDateHistory: IDateFilterObj[] = JSON.parse(
      sessionStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.timePicker.timeHistory`) ||
        JSON.stringify([])
    );
    currentDateHistory.push(currentDateRange);
    sessionStorage.setItem(
      `${process.env.REACT_APP_SSO_CLIENT_ID}.timePicker.timeHistory`,
      JSON.stringify(currentDateHistory)
    );
    publishDateObj(currentDateRange);
  }, [currentDateRange, publishDateObj]);
  const onChangeDateAbsoluteDateValues = (date: any, position: "start" | "end") => {
    if (date) {
      switch (position) {
        case "start":
          setCurrentDateRange({
            ...currentDateRange,
            status: true,
            startDate: moment(date).valueOf(),
            mode: "absolute",
            value: moment(date).from(currentDateRange.endDate),
          });
          break;
        case "end":
          setCurrentDateRange({
            ...currentDateRange,
            status: true,
            endDate: moment(date).valueOf(),
            mode: "absolute",
            value: moment(currentDateRange.startDate).from(date),
          });
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (quickRange) {
      switch (quickRange.value) {
        case "30min":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(30, "minutes").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "1hr":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(1, "hour").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "4hr":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(4, "hours").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "12hr":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(12, "hours").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "24hr":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(24, "hours").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "7day":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(7, "days").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "30day":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(30, "days").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "90day":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(90, "days").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "6mon":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(6, "months").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "1yr":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(1, "year").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "2yr":
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(2, "years").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        case "all_time":
          setCurrentDateRange({
            status: false,
            startDate: moment(new Date()).subtract(10, "years").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
        default:
          setCurrentDateRange({
            status: true,
            startDate: moment(new Date()).subtract(30, "days").valueOf(),
            endDate: moment(new Date()).valueOf(),
            mode: "quick",
            value: quickRange.label,
          });
          break;
      }
    }
  }, [quickRange]);
  useDeepCompareEffect(() => {
    if (quickRange) {
      applyNewDateRange();
    }
  }, [applyNewDateRange, currentDateRange, quickRange]);
  useDeepCompareEffect(() => {
    setCurrentDateRange(currentDateObj);
  }, [currentDateObj]);
  return (
    <Fragment>
      <Box className="date-range-container">
        <Box className="header">
          <Typography variant="h6" gutterBottom>
            Date Range
          </Typography>
        </Box>
        <Divider />
        <Box className="content">
          <Tabs
            variant="fullWidth"
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, value) => setTabValue(value)}
            aria-label="date-range-tabs"
          >
            <Tab label="Quick" />
            <Tab label="Absolute" />
          </Tabs>
          <Box hidden={tabValue !== 0}>
            <Box className="tab-panel quick-filters">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                spacing={1}
              >
                <Grid item xs={4}>
                  <List dense={true}>
                    {timeQuickFilters.map((f, i) =>
                      i < 4 ? (
                        <ListItem
                          button
                          onClick={() => setQuickRange({ label: f.label, value: f.value })}
                          key={f.label + f.value}
                        >
                          <ListItemText primary={f.label} />
                        </ListItem>
                      ) : null
                    )}
                  </List>
                </Grid>
                <Grid item xs={4}>
                  <List dense={true}>
                    {timeQuickFilters.map((f, i) =>
                      i > 3 && i < 8 ? (
                        <ListItem
                          button
                          key={f.label + f.value}
                          onClick={() => setQuickRange({ label: f.label, value: f.value })}
                        >
                          <ListItemText primary={f.label} />
                        </ListItem>
                      ) : null
                    )}
                  </List>
                </Grid>
                <Grid item xs={4}>
                  <List dense={true}>
                    {timeQuickFilters.map((f, i) =>
                      i > 7 ? (
                        <ListItem
                          button
                          key={f.label + f.value}
                          onClick={() => setQuickRange({ label: f.label, value: f.value })}
                        >
                          <ListItemText primary={f.label} />
                        </ListItem>
                      ) : null
                    )}
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box hidden={tabValue !== 1}>
            <Box className="tab-panel absolute-filters">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                spacing={1}
              >
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDateTimePicker
                      renderInput={(props) => <TextField {...props} size="small" />}
                      label="Start Date"
                      disableFuture
                      inputFormat="dd/MM/yyyy HH:mm"
                      value={currentDateRange.startDate}
                      onChange={(date) => onChangeDateAbsoluteDateValues(date, "start")}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDateTimePicker
                      renderInput={(props) => <TextField {...props} size="small" />}
                      label="End Date"
                      disableFuture
                      inputFormat="dd/MM/yyyy HH:mm"
                      value={currentDateRange.endDate}
                      onChange={(date) => onChangeDateAbsoluteDateValues(date, "end")}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Box paddingTop={2}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={applyNewDateRange}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};
