import _get from "lodash/get";
import _has from "lodash/has";
import React, { Fragment } from "react";
import { AppAssetsSectionBox } from "../../views/app.section.box";
import { AppDocView } from "../../views/app.doc.view";
import { IESDoc } from "../../../App.interface";
import isArray from "lodash/isArray";

export const DomainDetailsView: React.FC<{
  doc: IESDoc;
}> = ({ doc }) => {
  return (
    <Fragment>
      <AppAssetsSectionBox title="Domain Information" type="domain">
        <AppDocView
          colDefs={[
            { masterColumnName: "value", masterColumnTitle: "Domain", renderType: "text" },
            {
              masterColumnName: "whois.parsed.registrar_name",
              masterColumnTitle: "Registrar",
              renderType: "text",
            },
            {
              masterColumnName: "whois.parsed.domain_created_date",
              masterColumnTitle: "Creation Date",
              renderType: "date",
            },
            {
              masterColumnName: "whois.parsed.domain_expiry_date",
              masterColumnTitle: "Expiry Date",
              renderType: "date",
            },
            {
              masterColumnName: "whois.parsed.domain_updated_date",
              masterColumnTitle: "Update Date",
              renderType: "date",
            },
            {
              masterColumnName: "whois.parsed.registration_status",
              masterColumnTitle: "Status",
              renderType: "text",
            },
            {
              masterColumnName: "name_servers",
              masterColumnTitle: "Name Servers",
              renderType: "tags",
            },
          ]}
          doc={{
            ...doc,
            name_servers:
              _has(doc, "dns.ns_record") && isArray(_get(doc, "dns.ns_record"))
                ? _get(doc, "dns.ns_record").map((record: any) => record.nameserver)
                : [],
          }}
        />
      </AppAssetsSectionBox>
      {_has(doc, "whois.parsed.registrant") ? (
        <AppAssetsSectionBox title="Registrant Contact" type="domain">
          <AppDocView
            colDefs={[
              { masterColumnName: "name", masterColumnTitle: "Name", renderType: "text" },
              {
                masterColumnName: "organization",
                masterColumnTitle: "Organization",
                renderType: "text",
              },
              { masterColumnName: "telephone", masterColumnTitle: "Phone", renderType: "text" },
              {
                masterColumnName: "email",
                masterColumnTitle: "Email",
                renderType: "text",
              },
              { masterColumnName: "country", masterColumnTitle: "Country", renderType: "text" },
            ]}
            doc={_get(doc, "whois.parsed.registrant")}
          />
        </AppAssetsSectionBox>
      ) : null}
      {_has(doc, "whois.parsed.admin") ? (
        <AppAssetsSectionBox title="Administrative Contact" type="domain">
          <AppDocView
            colDefs={[
              { masterColumnName: "name", masterColumnTitle: "Name", renderType: "text" },
              {
                masterColumnName: "organization",
                masterColumnTitle: "Organization",
                renderType: "text",
              },
              { masterColumnName: "telephone", masterColumnTitle: "Phone", renderType: "text" },
              {
                masterColumnName: "email",
                masterColumnTitle: "Email",
                renderType: "text",
              },
              { masterColumnName: "country", masterColumnTitle: "Country", renderType: "text" },
            ]}
            doc={_get(doc, "whois.parsed.admin")}
          />
        </AppAssetsSectionBox>
      ) : null}
      {_has(doc, "whois.parsed.tech") ? (
        <AppAssetsSectionBox title="Technical Contact" type="domain">
          <AppDocView
            colDefs={[
              { masterColumnName: "name", masterColumnTitle: "Name", renderType: "text" },
              {
                masterColumnName: "organization",
                masterColumnTitle: "Organization",
                renderType: "text",
              },
              { masterColumnName: "telephone", masterColumnTitle: "Phone", renderType: "text" },
              {
                masterColumnName: "email",
                masterColumnTitle: "Email",
                renderType: "text",
              },
              { masterColumnName: "country", masterColumnTitle: "Country", renderType: "text" },
            ]}
            doc={_get(doc, "whois.parsed.tech")}
          />
        </AppAssetsSectionBox>
      ) : null}
    </Fragment>
  );
};
