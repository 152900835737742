import { ITableFilters } from "../modules/module.interface";
import axios from "./axios.service";

//summary module endpoints
export const FetchDmarcScore = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/dashboard/score");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDNSScore = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/dashboard/dns-score");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchIssuesSummary = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/dashboard/issues-summary");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchAuthorizedIPs = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/dashboard/authorized-ips");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchVerifiedSources = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/dashboard/verified-sources");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchSendingDomains = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/dashboard/sending-domains");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchCompliantEmails = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/dashboard/compliant-emails");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchSendingStats = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/dashboard/sending-stats");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

//environment modules endpoints
export const FetchDomainsList = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/environment/domains-list");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDomainRecord = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/environment/domain-record");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDomainAuthSources = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/environment/domain-auth-sources");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDomainHistoricalDNS = async (
  record_type: string,
  selectedSelector: string
): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/environment/domain-historical-dns", {
      record_type,
      selectedSelector,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDomainHistoricalSelectors = async (record_type: string): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/environment/domain-historical-selectors", {
      record_type,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDomainEmailSources = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/environment/sources-list");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDmarcIssuesList = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/environment/issues-list");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchDmarcIssueDetails = async (issue_name: string): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/environment/issue-details", {
      issue_name,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

// dmarc analytics module endpoints
export const FetchAnalyticsEmailSources = async (category: string): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/analytics/sources-list", { category });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchAnalyticsComplianceOvertime = async (category: string): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/analytics/compliance-overtime", { category });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchAnalyticsCategoriesCompliance = async (category: string): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/analytics/categories-compliance", { category });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchAnalyticsEmailsAuthScores = async (category: string): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/analytics/emails-auth-scores", { category });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchAnalyticsSourceDetailsList = async (
  source: string,
  category: string
): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/analytics/source-details", { source, category });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchAnalyticsDkimSourcesList = async (filters: ITableFilters[]): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/analytics/dkim-sources-list", { filters });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const FetchAnalyticsIpInfo = async (ipAddress: string): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/analytics/ip-info", { ipAddress });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};

export const FetchDmarcMemberRecord = async (): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/member-record");
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
export const DmarcVerifyDnsRecord = async (
  selectedDomain: string,
  selectedMember: number
): Promise<any> => {
  try {
    const response = await axios.post("/dmarc/verify-dns", {
      selectedDomain: selectedDomain,
      selectedMember: selectedMember,
    });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
