export const getSeverityStr = (severity: number): string => {
  let severityStr: string;
  switch (severity) {
    case -1:
      severityStr = "unassigned";
      break;
    case 0:
      severityStr = "informational";
      break;
    case 1:
      severityStr = "low";
      break;
    case 2:
      severityStr = "medium";
      break;
    case 3:
      severityStr = "high";
      break;
    case 4:
      severityStr = "critical";
      break;
    default:
      severityStr = "informational";
  }

  return severityStr;
};
export const getSeverityNumber = (severity: string): number => {
  switch (severity) {
    case "unassigned":
      return -1;
    case "informational":
      return 0;

    case "low":
      return 1;

    case "medium":
      return 2;

    case "high":
      return 3;

    case "critical":
      return 4;

    default:
      return 0;
  }
};

export const HV_ISSUES_SEVERITY = ["informational", "low", "medium", "high", "critical"];
