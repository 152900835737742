import React, { Fragment, useEffect } from "react";
import { IRiskProfile } from "../module.interface";
import { Alert, Box, Button, Chip, LinearProgress, Theme, useTheme } from "@mui/material";
import { FetchRiskProfileIssues } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import { IESDoc } from "../../App.interface";
import { isEmpty, startCase } from "lodash";
import { EmptyChart } from "../views/chart.empty";
import { AppMasterTable } from "../views/app.table";
import { getSeverityStr } from "../../util/severity-mappings";
import { useHistory } from "react-router-dom";

export const RiskProfileDetails: React.FC<{ doc: IRiskProfile }> = ({ doc }) => {
  const theme = useTheme<Theme>();
  const { push } = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const RiskProfileIssueCategories = () => {
    setLoading(true);
    FetchRiskProfileIssues(doc.key)
      .then((response) => {
        setDocs(response.data);
      })
      .catch(() => {
        toast(`Error loading issue details for ${doc.key}`, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(RiskProfileIssueCategories, [doc.key]);
  return (
    <Fragment>
      {doc.TOTAL_ISSUES.value > 0 ? (
        <Box>
          {loading ? (
            <LinearProgress />
          ) : isEmpty(docs) ? (
            <EmptyChart title={""} />
          ) : (
            <Box>
              <AppMasterTable
                loading={false}
                dataSource={""}
                name={""}
                title={""}
                colDefs={[
                  {
                    masterColumnName: "issue_name",
                    masterColumnTitle: "Issue",
                    renderType: "text",
                    filterable: true,
                    sortable: true,
                  },

                  {
                    masterColumnName: "issue_type",
                    masterColumnTitle: "Issue Type",
                    renderType: "text",
                    filterable: true,
                    sortable: true,
                  },
                  {
                    masterColumnName: "severity",
                    masterColumnTitle: "Severity",
                    renderType: "severity",
                    filterable: true,
                    sortable: true,
                    cellRender: (params) => (
                      <Chip
                        size="small"
                        className="severity-chip"
                        style={{
                          color: theme?.severity[getSeverityStr(params.severity)],
                          borderColor: theme?.severity[getSeverityStr(params.severity)],
                        }}
                        variant="outlined"
                        label={startCase(getSeverityStr(params.severity))}
                      />
                    ),
                  },
                  {
                    masterColumnName: "count",
                    masterColumnTitle: "Issues Detected",
                    renderType: "number",
                    filterable: true,
                    sortable: true,
                  },
                ]}
                docs={docs}
                checkedDocs={[]}
                totalDocs={docs.length}
                pageIndex={0}
                pageSize={docs.length}
                sortField={""}
                sortOrder={"desc"}
                enablePagination={false}
                enableCheckBox={false}
                enableSelection={false}
                enableBrandColum={false}
                onChangeSortField={() => null}
                onChangeSortOrder={() => null}
                onChangePageIndex={() => null}
                onChangePageSize={() => null}
                onChangeCheckedDocs={() => null}
                onChangeSelectedDoc={() => null}
              />
              <Box textAlign={"center"} margin={"8px 0"}>
                <Button
                  className="view-all-btn"
                  size="small"
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    push("/hackerview/issues");
                  }}
                >
                  View All
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Alert severity="success">No issues found</Alert>
      )}
    </Fragment>
  );
};
