import {
  LinearProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  Checkbox,
  TableSortLabel,
  Typography,
  TableBody,
  Button,
  TablePagination,
} from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";
import { Fragment, useCallback, useEffect } from "react";
import { IESDoc } from "../../App.interface";
import { useBehaviourSubject, useObservable } from "../../hooks/use-observable";
import { BrandService } from "../../services/brand.service";
import { user$ } from "../../state/user";
import { RenderCell } from "../../util/render.cell";
import { IMasterColumns } from "../module.interface";
import { EmptyTableView } from "./EmptyTable";
import useDeepCompareEffect from "use-deep-compare-effect";
interface IAppMasterTable {
  loading: boolean;
  dataSource: string;
  name: string;
  title: string;
  colDefs: IMasterColumns[];
  docs: IESDoc[];
  checkedDocs: IESDoc[];
  totalDocs: number;
  pageIndex: number;
  pageSize: number;
  sortField: string;
  sortOrder: "asc" | "desc";
  enablePagination: boolean;
  enableCheckBox: boolean;
  enableSelection: boolean;
  enableBrandColum: boolean;
  onChangeSortField: (field: string) => void;
  onChangeSortOrder: (order: "asc" | "desc") => void;
  onChangePageIndex: (index: number) => void;
  onChangePageSize: (size: number) => void;
  onChangeCheckedDocs: (docs: IESDoc[]) => void;
  onChangeSelectedDoc: (doc: IESDoc | undefined) => void;
  selectionNode?: ReactNode;
}
export const AppMasterTable: React.FC<IAppMasterTable> = ({
  loading,
  dataSource,
  name,
  title,
  colDefs,
  docs,
  checkedDocs,
  totalDocs,
  pageIndex,
  pageSize,
  sortField,
  sortOrder,
  enablePagination,
  enableCheckBox,
  enableSelection,
  enableBrandColum,
  onChangeCheckedDocs,
  onChangeSortField,
  onChangeSortOrder,
  onChangePageIndex,
  onChangePageSize,
  onChangeSelectedDoc,
  selectionNode,
}) => {
  const user = useBehaviourSubject(user$);
  const selectedMemberIds = useObservable<number[]>(BrandService.selectedMemberIds$);
  const [masterColumns, setMasterColumns] = React.useState<IMasterColumns[]>(colDefs);
  const onChangeCheckedDocument = (doc: IESDoc) => {
    const index = _.findIndex(checkedDocs, (d) => {
      return d._id === doc._id;
    });
    if (index === -1) {
      onChangeCheckedDocs([...checkedDocs, doc]);
    } else {
      onChangeCheckedDocs(checkedDocs.filter((d) => d._id !== doc._id));
    }
  };
  const displayBrandColumn = useCallback(() => {
    if (user) {
      if (user.multi_brand) {
        if (selectedMemberIds) {
          if ((_.isEmpty(selectedMemberIds) || selectedMemberIds.length > 1) && enableBrandColum) {
            setMasterColumns((prev) => prev.filter((col) => col.masterColumnName !== "brand"));
            setMasterColumns((arr) => [
              ...arr,
              {
                masterColumnName: "brand",
                masterColumnTitle: "Brand",
                renderType: "text",
                filterable: true,
                sortable: true,
              },
            ]);
          } else {
            setMasterColumns((prev) => prev.filter((col) => col.masterColumnName !== "brand"));
          }
        }
      } else {
        setMasterColumns((prev) => prev.filter((col) => col.masterColumnName !== "brand"));
      }
    }
  }, [enableBrandColum, selectedMemberIds, user]);
  useDeepCompareEffect(() => {
    setMasterColumns(colDefs);
  }, [colDefs]);
  useEffect(() => {
    displayBrandColumn();
  }, [displayBrandColumn]);

  return (
    <Fragment>
      <Box className="module-table-container" id={dataSource} key={title}>
        {loading ? <LinearProgress /> : null}
        <Box className="master-table-wrapper">
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {enableCheckBox ? (
                    <TableCell padding="checkbox">
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                      >
                        <Grid item>
                          <Checkbox
                            color="primary"
                            indeterminate={
                              checkedDocs.length > 0 && checkedDocs.length < docs.length
                            }
                            checked={checkedDocs.length > 0 && checkedDocs.length === docs.length}
                            onChange={() =>
                              checkedDocs.length !== docs.length
                                ? onChangeCheckedDocs(docs)
                                : onChangeCheckedDocs([])
                            }
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : null}
                  {masterColumns.map((col) => (
                    <TableCell key={`${col.masterColumnName}_${col.masterColumnTitle}`}>
                      <TableSortLabel
                        active={sortField === col.masterColumnName}
                        direction={sortOrder}
                        onClick={() => {
                          onChangeSortField(col.masterColumnName);
                          onChangeSortOrder(sortOrder === "asc" ? "desc" : "asc");
                        }}
                      >
                        <Typography
                          color="secondary"
                          variant="subtitle2"
                          className="table-cell-header"
                        >
                          {_.startCase(col.masterColumnTitle)}
                        </Typography>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {enableSelection ? <TableCell></TableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? (
                  !_.isEmpty(docs) ? (
                    docs.map((row, pos) => (
                      <TableRow hover key={row._id}>
                        {enableCheckBox ? (
                          <TableCell padding="checkbox">
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              alignContent="center"
                              wrap="nowrap"
                            >
                              <Grid item>
                                <Checkbox
                                  color="primary"
                                  checked={
                                    _.findIndex(checkedDocs, (d) => d._id === row._id) !== -1
                                  }
                                  onChange={() => onChangeCheckedDocument(row)}
                                  inputProps={{
                                    "aria-label": `primary checkbox ${row._id}`,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </TableCell>
                        ) : null}
                        {masterColumns.map((col) => (
                          <TableCell
                            key={`${col.masterColumnName}_${col.masterColumnTitle}`}
                            className={`cell-${col.renderType}`}
                          >
                            <span className="data-cell">
                              {col.cellRender
                                ? col.cellRender(row)
                                : _.has(row, col.masterColumnName) &&
                                  !_.isNull(_.get(row, col.masterColumnName)) &&
                                  !_.isEmpty(_.get(row, col.masterColumnName).toString()) &&
                                  !_.isUndefined(_.get(row, col.masterColumnName)) &&
                                  !_.isNaN(_.get(row, col.masterColumnName))
                                ? RenderCell(_.get(row, col.masterColumnName), col.renderType)
                                : "-"}
                            </span>
                          </TableCell>
                        ))}
                        {enableSelection ? (
                          <TableCell
                            id={`${name}_details_button_${pos}`}
                            align="right"
                            onClick={() => onChangeSelectedDoc(row)}
                          >
                            {selectionNode ? (
                              selectionNode
                            ) : (
                              <Button className="view-more-btn-cell" color="primary" size="small">
                                Details
                              </Button>
                            )}
                          </TableCell>
                        ) : null}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={
                          masterColumns.length +
                          (enableCheckBox ? 1 : 0) +
                          (enableSelection ? 1 : 0)
                        }
                      >
                        <EmptyTableView />
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
              </TableBody>
            </Table>
            {enablePagination ? (
              <TablePagination
                component="div"
                count={loading ? 0 : totalDocs}
                page={loading ? 0 : pageIndex}
                rowsPerPage={pageSize}
                rowsPerPageOptions={_.sortBy(_.uniq([10, 25, 100, pageSize]))}
                onPageChange={(event, page) => onChangePageIndex(page)}
                onRowsPerPageChange={(event) => {
                  onChangePageIndex(0);
                  onChangePageSize(parseInt(event.target.value));
                }}
              />
            ) : null}
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  );
};
