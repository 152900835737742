import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { Fragment } from "react";
import { useBehaviourSubject, useObservable } from "../../../hooks/use-observable";
import { user$ } from "../../../state/user";
import { AppCard } from "../../views/app.card";
import { DomainProtectionType } from "./domain_protection_type";
import { DomainProtectionRisks } from "./domain_protection_risks";
import { CredentialLeakType } from "./credential_leak_type";
import { MalwareLogType } from "./malware_log_type";
import { TakedownRequests } from "./takedown_requests";
import useDeepCompareEffect from "use-deep-compare-effect";
import { BrandService } from "../../../services/brand.service";
import { UUIDGenerator } from "../../../util/uuid";

export const CBSMainDashboard: React.FC = () => {
  const user = useBehaviourSubject(user$);
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const [updateId, setUpdateId] = React.useState("");
  const generateGreeting = (): string => {
    const hours = parseInt(moment().format("HH"));
    if (hours >= 3 && hours < 12) {
      return "Good Morning";
    } else if (hours >= 12 && hours < 15) {
      return "Good Afternoon";
    } else if (hours >= 15 && hours < 20) {
      return "Good Evening";
    } else {
      return "Welcome back,";
    }
  };
  useDeepCompareEffect(() => {
    setUpdateId(UUIDGenerator());
  }, [selectedMemberIds]);
  return (
    <Fragment>
      <Box className="threat-manager-fuse-dashboard-container">
        <Box className="fuse-dashboard-welcome-wrapper">
          <Typography variant="h6" color="textPrimary" className="user-greeting">
            {`${generateGreeting()} ${user?.given_name} !`}
          </Typography>
          <Typography
            variant="subtitle1"
            component={"p"}
            color="textSecondary"
            className="user-greeting-info"
          >
            {"Here's what's happening with your incidents."}
          </Typography>
        </Box>

        <Box marginTop={4}>
          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <AppCard
                name={""}
                title={"Typo squatted Domains"}
                description={"Breakdown of look alike domains by type"}
              >
                <DomainProtectionType updateId={updateId} />
              </AppCard>
            </Grid>
            <Grid item xl={8} lg={8} md={6} sm={6} xs={12}>
              <DomainProtectionRisks updateId={updateId} />
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={4}>
          <Grid container spacing={2}>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <AppCard
                name={""}
                title={"Credential Leaks"}
                description={"Breakdown of credential leaks"}
              >
                <CredentialLeakType updateId={updateId} />
              </AppCard>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <AppCard name={""} title={"Leak types"} description={"Breakdown of types of leaks"}>
                <MalwareLogType updateId={updateId} />
              </AppCard>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
              <TakedownRequests updateId={updateId} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Fragment>
  );
};
