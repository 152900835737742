import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Box,
  TextField,
} from "@mui/material";
import React, { Fragment } from "react";
import { useBehaviourSubject } from "../../hooks/use-observable";
import { user$ } from "../../state/user";
import { LoadingButton } from "@mui/lab";
import { SubmitCustomQuote } from "../../services/pricing.service";
import { toast } from "../../state/snackbar";
interface ICustomSalesQuotation {
  platform: string;
  onClose: () => void;
  onSuccess: () => void;
}
export const CustomSalesQuotation: React.FC<ICustomSalesQuotation> = ({
  platform,
  onClose,
  onSuccess,
}) => {
  const user = useBehaviourSubject(user$);
  const [loading, setLoading] = React.useState(false);
  const [customMessage, setCustomMessage] = React.useState(
    `Hey CTM360, \n\n This is ${user?.name} from ${user?.organization}.\n I would like to get a personalized pricing plan for your ${platform} platform !`
  );
  const onSubmitQuote = () => {
    setLoading(true);
    SubmitCustomQuote(platform, customMessage)
      .then(() => {
        toast("Request received successfully", "success");
        toast("Please expect callback within 24 hours", "info");
        onClose();
        onSuccess();
      })
      .catch(() => {
        toast("Error in requesting custom quote", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Box padding={4}>
            <Box marginBottom={4}>
              <Typography variant="h6" fontWeight={600} color={"primary"}>
                <strong>Happy to Help!</strong>
              </Typography>
              <Typography variant="subtitle2" color={"textSecondary"}>
                Need a personalized quote. We are on it.
              </Typography>
            </Box>
            <Box marginBottom={2}>
              <Typography variant="subtitle2" fontWeight={600} marginBottom={1}>
                {"Do you have any thoughts you'd like to share?"}
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                multiline
                rows={7}
                value={customMessage}
                fullWidth
                onChange={(e) => setCustomMessage(e.target.value)}
              />
            </Box>
            <Box>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <LoadingButton
                  autoFocus
                  color="primary"
                  variant="contained"
                  onClick={onSubmitQuote}
                  loading={loading}
                >
                  send
                </LoadingButton>
              </DialogActions>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
