import { Skeleton, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { FetchBrandName } from "../services/query.service";
import { IESDoc } from "../App.interface";

export const BrandName: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [doc, setDoc] = React.useState<IESDoc>();
  const FetchName = () => {
    setLoading(true);
    FetchBrandName()
      .then((response) => {
        setDoc(response);
      })
      .catch(() => {
        //
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(FetchName, []);
  return (
    <Fragment>
      {loading ? (
        <Skeleton width={150} height={40} />
      ) : doc ? (
        <span className="user-organization-name">
          <Typography variant="h6" color="inherit">
            {doc.brand}
          </Typography>
        </span>
      ) : null}
    </Fragment>
  );
};
