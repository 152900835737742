import React, { Fragment } from "react";
import { IESDoc } from "../../../App.interface";
import { Box } from "@mui/material";
import { AppAssetsSectionBox } from "../../views/app.section.box";
import { AppDocView } from "../../views/app.doc.view";
import { RiskScore } from "../../views/risk_score";
import { RiskRules } from "../../views/risk_rules";

export const DomainProtectionDetails: React.FC<{ doc: IESDoc }> = ({ doc }) => {
  return (
    <Fragment>
      {/* <Box marginBottom={2}>
        <ThreatsScreenshot doc={doc} disableFallback={true} />
      </Box> */}
      <Box marginBottom={2}>
        <Box>
          <AppAssetsSectionBox title={"Overview"} type={doc._id}>
            <Box padding={"4px"} paddingBottom={1.5}>
              <AppDocView
                doc={doc}
                colDefs={[
                  {
                    masterColumnName: "@timestamp",
                    masterColumnTitle: "Detected on",
                    renderType: "date_time",
                  },
                  {
                    masterColumnName: "brand",
                    masterColumnTitle: "Targeted Brand",
                    renderType: "brand",
                  },

                  {
                    masterColumnName: "detection_type",
                    masterColumnTitle: "Detection Type",
                    renderType: "title",
                  },
                  {
                    masterColumnName: "hackishness",
                    masterColumnTitle: "Risk Score",
                    renderType: "text",
                    cellRender: (params) => <RiskScore doc={params} field="hackishness" />,
                  },

                  {
                    masterColumnName: "risks",
                    masterColumnTitle: "Risks",
                    renderType: "text",
                    cellRender: (params: IESDoc) => <RiskRules doc={params} />,
                  },
                  {
                    masterColumnName: "under_monitoring",
                    masterColumnTitle: "Monitoring Status",
                    renderType: "boolean",
                  },
                  {
                    masterColumnName: "kw_matched",
                    masterColumnTitle: "Keywords Matched",
                    renderType: "array",
                  },
                  {
                    masterColumnName: "regex_matched",
                    masterColumnTitle: "Regex Matched",
                    renderType: "array",
                  },
                  {
                    masterColumnName: "susp_kw_matched",
                    masterColumnTitle: "Suspicious Keywords Matched",
                    renderType: "array",
                  },
                ]}
              />
            </Box>
          </AppAssetsSectionBox>
        </Box>
      </Box>
      <Box marginBottom={2}>
        <AppAssetsSectionBox title="DNS Records" type={"DNSInfo"}>
          <AppDocView
            doc={doc}
            colDefs={[
              {
                masterColumnName: "a_record",
                masterColumnTitle: "A Record",
                renderType: "url_array",
              },
              {
                masterColumnName: "cname_record",
                masterColumnTitle: "CNAME Record",
                renderType: "array",
              },
              {
                masterColumnName: "ns_record",
                masterColumnTitle: "NS Record",
                renderType: "array",
              },
              {
                masterColumnName: "mx_record",
                masterColumnTitle: "MX Record",
                renderType: "array",
              },
              {
                masterColumnName: "spf_record",
                masterColumnTitle: "SPF Record",
                renderType: "array",
              },
              {
                masterColumnName: "dkim_record",
                masterColumnTitle: "DKIM Record",
                renderType: "array",
              },
              {
                masterColumnName: "dmarc_record",
                masterColumnTitle: "DMARC Record",
                renderType: "array",
              },
              {
                masterColumnName: "txt_record",
                masterColumnTitle: "TXT Record",
                renderType: "array",
              },
              {
                masterColumnName: "soa_record",
                masterColumnTitle: "SOA Record",
                renderType: "array",
              },
            ]}
          />
        </AppAssetsSectionBox>
      </Box>
      <Box marginBottom={2}>{/* <DomainWHOIS doc={doc} disableFallback={true} /> */}</Box>
    </Fragment>
  );
};
