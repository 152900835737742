import { Box, Icon, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { IESDoc } from "../../../App.interface";
import { toast } from "../../../state/snackbar";
import { CreditCardVendor, CreditCardLogo, CreditCardFormatter } from "../../../util/credit_card";

export const CardNumberView: React.FC<{ doc: IESDoc }> = ({ doc }) => {
  const CopyCardNumber = () => {
    navigator.clipboard.writeText(doc.card_number);
    toast("Card Number copied to clipboard", "info");
  };
  return (
    <Fragment>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <Box>
          <Box
            bgcolor={"#ffffff"}
            padding={"4px 4px"}
            className={`${CreditCardVendor(doc.card_number)}-card-logo`}
            width={45}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"2px"}
          >
            <img width={"30px"} src={CreditCardLogo(doc.card_number)} alt={`${doc._id}-logo`} />
          </Box>
        </Box>
        <Box minWidth={"170px"}>
          <Typography fontSize={"13px"} fontWeight={600} whiteSpace={"nowrap"}>
            {CreditCardFormatter(doc.card_number, " - ")}
          </Typography>
        </Box>
        <Box>
          <Tooltip title={"Copy Card Number"}>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                CopyCardNumber();
              }}
            >
              <Icon fontSize="inherit" style={{ fontSize: "16px", opacity: 0.5 }}>
                content_copy
              </Icon>
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </Fragment>
  );
};
