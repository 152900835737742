import { Box, useTheme, Skeleton, Divider, Grid, Paper, Typography } from "@mui/material";
import moment from "moment";
import React, { Fragment } from "react";
import _ from "lodash";
import { ResponsiveContainer } from "recharts";
import useDeepCompareEffect from "use-deep-compare-effect";
import ReactApexChart from "react-apexcharts";
import { useBehaviourSubject } from "../../../../../hooks/use-observable";
import { user$ } from "../../../../../state/user";
import { BrandService } from "../../../../../services/brand.service";
import { KFormatter } from "../../../../../util/k_formatter";
import { FetchSendingStats } from "../../../../../services/dmarc.service";
import { toast } from "../../../../../state/snackbar";
import { EmptyChart } from "../../../../views/chart.empty";

export const DmarcDashboardSendStats: React.FC = () => {
  const user = useBehaviourSubject(user$);
  const theme = useTheme();
  const [loadingStat, setLoadingStat] = React.useState(false);
  const selectedMemberIds = useBehaviourSubject<number[]>(BrandService.selectedMemberIds$) || [];
  const [categories, setCategories] = React.useState<string[]>([]);
  const [histogram, setHisogram] = React.useState<ApexAxisChartSeries>([]);

  const chartSeries = histogram;
  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          customIcons: [],
        },
      },
      type: "area",
      stacked: false,
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 2,
        animateGradually: {
          enabled: true,
          delay: 1,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    // theme: {
    //   palette: "palette3",
    // },
    fill: {
      opacity: 0.7,
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0.8,
        opacityFrom: 0.2,
        opacityTo: 0.5,
        inverseColors: true,
        stops: [0, 100],
      },
    },
    colors: ["#33B2DF", "#13D8AA", "#D4526E"],

    stroke: {
      curve: "smooth",
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      categories: categories,
      type: "datetime",
      labels: {
        style: {
          fontFamily: "Montserrat",
          fontSize: "10px",
          colors: theme.palette.text.secondary,
        },
      },
      axisBorder: {
        show: true,
        color: theme.palette.background.default,
      },
      axisTicks: {
        show: true,
        color: theme.palette.background.default,
      },
    },
    yaxis: {
      title: {
        style: {
          color: theme.palette.text.primary,
        },
      },
      axisBorder: {
        show: true,
        color: theme.palette.background.default,
      },
      axisTicks: {
        show: true,
        color: theme.palette.background.default,
      },
      labels: {
        style: {
          fontFamily: "Montserrat",
          fontSize: "10px",
          colors: theme.palette.text.secondary,
        },
        formatter: (e) => KFormatter(e),
      },
    },
    grid: {
      show: false,
      row: {
        colors: [theme.palette.background.paper, theme.palette.background.default],
        opacity: 1,
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      fillSeriesColor: true,
      x: {
        show: false,
      },
    },

    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "10px",
      offsetY: 10,
      labels: {
        colors: theme.palette.text.secondary,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "10px",
            labels: {
              colors: theme.palette.text.secondary,
            },
            customLegendItems: [],
          },
        },
      },
    ],
  };

  const fetchSendingStats = () => {
    setLoadingStat(true);
    FetchSendingStats()
      .then((response) => {
        // console.log(response);
        const compliant: number[] = [];
        const non_compliant: number[] = [];
        const forwarded: number[] = [];
        const unauthenticated: number[] = [];
        _.forEach(response.aggs?.HISTOGRAM?.buckets, (doc) => {
          compliant.push(
            doc?.CATEGORIES?.buckets?.filter((f: any) => f?.key === "compliant")?.[0]?.COUNT
              ?.value || 0
          );
          non_compliant.push(
            doc?.CATEGORIES?.buckets?.filter((f: any) => f?.key === "non-compliant")?.[0]?.COUNT
              ?.value || 0
          );
          forwarded.push(
            doc?.CATEGORIES?.buckets?.filter((f: any) => f?.key === "forwarded")?.[0]?.COUNT
              ?.value || 0
          );
          unauthenticated.push(
            doc?.CATEGORIES?.buckets?.filter((f: any) => f?.key === "unauthenticated")?.[0]?.COUNT
              ?.value ||
              0 +
                doc?.CATEGORIES?.buckets?.filter((f: any) => f?.key === "non-compliant")?.[0]?.COUNT
                  ?.value ||
              0
          );
        });

        setHisogram([
          {
            name: "Compliant",
            data: compliant,
          },
          {
            name: "Forwarded",
            data: forwarded,
          },
          {
            name: "Unauthenticated",
            data: unauthenticated,
          },
        ]);

        setCategories(
          response.aggs?.HISTOGRAM?.buckets?.map((e: any) =>
            moment(parseInt(e?.key)).format("L")
          ) || []
        );
      })
      .catch(() => {
        toast("Error fetching Sending Stats data", "error");
      })
      .finally(() => {
        setLoadingStat(false);
      });
  };

  useDeepCompareEffect(() => {
    fetchSendingStats();
  }, [ selectedMemberIds]);

  return (
    <Fragment>
      <Paper style={{ height: "100%" }}>
        <Grid container direction="column" wrap="nowrap">
          <Grid item sx={{ paddingX: "8px" }}>
            <Grid container alignItems="center" style={{ margin: "10px 12px" }}>
              <Grid item xs={10}>
                <Typography color="primary" sx={{ fontSize: 13, fontWeight: 600 }}>
                  Sending Stats
                </Typography>
                <Typography color="textSecondary" sx={{ fontSize: 11 }}>
                  Your daily DMARC compliance statistics
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            {" "}
            <Divider />
          </Grid>

          <Grid item sx={{ padding: "8px" }}>
            <Box
              style={{
                display: "flex",
                height: "100%",
                width: "100%",
              }}
            >
              {loadingStat ? (
                <Skeleton variant="rectangular" height={300} />
              ) : user?.internal && _.isEmpty(selectedMemberIds) ? (
                <Box
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <EmptyChart title="no data found" />
                </Box>
              ) : (
                <ResponsiveContainer height={300}>
                  <ReactApexChart options={chartOptions} series={chartSeries} type="area" />
                </ResponsiveContainer>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};
