import { Box, Icon, IconButton, Stack, Tooltip } from "@mui/material";
import range from "lodash/range";

import React, { Fragment } from "react";
import { IESDoc } from "../../../App.interface";
import { UpgradeModel } from "../../../views/upgrade.model";

export const LeakPasswordMasker: React.FC<{ doc: IESDoc }> = ({ doc }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Fragment>
      {doc.password ? (
        <Box>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Box>
              <Stack direction={"row"} spacing={0.5}>
                {range(8).map((x) => (
                  <Icon style={{ fontSize: "6px" }} key={x.toString()}>
                    circle
                  </Icon>
                ))}
              </Stack>
            </Box>
            <Tooltip title={"Show Password"}>
              <IconButton
                size="small"
                onClick={(e) => {
                  setShowPassword((flag) => !flag);
                  e.stopPropagation();
                }}
              >
                <Icon fontSize="small">visibility</Icon>
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
      ) : (
        "-"
      )}
      {showPassword ? (
        <UpgradeModel
          onClose={() => {
            setShowPassword(false);
          }}
        />
      ) : null}
    </Fragment>
  );
};
