import _ from "lodash";
import moment from "moment";
import { IDateFilterObj } from "../modules/module.interface";

export const setInitialDateRanges = () => {
  const dateHistoryObj: IDateFilterObj[] = [
    {
      status: false,
      startDate: moment(new Date()).valueOf(),
      endDate: moment(new Date()).valueOf(),
      mode: "quick",
      value: "All Time",
    },
  ];
  let currentDateHistory: IDateFilterObj[] = JSON.parse(
    sessionStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.timePicker.timeHistory`) ||
      JSON.stringify(dateHistoryObj)
  );
  if (_.isEmpty(currentDateHistory)) {
    currentDateHistory = dateHistoryObj;
  }
  if (!_.isEmpty(currentDateHistory)) {
    if (currentDateHistory[currentDateHistory.length - 1].mode === "quick") {
      currentDateHistory[currentDateHistory.length - 1].endDate = moment(new Date()).valueOf();
    }
  }
  sessionStorage.setItem(
    `${process.env.REACT_APP_SSO_CLIENT_ID}.timePicker.timeHistory`,
    JSON.stringify(currentDateHistory)
  );
  return currentDateHistory[currentDateHistory.length - 1];
};
