import { LocalStorageBehaviorSubject } from "../util/localstorage-behaviour-subject";

export interface IAppPortalPolicy {
  role: string;
  policy: {
    [key: string]: {
      View: string[];
      Add: string[];
      Edit: string[];
      Delete: string[];
    };
  };
}
export interface IUser {
  email_verified: boolean;
  ted_ids: number[];
  organization: string;
  name: string;
  member_groups: number[];
  portal_policy: IAppPortalPolicy;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
  internal: boolean;
  user_role: string;
  designation: string;
  multi_brand: boolean;
  realm_resource_id: string;
}

export const user$ = new LocalStorageBehaviorSubject<IUser | null>(
  `${process.env.REACT_APP_SSO_CLIENT_ID}.sso.user`,
  null
);
