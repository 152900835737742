import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, { Fragment } from "react";
import "../../styles.scss";

interface Type {
  array: { label: string; value: string; id: string }[];
  title: string;
}
export default function OverviewTextArray({ title, array }: Type) {
  return (
    <Fragment>
      <Box className="cyber-assets-section-box" id={`${title}_advanced_view_section_box_${title}`}>
        <Box className="section-box-header">
          <Typography variant="h6" color="secondary" className="section-title">
            {title}
          </Typography>
        </Box>
        <Paper className="section-box-contents">
          <Fragment>
            <TableContainer>
              <Table size="small" className="info-table">
                <TableBody>
                  {array.map((x, index) => {
                    return (
                      <TableRow key={`${x.id}_${index}`}>
                        <TableCell>
                          <Typography variant="h6" className="item-label" color="textSecondary">
                            {x.label}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <span className="item-value">{x.value ? x.value : "-"}</span>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        </Paper>
      </Box>
    </Fragment>
  );
}
