import { Box, LinearProgress } from "@mui/material";
import React, { Fragment, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useBehaviourSubject } from "../hooks/use-observable";
import { RootRouter } from "../routes";
import { BrandService } from "../services/brand.service";
import { sidebar$ } from "../state/sidebar";
import { user$ } from "../state/user";
import { setLatestBuildHash } from "../util/cache-burster";
import {
  getSessionFingerPrint,
  getUserFingerPrint,
  getSessionSignature,
  setUserFingerPrint,
  saveSessionSignature,
  getCurrentFingerPrint,
  lastSyncDuration,
  userChangeRoute,
  clearTrackedSession,
} from "../util/user.activity.fingerprint";
import { getSourceVersion } from "../util/user.attributes";
import { ErrorBoundary } from "./error-boundary/error.boundary";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import "./styles.scss";
import "../modules/module.styles.scss";
import { AppFeedback } from "../components/feedback/Feedback";
import { LogUserFingerPrint } from "../services/query.service";
import { AppMD5Hash } from "../util/md5.hash";

export const AppMasterView: React.FC<{ theme: string }> = () => {
  const user = useBehaviourSubject(user$);
  const loadingBrands = useBehaviourSubject(BrandService.loadingBrands$);
  const { listen } = useHistory();
  const sidebarStatus = useBehaviourSubject(sidebar$);

  const cacheFlushService = () => {
    const currentBuildHash = getSourceVersion();
    fetch(`/meta.json?t=${new Date().valueOf()}`)
      .then((response) => response.json())
      .then((meta) => {
        if (meta.version !== currentBuildHash) {
          setLatestBuildHash(meta.version);
          window.location.reload();
        }
      })
      .catch(() => {
        //
      });
  };
  const saveCurrentSession = () => {
    const currentSession = getSessionFingerPrint();
    const historicSession = getUserFingerPrint();
    const sessionSignature = getSessionSignature();
    historicSession.push(...currentSession);
    setUserFingerPrint(historicSession);
    saveSessionSignature(sessionSignature);
  };
  const saveUserFingerPrint = useCallback(() => {
    const sessionSignature = getSessionSignature();
    const userFingerPrint = getCurrentFingerPrint();
    if (user && userFingerPrint.length) {
      LogUserFingerPrint([
        {
          _id: AppMD5Hash(`${user.email}`),
          session_signature: sessionSignature,
          user_email: user.email,
          user_name: user.name,
          user_organization: user.organization,
          tracked_session: userFingerPrint,
        },
      ]).then((response) => {
        if (!response.errors) {
          clearTrackedSession();
        }
      });
    }
  }, [user]);
  const checkToPushUserSession = useCallback(() => {
    if (lastSyncDuration() >= 15) {
      saveUserFingerPrint();
    }
  }, [saveUserFingerPrint]);

  useEffect(() => {
    window.addEventListener("beforeunload", saveCurrentSession);
    return () => {
      window.removeEventListener("beforeunload", saveCurrentSession);
    };
  }, []);

  useEffect(() => {
    listen(() => {
      setTimeout(() => {
        userChangeRoute(window.location.pathname);
      }, 3000);
    });
  }, [listen]);
  //////////////////////////CACHE FLUSHING/////////////////////////////
  useEffect(() => {
    cacheFlushService();
  }, []);
  useEffect(() => {
    let interval: any = null;
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      cacheFlushService();
    }, 900000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  //////////////////////////SESSION FINGERPRINT/////////////////////////////
  useEffect(() => {
    let interval: any = null;
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      checkToPushUserSession();
    }, 180000);
    return () => {
      clearInterval(interval);
    };
  }, [checkToPushUserSession]);
  useEffect(() => {
    checkToPushUserSession();
  }, [checkToPushUserSession]);
  return (
    <Fragment>
      <ErrorBoundary>
        <Box
          className={`app-webapp-master-container ${
            sidebarStatus ? "sidebar-expanded" : "sidebar-collapsed"
          }`}
        >
          {/* <TopPanel /> */}
          <Header />
          <Sidebar />
          <main className="router-container">
            {loadingBrands ? <LinearProgress /> : <RootRouter />}
          </main>
          <AppFeedback />
        </Box>
      </ErrorBoundary>
    </Fragment>
  );
};
