import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Box,
  TextField,
  Rating,
} from "@mui/material";
import React, { Fragment } from "react";
import { LoadingButton } from "@mui/lab";
import { toast } from "../../state/snackbar";
import { SubmitAppFeedback } from "../../services/query.service";
interface IAppFeedbackModel {
  onClose: () => void;
}
export const AppFeedbackModel: React.FC<IAppFeedbackModel> = ({ onClose }) => {
  const [loading, setLoading] = React.useState(false);
  const [customMessage, setCustomMessage] = React.useState("");
  const [value, setValue] = React.useState<number | null>(0);
  const onSubmitQuote = () => {
    setLoading(true);
    SubmitAppFeedback(value ? value : 0, customMessage)
      .then(() => {
        toast("Thank you very much for your feedback", "success");
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Box padding={4}>
            <Box marginBottom={4}>
              <Typography variant="h6" fontWeight={600} color={"primary"}>
                <strong>Give Feedback!</strong>
              </Typography>
              <Typography variant="subtitle2" color={"textSecondary"}>
                What do you think of the Lite Platform
              </Typography>
            </Box>
            <Box marginBottom={2}>
              <Box marginBottom={2}>
                <Rating
                  name="simple-controlled"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  size="large"
                />
              </Box>
              <Typography variant="subtitle2" fontWeight={600} marginBottom={1}>
                {"Do you have any thoughts you'd like to share?"}
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                multiline
                rows={7}
                value={customMessage}
                fullWidth
                onChange={(e) => setCustomMessage(e.target.value)}
              />
            </Box>
            <Box>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <LoadingButton
                  autoFocus
                  color="primary"
                  variant="contained"
                  onClick={onSubmitQuote}
                  loading={loading}
                >
                  send
                </LoadingButton>
              </DialogActions>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
