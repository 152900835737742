import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";
import { IESDoc } from "../../App.interface";
import { RenderCell } from "../../util/render.cell";
import { IMasterColumns } from "../module.interface";
import { EmptyTableView } from "../views/EmptyTable";

interface IFeedMasterTable {
  loading: boolean;
  dataSource: string;
  name: string;
  title: string;
  colDefs: IMasterColumns[];
  docs: IESDoc[];
}

export const FeedMasterTable: React.FC<IFeedMasterTable> = ({
  loading,
  dataSource,
  colDefs,
  docs,
}) => {
  const theme = useTheme<Theme>();
  const [masterColumns] = React.useState<IMasterColumns[]>(colDefs);
  return (
    <Fragment>
      <Box className="dashboard-table" id={dataSource} style={{ margin: 0 }}>
        {loading ? <LinearProgress /> : null}
        <Box className="master-table-wrapper">
          <TableContainer className="dashboard-table" id={dataSource}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {masterColumns.map((col) => (
                    <TableCell key={`${col.masterColumnName}_${col.masterColumnTitle}`}>
                      <Typography
                        color="secondary"
                        variant="subtitle2"
                        className="table-cell-header"
                        style={{ fontSize: 12 }}
                      >
                        {col.masterColumnTitle}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? (
                  !_.isEmpty(docs) ? (
                    docs.map((row) => (
                      <TableRow hover key={row._id}>
                        {masterColumns.map((col) => (
                          <TableCell
                            key={`${col.masterColumnName}_${col.masterColumnTitle}`}
                            className={`cell-${col.renderType}`}
                          >
                            <span className="data-cell">
                              {_.has(row, col.masterColumnName) &&
                              !_.isEmpty(_.get(row, col.masterColumnName).toString()) &&
                              !_.isNull(_.get(row, col.masterColumnName)) &&
                              !_.isUndefined(_.get(row, col.masterColumnName)) &&
                              !_.isNaN(_.get(row, col.masterColumnName))
                                ? RenderCell(
                                    _.get(row, col.masterColumnName),
                                    col.renderType,
                                    theme
                                  )
                                : col.renderType === "risk_grade"
                                ? RenderCell(-1, "risk_grade", theme)
                                : "-"}
                            </span>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={masterColumns.length}>
                        <EmptyTableView />
                      </TableCell>
                    </TableRow>
                  )
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  );
};
