import { Box, Grid, Paper, Theme, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { GradeView } from "./grade.view";
import { ITechName } from "../module.interface";
import { findBrandLogo } from "../../util/brand.logo";
import { AppDrawer } from "../views/Drawer";
import { BlurInfo } from "../module.views/BlurInfo";

interface IEnvironmentPlatform {
  theme: Theme;
  technology: ITechName;
}

export const EnvironmentPlatform: React.FC<IEnvironmentPlatform> = ({ technology, theme }) => {
  const [brandLogo, setBrandLogo] = React.useState(
    findBrandLogo(technology.name) || "javascript.svg"
  );
  const [advancedView, setAdvancedView] = React.useState(false);
  useEffect(() => {
    setBrandLogo(findBrandLogo(technology.name) || "javascript.svg");
  }, [technology.name]);

  return (
    <Fragment>
      <Paper
        variant="outlined"
        className="technology-name-wrapper"
        onClick={() => setAdvancedView(true)}
      >
        <Box className="technology-logo-wrapper">
          <img
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            src={require(`../../assets/images/svg-logos/${brandLogo}`).default}
            alt={`logo-${technology.name}`}
          />
        </Box>
        <Box className="technology-name-header">
          <Typography variant="subtitle1" color="secondary">
            {technology.name}
          </Typography>
        </Box>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item>
            <GradeView theme={theme} score={technology.score} />
          </Grid>
          <Grid item>
            <Box className="asset-count">
              <Typography variant="subtitle1" color="textPrimary" className="value">
                {technology.assetCount.toLocaleString()}
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                component="span"
                className="label"
              >
                {technology.assetCount > 1 ? "Assets" : "Asset"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      {advancedView ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={technology.name}
          onClose={() => {
            setAdvancedView(false);
          }}
        >
          <BlurInfo />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
