import { ITableFilters } from "../../../module.interface";

export const indepthTabs = [
  {
    title: "Global Data",
    category: 0,
    link: "/global",
    data: [],
    value: "",
  },
  {
    title: "Compliant",
    category: 1,
    link: "/compliant",
    data: [],
    value: "compliant",
  },
  {
    title: "Forwarded",
    category: 2,
    link: "/forwarded",
    data: [],
    value: "forwarded",
  },
  {
    title: "Unauthenticated",
    category: 3,
    link: "/unauthenticated",
    data: [],
    value: "unauthenticated",
  },
];

export const getTabFilter = (selectedTab: number): ITableFilters[] => {
  const tab = indepthTabs.filter((item) => item.category === selectedTab);
  const value = tab[0].value;
  const filters = [
    {
      id: "tab-filter",
      title: "tab-filter",
      field: "category",
      operator: "is one of",
      value: value,
    },
  ];
  if (selectedTab == 3) {
    filters.push({
      id: "tab-filter",
      title: "tab-filter",
      field: "category",
      operator: "is one of",
      value: "non-compliant",
    });
  }

  return filters;
};
