import { Box, Icon, Stack, Theme, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import { AppFeedbackModel } from "./feedback_model";

export const AppFeedback: React.FC = () => {
  const theme = useTheme<Theme>();
  const [open, setOpen] = React.useState(false);
  return (
    <Fragment>
      <Box
        className="feedback-trigger-button"
        bgcolor={theme.palette.primary.main}
        onClick={() => setOpen(true)}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <Icon style={{ fontSize: "24px" }}>rate_review</Icon>
          <Typography
            className="feedback-text"
            variant="subtitle1"
            fontSize={"12px"}
            fontWeight={600}
            whiteSpace={"nowrap"}
          >
            Submit Feedback
          </Typography>
        </Stack>
      </Box>
      {open ? (
        <AppFeedbackModel
          onClose={() => {
            setOpen(false);
          }}
        />
      ) : null}
    </Fragment>
  );
};
