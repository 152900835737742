import _ from "lodash";

export const IncidentStatusFormatted = (status: string): string => {
  switch (status) {
    case "analyst_review":
      return "Under Analyst Review";
    case "monitoring":
      return "Monitoring";
    case "member_feedback":
      return "Member Feedback";
    case "wip":
      return "WIP";
    case "work_in_progress":
      return "Work in Progress";
    case "closed":
      return "Closed";
    case "resolved":
      return "Resolved";

    default:
      return _.startCase(status);
  }
};
export const IncidentCoaFormatted = (coa: string): string => {
  switch (coa) {
    case "pre_emptive_block":
      return "Pre-emptive Block";
    default:
      return _.startCase(coa);
  }
};
export const IncidentClassFormatted = (cls: string): string => {
  switch (cls) {
    case "account_#":
      return "Account #";
    case "html_page":
      return "HTML Page";
    case "ioc":
      return "IOC";
    case "ip":
      return "IP";
    case "phone_#":
      return "Phone #";
    case "url":
      return "URL";
    case "user_id":
      return "User ID";
    case "va":
      return "VA";
    case "win32":
      return "Win32";
    case "card_#":
      return "Card #";
    default:
      return _.startCase(cls);
  }
};
export const IncidentSourceFormatted = (source: string): string => {
  switch (source) {
    case "member_team":
      return "Member Team";
    default:
      return "CTM360";
  }
};
export const IncidentTypeFormatted = (type: string): string => {
  switch (type) {
    case "se_vulnerability":
      return "SE Vulnerability";
    case "smshing":
      return "SMShing";
    case "trap_10":
      return "Trap 10";
    default:
      return _.startCase(type);
  }
};
