import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Box,
  TextField,
  Grid,
} from "@mui/material";
import React, { Fragment } from "react";
import { LoadingButton } from "@mui/lab";
import { ValidateDomain, ValidateURL } from "../../../util/validators";
import { toast } from "../../../state/snackbar";
import { RequestIncidentTakedown } from "../../../services/cbs.service";
import { AppEncryption } from "../../../util/aes.encryption";
import _ from "lodash";
import { AppAttachments } from "../../views/Attachment";
import { IAttachments } from "../../module.interface";
import { convertBase64 } from "../../../util/base_64";
interface ITakedownSubjectModel {
  onClose: () => void;
  onSuccess: () => void;
}
export const TakedownSubjectModel: React.FC<ITakedownSubjectModel> = ({ onClose, onSuccess }) => {
  const [loading, setLoading] = React.useState(false);
  const [customMessage, setCustomMessage] = React.useState("");
  const [attachments, setAttachments] = React.useState<IAttachments[]>([]);

  const receiveFile = async (files: FileList) => {
    setAttachments([]);
    const attachedFiles = files;
    for (let index = 0; index < attachedFiles.length; index++) {
      const file = attachedFiles[index];
      if (file.size > 6000000) {
        toast(`${file.name} size exceeded the size maximum 6MB allowed`, "warning");
      } else {
        const fileType = file.name.split(".").pop() || "png";
        const base64: any = await convertBase64(file);
        // const fileContent: any = await convertByteArray(file);
        const base64_content = base64.toString().split(",")[1];
        setAttachments((arr) => [
          ...arr,
          { fileName: file.name, fileType: fileType, b64Contents: base64_content },
        ]);
      }
    }
  };
  const onSubmitQuote = () => {
    if (!ValidateURL(customMessage) && !ValidateDomain(customMessage)) {
      return toast("Please provide some a valid Domain or URL", "warning");
    }
    if (_.isEmpty(attachments)) {
      return toast("Please provide an evidence of fraud", "warning");
    }
    setLoading(true);
    RequestIncidentTakedown(AppEncryption(customMessage), attachments)
      .then((response) => {
        if (response.errors) {
          toast("Error in submitting the incident", "error");
        }
        toast("Request received successfully", "success");
        onClose();
        onSuccess();
      })
      .catch((err) => {
        const receivedError = _.has(err, "errors.errors[0]")
          ? (_.get(err, "errors.errors[0]") as unknown as string)
          : "";
        toast(receivedError ? receivedError : "Error in submitting the incident", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Box padding={4}>
            <Box marginBottom={4}>
              <Typography variant="h6" fontWeight={600} color={"primary"}>
                <strong>Submit Incident</strong>
              </Typography>
              <Typography variant="subtitle2" fontWeight={600} marginBottom={1}>
                {"Contain, investigate and remediate cyber risks for FREE!"}
              </Typography>
            </Box>
            <Box marginBottom={2}>
              <Typography variant="subtitle2" color={"textSecondary"} marginBottom={2}>
                Please submit an incident, it can be a phishing URL, Social Media Profiles,
                Look-alike domains. We will verify the incident and neutralize it.
              </Typography>
            </Box>
            <Box marginBottom={2}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={customMessage}
                    fullWidth
                    autoFocus
                    placeholder="Provide some valid Domain/URL"
                    onChange={(e) => setCustomMessage(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AppAttachments
                    name={"raise_incident_attachment"}
                    accept={".eml,.msg,.jpg,.jpeg,.png,.txt,.zip,.pdf"}
                    multiple={true}
                    showFiles={true}
                    iconButton={false}
                    selectedFiles={receiveFile}
                    updateFlag=""
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <LoadingButton
                  autoFocus
                  color="primary"
                  variant="contained"
                  onClick={onSubmitQuote}
                  loading={loading}
                >
                  submit
                </LoadingButton>
              </DialogActions>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
