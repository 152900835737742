import React, { Fragment, useEffect } from "react";
import { IESDoc } from "../../App.interface";
import { FetchTechnologies } from "../../services/dashboard.service";
import { toast } from "../../state/snackbar";
import {
  Box,
  Chip,
  Grid,
  Icon,
  Paper,
  Stack,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";

import { AppMasterTable } from "../views/app.table";
import { TechnologiesLogoRender } from "./technologies_logo";
import { RenderRiskGrade, RenderSeverityIssueCount } from "../../util/cell-render/renderers";
import _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";
import { AppDrawer } from "../views/Drawer";
import { BlurInfo } from "../module.views/BlurInfo";
import { useObservable } from "../../hooks/use-observable";
import { BrandService } from "../../services/brand.service";
import { UUIDGenerator } from "../../util/uuid";
export const TechnologiesModule: React.FC = () => {
  const theme = useTheme<Theme>();
  const [requestCount, setRequestCount] = React.useState<number>(0);
  const [allDocs, setAllDocs] = React.useState<IESDoc[]>([]);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("asc");
  const [sortField, setSortField] = React.useState<string>("name");
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc>();
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const [updateId, setUpdateId] = React.useState("");
  const fetchTechnologies = () => {
    setRequestCount((c) => c + 1);
    FetchTechnologies()
      .then((response) => {
        setDocs(response);
        setAllDocs(response);
      })
      .catch(() => {
        toast("Error in loading technologies", "error");
      })
      .finally(() => {
        setRequestCount((c) => c - 1);
      });
  };
  useDeepCompareEffect(() => {
    if (searchQuery) {
      setDocs((arr) =>
        arr.filter(
          (f) =>
            _.includes(_.toLower(f.library), _.toLower(searchQuery)) ||
            _.includes(_.toLower(f.name), _.toLower(searchQuery)) ||
            _.includes(_.toLower(f?.categories?.toString()), _.toLower(searchQuery))
        )
      );
    } else {
      setDocs(allDocs);
    }
  }, [allDocs, searchQuery]);
  useEffect(() => {
    setDocs((arr) => _.orderBy(arr, [sortField], [sortOrder]));
  }, [sortField, sortOrder]);
  useEffect(fetchTechnologies, [updateId]);
  useDeepCompareEffect(() => {
    setUpdateId(UUIDGenerator());
  }, [selectedMemberIds]);
  return (
    <Fragment>
      <Box className="module-view-container">
        <Box className="header" marginBottom={3}>
          <Typography color="primary" variant="h4" className="title">
            Technologies
          </Typography>

          <Typography variant="body2" className="sub-title">
            List of technologies used in your attack surface
          </Typography>
        </Box>
        <Box className="module-actions-wrapper">
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  className="search-filter"
                  variant="outlined"
                  size="small"
                  id="outlined-basic"
                  label={"Search..."}
                  InputProps={{
                    endAdornment: <Icon>search</Icon>,
                  }}
                  fullWidth
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            </Grid>
          </Grid>
        </Box>
        <Paper>
          <AppMasterTable
            loading={requestCount > 0}
            dataSource={""}
            name={""}
            title={""}
            colDefs={[
              {
                masterColumnName: "library",
                masterColumnTitle: "",
                renderType: "",
                cellRender: (params) => <TechnologiesLogoRender doc={params} />,
                sortable: false,
              },
              {
                masterColumnName: "name",
                masterColumnTitle: "Technology",
                renderType: "text",
                sortable: false,
                cellRender: (params) => (
                  <Fragment>
                    <Typography variant="subtitle1" fontSize={"12px"} fontWeight={600} gutterBottom>
                      {params.name}
                    </Typography>
                    {!_.isEmpty(params.version) ? (
                      <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        {params.version.map((i: { key: string }) => (
                          <Chip key={i.key} variant="outlined" size="small" label={i.key} />
                        ))}
                      </Stack>
                    ) : null}
                  </Fragment>
                ),
              },
              {
                masterColumnName: "categories",
                masterColumnTitle: "Environment",
                renderType: "tags",
                sortable: false,
              },
              {
                masterColumnName: "assets",
                masterColumnTitle: "Asset Count",
                renderType: "text",
                sortable: false,
              },
              {
                masterColumnName: "issues_summary",
                masterColumnTitle: "Issue Count",
                renderType: "tags",
                sortable: false,
                cellRender: (params) => {
                  return <span>{RenderSeverityIssueCount(params.issues_summary, theme)}</span>;
                },
              },
              {
                masterColumnName: "score",
                masterColumnTitle: "Security Grade",
                renderType: "risk_grade",
                sortable: false,
                cellRender: (params) => {
                  return <span>{RenderRiskGrade(params.score, theme)}</span>;
                },
              },
            ]}
            docs={docs}
            checkedDocs={[]}
            totalDocs={docs.length}
            pageIndex={0}
            pageSize={0}
            sortField={sortField}
            sortOrder={sortOrder}
            enablePagination={false}
            enableCheckBox={false}
            enableSelection={true}
            enableBrandColum={false}
            onChangeSortField={setSortField}
            onChangeSortOrder={setSortOrder}
            onChangePageIndex={() => null}
            onChangePageSize={() => null}
            onChangeCheckedDocs={() => null}
            onChangeSelectedDoc={setSelectedDoc}
          />
        </Paper>
      </Box>
      {selectedDoc ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={selectedDoc.name}
          onClose={() => {
            setSelectedDoc(undefined);
          }}
        >
          <BlurInfo />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
