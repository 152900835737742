import axios from "./axios.service";

export const SubmitCustomQuote = async (platform: string, message?: string): Promise<any> => {
  try {
    const response = await axios.post("/pricing/custom_quote", { platform, message });
    return response.data;
  } catch (error) {
    return Promise.reject({ errors: error });
  }
};
