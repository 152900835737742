import { AccordionSummary, Icon, Grid, Typography, Box, useTheme, Theme } from "@mui/material";
import React, { Fragment } from "react";
import { IIssueProfile } from "../module.interface";
import { ColorOpacity } from "../../util/color_opacity";

interface IIssuesNamePanel {
  issue: IIssueProfile;
  expanded: boolean;
  showIcon: boolean;
  locked: boolean;

  portfolioIssues?: boolean;
  companyCount?: number;
}
export const IssuesNamePanel: React.FC<IIssuesNamePanel> = ({
  issue,
  expanded,
  portfolioIssues,
  companyCount,
  showIcon,
  locked,
}) => {
  const theme = useTheme<Theme>();
  return (
    <Fragment>
      <AccordionSummary
        aria-controls="panel1bh-content"
        id={`${issue.issueName}-header`}
        className="panel-header"
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                {showIcon ? (
                  <Icon
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "8px",
                    }}
                  >
                    {expanded ? "expand_more" : "chevron_right"}
                  </Icon>
                ) : null}
              </Grid>

              <Grid item>
                <Typography variant="h6" color="textPrimary" className="accordion-title">
                  {issue.issueName}
                </Typography>
              </Grid>
              <Grid item>
                {locked ? (
                  <Icon
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "8px",
                      fontSize: "18px",
                      color: ColorOpacity(theme.palette.text.primary, 0.5),
                    }}
                  >
                    lock
                  </Icon>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Box className="severity-wrapper">
                  {issue.severityDist.map((s) => (
                    <Box className="severity-box" key={s.label}>
                      <Typography
                        variant="h6"
                        className="severity-count"
                        style={{ color: theme.severity[s.label] }}
                      >
                        {s.count.toLocaleString()}
                      </Typography>
                      <Typography variant="h6" className="severity-label" color="textSecondary">
                        {s.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item>
                <Box className="issue-count">
                  <Typography variant="h6" color="error" className="issue-count-text">
                    {portfolioIssues
                      ? companyCount?.toLocaleString()
                      : issue.issueCount.toLocaleString()}
                  </Typography>
                  <Typography variant="h6" color="textSecondary" className="issue-count-label">
                    {portfolioIssues ? "Companies At Risk" : "Issues Detected"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
    </Fragment>
  );
};
