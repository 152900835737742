import { Box, LinearProgress, Link, Typography } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { CardLeaksMaster } from "./card_leaks.master";
import { FetchCardLeakPreCheck } from "../../../services/cbs.service";
import { toast } from "../../../state/snackbar";
import Lottie from "react-lottie";
import animationData from "../../../assets/lottie/no-flights.json";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useObservable } from "../../../hooks/use-observable";
import { BrandService } from "../../../services/brand.service";
import { UUIDGenerator } from "../../../util/uuid";
export const CardLeaksModule: React.FC = () => {
  const selectedMemberIds = useObservable(BrandService.selectedMemberIds$) || [];
  const [updateId, setUpdateId] = React.useState("");

  const [loading, setLoading] = React.useState(true);
  const [moduleStatus, setModuleStatus] = React.useState(false);
  const fetchModuleStatus = () => {
    setModuleStatus(false);
    setLoading(true);
    FetchCardLeakPreCheck()
      .then((response) => {
        setModuleStatus(response);
      })
      .catch(() => {
        toast("Error in checking the card leaks", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchModuleStatus, [updateId]);
  useDeepCompareEffect(() => {
    setUpdateId(UUIDGenerator());
  }, [selectedMemberIds]);
  return (
    <Fragment>
      {loading ? (
        <LinearProgress />
      ) : moduleStatus ? (
        <Fragment>
          <CardLeaksMaster updateId={updateId} />
        </Fragment>
      ) : (
        <Box>
          <Box
            width={"100%"}
            height={"80vh"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box maxWidth={"720px"} margin={"auto"} textAlign={"center"}>
              <Box>
                <Lottie
                  options={{
                    animationData: animationData,
                    loop: true,
                    autoplay: true,
                    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
                  }}
                  width={220}
                  height={220}
                />
              </Box>
              <Typography variant="h4" color={"primary"} fontWeight={600} gutterBottom>
                No matching results
              </Typography>
              <Typography variant="h6" fontSize={"14px"} textAlign={"justify"}>
                The card Leaks module finds compromised credit card information and only applies to
                organizations in the Financial Industry. We don’t have any BIN numbers configured
                for your organization. If you would like to set up this module, please contact us at
                <Link marginLeft={1} href="mailto:monitor@ctm360.com" target={"_blank"}>
                  monitor@ctm360.com
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
