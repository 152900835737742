import { Box, Icon, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import has from "lodash/has";

import React, { Fragment } from "react";

import get from "lodash/get";
import { RenderUrl } from "../../../util/cell-render/renderers";
import { toast } from "../../../state/snackbar";
import { IESDoc } from "../../../App.interface";

interface IPhishingInfo {
  doc: IESDoc;
}
export const PhishingInfo: React.FC<IPhishingInfo> = ({ doc }) => {
  const onCopyUrl = () => {
    navigator.clipboard.writeText(doc.url);
    toast("Phishing URL copied to clipboard", "info");
  };
  return (
    <Fragment>
      <Box
        minWidth={"300px"}
        maxWidth={"500px"}
        minHeight={"75px"}
        display={"flex"}
        alignItems={"center"}
      >
        <Box width={"100%"}>
          <Stack direction={"row"} alignItems={"center"}>
            <Box>
              {RenderUrl(
                doc.url,
                true,
                <Typography variant="subtitle1" fontSize={"13px"} fontWeight={600} component={"a"}>
                  {doc.url}
                </Typography>
              )}
            </Box>
            <Box>
              <Tooltip title={"Copy URL"}>
                <IconButton
                  color="default"
                  onClick={(e) => {
                    e.stopPropagation();
                    onCopyUrl();
                  }}
                  size="small"
                >
                  <Icon fontSize="inherit" style={{ fontSize: "16px", opacity: 0.5 }}>
                    content_copy
                  </Icon>
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>

          <Typography
            variant="subtitle1"
            fontSize={"12px"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            marginTop={0.5}
          >
            {has(doc, "enrichment.web_title") ? get(doc, "enrichment.web_title") : "-"}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};
