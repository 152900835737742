import _ from "lodash";
import React, { Fragment } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import { useTheme } from "@mui/material";

interface IAppDonutChart {
  chartHeight: number;
  data: { label: string; value: number }[];
  total?: number /* show total of values  /**
  example: you are loading all webServers you want to show the total of them but render top5 only.
  you can use this total to show totalWebServers
  */;
}

export const AppDonutChart: React.FC<IAppDonutChart> = ({ chartHeight, data, total }) => {
  const theme = useTheme();

  return (
    <Fragment>
      <ResponsiveContainer height={chartHeight}>
        <Chart
          options={{
            legend: {
              show: true,
              position: "bottom",
              horizontalAlign: "center",
              fontFamily: "Montserrat",
              fontSize: "10px",
              floating: false,
              labels: {
                colors: theme.palette.text.secondary,
              },
              customLegendItems: [],
            },
            plotOptions: {
              pie: {
                donut: {
                  size: "80%",
                  background: "transparent",
                  labels: {
                    show: true,
                    name: {
                      show: true,
                      formatter: () => "Total",
                      color: theme.palette.primary.main,
                    },
                    value: {
                      show: true,
                      fontFamily: "Montserrat",
                      color: theme.palette.text.primary,
                      formatter: () =>
                        total
                          ? total.toLocaleString()
                          : _.sum(data.map((x) => x.value)).toLocaleString(),
                      fontSize: "14px",
                    },
                    total: {
                      show: true,
                      fontFamily: "Montserrat",
                      color: theme.palette.primary.main,
                      formatter: () =>
                        total
                          ? total.toLocaleString()
                          : _.sum(data.map((x) => x.value)).toLocaleString(),
                    },
                  },
                },
              },
            },
            noData: {
              align: "center",
              verticalAlign: "middle",
              offsetX: 0,
              offsetY: 0,
              style: {
                color: theme.palette.text.primary,
                fontSize: "12px",
                fontFamily: "Montserrat",
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: false,
              curve: "smooth",
              lineCap: "butt",
              colors: undefined,
              width: 2,
              dashArray: 0,
            },
            colors: theme.piechart_palette,
            labels: data.map((x) => _.startCase(x.label)),
          }}
          series={data.map((x) => x.value)}
          type="donut"
        />
      </ResponsiveContainer>
    </Fragment>
  );
};
