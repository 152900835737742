import {
  Autocomplete,
  Button,
  ButtonGroup,
  Dialog,
  Grid,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import { IESDoc } from "../../../../../App.interface";
import { IMasterColumns, ITableFilters } from "../../../../module.interface";
import {
  FetchDomainHistoricalDNS,
  FetchDomainHistoricalSelectors,
} from "../../../../../services/dmarc.service";
import { toast } from "../../../../../state/snackbar";
import { OfflineAppMasterTable } from "../components/offline-table";

interface IHisotric {
  doc: IESDoc | undefined;
  onClose: () => void;
}
interface IButtons {
  disabled: boolean;
  col: IMasterColumns;
}

export const DmarcDomainHistoricData: React.FC<IHisotric> = ({ doc, onClose }) => {
  const [loading, setLoading] = React.useState(false);
  const [sortField, setSortField] = React.useState("first_seen");
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("desc");
  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [selectedSelector, setSelectedSelector] = React.useState<string>("");
  const [selectorsList, setSelectorsList] = React.useState<string[]>([]);

  const [tabs, setTabs] = React.useState<IButtons[]>([
    {
      disabled: false,
      col: {
        masterColumnName: "record.value",
        masterColumnTitle: "a_record",
        renderType: "chips",
      },
    },
    {
      disabled: false,
      col: {
        masterColumnName: "record.value",
        masterColumnTitle: "cname_record",
        renderType: "themed_complex_json",
      },
    },
    {
      disabled: false,
      col: {
        masterColumnName: "record.value",
        masterColumnTitle: "mx_record",
        renderType: "themed_complex_json",
      },
    },
    {
      disabled: false,
      col: {
        masterColumnName: "record.value",
        masterColumnTitle: "txt_record",
        renderType: "themed_complex_json",
      },
    },
    {
      disabled: false,
      col: {
        masterColumnName: "record.value",
        masterColumnTitle: "spf_record",
        renderType: "themed_complex_json",
      },
    },
    {
      disabled: false,
      col: {
        masterColumnName: "record.value",
        masterColumnTitle: "dkim_record",
        renderType: "themed_complex_json",
      },
    },
    {
      disabled: false,
      col: {
        masterColumnName: "record.value",
        masterColumnTitle: "dmarc_record",
        renderType: "themed_complex_json",
      },
    },
    {
      disabled: false,
      col: {
        masterColumnName: "record.value",
        masterColumnTitle: "ns_record",
        renderType: "themed_complex_json",
      },
    },
  ]);
  const [data, setData] = React.useState<IESDoc[]>([]);
  const [selectedTab, setSelectedTab] = React.useState<IMasterColumns>();
  const [colDefs, setColDefs] = React.useState<IMasterColumns[]>([]);
  const [fixedColDefs] = React.useState<IMasterColumns[]>([
    {
      masterColumnName: "duration",
      masterColumnTitle: "Record Time",
      renderType: "text",
      cellRender: (params) => (
        <Fragment>
          <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
            {moment(params?.created_at).format("MMM Do YYYY")} -{" "}
            {moment(params?.updated_at).format("MMM Do YYYY")}
          </Typography>
          <Typography color="text.secondary" sx={{ fontSize: 12 }}>
            {moment(params?.updated_at).diff(moment(params?.created_at), "days") + " day(s)"}
          </Typography>
        </Fragment>
      ),
    },
  ]);

  // fetch data
  const fetchDoc = () => {
    if ((doc?.hostname || doc?.main_domain) && !_.isUndefined(selectedTab)) {
      const filtersList: ITableFilters[] = [
        {
          id: "1",
          title: "record_type",
          field: "record_type",
          operator: "is",
          value: selectedTab.masterColumnTitle,
        },
        {
          id: "2",
          title: "domain",
          field: "meta.domain_name",
          operator: "is",
          value: doc?.hostname || doc?.main_domain,
        },
      ];

      if (
        selectedSelector &&
        !_.isEmpty(selectedSelector) &&
        selectedTab.masterColumnTitle === "dkim_record"
      ) {
        filtersList.push({
          id: "3",
          title: "dkim selector",
          field: "record.selector",
          operator: "is",
          value: selectedSelector,
        });
      }
      setLoading(true);
      FetchDomainHistoricalDNS(selectedTab.masterColumnTitle, selectedSelector)
        .then((response) => {
          //   console.log(response);
          setData(response.data);
        })
        .catch(() => toast("Error fetching historical data", "error"))
        .finally(() => setLoading(false));
    }
  };
  const filterDocs = () => {
    let index = 0;
    const array: any[] = [];
    while (index <= data.length - 1) {
      if (index === 0) {
        array.push({ ...data[index], updated_at: moment().valueOf() });
      } else {
        if (selectedTab?.masterColumnTitle === "dkim_record") {
          // check if doc is dkim record
          const items = _.filter(data, function (x) {
            return x.record.selector === data[index].record.selector;
          });
          if (items.length === 1) {
            array.push({
              ...data[index],
              updated_at: moment().valueOf(),
            });
          } else {
            array.push({
              ...data[index],
              updated_at: data[index - 1]?.created_at_at,
            });
          }
        } else {
          array.push({
            ...data[index],
            updated_at: data[index - 1]?.created_at,
          });
        }
      }
      index++;
    }
    const list = _.orderBy(array, ["created_at", "updated_at"], ["desc", "desc"]);

    return list;
  };

  const fetchSelectors = () => {
    if (
      (doc?.hostname || doc?.main_domain) &&
      !_.isUndefined(selectedTab) &&
      selectedTab.masterColumnTitle === "dkim_record"
    ) {
      setLoading(true);
      FetchDomainHistoricalSelectors(selectedTab.masterColumnTitle)
        .then((response) => {
          const list: any = _.uniq(
            response.data.map((i: any) => i?.record?.selector || "").filter((i: any) => i != "")
          );
          setSelectorsList(list);
        })
        .catch(() => toast("Error fetching historical data", "error"))
        .finally(() => setLoading(false));
    }
  };
  // hooks
  React.useEffect(fetchDoc, [doc, sortField, sortOrder, selectedTab, selectedSelector]);
  React.useEffect(() => {
    setColDefs([...fixedColDefs, ...(selectedTab ? [selectedTab] : [])]);
  }, [selectedTab, fixedColDefs]);
  React.useEffect(() => {
    setSelectedTab(tabs?.[0]?.col || undefined);
  }, [tabs]);

  React.useEffect(
    () => {
      if (selectedTab?.masterColumnTitle === "dkim_record") {
        fetchSelectors();
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [doc, selectedTab]
  );
  return (
    <Fragment>
      <Dialog
        onClose={() => {
          onClose();
          setTabs([]);
        }}
        open={doc ? true : false}
        fullWidth
        maxWidth="xl"
      >
        <Box justifyContent={"center"} justifyItems={"center"}>
          <Grid
            container
            justifyContent={"space-between"}
            style={{ paddingLeft: 20, paddingTop: 20 }}
          >
            <Typography variant="h6">{doc?.hostname}</Typography>
          </Grid>
          <Grid container justifyContent={"center"} style={{ paddingBottom: 20 }}>
            <ButtonGroup>
              {tabs.map((btn) => (
                <Button
                  disabled={btn.disabled}
                  color={"primary"}
                  variant={
                    selectedTab?.masterColumnTitle === btn.col.masterColumnTitle
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => setSelectedTab(btn.col)}
                  key={btn.col.masterColumnTitle}
                  style={{ width: 100 }}
                >
                  {btn.col.masterColumnTitle.substring(0, btn.col.masterColumnTitle.indexOf("_"))}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          {selectedTab?.masterColumnTitle == "dkim_record" ? (
            <Grid container justifyContent="flex-start">
              <Grid item marginLeft={1} display="flex" flexDirection="row" lg={3}>
                <Autocomplete
                  size="small"
                  fullWidth
                  options={selectorsList}
                  // getOptionLabel={(op) => op}
                  disableCloseOnSelect
                  sx={{ mt: 1 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Selector"
                    />
                  )}
                  noOptionsText="No Selector found"
                  limitTags={1}
                  loading={loading}
                  value={selectedSelector}
                  onChange={(e, v) => setSelectedSelector(v || "")}
                />
              </Grid>
            </Grid>
          ) : null}
          <OfflineAppMasterTable
            checkedDocs={[]}
            colDefs={colDefs}
            dataSource={""}
            docs={filterDocs()}
            enableBrandColum={false}
            enableCheckBox={false}
            enablePagination={true}
            enableSelection={false}
            loading={loading}
            name=""
            onChangeCheckedDocs={() => null}
            onChangePageIndex={setPageIndex}
            onChangePageSize={setPageSize}
            onChangeSelectedDoc={() => null}
            onChangeSortField={setSortField}
            onChangeSortOrder={setSortOrder}
            pageIndex={pageIndex}
            pageSize={pageSize}
            sortField={sortField}
            sortOrder={sortOrder}
            title=""
            totalDocs={filterDocs().length}
            enableSwitch={false}
            isCellMaxWidthFlexiable={true}
          />{" "}
        </Box>
      </Dialog>
    </Fragment>
  );
};
