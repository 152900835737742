import _ from "lodash";
import { IUser } from "../state/user";

export const getActiveTheme = (): string => {
  const userPreferences = JSON.parse(localStorage.getItem("user_preferences") || "{}");
  if (_.has(userPreferences, `${process.env.REACT_APP_SSO_CLIENT_ID}.theme`)) {
    return _.get(userPreferences, `${process.env.REACT_APP_SSO_CLIENT_ID}.theme`);
  } else {
    return "default-theme";
  }
};
export const getSourceVersion = (): string => {
  return (
    localStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.build`) || "RESOLVED_SOURCE_HASH"
  );
};
export const getUserAttributes = (): IUser => {
  const user = JSON.parse(
    localStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.sso.user`) || "{}"
  );
  return user;
};
