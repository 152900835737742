import { LinearProgress, Theme } from "@mui/material";
import { useTheme } from "@mui/styles";
import _ from "lodash";
import React, { useEffect } from "react";
import { Fragment } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import { EmptyChart } from "../../views/chart.empty";
import { FetchDomainProtectionRisks } from "../../../services/cbs.service";
import { toast } from "../../../state/snackbar";
import { AppCard } from "../../views/app.card";

export const DomainProtectionRisks: React.FC<{ updateId: string }> = ({ updateId }) => {
  const theme = useTheme<Theme>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [incidentTypes, setIncidentTypes] = React.useState<
    { id: string; label: string; value: number }[]
  >([]);
  const fetchTypeAggregation = () => {
    setLoading(true);
    FetchDomainProtectionRisks()
      .then((response) => {
        setIncidentTypes(response);
      })
      .catch(() => {
        toast("Error in loading typo squatted domain risks", "error");
        setIncidentTypes([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(fetchTypeAggregation, [updateId]);
  return (
    <Fragment>
      <AppCard
        name={""}
        title={"Typo squatted Risks"}
        description={`Typosquatted risks across ${
          loading ? "" : _.sum(incidentTypes.map((i) => i.value)).toLocaleString()
        } domains`}
      >
        {loading ? (
          <LinearProgress />
        ) : !_.isEmpty(incidentTypes) ? (
          <ResponsiveContainer height={300}>
            <Chart
              type="bar"
              series={[
                {
                  name: "Findings",
                  data: incidentTypes.map((x) => x.value),
                },
              ]}
              options={{
                chart: {
                  toolbar: {
                    show: false,
                  },
                  type: "bar",
                  stacked: true,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },

                legend: {
                  labels: {
                    colors: theme.palette.text.primary,
                  },
                },
                // stroke: {
                //   curve: "straight",
                //   width: 3,
                // },
                dataLabels: {
                  enabled: true,
                },
                // fill: {
                //   opacity: 0.9,
                //   type: "gradient",
                //   gradient: {
                //     shade: "dark",
                //     type: "vertical",
                //     shadeIntensity: 0.5,
                //     gradientToColors: undefined,
                //     inverseColors: true,
                //     opacityFrom: 0.6,
                //     opacityTo: 0.2,
                //     stops: [0, 100],
                //   },
                // },
                // markers: {
                //   size: 3,
                //   colors: ["#f04323"],
                //   strokeColors: [theme.palette.background.paper, theme.palette.background.paper],
                //   strokeWidth: 2,
                // },
                xaxis: {
                  categories: incidentTypes.map((x) => x.label),
                  axisTicks: {
                    show: false,
                  },
                  axisBorder: {
                    show: false,
                  },

                  title: {
                    text: "Findings",
                    style: {
                      color: theme.palette.primary.main,
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                    },
                  },
                  labels: {
                    style: {
                      colors: theme.palette.text.primary,
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                    },
                    formatter: (value) => _.startCase(value),
                  },
                },
                yaxis: {
                  title: {
                    text: "Risks",
                    offsetX: 25,
                    style: {
                      color: theme.palette.primary.main,
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                    },
                  },
                  labels: {
                    style: {
                      colors: theme.palette.text.primary,
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                    },
                    formatter: (value) => _.startCase(value.toString()),
                  },
                },
                grid: {
                  show: false,
                },
                tooltip: {
                  theme: "false",
                  style: {
                    fontSize: "12px",
                    fontFamily: "Montserrat",
                  },
                },
              }}
            />
          </ResponsiveContainer>
        ) : (
          <EmptyChart title="Incident by Type" />
        )}
      </AppCard>
    </Fragment>
  );
};
