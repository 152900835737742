import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Popover,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { CardLeakStats } from "./card_leak.stats";
import { IESDoc } from "../../../App.interface";
import { FetchCardLeakList, FetchThreatManagerFilterOptions } from "../../../services/cbs.service";
import { toast } from "../../../state/snackbar";
import _ from "lodash";
import { UUIDGenerator } from "../../../util/uuid";
import { AppMasterTable } from "../../views/app.table";
import { CardNumberView } from "./card_number";
import { RenderUrl } from "../../../util/cell-render/renderers";
import { AppDrawer } from "../../views/Drawer";
import useDeepCompareEffect from "use-deep-compare-effect";
import { BlurInfo } from "../../module.views/BlurInfo";
import { setInitialDateRanges } from "../../../util/date-range";
import { IDateFilterObj, ITableFilters } from "../../module.interface";
import moment from "moment";
import { DateRangeFilter } from "../../../components/date.filter";
import { AppButtonFilter } from "../../../components/button-filter/button.filter.index";
import { AppMD5Hash } from "../../../util/md5.hash";
import { UpgradeViewCell } from "../../views/UpgradeCell";

export const CardLeaksMaster: React.FC<{ updateId: string }> = ({ updateId }) => {
  const theme = useTheme<Theme>();
  const [requestCount, setRequestCount] = React.useState(0);
  const [docs, setDocs] = React.useState<IESDoc[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState<"desc" | "asc">("desc");
  const [sortField, setSortField] = React.useState<string>("last_seen");
  const [totalDocs, setTotalDocs] = React.useState<number>(0);
  const [updateID, setUpdateID] = React.useState(UUIDGenerator());
  const [selectedDoc, setSelectedDoc] = React.useState<IESDoc>();
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [selectedDateRange, setSelectedDateRange] = React.useState<IDateFilterObj>(
    setInitialDateRanges()
  );
  const [dateRange, setDateRange] = React.useState<HTMLButtonElement | null>(null);
  const [filterList, setFilterList] = React.useState<ITableFilters[]>([]);
  const [searchVal, setSearchVal] = React.useState<string>("");
  const [loadingFilters, setLoadingFilters] = React.useState(true);
  const [filterOptions, setFilterOptions] = React.useState<{ [key: string]: string[] }>({});
  const onChangeSearchQuery = (val: string) => setSearchVal(val);
  const onSetSearchQuery = () => {
    setSearchQuery(searchVal.trim().replace(/\/(?=[^/]*$)/, "/"));
    setDocs([]);
  };
  const fetchList = () => {
    setRequestCount((c) => c + 1);
    FetchCardLeakList(
      pageIndex,
      pageSize,
      searchQuery.replace(/[^a-zA-Z ]/g, ""),
      sortField,
      sortOrder,
      filterList,
      selectedDateRange.status ? selectedDateRange.startDate : undefined,
      selectedDateRange.status ? selectedDateRange.endDate : undefined
    )
      .then((response) => {
        setDocs(response.data);
        setTotalDocs(_.get(response, "metadata.total.value") as unknown as number);
      })
      .catch(() => {
        toast("Error in loading card leaks", "error");
      })
      .finally(() => {
        setRequestCount((x) => x - 1);
      });
  };
  const FetchFilterOptions = () => {
    setLoadingFilters(true);

    FetchThreatManagerFilterOptions("card_leaks")
      .then((response) => {
        const options: { [key: string]: string[] } = {};
        _.forEach(response, (v, k) => {
          options[k] = v.buckets.filter((f: any) => f.key).map((i: any) => i.key);
        });
        setFilterOptions(options);
      })
      .catch(() => {
        toast("Error in loading filters", "error");
      })
      .finally(() => {
        setLoadingFilters(false);
      });
  };
  useEffect(FetchFilterOptions, []);
  useDeepCompareEffect(fetchList, [
    filterList,
    pageIndex,
    pageSize,
    searchQuery,
    selectedDateRange,
    sortField,
    sortOrder,
    updateID,
    updateId,
  ]);
  return (
    <Fragment>
      <Box className="app-module-container">
        <Box className="app-module-header">
          <Grid container justifyContent="space-between" wrap="nowrap">
            <Grid item xs={8}>
              <Typography variant="h4" className="module-title" color="primary">
                Card Leaks
              </Typography>
              <Typography
                variant="body2"
                component="p"
                color="textPrimary"
                className="module-description"
              >
                Summary of exposed credit card information
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box marginBottom={2} paddingLeft={2} maxWidth={720}>
          <Alert severity="warning" variant="outlined">
            <AlertTitle>
              <Typography variant="h6" color={"inherit"} fontSize={"16px"} fontWeight={600}>
                Restricted View
              </Typography>
            </AlertTitle>
            <Typography
              variant="subtitle1"
              color={"inherit"}
              fontSize={"12px"}
              fontWeight={500}
              marginBottom={1.5}
            >
              This is not a comprehensive list of compromised cards and is only limited to 100 daily
              results. For a comprehensive list, please upgrade your plan
            </Typography>
            <Typography
              variant="subtitle1"
              color={"inherit"}
              fontSize={"12px"}
              fontWeight={500}
              marginBottom={1.5}
            >
              Disclaimer: Credit card data found on deep/dark web is often high in noise and is not
              considered credible. In the majority of cases, only a small percentage of cards may be
              valid. Members are encouraged to verify the findings
            </Typography>
            <Button
              variant="contained"
              component={Link}
              href={`https://${
                process.env.NODE_ENV === "development" ? "cyno.edxdev.link" : "start.ctm360.com"
              }/pricing`}
              target="_blank"
              size="small"
            >
              <Typography
                variant="h6"
                textTransform={"capitalize"}
                fontWeight={600}
                fontSize={"12px"}
              >
                Upgrade Now
              </Typography>
            </Button>
          </Alert>
        </Box>
        <Box className="app-module-contents">
          <CardLeakStats />
          <Box className="cbs-module-actions">
            <Grid
              spacing={2}
              container
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                <Grid
                  spacing={1}
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  wrap="nowrap"
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <Typography
                      variant="subtitle1"
                      className="button-filter-label"
                      color="textPrimary"
                      height={"30px"}
                    />
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        id="outlined-basic"
                        label={"Search..."}
                        value={searchVal}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Icon color="action">search</Icon>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        onKeyDown={(ev) => (ev.key === "Enter" ? onSetSearchQuery() : null)}
                        onChange={(e) => onChangeSearchQuery(e.target.value)}
                      />
                    </form>
                  </Grid>
                  <Grid item>
                    <AppButtonFilter
                      loading={loadingFilters}
                      name={"sources.source"}
                      title={"Source"}
                      options={filterOptions["sources"] || []}
                      selection={filterList
                        .filter((f) => f.field === "sources.source")
                        .map((x) => x.value as string)}
                      onChangeOption={(params) => {
                        setFilterList((arr) => [
                          ...arr.filter((f) => f.field !== "sources.source"),
                          ...params.map((x) => ({
                            id: AppMD5Hash(`sources.source_sources.source_${x}`),
                            field: "sources.source",
                            title: "Source",
                            operator: "is one of",
                            value: x,
                          })),
                        ]);
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      className="button-filter-label"
                      color="textPrimary"
                      height={"30px"}
                    />
                    <Button
                      variant="outlined"
                      size="small"
                      style={{
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.divider,
                        fontSize: "14px",
                        height: "40px",
                        textTransform: "capitalize",
                      }}
                      className="ticket-filter-button-customized"
                      onClick={(event) => setDateRange(event.currentTarget)}
                    >
                      {selectedDateRange.mode === "quick"
                        ? selectedDateRange.value
                        : moment(selectedDateRange.startDate).format("MMMM Do YYYY") +
                          " - " +
                          moment(selectedDateRange.endDate).format("MMMM Do YYYY")}
                    </Button>
                    <Popover
                      id="date-range-popover"
                      open={Boolean(dateRange)}
                      anchorEl={dateRange}
                      onClose={() => setDateRange(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Box>
                        <DateRangeFilter
                          currentDateObj={selectedDateRange}
                          publishDateObj={(params) => {
                            setSelectedDateRange(params);
                            setDateRange(null);
                          }}
                          onClose={() => setDateRange(null)}
                        />
                      </Box>
                    </Popover>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  className="button-filter-label"
                  color="textPrimary"
                  height={"30px"}
                />
                <Grid
                  spacing={2}
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  wrap="nowrap"
                >
                  <Grid item>
                    <Tooltip title="Refresh Data">
                      <IconButton
                        color="secondary"
                        size="small"
                        onClick={() => setUpdateID(UUIDGenerator())}
                      >
                        <Icon>refresh</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Paper>
            <AppMasterTable
              loading={Boolean(requestCount > 0)}
              dataSource={""}
              name={""}
              title={""}
              colDefs={[
                {
                  masterColumnName: "card_number",
                  masterColumnTitle: "Card Number",
                  renderType: "text",
                  cellRender: (params) => <CardNumberView doc={params} />,
                },
                {
                  masterColumnName: "cvv",
                  masterColumnTitle: "CVV",
                  renderType: "text",
                  cellRender: () => <UpgradeViewCell />,
                },
                {
                  masterColumnName: "month",
                  masterColumnTitle: "Expiry Date",
                  renderType: "text",
                  cellRender: (params) => (
                    <span>
                      <strong>{`${params.month}/${params.year}`}</strong>
                    </span>
                  ),
                },
                {
                  masterColumnName: "unique_source_uri",
                  masterColumnTitle: "Latest Source",
                  renderType: "url_array",
                  cellRender: (params) => (
                    <span>
                      {!_.isEmpty(params.unique_source_uri)
                        ? RenderUrl(params.unique_source_uri[params.unique_source_uri.length - 1])
                        : "-"}
                    </span>
                  ),
                },
                {
                  masterColumnName: "last_seen",
                  masterColumnTitle: "Last Seen",
                  renderType: "date",
                },
              ]}
              docs={docs}
              checkedDocs={[]}
              totalDocs={_.min([totalDocs, 100]) || 100}
              pageIndex={pageIndex}
              pageSize={pageSize}
              sortField={sortField}
              sortOrder={sortOrder}
              enablePagination={true}
              enableCheckBox={false}
              enableSelection={true}
              enableBrandColum={false}
              onChangeSortField={setSortField}
              onChangeSortOrder={setSortOrder}
              onChangePageIndex={setPageIndex}
              onChangePageSize={setPageSize}
              onChangeCheckedDocs={() => null}
              onChangeSelectedDoc={setSelectedDoc}
            />
          </Paper>
        </Box>
      </Box>
      {selectedDoc ? (
        <AppDrawer
          anchor={"right"}
          open={true}
          title={<CardNumberView doc={selectedDoc} />}
          onClose={() => {
            setSelectedDoc(undefined);
          }}
        >
          <BlurInfo />
        </AppDrawer>
      ) : null}
    </Fragment>
  );
};
