import _range from "lodash/range";
import { Theme, useTheme } from "@mui/material";
import { Box, Grid, Paper } from "@mui/material";
import { Skeleton } from "@mui/material";
import React, { Fragment } from "react";

export const EnvironmentLoading: React.FC<any> = () => {
  const theme = useTheme<Theme>();
  return (
    <Fragment>
      <Box className="brand-container">
        <Grid container spacing={5} justifyContent="center">
          <Grid item>
            <Box className="technology-category-container">
              <Box className="technology-category-wrapper">
                <Paper className="technology-category-name">
                  <Box>
                    <Skeleton
                      variant="circular"
                      width={35}
                      height={35}
                      style={{ margin: "auto", marginBottom: "8px" }}
                    ></Skeleton>

                    <Skeleton variant="text" width={150} height={25}></Skeleton>
                  </Box>
                </Paper>
              </Box>
              <Box
                className="horizontal-braces"
                style={{
                  borderColor: theme.palette.background.paper,
                  backgroundImage: `radial-gradient(circle at 0 0, rgba(255, 255, 255, 0) 14.5px, ${theme.palette.background.paper} 15.5px, ${theme.palette.background.paper} 19.5px, rgba(255, 255, 255, 0) 20.5px),radial-gradient(circle at 35px 0,rgba(255, 255, 255, 0) 14.5px,${theme.palette.background.paper} 15.5px, ${theme.palette.background.paper} 19.5px,rgba(255, 255, 255, 0) 20.5px)`,
                }}
              ></Box>
              <Box className="technology-name-container">
                <Grid container spacing={2} justifyContent="center" wrap="nowrap">
                  {_range(6).map((technology) => (
                    <Grid item key={technology.toString()}>
                      <Paper variant="outlined" className="technology-name-wrapper">
                        <Box className="technology-name-header">
                          <Skeleton variant="text"></Skeleton>
                        </Box>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                          wrap="nowrap"
                        >
                          <Grid item>
                            <Skeleton variant="rectangular" width={35} height={35}></Skeleton>
                          </Grid>
                          <Grid item>
                            <Box className="asset-count">
                              <Skeleton variant="text" width={20}></Skeleton>
                              <Skeleton variant="text" width={30}></Skeleton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};
