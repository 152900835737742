import { Box, Typography } from "@mui/material";
import React, { Fragment } from "react";

export const UnderConstruction: React.FC = () => {
  return (
    <Fragment>
      <Box height={"90vh"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Box maxWidth={"1000px"} margin={"auto"} textAlign={"center"}>
          <Typography variant="h2" color={"primary"} marginBottom={1.5}>
            <strong>Coming Soon!</strong>
          </Typography>
          <Typography variant="h6" textAlign={"justify"}>
            Stay tuned for the launch of our DRP Freemium, a bundle of automated systems, tools, and
            threat feeds that are capable of detecting typo-squatting domains, Malware logs and
            leaked credentials relevant to your organization along with a set of interesting
            features and technologies.
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};
