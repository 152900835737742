import { Theme } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";
import {
  RenderNumber,
  RenderText,
  RenderTitle,
  RenderTime,
  RenderUrl,
  RenderBoolean,
  RenderArray,
  RenderHTTPStatusCode,
  RenderDateTime,
  RenderTimeSince,
  RenderDate,
  RenderComplexJSON,
  RenderRiskGrade,
  RenderHostType,
  RenderThemedComplexJSON,
} from "./cell-render/renderers";

export const RenderCell = (
  value: string | number | boolean | string[] | number[] | null | undefined,
  renderType: string,
  theme?: Theme
): ReactNode => {
  switch (renderType) {
    case "number":
      return RenderNumber(value as number);
    case "text":
      return RenderText(value as string);
    case "title":
      return RenderTitle(value as string);
    case "time":
      return RenderTime(value as string);
    case "date":
      return RenderDate(value as string);
    case "url":
      return RenderUrl(value as string);
    case "boolean":
      return RenderBoolean(value as string);
    case "http-status-code":
      return RenderHTTPStatusCode(value as string);
    case "time_since":
      return RenderTimeSince(value as string);

    case "date_time":
      return RenderDateTime(value as string);
    case "array":
      return RenderArray(value as string[] | number[], "none");
    case "array_inline":
      return RenderArray(value as string[] | number[], "none", undefined, true);
    case "url_array":
      return RenderArray(value as string[] | number[], "url");
    case "url_array_max_block":
      return RenderArray(value as string[] | number[], "url", 2);
    case "tags_array_max_block":
      return RenderArray(value as string[] | number[], "tags", 2);
    case "title_array_max_block":
      return RenderArray(value as string[] | number[], "title", 2);
    case "array_max_block":
      return RenderArray(value as string[] | number[], "none", 2);
    case "tags":
      return RenderArray(value as string[] | number[], "tags");
    case "chips":
      return RenderArray(value as string[] | number[], "tags");
    case "complex_json":
      return RenderComplexJSON(value, _.upperCase(process.env.REACT_APP_SSO_CLIENT_ID));
    case "themed_complex_json":
      return RenderThemedComplexJSON(value);
    case "risk_grade":
      return RenderRiskGrade(value as number, theme);
    case "host_type":
      return RenderHostType(value as string[]);
    default:
      return RenderText(value as string);
  }
};
