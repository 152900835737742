import { Chip, Stack, Theme, useTheme } from "@mui/material";

import React, { Fragment, useEffect } from "react";
import { AppMD5Hash } from "../../../util/md5.hash";
import { isEmpty, uniq } from "lodash";
import { IESDoc } from "../../../App.interface";
import { ColorShades } from "../../../util/color-shades";
import { stringToColor } from "../../../util/color.generator";
import { ColorOpacity } from "../../../util/color_opacity";
import { StandardizeName } from "../../../util/string.formatters";

interface IStealerFamilyView {
  doc: IESDoc;
}
export const StealerFamilyView: React.FC<IStealerFamilyView> = ({ doc }) => {
  const theme = useTheme<Theme>();
  const [stealerSource, setStealerSource] = React.useState<string[]>(
    doc.chr ? doc.chr.map((i: any) => i.stealer_family).filter((f: any) => f) : []
  );
  const computeLabelColor = (lbl: string): string => {
    const intensity = theme.palette.mode === "dark" ? "40" : "700";
    const pallette = ColorShades(stringToColor(AppMD5Hash(lbl)), intensity);
    return pallette;
  };

  useEffect(() => {
    setStealerSource(
      doc.chr ? doc.chr.map((i: any) => i.stealer_family).filter((f: any) => f) : []
    );
  }, [doc]);
  return (
    <Fragment>
      {!isEmpty(stealerSource) ? (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          {uniq(stealerSource)
            .filter((f) => StandardizeName(f) !== "not_found")
            .map((i) => (
              <Chip
                variant="filled"
                label={i}
                size={"small"}
                key={i}
                style={{
                  fontWeight: 600,
                  borderRadius: "5px",
                  color: computeLabelColor(i),
                  backgroundColor: ColorOpacity(computeLabelColor(i), 0.15),
                }}
              />
            ))}
        </Stack>
      ) : (
        "-"
      )}
    </Fragment>
  );
};
