import { Box, CircularProgress, Grid, Paper, Skeleton, Typography } from "@mui/material";
import _ from "lodash";
import React, { Fragment } from "react";

export const LoadingIssueNames: React.FC<{ portfolioIssues?: boolean }> = ({ portfolioIssues }) => {
  return (
    <Fragment>
      {_.range(10).map((x) => (
        <Paper style={{ marginBottom: "8px", padding: "8px" }} key={x.toString()}>
          <Grid container justifyContent="space-between" spacing={3} alignItems="center">
            <Grid item xs={6}>
              <Skeleton variant="text" width={300} height={22} />
            </Grid>
            {!portfolioIssues ? (
              <Grid item sm={6} xs={12}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Box style={{ textAlign: "center" }}>
                      <CircularProgress size={18} />
                      <Skeleton variant="text" width={50} height={12} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box style={{ textAlign: "center" }}>
                      <CircularProgress size={20} />
                      <Typography variant="h6" color="textSecondary" style={{ fontSize: "12px" }}>
                        Issues Detected
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <Box style={{ textAlign: "center" }}>
                  <CircularProgress size={20} />
                  <Typography variant="h6" color="textSecondary" style={{ fontSize: "12px" }}>
                    Companies At Risk
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Paper>
      ))}
    </Fragment>
  );
};
