import { AttackSurfaceModule } from "./attack_surface/attack_surface.index";
import { CardLeaksModule } from "./cbs/card_leaks/card_leaks.index";
import { CredentialLeaksModule } from "./cbs/credential_leaks/credential_leaks.index";
import { CBSMainDashboard } from "./cbs/dashboard/dashboard.index";
import { UnderConstruction } from "./cbs/dashboard/under_construction";
import { DomainProtectionModule } from "./cbs/domain_protection/domain_protection.index";
import { EntityConfigurationModule } from "./cbs/entity_configuration/entity_configuration.index";
import { RougeMobileAppsModule } from "./cbs/mobile_apps/mobile_apps.index";
import { PhishingModule } from "./cbs/phishing/phishing.index";
// import { CTM360FeedsModule } from "./cbs/situational_awareness/ctm360_feeds/ctm360_feeds.index";
// import { OpenSourceFeedsModule } from "./cbs/situational_awareness/open_source_feeds/open_source_feeds.index";
// import { ThreatActorClaimsModule } from "./cbs/situational_awareness/threat_actor_claims/threat_actor_claims.index";
// import { SocialMediaModule } from "./cbs/social_media/social_media.index";
import { CyberAssetsModule } from "./cyber_assets/cyber_assets.index";
import { MainDashboard } from "./dashboard/dashboard.index";
import { DmarcAggregateReportsIndex } from "./dmarc/dmarc-analytics/aggregate-reports/aggregate-reports.index";
import { DmarcAnalyticsController } from "./dmarc/dmarc-analytics/dmarc-analytics.conroller";
import { DmarcMainDomains } from "./dmarc/environment/domains/domains.index";
import { EnvironmentController } from "./dmarc/environment/environment.controller";
import { DmarcIssuesController } from "./dmarc/environment/issues/issues.index";
import { DmarcDomainSourceDetails } from "./dmarc/environment/sources/sources.index";
import { DmarcDashboard } from "./dmarc/summary/dashboard/dashboard.index";
import { SummaryController } from "./dmarc/summary/summary.controller";
import { EnvironmentsModule } from "./enviornments/environments.index";
import { IssuesModule } from "./issues/issues.index";
import { IApplicationModules } from "./module.interface";
import { NetworkGraphController } from "./network-graph/network-graph.controller";
import { RiskProfileModule } from "./risk_profile/risk_profile.index";
import { SecurityRatingModule } from "./security_rating/security_rating.index";
import { TechnologiesModule } from "./technologies/technologies.index";
export const HackerViewModules: IApplicationModules[] = [
  {
    name: "dashboard",
    title: "Dashboard",
    icon: "dashboard",
    hidden: false,
    path: "/hackerview/dashboard",
    isBeta: false,
    cyno_policy_key: "Dashboard",
    master: false,
    controller: MainDashboard,
    subModules: [],
    groupName: "summary",
    showInSidebar: true,
    enabled: true,
  },
  {
    name: "attack_surface",
    title: "Attack Surface",
    icon: "track_changes",
    hidden: false,
    path: "/hackerview/attack_surface",
    isBeta: false,
    master: true,
    cyno_policy_key: "Dashboard",
    controller: AttackSurfaceModule,
    groupName: "attack_surface",
    renderSubModulesOnly: true,
    showInSidebar: false,
    subModules: [
      {
        name: "asset_map",
        title: "Network",
        icon: "keyboard_command_key",
        hidden: false,
        path: "/hackerview/attack_surface/asset_map",
        isBeta: false,
        cyno_policy_key: "Network",
        controller: NetworkGraphController,
        master: false,
        subModules: [],
        groupName: "attack_surface",
        showInSidebar: true,
        enabled: true,
      },
      {
        name: "environments",
        title: "Environments",
        icon: "travel_explore",
        hidden: false,
        path: "/hackerview/attack_surface/environments",
        isBeta: false,
        cyno_policy_key: "Environment",
        controller: EnvironmentsModule,
        master: false,
        subModules: [],
        groupName: "attack_surface",
        showInSidebar: true,
        enabled: true,
      },
      {
        name: "technologies",
        title: "Technologies",
        icon: "rocket_launch",
        hidden: false,
        path: "/hackerview/attack_surface/technologies",
        isBeta: false,
        cyno_policy_key: "Technologies",
        controller: TechnologiesModule,
        master: false,
        subModules: [],
        groupName: "attack_surface",
        showInSidebar: true,
        enabled: true,
      },
      {
        name: "assets",
        title: "Assets",
        icon: "verified_user",
        hidden: false,
        path: "/hackerview/attack_surface/assets",
        isBeta: false,
        cyno_policy_key: "Assets",
        controller: CyberAssetsModule,
        master: false,
        groupName: "attack_surface",
        showInSidebar: true,
        subModules: [],
        enabled: true,
      },
    ],
  },
  {
    name: "risk_profile",
    title: "Risk Profile",
    icon: "nearby_error",
    hidden: false,
    path: "/hackerview/risk_profile",
    isBeta: false,
    cyno_policy_key: "Dashboard",
    master: false,
    controller: RiskProfileModule,
    subModules: [],
    groupName: "attack_surface",
    showInSidebar: true,
    enabled: true,
  },
  {
    name: "issues",
    title: "Issues",
    icon: "warning",
    hidden: false,
    path: "/hackerview/issues",
    isBeta: false,
    cyno_policy_key: "Issues",
    master: false,
    controller: IssuesModule,
    subModules: [],
    groupName: "attack_surface",
    showInSidebar: true,
    enabled: true,
  },
  {
    name: "security_rating",
    title: "Security Rating",
    icon: "military_tech",
    hidden: false,
    path: "/hackerview/security_rating",
    isBeta: false,
    cyno_policy_key: "Dashboard",
    master: false,
    controller: SecurityRatingModule,
    subModules: [],
    groupName: "3rd Party Risk",
    showInSidebar: true,
    enabled: true,
  },
  {
    name: "thirdpartyrisk",
    title: "3rd Party Risk",
    icon: "functions",
    hidden: false,
    path: "/hackerview/thirdpartyrisk",
    isBeta: false,
    cyno_policy_key: "Third Party Risk",
    master: false,
    controller: MainDashboard,
    groupName: "3rd Party Risk",
    renderSubModulesOnly: true,
    showInSidebar: false,
    subModules: [
      {
        name: "summary",
        title: "Summary",
        icon: "description",
        hidden: false,
        path: "/hackerview/thirdpartyrisk/portfolio/summary",
        isBeta: false,
        cyno_policy_key: "Executive Summary",
        master: false,
        controller: MainDashboard,
        subModules: [],
        groupName: "3rd Party Risk",
        showInSidebar: true,
      },
      {
        name: "security_profile",
        title: "Security Profile",
        icon: "local_police",
        hidden: false,
        path: "/hackerview/thirdpartyrisk/portfolio/security_profile",
        isBeta: false,
        cyno_policy_key: "Security Profile",
        master: false,
        controller: MainDashboard,
        subModules: [],
        groupName: "3rd Party Risk",
        showInSidebar: true,
      },
      {
        name: "risk_profile",
        title: "Risk Profile",
        icon: "nearby_error",
        hidden: false,
        path: "/hackerview/thirdpartyrisk/portfolio/risk_profile",
        isBeta: false,
        cyno_policy_key: "Dashboard",
        master: false,
        controller: MainDashboard,
        subModules: [],
        groupName: "3rd Party Risk",
        showInSidebar: true,
      },
      {
        name: "issues",
        title: "Issues",
        icon: "warning",
        hidden: false,
        path: "/hackerview/thirdpartyrisk/portfolio/issues",
        isBeta: false,
        cyno_policy_key: "Dashboard",
        master: false,
        controller: MainDashboard,
        subModules: [],
        groupName: "3rd Party Risk",
        showInSidebar: true,
      },
      {
        name: "companies",
        title: "Companies",
        icon: "business",
        hidden: false,
        path: "/hackerview/thirdpartyrisk/portfolio/companies",
        isBeta: false,
        cyno_policy_key: "Vendors",
        master: false,
        controller: MainDashboard,
        subModules: [],
        groupName: "3rd Party Risk",
        showInSidebar: true,
      },
      {
        name: "comparison",
        title: "Comparison",
        icon: "donut_small",
        hidden: false,
        path: "/hackerview/thirdpartyrisk/portfolio/comparison",
        isBeta: false,
        cyno_policy_key: "Vendors",
        master: false,
        controller: MainDashboard,
        subModules: [],
        groupName: "3rd Party Risk",
        showInSidebar: true,
      },
    ],
  },
  {
    name: "global_benchmark",
    title: "Global Benchmark",
    icon: "functions",
    hidden: false,
    path: "/hackerview/benchmark",
    isBeta: false,
    cyno_policy_key: "Third Party Risk",
    master: false,
    controller: MainDashboard,
    groupName: "Global Benchmark",
    renderSubModulesOnly: true,
    showInSidebar: false,
    subModules: [
      {
        name: "summary",
        title: "Summary",
        icon: "description",
        hidden: false,
        path: "/hackerview/benchmark/portfolio/summary",
        isBeta: false,
        cyno_policy_key: "Executive Summary",
        master: false,
        controller: MainDashboard,
        subModules: [],
        groupName: "Global Benchmark",
        showInSidebar: true,
      },
      {
        name: "security_profile",
        title: "Security Profile",
        icon: "local_police",
        hidden: false,
        path: "/hackerview/benchmark/portfolio/security_profile",
        isBeta: false,
        cyno_policy_key: "Security Profile",
        master: false,
        controller: MainDashboard,
        subModules: [],
        groupName: "Global Benchmark",
        showInSidebar: true,
      },
      {
        name: "risk_profile",
        title: "Risk Profile",
        icon: "nearby_error",
        hidden: false,
        path: "/hackerview/benchmark/portfolio/risk_profile",
        isBeta: false,
        cyno_policy_key: "Security Profile",
        master: false,
        controller: MainDashboard,
        subModules: [],
        groupName: "Global Benchmark",
        showInSidebar: true,
      },
      {
        name: "companies",
        title: "Companies",
        icon: "business",
        hidden: false,
        path: "/hackerview/benchmark/portfolio/companies",
        isBeta: false,
        cyno_policy_key: "Vendors",
        master: false,
        controller: MainDashboard,
        showInSidebar: true,
        subModules: [],
        groupName: "Global Benchmark",
      },
    ],
  },
  {
    name: "reporting",
    title: "Reporting",
    icon: "document_scanner",
    hidden: false,
    path: "/hackerview/reporting",
    isBeta: false,
    cyno_policy_key: "Reports",
    master: false,
    controller: MainDashboard,
    subModules: [],
    groupName: "summary",
    showInSidebar: true,
  },
  {
    name: "change_detection",
    title: "AssetWatch",
    icon: "center_focus_strong",
    hidden: false,
    path: "/hackerview/assetWatch",
    isBeta: false,
    cyno_policy_key: "Dashboard",
    master: false,
    controller: MainDashboard,
    subModules: [],
    groupName: "attack_surface",
    showInSidebar: true,
  },

  {
    name: "settings",
    title: "Settings",
    icon: "settings",
    hidden: false,
    path: "/hackerview/settings",
    isBeta: false,
    cyno_policy_key: "Settings",
    master: true,
    controller: MainDashboard,
    groupName: "Settings",
    renderSubModulesOnly: true,
    showInSidebar: false,
    subModules: [],
  },

  {
    name: "integrations",
    title: "Integrations",
    //icon: "add_to_home_screen_icon",
    icon: "webhook",
    hidden: false,
    cyno_policy_key: "Integrations",
    path: "/hackerview/integrations",
    isBeta: false,
    master: false,
    controller: MainDashboard,
    subModules: [],
    groupName: "Settings",
    showInSidebar: true,
  },
  {
    name: "notifications",
    title: "Notifications",
    icon: "notifications",
    hidden: false,
    path: "/hackerview/notifications",
    isBeta: false,
    cyno_policy_key: "Notifications",
    master: true,
    controller: MainDashboard,
    groupName: "Settings",
    renderSubModulesOnly: false,
    showInSidebar: true,
    subModules: [
      {
        name: "subscriptions",
        title: "Subscriptions",
        icon: "notifications",
        hidden: false,
        path: "/hackerview/notifications/subscriptions",
        isBeta: false,
        cyno_policy_key: "Subscriptions",
        controller: MainDashboard,
        master: false,
        subModules: [],
        showInSidebar: false,
      },
      {
        name: "all_notifications",
        title: "Notifications",
        icon: "notifications",
        hidden: false,
        path: "/hackerview/notifications/all_notifications",
        isBeta: false,
        cyno_policy_key: "All Notifications",
        controller: MainDashboard,
        master: false,
        subModules: [],
        showInSidebar: false,
      },
    ],
  },
];
export const CBSModules: IApplicationModules[] = [
  {
    name: "threat_manager",
    title: "Threat Manager",
    icon: "warning",
    hidden: false,
    path: "/cbs/threat_manager/dashboard",
    isBeta: false,
    cyno_policy_key: "Threat Manager",
    master: true,
    controller: CBSMainDashboard,
    showInSidebar: false,
    groupName: "threat_manager",
    enabled: true,
    subModules: [
      {
        name: "dashboard",
        title: "Dashboard",
        icon: "dashboard",
        hidden: false,
        path: "/cbs/threat_manager/dashboard",
        isBeta: false,
        cyno_policy_key: "Threat Manager",
        master: false,
        controller: CBSMainDashboard,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: true,
      },
      {
        name: "incidents",
        title: "Incidents",
        icon: "report_problem",
        hidden: false,
        path: "/cbs/threat_manager/incidents",
        isBeta: false,
        cyno_policy_key: "Incidents",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: false,
      },
      {
        name: "credential_leaks",
        title: "Credential Leaks",
        icon: "password",
        hidden: false,
        path: "/threat_manager/credential_leaks",
        isBeta: false,
        cyno_policy_key: "Incidents",
        master: false,
        controller: CredentialLeaksModule,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: true,
      },
      {
        name: "card_leaks",
        title: "Card Leaks",
        icon: "credit_card",
        hidden: false,
        path: "/threat_manager/card_leaks",
        isBeta: false,
        cyno_policy_key: "Incidents",
        master: false,
        controller: CardLeaksModule,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: true,
      },
      {
        name: "domain_protection",
        title: "Domain Protection",
        icon: "verified_user",
        hidden: false,
        path: "/threat_manager/domain_protection",
        isBeta: false,
        cyno_policy_key: "Incidents",
        master: false,
        controller: DomainProtectionModule,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: true,
      },
      {
        name: "phishing",
        title: "Phishing",
        icon: "phishing",
        hidden: false,
        path: "/threat_manager/phishing",
        isBeta: false,
        cyno_policy_key: "Incidents",
        master: false,
        controller: PhishingModule,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: false,
      },
      {
        name: "mobile_apps",
        title: "Rogue Mobile Apps",
        icon: "android",
        hidden: false,
        path: "/threat_manager/mobile_apps",
        isBeta: false,
        cyno_policy_key: "Incidents",
        master: false,
        controller: RougeMobileAppsModule,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: true,
      },
      {
        name: "data_leaks",
        title: "Data Leaks",
        icon: "developer_mode",
        hidden: false,
        path: "/threat_manager/data_leaks",
        isBeta: false,
        cyno_policy_key: "Incidents",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: false,
      },

      {
        name: "social_media_findings",
        title: "Social Media Fraud",
        icon: "thumb_up",
        hidden: false,
        path: "/threat_manager/social_media_findings",
        isBeta: false,
        cyno_policy_key: "Incidents",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: false,
      },

      {
        name: "reporting",
        title: "Reporting",
        icon: "document_scanner",
        hidden: false,
        path: "/cbs/threat_manager/reporting",
        isBeta: false,
        cyno_policy_key: "Reporting",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: false,
      },
      {
        name: "entity_configuration",
        title: "Configuration",
        icon: "settings_suggest",
        hidden: false,
        path: "/threat_manager/entity_configuration",
        isBeta: false,
        cyno_policy_key: "Incidents",
        master: false,
        controller: EntityConfigurationModule,
        subModules: [],
        showInSidebar: true,
        groupName: "threat_manager",
        enabled: true,
      },
    ],
  },
  // {
  //   name: "situational_awareness",
  //   title: "Situational Awareness",
  //   icon: "local_library",
  //   hidden: false,
  //   path: "/cbs/situational_awareness/my_feeds",
  //   isBeta: false,
  //   cyno_policy_key: "Threat Manager",
  //   master: true,
  //   controller: OpenSourceFeedsModule,
  //   showInSidebar: false,
  //   enabled: true,
  //   groupName: "SITUATIONAL AWARENESS",
  //   subModules: [
  //     {
  //       name: "open_source_feeds",
  //       title: "Open-source Feeds",
  //       icon: "newspaper",
  //       hidden: false,
  //       path: "/cbs/situational_awareness/open_source_feeds",
  //       isBeta: false,
  //       cyno_policy_key: "Dashboard",
  //       master: false,
  //       controller: OpenSourceFeedsModule,
  //       subModules: [],
  //       showInSidebar: true,
  //       enabled: true,
  //       groupName: "SITUATIONAL AWARENESS",
  //     },
  //     {
  //       name: "threat_actor_claims",
  //       title: "Threat Actor Claims",
  //       icon: "groups",
  //       hidden: false,
  //       path: "/cbs/situational_awareness/threat_actor_claims",
  //       isBeta: false,
  //       cyno_policy_key: "Dashboard",
  //       master: false,
  //       controller: ThreatActorClaimsModule,
  //       subModules: [],
  //       showInSidebar: true,
  //       enabled: true,
  //       groupName: "SITUATIONAL AWARENESS",
  //     },
  //     // {
  //     //   name: "ctm360_feeds",
  //     //   title: "CTM360 Feeds",
  //     //   icon: "loyalty",
  //     //   hidden: false,
  //     //   path: "/cbs/situational_awareness/ctm360_feeds",
  //     //   isBeta: false,
  //     //   cyno_policy_key: "Dashboard",
  //     //   master: false,
  //     //   controller: CTM360FeedsModule,
  //     //   subModules: [],
  //     //   showInSidebar: true,
  //     //   enabled: true,
  //     //   groupName: "SITUATIONAL AWARENESS",
  //     // },
  //   ],
  // },
  {
    name: "investigations",
    title: "Investigations",
    icon: "local_library",
    hidden: false,
    path: "/cbs/investigations/deepscout",
    isBeta: false,
    cyno_policy_key: "Threat Manager",
    master: true,
    controller: UnderConstruction,
    showInSidebar: false,
    enabled: false,
    groupName: "INVESTIGATIONS",
    subModules: [
      {
        name: "deepscout",
        title: "Deepscout",
        icon: "tips_and_updates",
        hidden: false,
        path: "/cbs/investigations/deepscout",
        isBeta: false,
        cyno_policy_key: "Deepscout",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "INVESTIGATIONS",
      },
      {
        name: "analysis",
        title: "Analysis",
        icon: "biotech",
        hidden: false,
        path: "/cbs/investigations/analysis",
        isBeta: false,
        cyno_policy_key: "Analysis",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "INVESTIGATIONS",
      },
    ],
  },
  {
    name: "tailored_intelligence",
    title: "Tailored Intelligence",
    icon: "adb",
    hidden: false,
    path: "/cbs/tailored_intelligence/clear_web",
    isBeta: false,
    cyno_policy_key: "Tailored Intelligence",
    master: true,
    controller: UnderConstruction,
    showInSidebar: false,
    enabled: false,
    groupName: "TAILORED INTELLIGENCE",
    subModules: [
      {
        name: "clear_web",
        title: "Clear Web",
        icon: "touch_app",
        hidden: false,
        path: "/cbs/tailored_intelligence/clear_web",
        isBeta: false,
        cyno_policy_key: "Clear Web",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "TAILORED INTELLIGENCE",
      },
      {
        name: "deep_web",
        title: "Deep Web",
        icon: "compress",
        hidden: false,
        path: "/cbs/tailored_intelligence/deep_web",
        isBeta: false,
        cyno_policy_key: "Deep Web",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "TAILORED INTELLIGENCE",
      },
      {
        name: "dark_web",
        title: "Dark Web",
        icon: "layers",
        hidden: false,
        path: "/cbs/tailored_intelligence/dark_web",
        isBeta: false,
        cyno_policy_key: "Dark Web",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "TAILORED INTELLIGENCE",
      },
    ],
  },
  {
    name: "internet_intelligence",
    title: "Internet Intelligence",
    icon: "psychology",
    hidden: false,
    path: "/cbs/internet_intelligence/url_intelligence",
    isBeta: false,
    cyno_policy_key: "Internet Intelligence",
    master: true,
    controller: UnderConstruction,
    showInSidebar: true,
    enabled: false,
    groupName: "INTERNET INTELLIGENCE",

    subModules: [
      {
        name: "url_intelligence",
        title: "URL Intelligence",
        icon: "terminal",
        hidden: false,
        path: "/cbs/internet_intelligence/url_intelligence",
        isBeta: false,
        cyno_policy_key: "URL Intelligence",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "INTERNET INTELLIGENCE",
      },

      {
        name: "ssl_certificates",
        title: "SSL Certificates",
        icon: "verified_user",
        hidden: false,
        path: "/cbs/internet_intelligence/ssl_certificates",
        isBeta: false,
        cyno_policy_key: "SSL Certificates",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "INTERNET INTELLIGENCE",
      },
      {
        name: "data_breaches",
        title: "Data Breaches",
        icon: "save",
        hidden: false,
        path: "/cbs/internet_intelligence/data_breaches",
        isBeta: false,
        cyno_policy_key: "Data Breaches",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "INTERNET INTELLIGENCE",
      },

      {
        name: "carding_shops",
        title: "Carding Shops",
        icon: "payment",
        hidden: false,
        path: "/cbs/internet_intelligence/carding_shops",
        isBeta: false,
        cyno_policy_key: "Carding Shops",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "INTERNET INTELLIGENCE",
      },
      {
        name: "intellistream",
        title: "Intellistream",
        icon: "psychology",
        hidden: false,
        path: "/cbs/internet_intelligence/intellistream",
        isBeta: false,
        cyno_policy_key: "Intellistream",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "INTERNET INTELLIGENCE",
      },
    ],
  },
];

export const DMARCModules: IApplicationModules[] = [
  {
    name: "summary",
    title: "Summary",
    icon: "dashboard",
    hidden: false,

    path: "/dmarc/dashboard",
    isBeta: false,
    cyno_policy_key: "Dashboard",
    master: true,
    controller: SummaryController,
    enabled: true,
    showInSidebar: false,
    groupName: "summary",
    subModules: [
      {
        name: "dashboard",
        title: "Dashboard",
        icon: "dashboard",
        hidden: false,
        path: "/dmarc/dashboard",
        isBeta: false,
        cyno_policy_key: "Dashboard",
        master: false,
        controller: DmarcDashboard,
        subModules: [],
        groupName: "summary",
        enabled: true,
        showInSidebar: true,
      },
      {
        name: "reporting",
        title: "Reporting",
        icon: "document_scanner",
        hidden: false,

        path: "/dmarc/reporting",
        isBeta: false,
        cyno_policy_key: "Indepth Analytics",
        master: false,
        controller: UnderConstruction,
        subModules: [],
        showInSidebar: true,
        enabled: false,
        groupName: "summary",
      },
    ],
  },

  {
    name: "environment",
    title: "Environment",
    icon: "domain",
    hidden: false,

    path: "/dmarc/environment",
    isBeta: false,
    cyno_policy_key: "Domain Info",
    master: true,
    controller: EnvironmentController,
    enabled: true,
    groupName: "environment",
    showInSidebar: false,
    subModules: [
      {
        name: "domainInfo",
        title: "Domains",
        icon: "domain",
        hidden: false,

        path: "/dmarc/environment/domains",
        isBeta: false,
        cyno_policy_key: "Domain Info",
        master: false,
        controller: DmarcMainDomains,
        subModules: [],
        groupName: "environment",
        showInSidebar: true,
        enabled: true,
      },
      {
        name: "emailsources",
        title: "Sources",
        icon: "move_to_inbox",
        hidden: false,

        path: "/dmarc/environment/emailsources",
        isBeta: false,
        cyno_policy_key: "Email Sources",
        master: false,
        // controller: EmailSources,
        controller: DmarcDomainSourceDetails,
        subModules: [],
        showInSidebar: true,
        enabled: true,
        groupName: "environment",
      },
      {
        name: "issues",
        title: "Issues",
        icon: "track_changes",
        hidden: false,

        path: "/dmarc/environment/issues",
        isBeta: false,
        cyno_policy_key: "Issues",
        master: false,
        controller: DmarcIssuesController,
        subModules: [],
        showInSidebar: true,
        enabled: true,
        groupName: "environment",
      },
    ],
  },

  //   //dmarc analytics
  {
    name: "dmarc_analytics",
    title: "DMARC ANALYTICS",
    icon: "analytics",
    hidden: false,
    showInSidebar: false,
    groupName: "dmarc_analytics",
    path: "/dmarc/analytics",
    isBeta: false,
    cyno_policy_key: "Indepth Analytics",
    master: true,
    controller: DmarcAnalyticsController,
    enabled: true,
    subModules: [
      {
        name: "IndepthAnalytics",
        title: "Aggregate Reports",
        icon: "analytics",
        hidden: false,

        path: "/dmarc/analytics",
        isBeta: false,
        cyno_policy_key: "Indepth Analytics",
        master: false,
        controller: DmarcAggregateReportsIndex,
        enabled: true,
        subModules: [],
        showInSidebar: true,
        groupName: "dmarc_analytics",
      },

      {
        name: "ForensicReports",
        title: "Forensics Reports",
        icon: "content_paste_search",
        hidden: false,

        path: "/dmarc/ForensicReports",
        isBeta: false,
        cyno_policy_key: "Forensic Reports",
        master: false,
        controller: UnderConstruction,
        enabled: false,
        subModules: [],
        showInSidebar: true,
        groupName: "dmarc_analytics",
      },
    ],
  },

  {
    name: "beyond_dmarc",
    title: "Beyond DMARC",
    icon: "travel_explore",
    hidden: false,
    path: "/beyond_dmarc",
    isBeta: true,
    cyno_policy_key: "Beyond Dmarc",
    master: true,
    controller: UnderConstruction,
    enabled: false,

    groupName: "beyond_dmarc",
    renderSubModulesOnly: true,
    showInSidebar: false,
    subModules: [
      {
        name: "DNS Lookup",
        title: "DNS Lookup",
        icon: "travel_explore",
        hidden: false,
        path: "/beyond_dmarc/dns",
        isBeta: false,
        cyno_policy_key: "DNS Lookup",
        master: false,

        controller: UnderConstruction,
        enabled: false,
        subModules: [],
        showInSidebar: true,
        groupName: "beyond_dmarc",
      },
      {
        name: "Beyond Issues",
        title: "MATTERS",
        icon: "track_changes",
        hidden: false,
        path: "/beyond_dmarc/issues",
        isBeta: false,
        cyno_policy_key: "Additional Issues",
        master: false,
        controller: UnderConstruction,
        enabled: false,
        subModules: [],
        showInSidebar: true,
        groupName: "beyond_dmarc",
      },
    ],
  },

  //3rd party risk
  {
    name: "3rd_party_risk",
    title: "3RD Party Risk",
    icon: "travel_explore",
    hidden: false,
    path: "/GlobalBenchmark",
    isBeta: true,
    cyno_policy_key: "Third Party Risk",
    master: true,
    controller: UnderConstruction,
    enabled: false,

    groupName: "3rd_party_risk",
    renderSubModulesOnly: true,
    showInSidebar: false,
    subModules: [
      {
        name: "ThirdPartyRiskManagement",
        title: "Summary",
        icon: "description",
        hidden: false,
        path: "/ThirdPartyRisk/Summary",
        isBeta: false,
        cyno_policy_key: "Forensic Reports",
        master: false,

        controller: UnderConstruction,
        enabled: false,
        subModules: [],
        showInSidebar: true,
        groupName: "3rd_party_risk",
      },
    ],
  },
  //global benchmark
  {
    name: "global_benchmark",
    title: "Global Benchmark",
    icon: "travel_explore",
    hidden: false,
    path: "/GlobalBenchmark",
    isBeta: true,
    cyno_policy_key: "Global Benchmark",
    master: true,
    controller: UnderConstruction,
    enabled: false,

    groupName: "global_benchmark",
    renderSubModulesOnly: true,
    showInSidebar: false,
    subModules: [
      {
        name: "Summary",
        title: "Summary",
        icon: "description",
        hidden: false,
        path: "/GlobalBenchmark/Summary",
        isBeta: false,
        cyno_policy_key: "Global Benchmark",
        master: false,
        controller: UnderConstruction,
        enabled: false,
        subModules: [],
        showInSidebar: true,

        groupName: "global_benchmark",
      },
    ],
  },

  //settings

  {
    name: "CustomReports",
    title: "SETTINGS",
    icon: "document_scanner",
    hidden: false,
    path: "/CustomReports",
    isBeta: false,
    cyno_policy_key: "Custom Reports",
    master: true,
    controller: UnderConstruction,
    enabled: false,
    subModules: [
      {
        name: "Notifications",
        title: "Notifications",
        icon: "notifications",
        hidden: false,
        path: "/Notifications/:id",
        isBeta: false,
        cyno_policy_key: "Forensic Reports",
        master: false,
        controller: UnderConstruction,
        enabled: false,
        subModules: [],

        showInSidebar: true,
        groupName: "settings",
      },
    ],

    showInSidebar: false,
    groupName: "settings",
  },
];

export const receiveModules = () => {
  return {
    hacker_view: HackerViewModules,
    cbs: CBSModules,
    dmarc: DMARCModules,
  };
};
