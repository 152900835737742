import { Icon, Tooltip } from "@mui/material";
import React, { Fragment } from "react";

export const TooltipIcon = ({
  label,
  icon,
  color,
  value,
  removeComma,
}: {
  removeComma?: boolean;
  label: string;
  icon: string;
  color:
    | "inherit"
    | "disabled"
    | "action"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  value: string;
}) => {
  return (
    <Fragment>
      <Tooltip
        className="icon-tool-tip"
        style={{ margin: "0 3px" }}
        title={`${label} ${removeComma ? "" : ":"} ${value}`}
      >
        <Icon color={color}>{icon}</Icon>
      </Tooltip>
    </Fragment>
  );
};
