import CreditCard from "credit-card-type";
import masterCardLogo from "../assets/images/mastercard.svg";
import visaCardLogo from "../assets/images/visa.svg";
import amexLogo from "../assets/images/amex.svg";
import dinersClubLogo from "../assets/images/dinersclub.svg";
import discoverLogo from "../assets/images/discover.svg";
import jcbLogo from "../assets/images/jcb.svg";
import unionPayLogo from "../assets/images/unionpay.svg";
import maestroLogo from "../assets/images/maestro.svg";
import eloLogo from "../assets/images/elo.svg";
import hiperLogo from "../assets/images/hipercard.svg";

export const CreditCardFormatter = (value: string, separator?: string): string => {
  const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || "";
  const parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join(separator ? separator : " ");
  } else {
    return value;
  }
};
export const CreditCardVendor = (value: string) => {
  return CreditCard(value)[0]?.type || "";
};
export const CreditCardLogo = (value: string) => {
  const vendor = CreditCard(value)[0]?.type || "";
  switch (vendor) {
    case "mastercard":
      return masterCardLogo;
    case "visa":
      return visaCardLogo;
    case "american-express":
      return amexLogo;
    case "diners-club":
      return dinersClubLogo;
    case "discover":
      return discoverLogo;
    case "jcb":
      return jcbLogo;
    case "unionpay":
      return unionPayLogo;
    case "maestro":
      return maestroLogo;

    case "elo":
      return eloLogo;
    case "hiper":
    case "hipercard":
      return hiperLogo;

    default:
      return masterCardLogo;
  }
};
