import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { IUserProfile } from "../App.interface";
import { AppKeyCloak } from "../auth/Keycloak";
import { AESEncryption } from "../util/md5.hash";
import { getActiveTheme, getSourceVersion, getUserAttributes } from "../util/user.attributes";
import { logout } from "./auth.service";
import { SelectedMemberId } from "../util/current.member.id";
import { captureException } from "@sentry/react";

const userProfile = (): IUserProfile => {
  const theme = getActiveTheme();
  const sourceVersion = getSourceVersion();
  const navigator = window.navigator;
  const userAttributes = getUserAttributes();
  return {
    browser: navigator.vendor ? navigator.vendor : "Mozilla Firefox",
    platform: navigator.platform,
    code_version: sourceVersion,
    user_theme: theme,
    utc_offset: moment(new Date()).utcOffset(),
    user_designation: userAttributes.designation,
    user_organization: userAttributes.organization,
  };
};
const baseURL = process.env.REACT_APP_API_URL;
if (!baseURL) throw new Error("Required parameter APP_BASE_URL not set");
axios.defaults.baseURL = `${baseURL}/api`;
axios.interceptors.request.use(async (req) => {
  let AppKeyCloakToken = localStorage.getItem(
    `${process.env.REACT_APP_SSO_CLIENT_ID}.sso.authtoken`
  );
  if (AppKeyCloak.isTokenExpired(60)) {
    await AppKeyCloak.updateToken(60);
    AppKeyCloakToken = AppKeyCloak.token
      ? AppKeyCloak.token
      : localStorage.getItem(`${process.env.REACT_APP_SSO_CLIENT_ID}.sso.authtoken`);
  }
  req.headers.auth = AppKeyCloakToken;
  req.headers.user = AESEncryption(JSON.stringify(userProfile()));
  req.timeout = req.timeout ? req.timeout : 30 * 1000; // 30s
  let selectedBrands: number[] = SelectedMemberId();
  let overrideBrands;
  try {
    if (req.data) {
      if (req.data.memberIds) {
        overrideBrands = req.data.memberIds;
      } else {
        overrideBrands = undefined;
      }
    }
  } catch (err) {
    selectedBrands = [];
    overrideBrands = [];
  }
  overrideBrands
    ? _.set(req, "data.memberIds", overrideBrands)
    : _.set(req, "data.memberIds", selectedBrands);
  return req;
});
axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error) {
      captureException(error);
      if (error.response) {
        if (error.response.status === 401) {
          logout();
        }
      }
      // if (axios.isCancel(error)) {
      //   console.warn("Forcefully changed routing");
      // }
      if (error.isAxiosError) {
        return Promise.reject({ errors: error.response.data.errors });
      }
    }
  }
);
export default axios;
