import { IKeyCloakToken } from "../auth/Keycloak";
import { IAppPortalPolicy, IUser } from "../state/user";

const isInternalUser = (role: string): boolean => {
  if (role.includes("INTERNAL_ADMIN") || role.includes("INTERNAL_SUPER_ADMIN")) {
    return true;
  } else {
    return false;
  }
};
export const ParseUserAttributes = (params: IKeyCloakToken) => {
  const user: IUser = {
    email: params.email,
    name: params.name,
    family_name: params.family_name,
    given_name: params.given_name,
    internal: isInternalUser(params[`${process.env.REACT_APP_SSO_CLIENT_ID}_policy`].role),
    user_role: params[`${process.env.REACT_APP_SSO_CLIENT_ID}_policy`].role,
    designation: params.designation,
    organization: params.organization,
    multi_brand: params.ted_ids.length > 1 ? true : false,
    realm_resource_id: params.azp,
    portal_policy: params[`${process.env.REACT_APP_SSO_CLIENT_ID}_policy`] as IAppPortalPolicy,
    email_verified: params.email_verified,
    member_groups: params.member_groups,
    preferred_username: params.preferred_username,
    ted_ids: params.ted_ids,
  };
  return user;
};
