import includes from "lodash/includes";
import toLower from "lodash/toLower";

export const convertBase64 = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
export const MimeBase64 = (ETag: string): string => {
  if (includes(toLower(ETag), ".txt")) {
    return "text/plain";
  } else if (includes(toLower(ETag), ".jpg") || includes(toLower(ETag), ".jpeg")) {
    return "image/jpeg";
  } else if (includes(toLower(ETag), ".png")) {
    return "image/png";
  } else if (includes(toLower(ETag), ".eml") || includes(toLower(ETag), ".msg")) {
    return "message/rfc822";
  } else if (includes(toLower(ETag), ".zip")) {
    return "application/x-zip-compressed";
  } else if (includes(toLower(ETag), ".pdf")) {
    return "application/pdf";
  } else {
    return "text/plain";
  }
};
